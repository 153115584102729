import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Widget.scss';
import Svg from 'erpcore/components/Svg';
import ElementLoader from 'erpcore/components/ElementLoader';

class Widget extends Component {
    /**
     *
     * @returns {string}
     */
    handleClassName() {
        const { className, size } = this.props;

        let classNames = 'widget';

        if (className) {
            classNames = `${classNames} ${className}`;
        }

        // Size
        if (size) {
            classNames = `${classNames} widget--${size}`;
        } else {
            classNames = classNames.replace(` widget--${size}`, '');
        }

        return classNames;
    }

    /**
     *
     * @returns {*}
     */
    render() {
        const { title, subtitle, filters, headerAside, children, loading, draggable } = this.props;

        return (
            <div className={this.handleClassName()}>
                {loading === true && <ElementLoader overlay />}
                {draggable && <Svg icon="drag" className="widget__draggable-icon" />}
                {(title || subtitle || filters || headerAside) && (
                    <header className="widget__header">
                        {(title || subtitle || filters) && (
                            <div className="widget__header-main">
                                {title && <h3 className="widget__title">{title}</h3>}
                                {subtitle && <p className="widget__subtitle">{subtitle}</p>}
                                {filters && <div className="widget__filters">{filters}</div>}
                            </div>
                        )}
                        {headerAside && <div className="widget__header-aside">{headerAside}</div>}
                    </header>
                )}
                {children && (
                    <div
                        className="widget__content"
                        onMouseDown={e => e.stopPropagation()}
                        onTouchStart={e => e.stopPropagation()}
                        role="button"
                        tabIndex="0"
                    >
                        {children}
                    </div>
                )}
            </div>
        );
    }
}

Widget.defaultProps = {
    className: '',
    size: null,
    title: null,
    subtitle: null,
    filters: null,
    headerAside: null,
    children: null,
    loading: false,
    draggable: false
};

Widget.propTypes = {
    className: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    filters: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    headerAside: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    loading: PropTypes.bool,
    draggable: PropTypes.bool
};

// region Item

Widget.Item = function Item({ children, className, iconColor, iconName, title }) {
    return (
        <div className={`widget-item ${className}`}>
            {iconName && (
                <div className="widget-item__icon">
                    <Svg icon={iconName} iconColor={iconColor} />
                </div>
            )}
            <div className="widget-item__content">
                {title && <span className="widget-item__title">{title}</span>}
                {children}
            </div>
        </div>
    );
};

Widget.Item.defaultProps = {
    children: null,
    className: '',
    iconColor: null,
    iconName: null,
    title: null
};

Widget.Item.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    iconColor: PropTypes.string,
    iconName: PropTypes.string,
    title: PropTypes.string
};

// endregion

export default Widget;
