import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import Form, { Text } from 'erpcore/components/Form';
import { parseInteger } from 'erpcore/utils/utils';
import Button from 'erpcore/components/Button';
import { valueValidation } from 'erpcore/components/Form/Form.utils';

const EntityChallengesTimerForm = ({
    pristine,
    invalid,
    submitLabel,
    submitting,
    onSubmit,
    handleSubmit
}) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
                <Field
                    name="timer"
                    fieldAttr={{
                        id: 'timer',
                        required: true
                    }}
                    fieldProps={{ label: 'Time in sec' }}
                    component={Text}
                    parse={parseInteger}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Button
                submit
                disabled={pristine || invalid}
                loading={submitting}
                label={submitLabel}
            />
        </Form>
    );
};

EntityChallengesTimerForm.defaultProps = {
    pristine: false,
    invalid: false,
    submitting: false,
    submitLabel: 'Add',
    onSubmit: () => {}
};

EntityChallengesTimerForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
    submitLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default reduxForm({
    form: 'EntityChallengesTimerForm',
    enableReinitialize: true
})(EntityChallengesTimerForm);
