import React from 'react';
import PropTypes from 'prop-types';
import Tabs from 'erpcore/components/Tabs';
import { useSelector } from 'react-redux';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { getSingleEventData } from 'erpcore/screens/Events/Events.selectors';
import { hasPermission } from 'erpcore/utils/RolesManager';

const EventsEditTabs = ({ id }) => {
    const eventData = useSelector(state => getSingleEventData(state, `/api/events/${id}`));
    const meData = useSelector(getMeData) || {};
    const isUserAdmin = hasPermission('CAN_MANAGE_COMMON_EVENT', meData);

    const links = [
        {
            link: `/events/${id}/edit/general`,
            label: 'General'
        },
        {
            link: `/events/${id}/edit/settings`,
            label: 'Settings'
        },
        {
            link: `/events/${id}/edit/participants`,
            label: 'Participants'
        },
        {
            link: `/events/${id}/edit/teams`,
            label: 'Teams'
        },
        {
            link: `/events/${id}/edit/challenges`,
            label: 'Challenges'
        },
        {
            link: `/events/${id}/edit/notification`,
            label: 'Notification'
        },
        {
            link: `/events/${id}/edit/content`,
            label: 'Content'
        },
        {
            link: `/events/${id}/edit/guideline`,
            label: 'Event Guidelines'
        }
    ];

    const completedEventAdminLinks = [
        {
            link: `/events/${id}/edit/settings`,
            label: 'Settings'
        },
        {
            link: `/events/${id}/edit/challenges`,
            label: 'Challenges'
        }
    ];

    if (eventData?.status !== 'completed') {
        return <Tabs links={links} />;
    }

    if (isUserAdmin) {
        return <Tabs links={completedEventAdminLinks} />;
    }

    return null;
};

EventsEditTabs.defaultProps = {
    id: 0
};

EventsEditTabs.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default EventsEditTabs;
