import React, { useEffect, useState } from 'react';
import LayoutManager from 'erpcore/utils/LayoutManager';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import PageNotifications from 'erpcore/components/Layout/PageNotifications';
import PageContent from 'erpcore/components/Layout/PageContent';
import ChallengeCreateEditForm from 'erpcore/screens/Challenges/components/ChallengeCreateEditForm';
import { actions as challengeActions } from 'erpcore/screens/Challenges/Challenges.reducer';
import { useDispatch, useSelector } from 'react-redux';
import {
    formatFormValuesBasedOnType,
    formatAnswersBasedOnType
} from 'erpcore/screens/Challenges/Challenges.utils';
import { useRouteMatch } from 'react-router-dom';
import {
    getSingleChallengeFetching,
    getChallengeData,
    getChallengeTypes
} from 'erpcore/screens/Challenges/Challenges.selectors';
import PageLoader from 'erpcore/components/PageLoader';
import { diff } from 'deep-object-diff';
import { getFormValues } from 'redux-form';

const ChallengeEdit = () => {
    const [selectedType, setSelectedType] = useState(null);
    const match = useRouteMatch();
    const challengeID = match.params?.id;
    const challengeIri = `/api/challenges/${challengeID}`;
    const dispatch = useDispatch();
    const title = 'Edit challenge';
    const fetching = useSelector(getSingleChallengeFetching);
    const challengeData = useSelector(state => getChallengeData(state, challengeIri));
    const formValues = useSelector(state => getFormValues('ChallengeCreateEditForm')(state));
    const challengeTypes = useSelector(getChallengeTypes);
    const { type: _type } = { ...formValues };

    useEffect(() => {
        if (_type) {
            const identifier = challengeTypes?.data?.find(
                singleChallengeType => _type === singleChallengeType?.iri
            );

            setSelectedType(identifier);
        }
    }, [_type]);

    const {
        _type: type,
        answers,
        timer,
        points,
        title: challengeTitle,
        wall,
        description,
        media = []
    } = {
        ...challengeData
    };

    const [mediaItem] = media;

    const imageIri = mediaItem?.media?.iri || undefined;

    const initialValues = {
        type: type?.iri,
        ...formatAnswersBasedOnType(answers, type?._type),
        timer,
        points,
        title: challengeTitle,
        wall,
        description,
        media: imageIri,
        constraint: answers?.constraint
    };

    const fetchSingleChallenge = () => {
        const params = {
            include: 'type,media,media.media'
        };
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                iri: challengeIri,
                type: challengeActions.START_FETCHING_CHALLENGE,
                params
            });
        });
    };

    useEffect(() => {
        fetchSingleChallenge();
    }, []);

    const onSubmit = ({ formData, challengeType }) => {
        const difference = diff(initialValues, formData);
        const values = { ...difference };

        if (difference?.answersTrivia) {
            values.answersTrivia = formData.answersTrivia;
        }

        if (difference?.answersMultiple) {
            values.answersMultiple = formData.answersMultiple;
        }

        if (difference?.answersDate) {
            values.answersDate = formData.answersDate;
            values.constraint = formData.constraint;
        }

        if (difference?.answersSticker) {
            values.answersSticker = formData.answersSticker;
        }

        if (difference?.answersQr) {
            values.answersQr = formData.answersQr;
        }

        if (
            challengeType === 'TYPE_DATE' &&
            (difference.constraint || difference.day || difference.month)
        ) {
            values.constraint = formData.constraint;
        }

        const formatedValues = formatFormValuesBasedOnType(values, challengeType);

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                formData: formatedValues,
                type: challengeActions.START_UPDATE_CHALLENGE,
                iri: challengeIri
            });
        })
            .then(() => {
                fetchSingleChallenge();
            })
            .catch(err => err);
    };

    return (
        <LayoutManager className="main--narrow" slot="main" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title} />
            {fetching && <PageLoader />}
            <PageNotifications />
            <PageContent>
                <ChallengeCreateEditForm
                    form="ChallengeCreateEditForm"
                    onSubmit={onSubmit}
                    submitLabel="Update"
                    initialValues={initialValues}
                    wallDisabled={!selectedType?.wallable}
                />
            </PageContent>
        </LayoutManager>
    );
};

export default ChallengeEdit;
