import { createSelector } from 'reselect';

export const getEmploymentRecordsListingFetching = state =>
    state?.employmentRecords?.listingFetching;

export const getEmploymentRecordsListingResponse = (state, userIri) => {
    if (state?.employmentRecords?.listingResponse?.[userIri]) {
        return state?.employmentRecords?.listingResponse?.[userIri];
    }
    return {
        data: [],
        meta: {}
    };
};

export const getEmploymentRecordsPerUser = (state, userIri) => {
    if (state?.employmentRecords?.listingPerUser?.[userIri]) {
        return state?.employmentRecords?.listingPerUser?.[userIri];
    }
    return {
        data: [],
        meta: {}
    };
};

export const getEmploymentRecordData = (state, iri) => state.employmentRecords?.[iri];

export const getSingleEmploymentRecordFetching = state => state.employmentRecords?.singleFetching;

export default createSelector([
    getEmploymentRecordsListingFetching,
    getEmploymentRecordsListingResponse,
    getEmploymentRecordsPerUser,
    getEmploymentRecordData,
    getSingleEmploymentRecordFetching
]);
