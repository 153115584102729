import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { useDispatch } from 'react-redux';
import Form from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';
import Svg from 'erpcore/components/Svg';
import Tooltip from 'erpcore/components/Tooltip';
import Modal from 'erpcore/components/Modal';
import { actions as customerContactsActions } from 'erpcore/screens/Customers/screens/CustomerContacts/CustomerContacts.reducer';

const CustomerContactDelete = ({ id, handleSubmit, submitting, name, customerIri }) => {
    const dispatch = useDispatch();
    const [opened, setOpened] = useState(false);
    const companyContactIri = `/api/contacts/${id}`;

    const handleModal = () => {
        setOpened(!opened);
    };
    const onSubmit = () => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: customerContactsActions.START_DELETE_CUSTOMER_CONTACT,
                iri: companyContactIri,
                customerIri
            })
        )
            .then(() => {
                handleModal();
            })
            .catch(error => {
                handleModal();
                return error;
            });
    };

    return (
        <Fragment>
            <Tooltip content="Delete contact">
                <button type="button" onClick={() => handleModal()}>
                    <Svg icon="remove" />
                </button>
            </Tooltip>
            <Modal
                variation="small"
                opened={opened}
                onClose={() => handleModal()}
                title="Delete contact"
                subtitle={
                    <>
                        Are you sure you want to delete contact <strong>{name}</strong>?
                        <br />
                        This action is irreversible, record will be permanently deleted.
                    </>
                }
            >
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Button submit loading={submitting} label="Delete" />
                    <Button variation="secondary" label="Cancel" onClick={() => handleModal()} />
                </Form>
            </Modal>
        </Fragment>
    );
};

CustomerContactDelete.defaultProps = {
    submitting: false,
    id: null,
    customerIri: null,
    name: null
};

CustomerContactDelete.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    customerIri: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string
};

export default reduxForm({
    form: 'CustomerContactDeleteForm',
    enableReinitialize: true
})(CustomerContactDelete);
