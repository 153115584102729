import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import Modal from 'erpcore/components/Modal';
import Form, { Text, Checkbox } from 'erpcore/components/Form';
import { valueValidation } from 'erpcore/components/Form/Form.utils';
import { parseInteger } from 'erpcore/utils/utils';
import Button from 'erpcore/components/Button';
import { actions as eventTeamActions } from '../../Teams.reducer';

const ReshuffleTeamsModal = ({
    isOpened,
    eventIri,
    onClose,
    onComplete,
    handleSubmit,
    submitting,
    pristine,
    invalid
}) => {
    const dispatch = useDispatch();
    const [opened, setOpened] = useState(false);

    const handleModal = () => {
        setOpened(!opened);
        if (opened && onClose && typeof onClose === 'function') onClose();
    };

    useEffect(() => {
        setOpened(isOpened);
    }, [isOpened, setOpened]);

    const onSubmit = formData => {
        const { teamSize } = { ...formData };
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: eventTeamActions.START_RESHUFFLE_EVENT_TEAM,
                teamSize,
                eventIri
            })
        )
            .then(() => {
                handleModal();
                if (onComplete && typeof onComplete === 'function') onComplete();
            })
            .catch(error => {
                handleModal();
                return error;
            });
    };

    return (
        <Modal
            opened={opened}
            variation="medium"
            onClose={() => handleModal()}
            title="Reshuffle teams"
        >
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Row>
                    <Field
                        name="teamSize"
                        id="teamSize"
                        fieldProps={{
                            label: 'Members per team',
                            clearable: true
                        }}
                        parse={parseInteger}
                        fieldAttr={{ required: true }}
                        component={Text}
                        validate={valueValidation([
                            { validator: 'required' },
                            { validator: 'numericality' }
                        ])}
                    />
                </Form.Row>
                <Form.Row>
                    <Field
                        name="confirm"
                        id="confirm"
                        fieldProps={{
                            label: 'Confirm that this will reshuffle the teams',
                            value: true
                        }}
                        fieldAttr={{ required: true }}
                        component={Checkbox}
                        validate={valueValidation([{ validator: 'required' }])}
                    />
                </Form.Row>
                <Button submit disabled={pristine || invalid} loading={submitting} label="Submit" />
                <Button variation="secondary" label="Cancel" onClick={() => handleModal()} />
            </Form>
        </Modal>
    );
};

ReshuffleTeamsModal.defaultProps = {
    isOpened: false,
    submitting: false,
    pristine: false,
    invalid: false,
    onClose: null,
    onComplete: () => {}
};

ReshuffleTeamsModal.propTypes = {
    isOpened: PropTypes.bool,
    eventIri: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    onComplete: PropTypes.func
};

export default reduxForm({
    enableReinitialize: true
})(ReshuffleTeamsModal);
