import React from 'react';
import LayoutManager from 'erpcore/utils/LayoutManager';
import { useSelector, useDispatch } from 'react-redux';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import Button from 'erpcore/components/Button';
import TableActions from 'erpcore/components/Listing/components/TableActions';
import Listing from 'erpcore/components/Listing';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import {
    getListingFetching,
    getListingResponse,
    getQueryParams
} from 'erpcore/components/Listing/Listing.selectors';
import Tooltip from 'erpcore/components/Tooltip';
import { NavLink } from 'react-router-dom';
import Svg from 'erpcore/components/Svg';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import ActivitiesDelete from 'erpcore/screens/Activities/components/ActivitiesDelete';
import imagePlaceholder from 'erpcore/assets/images/image-placeholder.png';
import './ActivitiesListing.scss';
import { getImageSrcFromMediaObject } from 'erpcore/components/ImageManager';
import { actions as activityActions } from 'erpcore/screens/Activities/Activities.reducer';
import { getIsDuplicateActivityInProgress } from 'erpcore/screens/Activities/Activities.selectors';

const ActivitiesListing = () => {
    const reducerName = 'activities';
    const actionName = 'ACTIVITIES';
    const dispatch = useDispatch();
    const listing = useSelector(state => getListingResponse(state, reducerName));
    const listingFetching = useSelector(state => getListingFetching(state, reducerName));
    const isDuplicateActivityInProgress = useSelector(getIsDuplicateActivityInProgress);
    const listingParams = useSelector(state =>
        getQueryParams(state, {
            name: reducerName
        })
    );
    const title = 'Templates';

    const fetchActivities = params => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                entity: actionName,
                type: listingActions.START_FETCHING_LISTING,
                name: reducerName,
                params,
                endpoint: 'api/activities?include=type,image,image.versions'
            });
        }).catch(error => {
            return error;
        });
    };

    const duplicateActivity = activityId => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: activityActions.START_DUPLICATE_ACTIVITY,
                activityId
            });
        })
            .then(() => {
                fetchActivities(listingParams);
            })
            .catch(error => {
                return error;
            });
    };

    const tableData = () => {
        const table = {};
        table.data = [];

        table.filters = [
            {
                name: 'type',
                label: 'Type',
                defaultOperator: 'equals',
                filterFields: {
                    value: {
                        component: 'autocomplete',
                        fieldProps: {
                            label: 'Type',
                            options: {
                                endpoint: `/api/activity-types`,
                                mapData: {
                                    value: 'iri',
                                    label: 'name'
                                }
                            },
                            clearable: false
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            }
        ];

        table.schema = [
            {
                title: 'Name',
                field: 'name',
                sortable: 'name',
                mobile: 'title'
            },
            {
                title: 'Description',
                field: 'description'
            },
            {
                title: 'Challenges',
                field: 'challenges'
            },
            {
                title: 'Actions',
                field: 'actions'
            }
        ];

        table.defaultSort = {
            order: 'ASC',
            sortable: 'name'
        };

        if (listing.data) {
            listing.data.map(row => {
                const {
                    id,
                    iri,
                    name,
                    description,
                    challenges_count: challengesCount = 0,
                    deleted,
                    image,
                    _type: type
                } = {
                    ...row
                };

                const smallImageUrl = getImageSrcFromMediaObject(image, 'small');

                return table.data.push({
                    id,
                    iri,
                    name: (
                        <div className="activities-listing__name">
                            <div
                                className={`activities-listing__name-image ${
                                    !smallImageUrl
                                        ? 'activities-listing__name-image--placeholder'
                                        : ''
                                }`}
                            >
                                <img src={smallImageUrl || imagePlaceholder} alt={title} />
                            </div>
                            <div className="activities-listing__name-content">
                                <h4>{name}</h4>

                                <p>{type?.name || '-'}</p>
                            </div>
                        </div>
                    ),
                    description: (
                        <Tooltip content={description}>
                            {description?.length > 90
                                ? `${description?.slice(0, 90)} ...`
                                : description}
                        </Tooltip>
                    ),
                    challenges: challengesCount,
                    actions: (
                        <TableActions>
                            <TableActions.Action>
                                <Tooltip content="Duplicate template">
                                    <button type="button" onClick={() => duplicateActivity(id)}>
                                        <Svg icon="copy" />
                                    </button>
                                </Tooltip>
                            </TableActions.Action>
                            <TableActions.Action>
                                <Tooltip content="Edit template">
                                    <NavLink to={`/activities/${id}/edit/general`}>
                                        <Svg icon="edit" />
                                    </NavLink>
                                </Tooltip>
                            </TableActions.Action>
                            {!deleted && (
                                <TableActions.Action>
                                    <ActivitiesDelete
                                        id={id}
                                        key={`ActivityDelete ${id}`}
                                        callback={() => fetchActivities(listingParams)}
                                    />
                                </TableActions.Action>
                            )}
                        </TableActions>
                    )
                });
            });
        }

        return table;
    };

    return (
        <LayoutManager slot="main" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title}>
                <Button href="/activities/create" label="Create template" />
            </PageHeader>
            <Listing
                name={reducerName}
                reducerName={reducerName}
                loading={listingFetching || isDuplicateActivityInProgress}
                meta={listing?.meta}
                table={tableData()}
                onListingConfigUpdate={params => fetchActivities(params)}
            />
        </LayoutManager>
    );
};

export default ActivitiesListing;
