import React from 'react';
import LayoutManager from 'erpcore/utils/LayoutManager';
import ActivityEditPageHeader from 'erpcore/screens/Activities/screens/ActivitiesEdit/components/ActivityEditPageHeader';
import PageContent from 'erpcore/components/Layout/PageContent';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleActivityData } from 'erpcore/screens/Activities/Activities.selectors';
import { useRouteMatch } from 'react-router-dom';
import { actions as activityActions } from 'erpcore/screens/Activities/Activities.reducer';
import SettingsForm from 'erpcore/screens/Activities/screens/ActivitiesEdit/screens/Settings/components/SettingsForm';

const Settings = () => {
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const id = match?.params?.id;
    const iri = `/api/activities/${id}`;
    const activityData = useSelector(state => getSingleActivityData(state, iri));
    const { settings } = { ...activityData };

    const initialValues = {
        settings: {
            enableHelpRequest: settings?.enableHelpRequest || false,
            chat: settings?.chat || false,
            wall: settings?.wall || false,
            wallPasswordOption: settings?.wallPasswordOption || false,
            wallPassword: settings?.wallPassword || null,
            lockedChanges: settings?.lockedChanges || false,
            validateLockedByChallenges: settings?.validateLockedByChallenges || false,
            welcomeChatMessage:
                settings?.welcomeChatMessage === undefined
                    ? settings?.welcomeChatMessage
                    : settings?.welcomeChatMessage,
            chatMessage: settings?.chatMessage
                ? settings?.chatMessage
                : `Welcome %companyName% to your %activityName%. %activityName% is fun engaging team building activity where you and your team...`
        }
    };

    const onSubmit = formValues => {
        if (!formValues?.welcomeChatMessage) {
            formValues = {
                ...formValues,
                chatMessage: undefined
            };
        }
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: activityActions.START_UPDATE_ACTIVITY,
                formData: {
                    ...formValues
                },
                iri
            });
        });
    };

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <ActivityEditPageHeader />
            <PageContent>
                <SettingsForm onSubmit={onSubmit} initialValues={initialValues} />
            </PageContent>
        </LayoutManager>
    );
};

export default Settings;
