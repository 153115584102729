import { put, call, takeLatest, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import { getQueryParams } from 'erpcore/components/Listing/Listing.selectors';
import { actions as challengeTypesActions } from 'erpcore/screens/Settings/ChallengeTypes/ChallengeTypes.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';

/**
 * Update Challenge Type Icon
 * @param promise
 * @param iri {string}
 * @param formData {object}
 * @param refreshListing {boolean}
 * @returns {Generator<SimpleEffect<"CALL", CallEffectDescriptor<RT | RT | RT>>, void, *>}
 */
export function* updateChallengeTypeIcon({ promise, iri, formData, refreshListing = false }) {
    try {
        yield restClient.patch(iri, formData);
        yield put({
            type: challengeTypesActions.SETTINGS_CHALLENGE_TYPE_ICON_UPDATED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: { code: 'challengeTypes.iconSuccesfullyUpdated' }
        });
        if (refreshListing) {
            const params = yield select(getQueryParams, { name: 'settingsChallengeTypes' });
            yield put({
                type: listingActions.START_FETCHING_LISTING,
                params,
                name: 'settingsChallengeTypes',
                entity: 'SETTINGS_CHALLENGE_TYPES',
                endpoint: 'api/challenge-types?include=icon'
            });
        }
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: challengeTypesActions.SETTINGS_CHALLENGE_TYPE_ICON_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export const challengeTypesSaga = [
    takeLatest(
        challengeTypesActions.START_UPDATING_SETTINGS_CHALLENGE_TYPE_ICON,
        updateChallengeTypeIcon
    )
];
