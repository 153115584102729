import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { actions as authActions } from 'erpcore/utils/AuthManager/AuthManager.reducer';
import { connect } from 'react-redux';

const SignOut = ({ triggerSignOut }) => {
    triggerSignOut();
    return (
        <Fragment>
            <h1>Signin out...</h1>
        </Fragment>
    );
};
SignOut.propTypes = {
    triggerSignOut: PropTypes.func.isRequired
};

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({
    triggerSignOut: () =>
        dispatch({
            type: authActions.START_SIGN_OUT
        })
});
export default connect(mapStateToProps, mapDispatchToProps)(SignOut);
