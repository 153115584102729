import { useMemo } from 'react';
import last from 'lodash/last';
import toInteger from 'lodash/toInteger';
import toString from 'lodash/toString';
import set from 'lodash/set';
import get from 'lodash/get';

const getStandardizedItem = item => {
    const standardizedItem = { ...item };
    standardizedItem.points = !standardizedItem?.points ? 0 : toInteger(standardizedItem.points);

    standardizedItem.completed_challenges_count = !standardizedItem?.completed_challenges_count
        ? 0
        : toInteger(standardizedItem.completed_challenges_count);

    return standardizedItem;
};

const rankKey = 'calculatedRank';

/**
 *
 * @param teams
 * @returns {{anyHasPoints: boolean, all: *[], first: *[], notFirst: *[], groupedByRank: {}}}
 */
// eslint-disable-next-line import/prefer-default-export
const orderTeamsByPoints = (teams = []) => {
    const first = [];
    const notFirst = [];
    const groupedByRank = {};

    // order team array
    const orderedTeams = [...teams].sort((a, b) => {
        const A_RANKS_BETTER = -1;
        const B_RANKS_BETTER = 1;
        const EQUAL_RANK = 0;

        const teamA = { ...getStandardizedItem(a) };
        const teamB = { ...getStandardizedItem(b) };

        const pointDifference = teamA.points - teamB.points;
        const completedChallengesDifference =
            teamA.completed_challenges_count - teamB.completed_challenges_count;

        if (pointDifference > 0) {
            return A_RANKS_BETTER;
        }
        if (pointDifference < 0) {
            return B_RANKS_BETTER;
        }
        if (completedChallengesDifference > 0) {
            return A_RANKS_BETTER;
        }
        if (completedChallengesDifference < 0) {
            return B_RANKS_BETTER;
        }
        return EQUAL_RANK;
    });

    const anyHasPoints = !!orderedTeams?.[0]?.points;

    // add rank to teams
    const orderedRankedTeams = orderedTeams.reduce((accumulator, current) => {
        const standardizedItem = { ...getStandardizedItem(current) };

        if (!accumulator.length) {
            standardizedItem[rankKey] = 1;
        } else {
            const previousTeam = last(accumulator);
            standardizedItem[rankKey] =
                previousTeam.points === standardizedItem.points
                    ? previousTeam[rankKey]
                    : 1 + previousTeam[rankKey];
        }

        // push to orderedRankedTeams
        accumulator.push(standardizedItem);

        // push to groupedByRank
        const rankString = toString(standardizedItem[rankKey]);
        const currentRankGroup = get(groupedByRank, rankString, []);
        set(groupedByRank, `${rankString}`, [...currentRankGroup, standardizedItem]);

        // push to first
        if (anyHasPoints && standardizedItem[rankKey] === 1) {
            first.push(standardizedItem);
        } else {
            notFirst.push(standardizedItem);
        }

        return accumulator;
    }, []);

    return {
        anyHasPoints,
        all: orderedRankedTeams,
        first,
        notFirst,
        groupedByRank
    };
};

const useOrderTeamsByPoints = teams => {
    const orderedTeams = useMemo(() => {
        return orderTeamsByPoints(teams);
    }, [teams]);

    return orderedTeams;
};

export { orderTeamsByPoints, useOrderTeamsByPoints };
