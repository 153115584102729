import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';

//  Importing Reducers
// General Reducers
import { reducer as form } from 'redux-form';
import authManager from 'erpcore/utils/AuthManager/AuthManager.reducer';
import routerManager from 'erpcore/utils/RouterManager/RouterManager.reducer';
import layoutManager from 'erpcore/utils/LayoutManager/LayoutManager.reducer';
import notificationManager from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import forgotPassword from 'erpcore/screens/ForgotPassword/ForgotPassword.reducer';
import imageManager from 'erpcore/components/ImageManager/ImageManager.reducer';
import mediaLibraryGallery from 'erpcore/components/Form/components/Media/components/MediaLibraryModal/components/MediaLibraryGallery/MediaLibraryGallery.reducer';
import myAccount from 'erpcore/screens/MyAccount/MyAccount.reducer';
import activityLog from 'erpcore/components/ActivityLog/ActivityLog.reducer';
import comments from 'erpcore/components/Comments/Comments.reducer';
import listing from 'erpcore/components/Listing/Listing.reducer';
import dashboards from 'erpcore/screens/Dashboard/Dashboard.reducer';
import dashboardWidgets from 'erpcore/screens/Dashboard/DashboardWidgets.reducer';
// Project Reducers - REMEMBER TO ORDER THEM ALPHABETICALLY //
import activities from 'erpcore/screens/Activities/Activities.reducer';
import challenges from 'erpcore/screens/Challenges/Challenges.reducer';
import settingsChallengeTypes from 'erpcore/screens/Settings/ChallengeTypes/ChallengeTypes.reducer';
import settingsActivityTypes from 'erpcore/screens/Settings/ActivityTypes/ActivityTypes.reducer';
import customers from 'erpcore/screens/Customers/Customers.reducer';
import customerContacts from 'erpcore/screens/Customers/screens/CustomerContacts/CustomerContacts.reducer';
import contacts from 'erpcore/screens/Contacts/Contacts.reducer';
import events from 'erpcore/screens/Events/Events.reducer';
import eventParticipants from 'erpcore/screens/Events/screens/EventsEdit/screens/Participants/Participants.reducer';
import eventTeams from 'erpcore/screens/Events/screens/EventsEdit/screens/Teams/Teams.reducer';
import permissions from 'erpcore/screens/Settings/Permissions/Permissions.reducer';
import roles from 'erpcore/screens/Settings/Roles/Roles.reducer';
import mediaLibrary from 'erpcore/screens/MediaLibrary/MediaLibrary.reducer';
import guidelines from 'erpcore/screens/Settings/EventGuidelines/EventGuidelines.reducer';
import employmentRecords from 'erpcore/screens/Users/screens/UserEmploymentRecords/UserEmploymentRecords.reducer';
import entityChallenges from 'erpcore/screens/Challenges/screens/EntityChallenges/EntityChallenges.reducer';
import entityNotifications from 'erpcore/screens/EntityNotifications/EntityNotifications.reducer';
import entityContent from 'erpcore/screens/EntityContent/EntityContent.reducer';
import users from 'erpcore/screens/Users/Users.reducer';

//  Combining all existing reducers
const appReducer = combineReducers({
    authManager,
    routerManager,
    layoutManager,
    notificationManager,
    activityLog,
    comments,
    listing,
    form,
    forgotPassword,
    imageManager,
    mediaLibraryGallery,
    myAccount,
    dashboards,
    dashboardWidgets, // system reducers end
    activities,
    entityChallenges,
    challenges,
    settingsChallengeTypes,
    settingsActivityTypes,
    customers,
    customerContacts,
    contacts,
    events,
    eventParticipants,
    eventTeams,
    employmentRecords,
    entityNotifications,
    entityContent,
    mediaLibrary,
    permissions,
    roles,
    guidelines,
    users
});

const rootReducer = (state, action) => {
    if (action.type === 'SIGN_OUT_SUCCESSFUL') {
        storage.removeItem('persist:root');
        state = undefined;
    }

    return appReducer(state, action);
};

export default rootReducer;
