import { takeEvery, put, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import dto from 'erpcore/utils/dto';

import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as dashboardsActions } from './Dashboard.reducer';

/**
 * Fetch Dashboards
 * @param  {Object} promise
 * @return {string}
 */
export function* fetchDashboards({ promise, params }) {
    try {
        const fetchDashboardsAPI = yield restClient.get(`api/dashboards`, {
            params
        });
        yield put({
            type: dashboardsActions.FETCHING_SUCCESSFUL_DASHBOARDS
        });
        yield put({
            type: dashboardsActions.STORE_DASHBOARDS_DATA,
            response: dto(fetchDashboardsAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: dashboardsActions.FETCHING_FAILED_DASHBOARDS
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Dashboard data
 * @param  {Object} iri iri of Dashboard
 * @return {Object} Response from API
 */
export function* updateDashboard({ promise, data, iri }) {
    try {
        const updateupdateDashboardAPI = yield restClient.patch(iri, data);

        yield put({
            type: dashboardsActions.UPDATE_DASHBOARD_SUCCESSFUL
        });
        yield put({
            type: dashboardsActions.STORE_DASHBOARD_DATA,
            iri,
            response: dto(updateupdateDashboardAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: dashboardsActions.UPDATE_DASHBOARD_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}
/**
 * Register action to watcher
 */
export default [
    takeEvery(dashboardsActions.START_FETCHING_DASHBOARDS, fetchDashboards),
    takeEvery(dashboardsActions.START_UPDATE_DASHBOARD, updateDashboard)
];
