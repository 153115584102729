import React, { useState, useEffect } from 'react';
import Button from 'erpcore/components/Button';
import Modal from 'erpcore/components/Modal';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { useDispatch, useSelector } from 'react-redux';
import {
    getListingFetching,
    getListingResponse
} from 'erpcore/components/Listing/Listing.selectors';
import ElementLoader from 'erpcore/components/ElementLoader';
import imagePlaceholder from 'erpcore/assets/images/image-placeholder.png';
import PropTypes from 'prop-types';
import { actions as entityChallengeActions } from 'erpcore/screens/Challenges/screens/EntityChallenges/EntityChallenges.reducer';
import ChallengeCreateEditForm from 'erpcore/screens/Challenges/components/ChallengeCreateEditForm';
import { formatFormValuesBasedOnType } from 'erpcore/screens/Challenges/Challenges.utils';
import debounce from 'lodash/debounce';
import './EntityChallengeAddNew.scss';
import { getImageSrcFromMediaObject } from 'erpcore/components/ImageManager';

const EntityChallengeAddNew = ({
    callback,
    endpoint,
    entity,
    entityIri,
    modalOpened,
    handleModal,
    addNewFormName,
    wallDisabled,
    wallTooltipContent
}) => {
    const actionName = 'CHALLENGES';
    const reducerName = 'challenges';
    const dispatch = useDispatch();
    const challengesData = useSelector(state => getListingResponse(state, reducerName));
    const challengesFetching = useSelector(state => getListingFetching(state, reducerName));
    const [createNewModal, setCreateNewModal] = useState(false);
    const [creatingEntityChallenge, setCreatingEntityChallenge] = useState(false);

    const handleCreateNewModal = () => {
        setCreateNewModal(!createNewModal);
    };

    const fetchChallenges = (params = {}) => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: {
                    resolve,
                    reject
                },
                entity: actionName,
                type: listingActions.START_FETCHING_LISTING,
                name: reducerName,
                params,
                endpoint: 'api/challenges?include=type,media,media.media&order_by[times_used]=desc'
            });
        }).catch(error => {
            return error;
        });
    };

    const delayedSearch = debounce(q => fetchChallenges(q), 350);

    useEffect(() => {
        fetchChallenges();
    }, []);

    const onSubmit = ({ formData, challengeType }) => {
        const values = formatFormValuesBasedOnType(formData, challengeType);

        return new Promise((resolve, reject) => {
            dispatch({
                promise: {
                    resolve,
                    reject
                },
                formData: {
                    ...values,
                    [entity]: entityIri
                },
                endpoint,
                type: entityChallengeActions.START_CREATE_ENTITY_CHALLENGE
            });
        })
            .then(() => {
                handleModal();
                handleCreateNewModal();
                callback();
            })
            .catch(err => err);
    };

    const selectChallenge = selectedChallengeIri => {
        setCreatingEntityChallenge(true);

        return new Promise((resolve, reject) => {
            dispatch({
                promise: {
                    resolve,
                    reject
                },
                formData: {
                    [entity]: entityIri,
                    challenge: selectedChallengeIri
                },
                endpoint,
                type: entityChallengeActions.START_CREATE_ENTITY_CHALLENGE
            });
        })
            .then(() => {
                handleModal();
                callback();
            })
            .catch(err => err)
            .finally(() => {
                setCreatingEntityChallenge(false);
            });
    };

    const renderChallenges = () => {
        const data = Array.from(challengesData?.data || []);

        if (!challengesFetching && !data?.length) {
            return <p>No results found.</p>;
        }

        return data.map(item => {
            const { media = [], title, description, _type: type, iri: challengeIri } = {
                ...item
            };

            const [image] = media;
            const { media: imageMedia } = {
                ...image
            };

            const smallImage = getImageSrcFromMediaObject(imageMedia, 'small');

            return (
                <div
                    key={`challenge-${challengeIri}`}
                    className="entity-challenge-add-new__card"
                    onClick={() => selectChallenge(challengeIri)}
                    tabIndex="0"
                    role="button"
                    onKeyDown={() => {}}
                >
                    <div
                        className={`entity-challenge-add-new__card-image ${
                            !smallImage ? 'entity-challenge-add-new__card-image--placeholder' : ''
                        }`}
                    >
                        <img src={smallImage || imagePlaceholder} alt={title} />
                    </div>
                    <div className="entity-challenge-add-new__card-content">
                        <h4>{title}</h4>
                        <p dangerouslySetInnerHTML={{ __html: description }} />
                        <p>
                            <b>Type:</b> {type?.name}
                        </p>
                    </div>
                </div>
            );
        });
    };

    const generateLockedByEndpoint = () => {
        if (entity === 'activity') {
            return `api/activity-challenges?filters[activity][equals]=${entityIri}`;
        }

        if (entity === 'event') {
            return `api/event-challenges?filters[event][equals]=${entityIri}`;
        }

        return undefined;
    };

    return (
        <>
            <Modal
                variation="large"
                opened={modalOpened}
                onClose={handleModal}
                title="Add Challenge"
            >
                {(challengesFetching || creatingEntityChallenge) && <ElementLoader overlay />}
                <h3>Choose existing Challenge</h3>

                <div className="entity-challenge-add-new__existing-challenges">
                    <input
                        type="text"
                        className="entity-challenge-add-new__search"
                        placeholder="Search"
                        onChange={e =>
                            delayedSearch({
                                q: e?.target?.value,
                                page: 1
                            })
                        }
                    />

                    <div className="entity-challenge-add-new__wrapper">{renderChallenges()}</div>
                </div>

                {challengesData?.meta?.totalItems > 10 && (
                    <p className="entity-challenge-add-new__info">
                        Showing {challengesData?.meta?.itemsPerPage} of{' '}
                        {challengesData?.meta?.totalItems}. Use Search to find your challenge.
                    </p>
                )}

                <h3>OR</h3>

                <Button label="Create new challenge" onClick={handleCreateNewModal} />
                <Button label="Cancel" onClick={handleModal} variation="secondary" />

                <Modal
                    variation="large"
                    opened={createNewModal}
                    onClose={handleCreateNewModal}
                    title="Add new challenge"
                >
                    <ChallengeCreateEditForm
                        wallDisabled={wallDisabled}
                        destroyOnUnmount={false}
                        enableReinitialize={false}
                        wallTooltipContent={wallTooltipContent}
                        initialValues={{
                            enabled: true
                        }}
                        submitLabel="Submit"
                        onCancel={handleCreateNewModal}
                        settings={{
                            formLayout: entity,
                            lockedByEndpoint: generateLockedByEndpoint()
                        }}
                        onSubmit={onSubmit}
                        form={addNewFormName}
                    />
                </Modal>
            </Modal>
        </>
    );
};

EntityChallengeAddNew.defaultProps = {
    callback: () => {},
    modalOpened: false,
    handleModal: () => {},
    wallDisabled: false,
    wallTooltipContent: 'This challenge type cannot be walled'
};

EntityChallengeAddNew.propTypes = {
    callback: PropTypes.func,
    endpoint: PropTypes.string.isRequired,
    entity: PropTypes.string.isRequired,
    entityIri: PropTypes.string.isRequired,
    modalOpened: PropTypes.bool,
    handleModal: PropTypes.func,
    addNewFormName: PropTypes.string.isRequired,
    wallDisabled: PropTypes.bool,
    wallTooltipContent: PropTypes.string
};

export default React.memo(EntityChallengeAddNew);
