import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm } from 'redux-form';

import Button from 'erpcore/components/Button';
import Form from 'erpcore/components/Form';

import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { getBulkActionsModalIsOpened } from 'erpcore/components/Listing/Listing.selectors';

const EntityChallengeBulkMediaDelete = ({
    handleSubmit,
    submitting,
    reducerName,
    callback,
    batchRequestEndpoint
}) => {
    const dispatch = useDispatch();
    const isOpen = useSelector(state => getBulkActionsModalIsOpened(state, reducerName));

    const handleModal = () => {
        return dispatch({
            type: listingActions.TOGGLE_BULK_ACTIONS_MODAL,
            name: reducerName,
            response: !isOpen
        });
    };

    const onSubmit = () => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: listingActions.START_BATCH_REQUESTS,
                reducerName,
                notificationCode: 'challenge.bulkActions.challengesSuccessfullyUpdated',
                method: 'PUT',
                data: {
                    media: []
                },
                apiEndpoint: batchRequestEndpoint
            })
        )
            .then(() => {
                if (callback) {
                    callback();
                }
                handleModal();
            })
            .catch(error => {
                return error;
            });
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <p>Are you sure you want to delete icon from these challenges?</p>

            <Button submit loading={submitting} label="Yes" />
            <Button variation="secondary" label="Cancel" onClick={() => handleModal()} />
        </Form>
    );
};

EntityChallengeBulkMediaDelete.defaultProps = {
    submitting: false,
    callback: null
};

EntityChallengeBulkMediaDelete.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    reducerName: PropTypes.string.isRequired,
    callback: PropTypes.func,
    batchRequestEndpoint: PropTypes.string.isRequired
};

export default reduxForm({
    form: 'EntityChallengeBulkEdit',
    enableReinitialize: true
})(EntityChallengeBulkMediaDelete);
