import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Svg from 'erpcore/components/Svg';
import Select from 'react-select';
import '../Button/Button.scss';
import styles from '../Form/components/Select/Select.styles';

/**
 *
 * @param {*} props
 */
class ButtonDropdown extends Component {
    constructor(props) {
        super(props);

        this.props = props;

        this.state = {
            activeOption: null,
            menuIsOpen: false
        };

        this.mainElement = React.createRef();
    }

    componentWillMount() {
        document.addEventListener('click', this.handleOutsideClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleOutsideClick, false);
    }

    getDropdown() {
        const { options, triggerActionOnOptionSelection } = this.props;

        if (!options) {
            return null;
        }

        const { activeOption, menuIsOpen } = this.state;

        const selectOptions = [];

        options.forEach(item => {
            const { id, label } = item;
            selectOptions.push({
                value: id,
                label
            });
        });

        return (
            <Select
                styles={styles}
                onChange={selectedOption => {
                    if (selectedOption) {
                        if (triggerActionOnOptionSelection) {
                            const selectedOptionData = options.find(item => {
                                return selectedOption.value === item.id;
                            });
                            if (selectedOptionData && selectedOptionData.onClick !== undefined) {
                                selectedOptionData.onClick();
                            }
                        }
                        this.setState({ activeOption: selectedOption });
                    } else {
                        this.setState({ activeOption: false });
                    }
                }}
                placeholder=""
                className="react-select"
                classNamePrefix="react-select"
                isClearable={false}
                hideSelectedOptions={false}
                isSearchable={false}
                menuPortalTarget={this.mainElement.current ? this.mainElement.current : null}
                menuIsOpen={menuIsOpen}
                onMenuOpen={() => this.setMenuVisibility(true)}
                onMenuClose={() => this.setMenuVisibility(false)}
                value={activeOption}
                options={selectOptions}
            />
        );
    }

    getActiveButtonProps() {
        const { options, placeholder } = this.props;

        const { activeOption } = this.state;

        if (!options) {
            return null;
        }

        const defaultProps = {
            id: 'undefined',
            label: 'Button',
            onClick: () => {}
        };

        let activeButtonProps = {};

        if (activeOption) {
            // get active item
            const targetItem = options.find(item => {
                return activeOption.value === item.id;
            });
            if (targetItem) {
                activeButtonProps = targetItem;
            }
        } else if (placeholder) {
            // get placeholder
            activeButtonProps = {
                id: 'placeholder',
                label: placeholder,
                onClick: () => this.setMenuVisibility(true)
            };
        } else if (!activeOption) {
            // get first item
            const targetItem = options[0];
            if (targetItem) {
                activeButtonProps = targetItem;
            }
        }

        return { ...defaultProps, ...activeButtonProps };
    }

    setMenuVisibility(open = true) {
        this.setState({ menuIsOpen: open });
    }

    handleOutsideClick = e => {
        if (this.mainElement.current && !this.mainElement.current.contains(e.target)) {
            this.setMenuVisibility(false);
        }
    };

    handleVersions() {
        const { variation, className, size, disabled } = this.props;

        const { menuIsOpen } = this.state;

        this.className = `button button--dropdown ${className}`;

        if (menuIsOpen) {
            this.className = `${this.className} button--dropdown-open`;
        } else {
            this.className = this.className.replace(' button--dropdown-open', '');
        }

        if (variation && variation === 'secondary') {
            this.className = `${this.className} button--secondary`;
        } else {
            this.className = this.className.replace(' button--secondary', '');
        }

        if (variation && variation === 'tertiary') {
            this.className = `${this.className} button--tertiary`;
        } else {
            this.className = this.className.replace(' button--tertiary', '');
        }

        if (size && size === 'small') {
            this.className = `${this.className} button--small`;
        } else {
            this.className = this.className.replace(' button--small', '');
        }

        if (disabled) {
            this.className = `${this.className} button--disabled`;
        } else {
            this.className = this.className.replace(' button--disabled', '');
        }

        return this.className;
    }

    renderActiveButton() {
        const { disabled } = this.props;
        const { id, label, onClick } = this.getActiveButtonProps();

        return (
            <button
                key={id}
                type="button"
                disabled={disabled}
                aria-label={label}
                className="button__inner-button"
                onClick={onClick}
            >
                {label}
            </button>
        );
    }

    renderToggle() {
        const { disabled } = this.props;
        const { menuIsOpen } = this.state;

        return (
            <button
                type="button"
                disabled={disabled}
                aria-haspopup="true"
                className="button__toggle"
                onClick={() => {
                    this.setMenuVisibility(!menuIsOpen);
                }}
            >
                <Svg className="button__toggle-icon" icon="arrowDown" />
            </button>
        );
    }

    render() {
        this.handleVersions();

        return (
            <div ref={this.mainElement} className={this.className || ''}>
                {this.renderActiveButton()}
                {this.renderToggle()}
                {this.getDropdown()}
            </div>
        );
    }
}

ButtonDropdown.defaultProps = {
    options: [
        {
            id: 'undefined',
            label: 'Button',
            onClick: () => {}
        }
    ],
    placeholder: null,
    triggerActionOnOptionSelection: false,
    variation: 'primary',
    size: null,
    className: '',
    disabled: false
};

ButtonDropdown.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.string,
            onClick: PropTypes.func
        })
    ),
    placeholder: PropTypes.string,
    triggerActionOnOptionSelection: PropTypes.bool,
    variation: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
    size: PropTypes.oneOf(['small']),
    className: PropTypes.string,
    disabled: PropTypes.bool
};

export default ButtonDropdown;
