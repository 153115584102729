import React from 'react';
import PropTypes from 'prop-types';
import Form, { Text, Location, Phone, Email, Autocomplete, Media } from 'erpcore/components/Form';
import { valueValidation } from 'erpcore/components/Form/Form.utils';
import {
    Field,
    getFormInitialValues,
    getFormValues,
    hasSubmitSucceeded,
    reduxForm
} from 'redux-form';
import Button from 'erpcore/components/Button';
import UnsavedChanges from 'erpcore/components/UnsavedChanges';
import { useSelector } from 'react-redux';
import { diff } from 'deep-object-diff';

const CustomerCreateEditForm = ({
    handleSubmit,
    onSubmit,
    pristine,
    invalid,
    submitting,
    submitLabel,
    onCancel,
    form
}) => {
    const hasSubmitted = useSelector(state => hasSubmitSucceeded(form)(state));
    const formValues = useSelector(state => getFormValues(form)(state));
    const initialValues = useSelector(state => getFormInitialValues(form)(state));

    const checkIfFormIsDirty = () => {
        const difference = diff(initialValues, formValues);
        const keys = Object.keys(difference);

        keys.forEach(key => {
            if (difference[key] === null || difference[key] === '') {
                delete difference[key];
            }
        });

        delete difference?.included;
        delete difference?.location?.fullAddressSelected;

        if (difference?.location && !Object?.keys(difference?.location)?.length) {
            delete difference.location;
        }

        if (Object.keys(difference)?.length) {
            return true;
        }

        return false;
    };

    return (
        <Form
            onSubmit={handleSubmit(formData => onSubmit({ formData, preventEditRedirect: false }))}
        >
            <UnsavedChanges
                promptWhen={checkIfFormIsDirty() && !hasSubmitted && !submitting}
                onSubmit={handleSubmit(formData =>
                    onSubmit({ formData, preventEditRedirect: true })
                )}
            />
            <Form.Row>
                <Field
                    name="name"
                    id="name"
                    fieldProps={{
                        label: 'Customer name',
                        clearable: true
                    }}
                    fieldAttr={{ required: true }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="location"
                    id="location"
                    component={Location}
                    fieldProps={{
                        locationManualTogglelabel: 'Input address manually'
                    }}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="phone"
                    id="phone"
                    fieldProps={{
                        label: 'Phone',
                        country: 'CA'
                    }}
                    component={Phone}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="email"
                    id="email"
                    fieldProps={{
                        label: 'Email',
                        clearable: true
                    }}
                    component={Email}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="website"
                    id="website"
                    fieldProps={{
                        label: 'Website',
                        clearable: true
                    }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="account_manager"
                    id="account_manager"
                    fieldProps={{
                        label: 'Account manager',
                        options: {
                            endpoint:
                                '/api/users?order_by[last_name]=ASC&filters[roles.attribute][equals]=CAN_USER_TYPE_SALES',
                            mapData: {
                                value: 'iri',
                                label: '{first_name} {last_name}'
                            }
                        }
                    }}
                    component={Autocomplete}
                />
            </Form.Row>
            <Form.Row>
                <Form.SectionTitleSmall>Logo upload</Form.SectionTitleSmall>
                <Field
                    name="logo"
                    id="logo"
                    canDelete
                    component={Media}
                    useLightbox={false}
                    useImageManager
                    allowedFileTypes={['jpg', 'jpeg', 'png', 'gif']}
                />
            </Form.Row>

            <Button
                submit
                disabled={pristine || invalid}
                loading={submitting}
                label={submitLabel}
            />
            {onCancel && <Button variation="secondary" label="Cancel" onClick={() => onCancel()} />}
        </Form>
    );
};

CustomerCreateEditForm.defaultProps = {
    submitting: false,
    pristine: false,
    invalid: false,
    submitLabel: null,
    onCancel: null,
    form: 'CustomerCreateEditForm'
};

CustomerCreateEditForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
    submitLabel: PropTypes.string,
    onCancel: PropTypes.func,
    form: PropTypes.string
};

export default reduxForm({
    enableReinitialize: true
})(CustomerCreateEditForm);
