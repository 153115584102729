import React from 'react';
import PropTypes from 'prop-types';
import Svg from 'erpcore/components/Svg';
import styles from './EventsWidgetStats.module.scss';

const EventsWidgetStats = ({ data }) => {
    return (
        <>
            {data.length > 0 && (
                <div className={styles.main}>
                    <ul className={styles.list}>
                        {data.map((item, index) => {
                            return (
                                <li key={item.id || index} className={styles.list__item}>
                                    <span className={styles.list__icon}>
                                        <Svg icon="calendar" />
                                    </span>
                                    <div>
                                        <span className={styles.list__title}>{item.label}</span>
                                        <span className={styles.list__subtitle}>{item.value}</span>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}
        </>
    );
};

EventsWidgetStats.defaultProps = {
    data: []
};

EventsWidgetStats.propTypes = {
    data: PropTypes.oneOfType([PropTypes.array])
};

export default React.memo(EventsWidgetStats);
