import React, { useState } from 'react';
import { reduxForm } from 'redux-form';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Svg from 'erpcore/components/Svg';
import Tooltip from 'erpcore/components/Tooltip';
import Modal from 'erpcore/components/Modal';
import Form from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';
import { actions as eventParticipantActions } from '../../Participants.reducer';

const MakeTeamLeadModal = ({
    id,
    eventIri,
    handleSubmit,
    submitting,
    firstName,
    lastName,
    teamName,
    onUpdate
}) => {
    const dispatch = useDispatch();
    const [opened, setOpened] = useState(false);

    const handleModal = () => {
        setOpened(!opened);
    };

    const onSubmit = () => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: eventParticipantActions.START_MAKING_PARTICIPANT_TEAM_LEADER,
                id,
                eventIri
            })
        )
            .then(() => {
                handleModal();
                if (onUpdate && typeof onUpdate === 'function') onUpdate();
            })
            .catch(error => {
                handleModal();
                return error;
            });
    };

    return (
        <>
            <Tooltip content="Make a team leader">
                <button type="button" onClick={() => handleModal()}>
                    <Svg icon="leader" />
                </button>
            </Tooltip>
            <Modal
                opened={opened}
                variation="small"
                onClose={() => handleModal()}
                title="Make a team leader"
                subtitle={
                    <>
                        Are you sure you want to make{' '}
                        <strong>
                            {firstName} {lastName}
                        </strong>{' '}
                        a team leader for a <strong>{teamName}</strong>?
                    </>
                }
            >
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Button submit loading={submitting} label="Yes" />
                    <Button variation="secondary" label="Cancel" onClick={() => handleModal()} />
                </Form>
            </Modal>
        </>
    );
};

MakeTeamLeadModal.defaultProps = {
    submitting: false,
    onUpdate: () => {}
};

MakeTeamLeadModal.propTypes = {
    id: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    eventIri: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    teamName: PropTypes.string.isRequired,
    onUpdate: PropTypes.func
};

export default reduxForm({
    enableReinitialize: true
})(MakeTeamLeadModal);
