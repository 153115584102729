import React, { useLayoutEffect, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getSingleEventData } from 'erpcore/screens/Events/Events.selectors';
import './EventViewDetails.scss';
import imagePlaceholder from 'erpcore/assets/images/image-placeholder.png';
import Button from 'erpcore/components/Button';
import { getImageSrcFromMediaObject } from 'erpcore/components/ImageManager';

const EventViewDetails = () => {
    const [showMore, setShowMore] = useState(false);
    const [expand, setExpand] = useState(false);
    const match = useRouteMatch();
    const id = match?.params?.id;
    const iri = `/api/events/${id}`;
    const eventData = useSelector(state => getSingleEventData(state, iri));
    const { image, description, name } = { ...eventData };
    const descriptionRef = useRef();
    const contentRef = useRef();

    const handleShowMore = () => {
        const descriptionHeight = descriptionRef?.current?.clientHeight;
        const contentHeight = contentRef?.current?.clientHeight;

        if (descriptionHeight > contentHeight) {
            setShowMore(true);
        } else {
            setShowMore(false);
        }
    };

    useLayoutEffect(() => {
        handleShowMore();

        window.addEventListener('resize', handleShowMore);

        return () => {
            window.removeEventListener('resize', handleShowMore);
        };
    }, [description]);

    const mediumImage = getImageSrcFromMediaObject(image, 'medium');

    return (
        <div className="event-view-details">
            <div
                className={`event-view-details__image ${
                    !mediumImage ? 'event-view-details__image--placeholder' : ''
                }`}
            >
                <img src={mediumImage || imagePlaceholder} alt={name} />
            </div>
            <div
                ref={contentRef}
                className={`event-view-details__content ${
                    expand ? 'event-view-details__content--expanded' : ''
                }`}
            >
                <p>Description</p>
                <div
                    ref={descriptionRef}
                    className="event-view-details__content-description"
                    dangerouslySetInnerHTML={{ __html: description }}
                />
                {showMore && (
                    <Button
                        className="event-view-details__show-more"
                        onClick={() => setExpand(!expand)}
                        size="small"
                        variation="link"
                        label={expand ? 'Show less' : 'Show more'}
                    />
                )}
            </div>
        </div>
    );
};

export default EventViewDetails;
