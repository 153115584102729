import restClient from 'erpcore/api/restClient';
import { call, put, takeLatest } from 'redux-saga/effects';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as mediaLibraryActions } from './MediaLibrary.reducer';

/**
 * Delete Single Media Object
 * @param promise
 * @param iri
 * @returns {Generator<SimpleEffect<"CALL", CallEffectDescriptor<RT | RT | RT>>|SimpleEffect<"PUT", PutEffectDescriptor<{response: *, type: string}>>|AxiosPromise<any>|SimpleEffect<"PUT", PutEffectDescriptor<{type: string}>>, void, *>}
 */
export function* deleteSingleMediaObject({ promise, iri }) {
    try {
        const deleteSingleEventAPI = yield restClient.delete(iri);
        yield put({
            type: mediaLibraryActions.MEDIA_LIBRARY_ITEM_DELETE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteSingleEventAPI?.data
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: mediaLibraryActions.MEDIA_LIBRARY_ITEM_DELETE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export const mediaLibrarySaga = [
    takeLatest(mediaLibraryActions.START_DELETING_MEDIA_LIBRARY_ITEM, deleteSingleMediaObject)
];
