import React, { useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, reduxForm, getFormValues } from 'redux-form';

import Button from 'erpcore/components/Button';
import Form, { Text, MultiAutocomplete } from 'erpcore/components/Form';

import { valueValidation } from 'erpcore/components/Form/Form.utils';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { getListingResponse } from 'erpcore/components/Listing/Listing.selectors';
import UnsavedChanges from 'erpcore/components/UnsavedChanges';
import styles from './RoleForm.module.scss';

const RoleForm = ({
    form,
    handleSubmit,
    submitting,
    pristine,
    invalid,
    onSubmit,
    submitLabel,
    dirty
}) => {
    const permissionsReducerName = 'permissions';
    const permissionsActionName = 'PERMISSIONS';

    const dispatch = useDispatch();

    const formValues = useSelector(state => getFormValues(form)(state));
    const permissions = useSelector(state => getListingResponse(state, permissionsReducerName));

    const fetchPermissions = useCallback(params => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: listingActions.START_FETCHING_LISTING,
                params,
                entity: permissionsActionName,
                name: permissionsReducerName,
                endpoint: 'api/permissions'
            });
        });
    }, []);

    const currentPermissions = useMemo(() => {
        if (formValues?.permissions?.length > 0 && permissions?.data?.length > 0) {
            return formValues.permissions.reduce((acc, permissionIri) => {
                const data = permissions?.data?.find(
                    permission => permission.iri === permissionIri
                );
                return [
                    ...acc,
                    { id: permissionIri, label: data?.name, description: data?.description }
                ];
            }, []);
        }
        return [];
    }, [permissions, formValues]);

    useEffect(() => {
        fetchPermissions({}).catch(error => {
            return error;
        });
    }, []);

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <UnsavedChanges promptWhen={dirty && !submitting} onSubmit={handleSubmit(onSubmit)} />
            <Form.Row>
                <Field
                    name="name"
                    id="name"
                    fieldProps={{
                        label: 'Role name',
                        clearable: true
                    }}
                    fieldAttr={{ required: true }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="permissions"
                    id="permissions"
                    fieldProps={{
                        label: 'Permissions',
                        options: {
                            endpoint: '/api/permissions?filters[active][equals]=true',
                            mapData: {
                                value: 'iri',
                                label: 'name'
                            }
                        },
                        clearable: true
                    }}
                    fieldAttr={{ required: true }}
                    component={MultiAutocomplete}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            {currentPermissions.length > 0 && (
                <>
                    <p className={styles.roletext}>With this set of permissions User can:</p>
                    <ul>
                        {currentPermissions.map(item => {
                            const permissionLabel = item?.label;
                            const permissionDescription = item?.description;
                            return (
                                <li key={item.id}>
                                    {permissionLabel ? <strong>{permissionLabel}</strong> : ''}
                                    {permissionLabel && permissionDescription ? ' - ' : ''}
                                    {permissionDescription || ''}
                                </li>
                            );
                        })}
                    </ul>
                </>
            )}
            <Form.Row>
                <Button
                    submit
                    disabled={pristine || invalid}
                    loading={submitting}
                    label={submitLabel}
                />
            </Form.Row>
        </Form>
    );
};

RoleForm.defaultProps = {
    form: 'RoleForm',
    onSubmit: () => {},
    submitting: false,
    pristine: false,
    invalid: false,
    submitLabel: 'Create',
    dirty: false
};

RoleForm.propTypes = {
    form: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    submitLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    dirty: PropTypes.bool
};

export default reduxForm({
    form: 'RoleForm',
    enableReinitialize: true
})(RoleForm);
