import React from 'react';
import PropTypes from 'prop-types';
import styles from './Tag.module.scss';

const Tag = ({ children }) => {
    return <span className={styles.tag}>{children}</span>;
};
Tag.defaultProps = {
    children: null
};
Tag.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    children: PropTypes.any
};

Tag.Wrapper = ({ children }) => {
    return <div className={styles['tag-wrapper']}>{children}</div>;
};
Tag.Wrapper.defaultProps = {
    children: null
};
Tag.Wrapper.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    children: PropTypes.any
};

export default Tag;
