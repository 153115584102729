import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Tabs from 'erpcore/components/Tabs';

const CustomersEditTabs = ({ match }) => {
    const links = [
        {
            link: `/companies/${match?.params?.id}/edit/general-info`,
            label: 'General info'
        },
        {
            link: `/companies/${match?.params?.id}/edit/contacts`,
            label: 'Contacts'
        },
        {
            link: `/companies/${match?.params?.id}/edit/comments`,
            label: 'Comments'
        }
    ];

    return <Tabs links={links} />;
};

CustomersEditTabs.defaultProps = {
    match: {}
};

CustomersEditTabs.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object])
};

export default withRouter(CustomersEditTabs);
