import { takeLatest, put, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as entityContentActions } from './EntityContent.reducer';

/**
 * Delete Single Entity Content
 * @param  {Object} id ID of a Entity
 * @return {Object} Response from API
 */
export function* deleteSingleEntityContent({ promise, iri }) {
    try {
        const deleteSingleEntityContentAPI = yield restClient.delete(iri);
        yield put({
            type: entityContentActions.DELETE_ENTITY_CONTENT_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteSingleEntityContentAPI?.data
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: entityContentActions.DELETE_ENTITY_CONTENT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Create Entity Content
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createEntityContent({ promise, formData, endpoint }) {
    try {
        const createEntityContentAPI = yield restClient.post(endpoint, formData);
        yield put({
            type: entityContentActions.CREATE_ENTITY_CONTENT_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createEntityContentAPI?.data
        });
        yield call(promise.resolve, createEntityContentAPI?.data);
    } catch (error) {
        yield put({
            type: entityContentActions.CREATE_ENTITY_CONTENT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Create Entity Content Item
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createEntityContentItem({ promise, formData }) {
    try {
        const createEntityContentItemAPI = yield restClient.post(
            `/api/activity-content-items`,
            formData
        );
        yield put({
            type: entityContentActions.CREATE_ENTITY_CONTENT_ITEM_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createEntityContentItemAPI?.data
        });
        yield call(promise.resolve, createEntityContentItemAPI?.data);
    } catch (error) {
        yield put({
            type: entityContentActions.CREATE_ENTITY_CONTENT_ITEM_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Create Entity Content Item Media
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createEntityContentItemMedia({ promise, formData }) {
    try {
        const createEntityContentItemAPI = yield restClient.post(
            `/api/activity-content-item-media`,
            formData
        );
        yield put({
            type: entityContentActions.CREATE_ENTITY_CONTENT_ITEM_MEDIA_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createEntityContentItemAPI?.data
        });
        yield call(promise.resolve, createEntityContentItemAPI?.data);
    } catch (error) {
        yield put({
            type: entityContentActions.CREATE_ENTITY_CONTENT_ITEM_MEDIA_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Single Entity Content data
 * @param  {String} Entity
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* updateSingleEntityContent({ promise, formData, iri, config = {} }) {
    try {
        const updateSingleEntityContentAPI = yield restClient.put(iri, formData, config);
        yield put({
            type: entityContentActions.UPDATE_ENTITY_CONTENT_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleEntityContentAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: entityContentActions.UPDATE_ENTITY_CONTENT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export const entityContentSaga = [
    takeLatest(entityContentActions.START_DELETE_ENTITY_CONTENT, deleteSingleEntityContent),
    takeLatest(entityContentActions.START_CREATE_ENTITY_CONTENT, createEntityContent),
    takeLatest(entityContentActions.START_UPDATE_ENTITY_CONTENT, updateSingleEntityContent),
    takeLatest(entityContentActions.START_CREATE_ENTITY_CONTENT_ITEM, createEntityContentItem),
    takeLatest(
        entityContentActions.START_CREATE_ENTITY_CONTENT_ITEM_MEDIA,
        createEntityContentItemMedia
    )
];
