import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { Route, Switch, Redirect, withRouter } from 'react-router-dom';

import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as routerManagerActions } from 'erpcore/utils/RouterManager/RouterManager.reducer';
import { getSignedIn, getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { hasPermission, getPermissionsAccessForEntities } from 'erpcore/utils/RolesManager';

// Screens / Pages
// System
import Error404 from 'erpcore/screens/Error404';
// Auth
import SignIn from 'erpcore/screens/SignIn';
import SignOut from 'erpcore/screens/SignOut';
import ForgotPassword from 'erpcore/screens/ForgotPassword/screens/ForgotPassword';
import ResetPassword from 'erpcore/screens/ForgotPassword/screens/ResetPassword';
import ResetPasswordSignedIn from 'erpcore/screens/ForgotPassword/screens/ResetPasswordSignedIn';
// General
import Dashboard from 'erpcore/screens/Dashboard';
// My Account
import MyAccountPersonalDetails from 'erpcore/screens/MyAccount/screens/MyAccountPersonalDetails';
// Activities
import ActivitiesListing from 'erpcore/screens/Activities/screens/ActivitiesListing';
import ActivitiesCreate from 'erpcore/screens/Activities/screens/ActivitiesCreate';
import ActivitiesEditGeneral from 'erpcore/screens/Activities/screens/ActivitiesEdit/screens/General';
import ActivitiesEditChallenge from 'erpcore/screens/Activities/screens/ActivitiesEdit/screens/Challenges';
import ActivitiesEditSettings from 'erpcore/screens/Activities/screens/ActivitiesEdit/screens/Settings';
import ActivitiesEditNotifications from 'erpcore/screens/Activities/screens/ActivitiesEdit/screens/Notifications';
import ActivitiesContent from 'erpcore/screens/Activities/screens/ActivitiesEdit/screens/Content';
import ActivitiesEventGuidelines from 'erpcore/screens/Activities/screens/ActivitiesEdit/screens/EventGuidelines';
import ActivityTypes from 'erpcore/screens/Settings/ActivityTypes';
// Challenges
import ChallangesListing from 'erpcore/screens/Challenges/screens/ChallengesListing';
import ChallengeCreate from 'erpcore/screens/Challenges/screens/ChallengeCreate';
import ChallengeEdit from 'erpcore/screens/Challenges/screens/ChallengeEdit';
// Company
import CustomersListing from 'erpcore/screens/Customers/screens/CustomersListing';
import CustomerCreate from 'erpcore/screens/Customers/screens/CustomersCreate';
import CustomerEdit from 'erpcore/screens/Customers/screens/CustomersEdit';
import CustomerContacts from 'erpcore/screens/Customers/screens/CustomerContacts';
import CustomerComments from 'erpcore/screens/Customers/screens/CustomerComments';
// Contacts
import ContactsListing from 'erpcore/screens/Contacts/screens/ContactsListing';
import ContactCreate from 'erpcore/screens/Contacts/screens/ContactCreate';
import ContactEdit from 'erpcore/screens/Contacts/screens/ContactEdit';
// Events
import EventsListing from 'erpcore/screens/Events/screens/EventsListing';
import EventsCreate from 'erpcore/screens/Events/screens/EventsCreate';
import EventsEditGeneral from 'erpcore/screens/Events/screens/EventsEdit/screens/General';
import EventsEditSettings from 'erpcore/screens/Events/screens/EventsEdit/screens/Settings';
import EventsEditParticipants from 'erpcore/screens/Events/screens/EventsEdit/screens/Participants';
import EventEditTeamsListing from 'erpcore/screens/Events/screens/EventsEdit/screens/Teams';
import EventsEditNotification from 'erpcore/screens/Events/screens/EventsEdit/screens/Notification';
import EventEditContent from 'erpcore/screens/Events/screens/EventsEdit/screens/Content';
import EventEditGuidelines from 'erpcore/screens/Events/screens/EventsEdit/screens/EventGuidelines';
import EventChallengesListing from 'erpcore/screens/Events/screens/EventsEdit/screens/Challenges';
import EventInfo from 'erpcore/screens/Events/screens/EventsView/screens/EventInfo';
import EventFeed from 'erpcore/screens/Events/screens/EventsView/screens/Feed';

// Media Library
import MediaLibrary from 'erpcore/screens/MediaLibrary/screens/MediaLibraryListing';

// Users
import UsersListing from 'erpcore/screens/Users/screens/UsersListing';
import UserCreate from 'erpcore/screens/Users/screens/UserCreate';
import UserEdit from 'erpcore/screens/Users/screens/UserEdit';
import UserEmploymentRecords from 'erpcore/screens/Users/screens/UserEmploymentRecords';
import UserPermissions from 'erpcore/screens/Users/screens/UserPermissions';
// Settings
import Permissions from 'erpcore/screens/Settings/Permissions';
import Roles from 'erpcore/screens/Settings/Roles/screens/Roles';
import RoleCreate from 'erpcore/screens/Settings/Roles/screens/RoleCreate';
import RoleEdit from 'erpcore/screens/Settings/Roles/screens/RoleEdit';
import ChallengeTypes from 'erpcore/screens/Settings/ChallengeTypes';
import EventGuidelines from 'erpcore/screens/Settings/EventGuidelines/screens/EventGuidelines';
import EventGuidelineCreate from 'erpcore/screens/Settings/EventGuidelines/screens/EventGuidelineCreate';
import EventGuidelineEdit from 'erpcore/screens/Settings/EventGuidelines/screens/EventGuidelineEdit';

import { actions as authActions } from 'erpcore/utils/AuthManager/AuthManager.reducer';

/**
 * Router Manager
 * @return {Node} Returns current active route component
 */
const RouterManager = ({ location }) => {
    const [initial, setInitial] = useState(true);
    const dispatch = useDispatch();
    const isSignedIn = useSelector(getSignedIn);
    const allowedToClearNotifications = useRef(false);
    const { pathname, search } = location;
    const meData = useSelector(getMeData) || {};
    const hasPermissionsAccessForEntities = getPermissionsAccessForEntities(meData);

    const fetchUserData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: authActions.START_FETCHING_ME,
                promise: { resolve, reject }
            });
        });
    };

    useEffect(() => {
        if (initial && isSignedIn) {
            setInitial(false);
            fetchUserData();
        }
    }, []);

    const clearNotificationManagerPageNotification = () => {
        dispatch({
            type: notificationManagerActions.REMOVE_PAGE_NOTIFICATIONS,
            initiator: 'router'
        });
    };

    const setUrlQueryParams = params => {
        dispatch({
            type: routerManagerActions.SET_URL_QUERY_PARAMS,
            response: params
        });
    };

    /**
     * Effect used only on location path (route) change
     */
    useEffect(() => {
        const handleLocationChange = () => {
            if (allowedToClearNotifications.current) {
                // Clear Page Notifications
                clearNotificationManagerPageNotification();
            }
            allowedToClearNotifications.current = true;
            setUrlQueryParams('');
        };

        const renderBodyClassName = () => {
            //  dynamicly udating body className
            let cssClass = 'page-homepage';
            if (pathname && pathname !== '/') {
                cssClass = location.pathname.replace(/\//, 'page-');
                cssClass = cssClass.replace(/\//g, '-');
            } else {
                cssClass = 'page-404';
            }

            document.body.className = cssClass;
        };
        // Invoke functions
        renderBodyClassName();
        handleLocationChange();
    }, [pathname]);

    /**
     * Effect used only on location search (query params) change
     */
    useEffect(() => {
        setUrlQueryParams(search);
    }, [search]);

    // Render the private routes when the user is signed in
    if (isSignedIn) {
        return (
            <Switch>
                <Route key="SignOut" path="/sign-out" exact component={SignOut} />
                <Route exact path="/reset-password" component={ResetPasswordSignedIn} />
                <Route exact path="/reset-password/:token" component={ResetPasswordSignedIn} />
                <Route
                    key="ResetPasswordLogin"
                    path="/reset-password-login"
                    exact
                    component={ResetPassword}
                />
                <Route key="Dashboard" path="/dashboard" exact component={Dashboard} />
                {/* My Account */}
                <Route
                    key="MyAccountPersonalDetails"
                    path="/my-account"
                    exact
                    component={MyAccountPersonalDetails}
                />
                {/* Users */}
                {hasPermissionsAccessForEntities.users && (
                    <Route key="UsersListing" path="/users" exact component={UsersListing} />
                )}
                {hasPermission('CAN_MANAGE_CORE_BUNDLE_USER', meData) && (
                    <Route key="UserCreate" path="/users/create" exact component={UserCreate} />
                )}
                {hasPermission('CAN_MANAGE_CORE_BUNDLE_USER', meData) && (
                    <Route
                        key="UserEdit"
                        path="/users/:id/edit/details"
                        exact
                        component={UserEdit}
                    />
                )}
                {hasPermission('CAN_MANAGE_COMMON_EMPLOYMENTRECORD', meData) && (
                    <Route
                        key="UserEmploymentRecords"
                        path="/users/:id/edit/employment-records"
                        exact
                        component={UserEmploymentRecords}
                    />
                )}
                {hasPermission('CAN_MANAGE_CORE_BUNDLE_USER', meData) && (
                    <Route
                        key="UserPermissions"
                        path="/users/:id/edit/permissions"
                        exact
                        component={UserPermissions}
                    />
                )}
                {hasPermission('CAN_MANAGE_SECURITY_BUNDLE_ROLE', meData) && (
                    <Route key="Roles" path="/settings/roles" exact component={Roles} />
                )}
                {hasPermission('CAN_MANAGE_SECURITY_BUNDLE_ROLE', meData) && (
                    <Route
                        key="RoleCreate"
                        path="/settings/roles/create"
                        exact
                        component={RoleCreate}
                    />
                )}
                {hasPermission('CAN_MANAGE_SECURITY_BUNDLE_ROLE', meData) && (
                    <Route
                        key="RoleEdit"
                        path="/settings/roles/:id/edit"
                        exact
                        component={RoleEdit}
                    />
                )}
                {hasPermission('CAN_MANAGE_COMMON_GUIDELINE', meData) && (
                    <Route
                        key="EventGuidelines"
                        path="/settings/guidelines"
                        exact
                        component={EventGuidelines}
                    />
                )}
                {hasPermission('CAN_MANAGE_COMMON_GUIDELINE', meData) && (
                    <Route
                        key="RoleCreate"
                        path="/settings/guidelines/create"
                        exact
                        component={EventGuidelineCreate}
                    />
                )}
                {hasPermission('CAN_MANAGE_COMMON_GUIDELINE', meData) && (
                    <Route
                        key="RoleEdit"
                        path="/settings/guidelines/:id/edit"
                        exact
                        component={EventGuidelineEdit}
                    />
                )}
                {hasPermission('CAN_MANAGE_SECURITY_BUNDLE_PERMISSION', meData) && (
                    <Route
                        key="Permissions"
                        path="/settings/permissions"
                        exact
                        component={Permissions}
                    />
                )}
                {/* Activities */}
                {hasPermissionsAccessForEntities.activities && (
                    <Route
                        key="ActivitiesListing"
                        path="/activities"
                        exact
                        component={ActivitiesListing}
                    />
                )}
                {hasPermissionsAccessForEntities.activities && (
                    <Route
                        key="ActivitiesCreate"
                        path="/activities/create"
                        exact
                        component={ActivitiesCreate}
                    />
                )}
                {hasPermissionsAccessForEntities.activities && (
                    <Route
                        key="ActivitiesEditGeneral"
                        path="/activities/:id/edit/general"
                        exact
                        component={ActivitiesEditGeneral}
                    />
                )}
                {hasPermission('CAN_MANAGE_COMMON_ACTIVITY_CHALLENGE', meData) && (
                    <Route
                        key="ActivitiesEditChallenge"
                        path="/activities/:id/edit/challenges"
                        exact
                        component={ActivitiesEditChallenge}
                    />
                )}
                {hasPermissionsAccessForEntities.activities && (
                    <Route
                        key="ActivitiesEditSettings"
                        path="/activities/:id/edit/settings"
                        exact
                        component={ActivitiesEditSettings}
                    />
                )}
                {hasPermission('CAN_MANAGE_COMMON_ACTIVITY_NOTIFICATION', meData) && (
                    <Route
                        key="ActivitiesEditNotification"
                        path="/activities/:id/edit/notifications"
                        exact
                        component={ActivitiesEditNotifications}
                    />
                )}
                {hasPermission('CAN_MANAGE_COMMON_ACTIVITY_CONTENT', meData) && (
                    <Route
                        key="ActivitiesContent"
                        path="/activities/:id/edit/content"
                        exact
                        component={ActivitiesContent}
                    />
                )}
                {hasPermission('CAN_MANAGE_COMMON_ACTIVITY_CONTENT', meData) && (
                    <Route
                        key="ActivitiesEventGuidelines"
                        path="/activities/:id/edit/guidelines"
                        exact
                        component={ActivitiesEventGuidelines}
                    />
                )}
                {hasPermission('CAN_MANAGE_COMMON_ACTIVITY_TYPE', meData) && (
                    <Route
                        key="ActivitiesEditSettings"
                        path="/settings/activity-types"
                        exact
                        component={ActivityTypes}
                    />
                )}
                {/* Challenges */}
                {hasPermissionsAccessForEntities.challenges && (
                    <Route
                        key="ChallengesListing"
                        path="/challenges"
                        exact
                        component={ChallangesListing}
                    />
                )}
                {hasPermissionsAccessForEntities.challenges && (
                    <Route
                        key="ChallengeCreate"
                        path="/challenges/create"
                        exact
                        component={ChallengeCreate}
                    />
                )}
                {hasPermissionsAccessForEntities.challenges && (
                    <Route
                        key="ChallengeEdit"
                        path="/challenges/:id/edit"
                        exact
                        component={ChallengeEdit}
                    />
                )}
                {hasPermissionsAccessForEntities.challenges && (
                    <Route
                        key="ChallengeTypesListing"
                        path="/settings/challenge-types"
                        exact
                        component={ChallengeTypes}
                    />
                )}
                {/* Customers */}
                {hasPermissionsAccessForEntities.companies && (
                    <Route
                        key="CompaniesListing"
                        path="/companies"
                        exact
                        component={CustomersListing}
                    />
                )}
                {hasPermission('CAN_MANAGE_COMPANY_BUNDLE_COMPANY', meData) && (
                    <Route
                        key="CustomersCreate"
                        path="/companies/create"
                        exact
                        component={CustomerCreate}
                    />
                )}
                {hasPermission('CAN_MANAGE_COMPANY_BUNDLE_COMPANY', meData) && (
                    <Route
                        key="CustomersEdit"
                        path="/companies/:id/edit/general-info"
                        exact
                        component={CustomerEdit}
                    />
                )}
                {hasPermission('CAN_MANAGE_COMPANY_BUNDLE_COMPANY', meData) && (
                    <Route
                        key="CustomersContacts"
                        path="/companies/:id/edit/contacts"
                        exact
                        component={CustomerContacts}
                    />
                )}
                {hasPermission('CAN_MANAGE_COMPANY_BUNDLE_COMPANY', meData) && (
                    <Route
                        key="CustomersComments"
                        path="/companies/:id/edit/comments"
                        exact
                        component={CustomerComments}
                    />
                )}
                {/* Contacts */}
                {hasPermissionsAccessForEntities.contacts && (
                    <Route key="Contacts" path="/contacts" exact component={ContactsListing} />
                )}
                {/* Events */}
                {!!(
                    hasPermissionsAccessForEntities.events ||
                    hasPermissionsAccessForEntities.selfHostedEvents
                ) && <Route key="EventsListing" path="/events" exact component={EventsListing} />}
                {hasPermissionsAccessForEntities.events && (
                    <Route
                        key="EventsCreate"
                        path="/events/create"
                        exact
                        component={EventsCreate}
                    />
                )}
                {hasPermissionsAccessForEntities.events && (
                    <Route
                        key="EventsEditGeneral"
                        path="/events/:id/edit/general"
                        exact
                        component={EventsEditGeneral}
                    />
                )}
                {!!(
                    hasPermissionsAccessForEntities.events ||
                    hasPermissionsAccessForEntities.selfHostedEvents
                ) && (
                    <Route
                        key="EventsEditSettings"
                        path="/events/:id/edit/settings"
                        exact
                        component={EventsEditSettings}
                    />
                )}
                {!!(
                    hasPermissionsAccessForEntities.events ||
                    hasPermissionsAccessForEntities.selfHostedEvents
                ) && (
                    <Route
                        key="EventsEditParticipants"
                        path="/events/:id/edit/participants"
                        exact
                        component={EventsEditParticipants}
                    />
                )}
                {!!(
                    hasPermissionsAccessForEntities.events ||
                    hasPermissionsAccessForEntities.selfHostedEvents
                ) && (
                    <Route
                        key="EventsEditTeams"
                        path="/events/:id/edit/teams"
                        exact
                        component={EventEditTeamsListing}
                    />
                )}
                {hasPermissionsAccessForEntities.events && (
                    <Route
                        key="EventChallengesListing"
                        path="/events/:id/edit/challenges"
                        exact
                        component={EventChallengesListing}
                    />
                )}
                {hasPermissionsAccessForEntities.events && (
                    <Route
                        key="EventsEditNotification"
                        path="/events/:id/edit/notification"
                        exact
                        component={EventsEditNotification}
                    />
                )}
                {hasPermissionsAccessForEntities.events && (
                    <Route
                        key="EventsEditNotification"
                        path="/events/:id/edit/content"
                        exact
                        component={EventEditContent}
                    />
                )}
                {hasPermissionsAccessForEntities.events && (
                    <Route
                        key="EventsEditGuideline"
                        path="/events/:id/edit/guideline"
                        exact
                        component={EventEditGuidelines}
                    />
                )}

                {!!(
                    hasPermissionsAccessForEntities.events ||
                    hasPermissionsAccessForEntities.selfHostedEvents
                ) && (
                    <Route
                        key="EventInfo"
                        path="/events/:id/view/event-info"
                        exact
                        component={EventInfo}
                    />
                )}
                {!!(
                    hasPermissionsAccessForEntities.events ||
                    hasPermissionsAccessForEntities.selfHostedEvents
                ) && (
                    <Route
                        key="EventFeed"
                        path="/events/:id/view/submissions"
                        exact
                        component={EventFeed}
                    />
                )}

                {hasPermission('CAN_MANAGE_CONTACT_BUNDLE_CONTACT', meData) && (
                    <Route
                        key="ContactCreate"
                        path="/contacts/create"
                        exact
                        component={ContactCreate}
                    />
                )}
                {hasPermission('CAN_MANAGE_CONTACT_BUNDLE_CONTACT', meData) && (
                    <Route
                        key="ContactEdit"
                        path="/contacts/:id/edit"
                        exact
                        component={ContactEdit}
                    />
                )}
                {/* Media Library */}
                {hasPermission('CAN_MANAGE_MEDIA_BUNDLE_MEDIAOBJECT', meData) && (
                    <Route
                        key="MediaLibrary"
                        path="/media-library"
                        exact
                        component={MediaLibrary}
                    />
                )}
                <Redirect exact from="/" to="/dashboard" />
                <Route component={Error404} />
            </Switch>
        );
    }

    // Render the public router when the user is not signed in
    return (
        <Switch>
            <Route exact path="/" component={SignIn} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/reset-password/:token" component={ResetPassword} />
            <Route exact path="/reset-password-login" component={ResetPassword} />
            <Redirect
                to={{
                    pathname: '/',
                    state: { from: location }
                }}
            />
        </Switch>
    );
};

RouterManager.defaultProps = {
    location: {}
};

RouterManager.propTypes = {
    location: PropTypes.oneOfType([PropTypes.object])
};

export default withRouter(RouterManager);
