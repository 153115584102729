import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { SubmissionError, reset } from 'redux-form';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';

import AddRoleToUsersForm from 'erpcore/screens/Settings/Roles/components/AddRoleToUsersForm';
import ElementLoader from 'erpcore/components/ElementLoader';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import LayoutManager from 'erpcore/utils/LayoutManager';
import Listing from 'erpcore/components/Listing';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import RemoveRoleFromUser from 'erpcore/screens/Settings/Roles/components/RemoveRoleFromUser';
import RoleForm from 'erpcore/screens/Settings/Roles/components/RoleForm';
import TableActions from 'erpcore/components/Listing/components/TableActions';
import UserListingName from 'erpcore/screens/Users/components/UserListingName';

import { actions as rolesActions } from 'erpcore/screens/Settings/Roles/Roles.reducer';
import { getRoleData, getRoleFetching } from 'erpcore/screens/Settings/Roles/Roles.selectors';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import {
    getListingFetching,
    getListingResponse,
    getQueryParams
} from 'erpcore/components/Listing/Listing.selectors';
import { dtoForm } from 'erpcore/utils/dto';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { getImageSrcFromMediaObject } from 'erpcore/components/ImageManager';
import { hasPermission } from 'erpcore/utils/RolesManager';

const RoleEdit = ({ match }) => {
    const dispatch = useDispatch();
    const roleIri = `/api/roles/${match?.params?.id}`;
    const roleData = dtoForm(useSelector(state => getRoleData(state, roleIri)));
    const fetching = useSelector(getRoleFetching);
    const meData = useSelector(getMeData) || {};
    const canManageUsers = hasPermission('CAN_MANAGE_CORE_BUNDLE_USER', meData);
    const usersListing = useSelector(state => getListingResponse(state, 'users'));
    const usersListingFetching = useSelector(state => getListingFetching(state, 'users'));
    const usersListingParams = useSelector(state => getQueryParams(state, { name: 'users' }));

    const fetchRoleData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: rolesActions.START_FETCHING_ROLE,
                iri: roleIri
            });
        }).catch(error => ({ error }));
    };

    const fetchUsers = params => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: listingActions.START_FETCHING_LISTING,
                params,
                entity: 'USERS',
                name: 'users',
                endpoint: `api/users?filters[roles][in][]=${roleIri}&include=image,image.versions,office,roles`
            });
        }).catch(error => {
            return error;
        });
    };

    useEffect(() => {
        fetchRoleData();
        if (canManageUsers) {
            fetchUsers();
        }
    }, []);

    const onSubmitRoleEditForm = formData => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: rolesActions.START_UPDATE_ROLE,
                iri: roleIri,
                formData
            });
        }).catch(error => {
            throw new SubmissionError(reduxFormErrorMapper(error));
        });
    };

    const onSubmitAddRoleToUsersForm = formData => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: rolesActions.START_ADD_ROLE_TO_USERS,
                roleIri,
                formData
            });
        })
            .then(() => {
                dispatch(reset(`AddRoleToUsersForm-${roleIri}`));
                fetchUsers(usersListingParams);
            })
            .catch(error => {
                return error;
            });
    };

    const pageTitle = () => {
        if (roleData?.name) {
            return `Role edit - ${roleData.name}`;
        }

        return `Role edit`;
    };

    const tableData = () => {
        const table = {};
        table.data = [];

        table.schema = [
            {
                title: 'User name',
                field: 'name',
                mobile: 'title'
            },
            {
                title: 'Actions',
                field: 'actions'
            }
        ];

        table.defaultSort = {
            order: 'ASC',
            sortable: 'lastName'
        };

        const { data: listingData } = { ...usersListing };
        if (listingData) {
            listingData.forEach(row => {
                const { id, iri, first_name: firstName, last_name: lastName, image, office } = row;
                const userName = `${firstName || ''} ${lastName || ''}`;

                const contentUrl = getImageSrcFromMediaObject(image, 'small');

                table.data.push({
                    id,
                    iri,
                    name: <UserListingName name={userName} imageUrl={contentUrl} office={office} />,
                    actions: (
                        <TableActions>
                            <TableActions.Action>
                                <RemoveRoleFromUser
                                    roleIri={roleIri}
                                    userData={dtoForm(row)}
                                    form={`RemoveRoleFromUserForm-${iri}`}
                                    callback={() => fetchUsers(usersListingParams)}
                                />
                            </TableActions.Action>
                        </TableActions>
                    )
                });
            });
        }
        return table;
    };

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <HeadMeta title={pageTitle()} />
            <PageHeader title={pageTitle()} />
            <PageContent>
                <PageContent.Section>
                    {fetching === true && <ElementLoader overlay />}
                    <RoleForm
                        onSubmit={onSubmitRoleEditForm}
                        form={`RoleEditForm-${roleIri}`}
                        initialValues={roleData}
                        submitLabel="Update"
                    />
                </PageContent.Section>

                {canManageUsers && (
                    <PageContent.Section>
                        <PageContent.Title>Add or remove this role from user</PageContent.Title>
                        <AddRoleToUsersForm
                            onSubmit={onSubmitAddRoleToUsersForm}
                            form={`AddRoleToUsersForm-${roleIri}`}
                            roleIri={roleIri}
                        />
                        <Listing
                            name="users"
                            reducerName="users"
                            loading={usersListingFetching}
                            meta={usersListing?.meta}
                            table={tableData()}
                            onListingConfigUpdate={params => fetchUsers(params)}
                            hideHeader
                        />
                    </PageContent.Section>
                )}
            </PageContent>
        </LayoutManager>
    );
};

RoleEdit.defaultProps = {
    match: {}
};

RoleEdit.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object])
};

export default withRouter(RoleEdit);
