import React from 'react';
import Form, { TagsInput } from 'erpcore/components/Form';
import { Field } from 'redux-form';
import { valueValidation } from 'erpcore/components/Form/Form.utils';

const ChallengeTypeTrivia = () => {
    return (
        <>
            <Form.Row>
                <Field
                    name="answersTrivia"
                    fieldProps={{
                        label: 'Answers',
                        placeholder: 'Add new answer'
                    }}
                    fieldAttr={{ required: true, id: 'answersTrivia' }}
                    component={TagsInput}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
        </>
    );
};

export default ChallengeTypeTrivia;
