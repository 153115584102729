import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import Form, { Text, NumberField, MultiCreatable } from 'erpcore/components/Form';

const ImageEditableMeta = ({ displayQuality, displayTags }) => {
    return (
        <Form className="image-editor__editable-meta" onSubmit={() => {}}>
            <Form.Row>
                <Field
                    name="alt"
                    fieldProps={{
                        label: 'Alt',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'image-meta-alt' }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="title"
                    fieldProps={{
                        label: 'Title',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'image-meta-title' }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="caption"
                    fieldProps={{
                        label: 'Caption',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'image-meta-caption' }}
                    component={Text}
                />
            </Form.Row>
            {!!displayQuality && (
                <Form.Row>
                    <Field
                        name="quality"
                        fieldProps={{
                            min: 10,
                            max: 100,
                            label: 'Quality (10 - 100)'
                        }}
                        fieldAttr={{ id: 'image-options-quality' }}
                        component={NumberField}
                    />
                </Form.Row>
            )}
            {!!displayTags && (
                <Form.Row>
                    <Field
                        name="tags"
                        id="tags"
                        fieldProps={{
                            label: 'Tags',
                            options: {
                                endpoint: '/api/media-object-tags',
                                mapData: {
                                    value: 'iri',
                                    label: 'name'
                                }
                            },
                            menuPlacement: 'top'
                        }}
                        component={MultiCreatable}
                    />
                </Form.Row>
            )}
        </Form>
    );
};

ImageEditableMeta.defaultProps = {
    displayQuality: false,
    displayTags: true
};

ImageEditableMeta.propTypes = {
    displayQuality: PropTypes.bool,
    displayTags: PropTypes.bool
};

export default reduxForm({
    form: 'ImageMeta',
    enableReinitialize: true
})(ImageEditableMeta);
