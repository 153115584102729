import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Receiver } from 'react-file-uploader';
import SortableItem from 'erpcore/components/Form/components/Media/components/SortableItem';
import MediaLibraryModal from 'erpcore/components/Form/components/Media/components/MediaLibraryModal';

const MediaAddItem = ({
    id,
    isReceiverOpen,
    useMediaLibrary,
    setIsReceiverOpen,
    onImageDrop,
    input,
    allowedFileTypes,
    maxImageCount,
    isMulti,
    isDroppable
}) => {
    const [mediaLibraryOpened, setOpened] = useState(false);
    const handleMediaLibrary = event => {
        if (useMediaLibrary) {
            event.preventDefault();
            setOpened(!mediaLibraryOpened);
        }
    };

    return (
        <SortableItem key="add-new" collection="control" disabled index={0}>
            <div className="gallery__item-inner" id={id}>
                <div
                    className={`gallery__upload${
                        isReceiverOpen ? ' gallery__upload--drag-entered' : ''
                    }`}
                >
                    <label className="gallery__upload-button">
                        <input
                            multiple
                            type="file"
                            onClick={event => handleMediaLibrary(event)}
                            onChange={event => onImageDrop(event, event.target.files, true)}
                        />
                        <span className="gallery__upload-button-icon">
                            {isReceiverOpen ? '⭳' : '+'}
                        </span>
                    </label>
                    {isDroppable && (
                        <Receiver
                            wrapperId={id}
                            customClass="gallery__upload-receiver"
                            isOpen={isReceiverOpen}
                            onDragEnter={() => {
                                setIsReceiverOpen(true);
                            }}
                            onDragOver={() => {}}
                            onDragLeave={() => {
                                setIsReceiverOpen(false);
                            }}
                            onFileDrop={onImageDrop}
                        />
                    )}
                    {useMediaLibrary && (
                        <MediaLibraryModal
                            input={input}
                            maxImageCount={maxImageCount}
                            opened={mediaLibraryOpened}
                            onClose={() => setOpened(false)}
                            allowedFileTypes={allowedFileTypes}
                            isMulti={isMulti}
                        />
                    )}
                </div>
            </div>
        </SortableItem>
    );
};

MediaAddItem.defaultProps = {
    id: null,
    isReceiverOpen: false,
    useMediaLibrary: false,
    setIsReceiverOpen: () => {},
    onImageDrop: () => {},
    input: null,
    allowedFileTypes: null,
    maxImageCount: -1,
    isMulti: false,
    isDroppable: false
};
MediaAddItem.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isReceiverOpen: PropTypes.bool,
    useMediaLibrary: PropTypes.bool,
    setIsReceiverOpen: PropTypes.func,
    onImageDrop: PropTypes.func,
    input: PropTypes.oneOfType([PropTypes.object]),
    allowedFileTypes: PropTypes.oneOfType([PropTypes.array]),
    maxImageCount: PropTypes.oneOfType([PropTypes.number]),
    isMulti: PropTypes.bool,
    isDroppable: PropTypes.bool
};

export default MediaAddItem;
