export const actions = {
    START_FETCHING_EVENTS: 'START_FETCHING_EVENTS',
    FETCHING_SUCCESSFUL_EVENTS: 'FETCHING_SUCCESSFUL_EVENTS',
    FETCHING_FAILED_EVENTS: 'FETCHING_FAILED_EVENTS',

    START_DELETE_EVENT: 'START_DELETE_EVENT',
    DELETE_EVENT_SUCCESSFUL: 'DELETE_EVENT_SUCCESSFUL',
    DELETE_EVENT_FAILED: 'DELETE_EVENT_FAILED',

    START_CREATE_EVENT: 'START_CREATE_EVENT',
    CREATE_EVENT_SUCCESSFUL: 'CREATE_EVENT_SUCCESSFUL',
    CREATE_EVENT_FAILED: 'CREATE_EVENT_FAILED',

    START_FETCHING_EVENT: 'START_FETCHING_EVENT',
    FETCH_EVENT_SUCCESSFUL: 'FETCH_EVENT_SUCCESSFUL',
    FETCH_EVENT_FAILED: 'FETCH_EVENT_FAILED',
    STORE_EVENT_DATA: 'STORE_EVENT_DATA',

    START_UPDATE_EVENT: 'START_UPDATE_EVENT',
    UPDATE_EVENT_SUCCESSFUL: 'UPDATE_EVENT_SUCCESSFUL',
    UPDATE_EVENT_FAILED: 'UPDATE_EVENT_FAILED',

    START_FETCHING_LEADERBOARDS: 'START_FETCHING_LEADERBOARDS',
    FETCHING_SUCCESSFUL_LEADERBOARDS: 'FETCHING_SUCCESSFUL_LEADERBOARDS',
    FETCHING_FAILED_LEADERBOARDS: 'FETCHING_FAILED_LEADERBOARDS',

    START_FETCHING_EVENT_FEEDS: 'START_FETCHING_EVENT_FEEDS',
    FETCHING_SUCCESSFUL_EVENT_FEEDS: 'FETCHING_SUCCESSFUL_EVENT_FEEDS',
    FETCHING_FAILED_EVENT_FEEDS: 'FETCHING_FAILED_EVENT_FEEDS',

    START_UPDATE_EVENT_FEED: 'START_UPDATE_EVENT_FEED',
    UPDATE_SUCCESSFUL_EVENT_FEED: 'UPDATE_SUCCESSFUL_EVENT_FEED',
    UPDATE_FAILED_EVENT_FEED: 'UPDATE_FAILED_EVENT_FEED',

    CLEAR_EVENT_STATE: 'CLEAR_EVENT_STATE',

    END_EVENT: 'END_EVENT',
    END_EVENT_SUCCESSFUL: 'END_EVENT_SUCCESSFUL',
    END_EVENT_FAILED: 'END_EVENT_FAILED',

    COMPLETE_EVENT: 'COMPLETE_EVENT',
    COMPLETE_EVENT_SUCCESSFUL: 'COMPLETE_EVENT_SUCCESSUL',
    COMPLETE_EVENT_FAILED: 'COMPLETE_EVENT_FAILED',

    START_EVENT: 'START_EVENT',
    START_EVENT_SUCCESSFUL: 'START_EVENT_SUCCESSFUL',
    START_EVENT_FAILED: 'START_EVENT_FAILED',

    START_SEND_EVENT_INVITE_EMAILS: 'START_SEND_EVENT_INVITE_EMAILS',
    SEND_EVENT_INVITE_EMAILS_SUCCESS: 'SEND_EVENT_INVITE_EMAILS_SUCCESS',
    SEND_EVENT_INVITE_EMAILS_FAILED: 'SEND_EVENT_INVITE_EMAILS_FAILED',

    START_DUPLICATE_EVENT: 'START_DUPLICATE_EVENT',
    DUPLICATE_EVENT_SUCCESS: 'DUPLICATE_EVENT_SUCCESS',
    DUPLICATE_EVENT_FAILED: 'DUPLICATE_EVENT_FAILED',

    SEND_WELCOME_EMAIL: 'SEND_WELCOME_EMAIL',
    SEND_WELCOME_EMAIL_SUCCESS: 'SEND_WELCOME_EMAIL_SUCCESS',
    SEND_WELCOME_EMAIL_FAILED: 'SEND_WELCOME_EMAIL_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: [],
    singleFetching: false,
    singleError: null,
    deleteSingleError: null,
    leaderboards: {},
    leaderboardsFetching: false,
    eventFeeds: {},
    eventFeedsFetching: false,
    stopSingleError: null,
    duplicateEventPatching: false,
    sendingEventWelcomeEmail: false
};

export default (state = initialState, { type, response, iri }) => {
    switch (type) {
        case actions.START_DUPLICATE_EVENT: {
            return {
                ...state,
                duplicateEventPatching: true
            };
        }
        case actions.DUPLICATE_EVENT_SUCCESS: {
            return {
                ...state,
                duplicateEventPatching: false
            };
        }
        case actions.DUPLICATE_EVENT_FAILED: {
            return {
                ...state,
                duplicateEventPatching: false
            };
        }
        case actions.START_FETCHING_EVENT_FEEDS: {
            return {
                ...state,
                eventFeedsFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_EVENT_FEEDS: {
            return {
                ...state,
                eventFeedsFetching: false,
                eventFeeds: response
            };
        }
        case actions.FETCHING_FAILED_EVENT_FEEDS: {
            return {
                ...state,
                eventFeedsFetching: false
            };
        }
        case actions.START_FETCHING_EVENTS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.START_FETCHING_EVENT: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCH_EVENT_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.START_FETCHING_LEADERBOARDS: {
            return {
                ...state,
                leaderboardsFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_LEADERBOARDS: {
            return {
                ...state,
                leaderboards: response,
                leaderboardsFetching: false
            };
        }
        case actions.FETCHING_FAILED_LEADERBOARDS: {
            return {
                ...state,
                leaderboardsFetching: false
            };
        }
        case actions.STORE_EVENT_DATA: {
            return {
                ...state,
                [iri]: response?.data
            };
        }
        case actions.FETCH_EVENT_FAILED: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCHING_SUCCESSFUL_EVENTS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_EVENTS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.DELETE_EVENT_FAILED: {
            return {
                ...state,
                deleteSingleError: response
            };
        }

        case actions.UPDATE_EVENT_FAILED: {
            return {
                ...state,
                singleError: response
            };
        }

        case actions.END_EVENT_FAILED: {
            return {
                ...state,
                stopSingleError: response
            };
        }

        case actions.CLEAR_EVENT_STATE: {
            return initialState;
        }

        case actions.SEND_WELCOME_EMAIL: {
            return {
                ...state,
                sendingEventWelcomeEmail: true
            };
        }
        case actions.SEND_WELCOME_EMAIL_SUCCESS: {
            return {
                ...state,
                sendingEventWelcomeEmail: false
            };
        }

        case actions.SEND_WELCOME_EMAIL_FAILED: {
            return {
                ...state,
                sendingEventWelcomeEmail: false
            };
        }

        default:
            return state;
    }
};
