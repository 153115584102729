import React, { useState } from 'react';
import PropTypes from 'prop-types';
import 'erpcore/screens/Challenges/screens/EntityChallenges/screens/EntityChallenges/EntityChallenges.scss';
import Switch from 'erpcore/components/Switch';
import Tooltip from 'erpcore/components/Tooltip';
import Modal from 'erpcore/components/Modal';
import EntityChallengesTimerForm from 'erpcore/screens/Challenges/screens/EntityChallenges/components/EntityChallengesTimerForm';
import EntityChallengesLockedForm from 'erpcore/screens/Challenges/screens/EntityChallenges/components/EntityChallengesLockedForm';

const EntityChallengeSwitchCard = ({
    name,
    iri,
    handleOnChange,
    value,
    disabled,
    timer,
    lockedBy,
    generateLockedByEndpoint,
    tooltipContent
}) => {
    const [opened, setOpened] = useState(false);

    const handleModal = () => {
        setOpened(!opened);
    };

    const handleTitle = () => {
        switch (name) {
            case 'locked':
                return 'Add locks';
            case 'timed':
                return 'Add time';
            default:
                return null;
        }
    };

    const onSubmit = async formData => {
        await handleOnChange(true, name, iri, formData);
        handleModal();
    };

    return (
        <div className="entity-challenges__switch-wrapper">
            {disabled ? (
                <Tooltip content={tooltipContent}>
                    <Switch
                        name={name}
                        id={`${name}_${iri}`}
                        disabled={disabled}
                        onChange={changeValue => handleOnChange(changeValue, name, iri)}
                        value={value}
                    />
                </Tooltip>
            ) : (
                <Switch
                    name={name}
                    id={`${name}_${iri}`}
                    onChange={changeValue => {
                        if (
                            (!timer && name === 'timed' && changeValue) ||
                            (!lockedBy?.length && name === 'locked' && changeValue)
                        ) {
                            handleModal();
                        } else {
                            handleOnChange(changeValue, name, iri);
                        }
                    }}
                    value={value}
                    preventChangeIf={
                        (name === 'timed' && !timer) || (name === 'locked' && !lockedBy?.length)
                    }
                />
            )}
            <Modal
                opened={opened}
                variation="medium"
                title={handleTitle()}
                onClose={() => {
                    handleModal();
                }}
            >
                {name === 'locked' && (
                    <EntityChallengesLockedForm
                        onSubmit={onSubmit}
                        endpoint={generateLockedByEndpoint()}
                        iri={iri}
                    />
                )}
                {name === 'timed' && <EntityChallengesTimerForm onSubmit={onSubmit} />}
            </Modal>
        </div>
    );
};

EntityChallengeSwitchCard.defaultProps = {
    iri: null,
    name: null,
    handleOnChange: () => {},
    value: null,
    disabled: false,
    timer: null,
    lockedBy: [],
    generateLockedByEndpoint: () => {},
    tooltipContent: ''
};

EntityChallengeSwitchCard.propTypes = {
    iri: PropTypes.string,
    name: PropTypes.string,
    handleOnChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    disabled: PropTypes.bool,
    timer: PropTypes.number,
    lockedBy: PropTypes.oneOfType([PropTypes.array]),
    generateLockedByEndpoint: PropTypes.func,
    tooltipContent: PropTypes.string
};

export default EntityChallengeSwitchCard;
