const eventsActiveStatus = [
    {
        label: 'Active',
        value: 'active'
    },
    {
        label: 'Draft',
        value: 'draft'
    },
    {
        label: 'Completed',
        value: 'completed'
    }
];

const eventFeedStatus = [
    {
        label: 'Needs audit',
        value: 'needs_audit'
    },
    {
        label: 'Completed',
        value: 'completed'
    }
];

const eventFeedStatusBadges = {
    completed: {
        type: 'apple',
        label: 'Completed'
    },
    needs_audit: {
        type: 'sun',
        label: 'Needs audit'
    }
};

const filterStatus = [
    { label: 'Draft', value: 'draft' },
    { label: 'Active', value: 'active' },
    { label: 'Completed', value: 'completed' }
];

const filterState = [
    { label: 'Running', value: 'running' },
    { label: 'Paused', value: 'paused' },
    { label: 'Ended', value: 'ended' }
];

const statusBadges = {
    active: {
        type: 'sky',
        label: 'Active'
    },
    draft: {
        type: 'grey',
        label: 'Draft'
    },
    completed: {
        type: 'apple',
        label: 'Completed'
    },
    paused: {
        type: 'sun',
        label: 'Paused'
    }
};

const stateBadges = {
    running: {
        type: 'sky',
        label: 'Running'
    },
    paused: {
        type: 'sun',
        label: 'Paused'
    },
    ended: {
        type: 'apple',
        label: 'Ended'
    }
};

export {
    eventsActiveStatus,
    statusBadges,
    stateBadges,
    eventFeedStatusBadges,
    eventFeedStatus,
    filterStatus,
    filterState
};
