export const actions = {
    START_FETCHING_GUIDELINES: 'START_FETCHING_GUIDELINES',
    FETCHING_SUCCESSFUL_GUIDELINES: 'FETCHING_SUCCESSFUL_GUIDELINES',
    FETCHING_FAILED_GUIDELINES: 'FETCHING_FAILED_GUIDELINES',

    START_CREATE_GUIDELINE: 'START_CREATE_GUIDELINE',
    CREATE_GUIDELINE_SUCCESFUL: 'CREATE_GUIDELINE_SUCCESFUL',
    CREATE_GUIDELINE_FAILED: 'CREATE_GUIDELINE_FAILED',

    START_UPDATE_GUIDELINE: 'START_UPDATE_GUIDELINE',
    UPDATE_GUIDELINE_SUCCESSFUL: 'UPDATE_GUIDELINE_SUCCESSFUL',
    UPDATE_GUIDELINE_FAILED: 'UPDATE_GUIDELINE_FAILED',

    START_FETCHING_GUIDELINE: 'START_FETCHING_GUIDELINE',
    FETCHING_GUIDELINE_SUCCESSFUL: 'FETCHING_GUIDELINE_SUCCESSFUL',
    FETCHING_GUIDELINE_FAILED: 'FETCHING_GUIDELINE_FAILED',

    STORE_GUIDELINE_DATA: 'STORE_GUIDELINE_DATA',

    START_DELETE_GUIDELINE: 'START_DELETE_GUIDELINE',
    DELETE_GUIDELINE_SUCCESSFUL: 'DELETE_GUIDELINE_SUCCESSFUL',
    DELETE_GUIDELINE_FAILED: 'DELETE_GUIDELINE_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: [],
    singleFetching: false
};

export default (state = initialState, { type, response, iri }) => {
    switch (type) {
        case actions.START_FETCHING_GUIDELINES: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_GUIDELINES: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_GUIDELINES: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.START_FETCHING_GUIDELINE: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCHING_GUIDELINE_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCHING_GUIDELINE_FAILED: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.STORE_GUIDELINE_DATA: {
            return {
                ...state,
                [iri]: response.data
            };
        }
        default:
            return state;
    }
};
