import React from 'react';
import PropTypes from 'prop-types';
import Input from '../Input';

const Text = ({ input, meta, fieldProps, fieldAttr, field }) => {
    const { id, required, disabled, className } = { ...fieldAttr };

    return (
        <Input
            fieldProps={fieldProps}
            fieldAttr={fieldAttr}
            field={field}
            input={input}
            meta={meta}
            className={className}
        >
            <input
                {...input}
                id={id || input.name}
                aria-label={`${input.name}`}
                type="text"
                required={required}
                disabled={disabled}
                className="input__field"
            />
        </Input>
    );
};

Text.defaultProps = {
    fieldProps: {},
    fieldAttr: {},
    field: {},
    input: {},
    meta: {}
};

Text.propTypes = {
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    field: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object])
};

export default Text;
