import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import ActivityEditTabs from 'erpcore/screens/Activities/screens/ActivitiesEdit/components/ActivityEditTabs';
import { useRouteMatch } from 'react-router-dom';
import { actions as activityActions } from 'erpcore/screens/Activities/Activities.reducer';
import {
    getSingleActivityData,
    getSingleActivityFetching
} from 'erpcore/screens/Activities/Activities.selectors';
import PageLoader from 'erpcore/components/PageLoader';

const ActivityEditPageHeader = () => {
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const id = match?.params?.id;
    const iri = `/api/activities/${id}`;
    const fetching = useSelector(getSingleActivityFetching);
    const activityData = useSelector(state => getSingleActivityData(state, iri));
    const { name } = { ...activityData };

    const getActivityData = () => {
        const params = {
            include:
                'image,contents,contents.items,contents.items.media,contents.items.media.media,type,guideline'
        };

        return new Promise((resolve, reject) => {
            dispatch({
                type: activityActions.START_FETCHING_ACTIVITY,
                iri,
                promise: { resolve, reject },
                params
            });
        });
    };

    useEffect(() => {
        getActivityData();
    }, []);

    const getName = () => {
        if (name) {
            return `Edit template - ${name}`;
        }

        return 'Edit template';
    };

    return (
        <>
            {fetching && <PageLoader />}
            <HeadMeta title={getName()} />
            <PageHeader title={getName()} />
            <ActivityEditTabs id={id} />
        </>
    );
};

export default ActivityEditPageHeader;
