import React from 'react';
import { Field } from 'redux-form';
import Form, { Text, TextEditor } from 'erpcore/components/Form';
import PropTypes from 'prop-types';
import { valueValidation } from 'erpcore/components/Form/Form.utils';
import Collapse from 'erpcore/components/Collapse';
import EntityContentItemMediaForm from 'erpcore/screens/EntityContent/components/EntityContentItemMediaForm';

const EntityContentItemRepeaterItem = ({ member, data }) => {
    const { isNewRepeaterItem } = {
        ...data
    };

    if (!isNewRepeaterItem) {
        return (
            <Collapse>
                <Collapse.Visible>
                    <Form.Row>
                        <Field
                            name={`${member}.title`}
                            fieldProps={{
                                label: 'Name',
                                clearable: true
                            }}
                            fieldAttr={{ id: `${member}.title`, required: true }}
                            component={Text}
                            validate={valueValidation([{ validator: 'required' }])}
                        />
                    </Form.Row>
                </Collapse.Visible>
                <Collapse.Expandable>
                    <Form.Row>
                        <Field
                            name={`${member}.description`}
                            fieldProps={{
                                label: 'Description',
                                clearable: true
                            }}
                            fieldAttr={{ id: `${member}.description`, required: true }}
                            component={TextEditor}
                            validate={valueValidation([{ validator: 'required' }])}
                        />
                    </Form.Row>
                    <EntityContentItemMediaForm member={member} />
                </Collapse.Expandable>
            </Collapse>
        );
    }

    return (
        <>
            <Form.Row>
                <Field
                    name={`${member}.title`}
                    fieldProps={{
                        label: 'Name',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member}.title`, required: true }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name={`${member}.description`}
                    fieldProps={{
                        label: 'Description',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member}.description`, required: true }}
                    component={TextEditor}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>

            <Form.Row>
                <EntityContentItemMediaForm member={member} />
            </Form.Row>
        </>
    );
};

EntityContentItemRepeaterItem.defaultProps = {
    member: null,
    data: {}
};

EntityContentItemRepeaterItem.propTypes = {
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    data: PropTypes.oneOfType([PropTypes.object])
};

export default EntityContentItemRepeaterItem;
