import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { arrayMove } from 'react-sortable-hoc';
import VirtualizedTable from 'erpcore/components/Listing/components/VirtualizedTable';
import MobileDraggableList from 'erpcore/components/Listing/components/MobileDraggableList';
import MobileDraggableItem from 'erpcore/components/Listing/components/MobileDraggableItem';

const TableDraggableList = ({
    tableList,
    onDragStart,
    onDragFinished,
    schema,
    mobileOpened,
    handleMobileOpened
}) => {
    const [isSorting, setIsSorting] = useState(false);
    const [draggableItems, setDraggableItems] = useState(tableList);

    useEffect(() => {
        if (!draggableItems?.length || draggableItems?.length !== tableList?.length)
            setDraggableItems(tableList);
    }, [tableList]);

    const dragEnd = useCallback(
        sortEvent => {
            const { oldIndex, newIndex } = sortEvent;

            const newArray = arrayMove(draggableItems, oldIndex, newIndex);
            const sortedItems = newArray.map((item, index) => ({ ...item, position: index + 1 }));

            setDraggableItems(sortedItems);
            setIsSorting(false);

            document.body.style.cursor = '';

            if (onDragFinished) {
                if (newIndex < oldIndex && newIndex !== 0) {
                    onDragFinished(newArray, {
                        sourceItem: draggableItems[oldIndex],
                        destinationItem: draggableItems[newIndex - 1]
                    });
                } else if (newIndex < oldIndex && newIndex === 0) {
                    onDragFinished(newArray, {
                        sourceItem: draggableItems[oldIndex],
                        destinationItem: null
                    });
                } else {
                    onDragFinished(newArray, {
                        sourceItem: draggableItems[oldIndex],
                        destinationItem: draggableItems[newIndex]
                    });
                }
            }
        },
        [draggableItems]
    );

    const dragStart = useCallback((sortEvent, nativeEvent) => {
        setIsSorting(true);

        document.body.style.cursor = 'grabbing';

        if (onDragStart) {
            onDragStart(sortEvent, nativeEvent);
        }
    }, []);

    if (handleMobileOpened) {
        return (
            <MobileDraggableList onSortEnd={dragEnd}>
                {draggableItems.map((item, index) => (
                    <MobileDraggableItem
                        key={`item-${item?.iri}`}
                        index={index}
                        mobileOpened={mobileOpened}
                        item={item}
                        handleMobileOpened={handleMobileOpened}
                        mobileSchema={schema}
                    />
                ))}
            </MobileDraggableList>
        );
    }

    return (
        <VirtualizedTable
            isSorting={isSorting}
            tableList={draggableItems}
            onDragStart={dragStart}
            onDragEnd={dragEnd}
            schema={schema}
        />
    );
};

TableDraggableList.defaultProps = {
    schema: [],
    tableList: [],
    onDragFinished: () => {},
    onDragStart: () => {},
    handleMobileOpened: null,
    mobileOpened: []
};

TableDraggableList.propTypes = {
    schema: PropTypes.oneOfType([PropTypes.object]),
    tableList: PropTypes.oneOfType([PropTypes.array]),
    onDragFinished: PropTypes.func,
    onDragStart: PropTypes.func,
    handleMobileOpened: PropTypes.func,
    mobileOpened: PropTypes.oneOfType([PropTypes.array])
};

export default TableDraggableList;
