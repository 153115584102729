export const actions = {
    START_FETCHING_DASHBOARDS: 'START_FETCHING_DASHBOARDS',
    FETCHING_SUCCESSFUL_DASHBOARDS: 'FETCHING_SUCCESSFUL_DASHBOARDS',
    FETCHING_FAILED_DASHBOARDS: 'FETCHING_FAILED_DASHBOARDS',
    STORE_DASHBOARDS_DATA: 'STORE_DASHBOARDS_DATA',

    START_UPDATE_DASHBOARD: 'START_UPDATE_DASHBOARD',
    UPDATE_DASHBOARD_SUCCESSFUL: 'UPDATE_DASHBOARD_SUCCESSFUL',
    UPDATE_DASHBOARD_FAILED: 'UPDATE_DASHBOARD_FAILED',
    STORE_DASHBOARD_DATA: 'STORE_DASHBOARD_DATA',

    ADD_NEW_WIDGET: 'ADD_NEW_WIDGET',
    REMOVE_NEW_WIDGET: 'REMOVE_NEW_WIDGET',

    ADD_WIDGET_TO_DASHBOARD: 'ADD_WIDGET_TO_DASHBOARD',
    REMOVE_WIDGET_FROM_DASHBOARD: 'REMOVE_WIDGET_FROM_DASHBOARD'
};

export const initialState = {
    fetching: false,
    error: null,
    newWidget: null
};

export default (state = initialState, { type, response, iri, widgetId }) => {
    switch (type) {
        case actions.START_FETCHING_DASHBOARDS: {
            return {
                ...state,
                fetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_DASHBOARDS: {
            return {
                ...state,
                fetching: false
            };
        }
        case actions.FETCHING_FAILED_DASHBOARDS: {
            return {
                ...state,
                fetching: false,
                error: response
            };
        }
        case actions.STORE_DASHBOARDS_DATA: {
            const dashboards = {};

            response.data.map(dashboard => {
                dashboards[dashboard.iri] = { ...dashboard };

                return dashboards;
            });

            return { ...state, ...dashboards };
        }
        case actions.STORE_DASHBOARD_DATA: {
            return {
                ...state,
                [response.data.iri]: {
                    ...state?.[response.data.iri],
                    ...response.data
                }
            };
        }
        case actions.ADD_NEW_WIDGET: {
            return {
                ...state,
                newWidget: response
            };
        }
        case actions.REMOVE_NEW_WIDGET: {
            return {
                ...state,
                newWidget: null
            };
        }
        case actions.ADD_WIDGET_TO_DASHBOARD: {
            return {
                ...state,
                [iri]: {
                    ...state?.[iri],
                    config: {
                        ...state?.[iri]?.config,
                        widgets: { ...state?.[iri]?.config?.widgets, ...response }
                    }
                }
            };
        }
        case actions.REMOVE_WIDGET_FROM_DASHBOARD: {
            const updatedWidgets = { ...state?.[iri]?.config?.widgets };
            delete updatedWidgets[widgetId];

            return {
                ...state,
                [iri]: {
                    ...state?.[iri],
                    config: {
                        ...state?.[iri]?.config,
                        widgets: { ...updatedWidgets }
                    }
                }
            };
        }
        default:
            return state;
    }
};
