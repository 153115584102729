/**
 * Different Challenge types require different payload formats, so this switch takes care of different challenge types
 * @param formValues
 * @param challengeType
 * @return {Object}
 */
import { insertIf } from 'erpcore/utils/utils';

const formatFormValuesBasedOnType = (formValues, challengeType) => {
    switch (challengeType) {
        case 'TYPE_TRIVIA': {
            return {
                type: formValues?.type,
                enabled: formValues?.enabled,
                wall: formValues?.wall,
                timed: formValues?.timed,
                timer: formValues.timer !== undefined ? formValues.timer : undefined,
                points: formValues.points !== undefined ? formValues.points : undefined,
                media:
                    formValues?.media !== undefined
                        ? [
                              ...insertIf(formValues?.media, [
                                  { media: formValues?.media, title: '', description: '' }
                              ])
                          ]
                        : undefined,
                title: formValues?.title,
                description: formValues?.description,
                answers: formValues?.answersTrivia?.map(answer => answer.name),
                locked: formValues?.locked,
                locked_by: formValues.locked_by
            };
        }
        case 'TYPE_DATE': {
            const { constraint, day, month, answersDate } = { ...formValues };

            const dateValue = () => {
                if (constraint === 'FULL_DATE' || constraint === 'YEAR') {
                    return answersDate.toString();
                }

                return `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}`;
            };

            return {
                type: formValues?.type,
                enabled: formValues?.enabled,
                wall: formValues?.wall,
                timed: formValues?.timed,
                timer: formValues.timer !== undefined ? formValues.timer : undefined,
                points: formValues.points !== undefined ? formValues.points : undefined,
                media:
                    formValues?.media !== undefined
                        ? [
                              ...insertIf(formValues?.media, [
                                  { media: formValues?.media, title: '', description: '' }
                              ])
                          ]
                        : undefined,
                title: formValues?.title,
                description: formValues?.description,
                answers:
                    (day && month) || answersDate
                        ? {
                              constraint,
                              value: dateValue()
                          }
                        : undefined,
                locked: formValues?.locked,
                locked_by: formValues.locked_by
            };
        }
        case 'TYPE_QR': {
            return {
                type: formValues?.type,
                enabled: formValues?.enabled,
                wall: formValues?.wall,
                timed: formValues?.timed,
                timer: formValues.timer !== undefined ? formValues.timer : undefined,
                points: formValues.points !== undefined ? formValues.points : undefined,
                media:
                    formValues?.media !== undefined
                        ? [
                              ...insertIf(formValues?.media, [
                                  { media: formValues?.media, title: '', description: '' }
                              ])
                          ]
                        : undefined,
                title: formValues?.title,
                description: formValues?.description,
                answers: formValues?.answersQr ? { text: formValues?.answersQr } : undefined,
                locked: formValues?.locked,
                locked_by: formValues.locked_by
            };
        }
        case 'TYPE_PHOTO': {
            return {
                type: formValues?.type,
                enabled: formValues?.enabled,
                wall: formValues?.wall,
                timed: formValues?.timed,
                timer: formValues.timer !== undefined ? formValues.timer : undefined,
                points: formValues.points !== undefined ? formValues.points : undefined,
                media:
                    formValues?.media !== undefined
                        ? [
                              ...insertIf(formValues?.media, [
                                  { media: formValues?.media, title: '', description: '' }
                              ])
                          ]
                        : undefined,
                title: formValues?.title,
                description: formValues?.description,
                locked: formValues?.locked,
                locked_by: formValues.locked_by
            };
        }
        case 'TYPE_VIDEO': {
            return {
                type: formValues?.type,
                enabled: formValues?.enabled,
                wall: formValues?.wall,
                timed: formValues?.timed,
                timer: formValues.timer !== undefined ? formValues.timer : undefined,
                points: formValues.points !== undefined ? formValues.points : undefined,
                media:
                    formValues?.media !== undefined
                        ? [
                              ...insertIf(formValues?.media, [
                                  { media: formValues?.media, title: '', description: '' }
                              ])
                          ]
                        : undefined,
                title: formValues?.title,
                description: formValues?.description,
                locked: formValues?.locked,
                locked_by: formValues.locked_by
            };
        }
        case 'TYPE_TEXT': {
            return {
                type: formValues?.type,
                enabled: formValues?.enabled,
                wall: formValues?.wall,
                timed: formValues?.timed,
                timer: formValues.timer !== undefined ? formValues.timer : undefined,
                points: formValues.points !== undefined ? formValues.points : undefined,
                media:
                    formValues?.media !== undefined
                        ? [
                              ...insertIf(formValues?.media, [
                                  { media: formValues?.media, title: '', description: '' }
                              ])
                          ]
                        : undefined,
                title: formValues?.title,
                description: formValues?.description,
                locked: formValues?.locked,
                locked_by: formValues.locked_by
            };
        }
        case 'TYPE_STICKER_PHOTO': {
            return {
                type: formValues?.type,
                enabled: formValues?.enabled,
                wall: formValues?.wall,
                timed: formValues?.timed,
                timer: formValues.timer !== undefined ? formValues.timer : undefined,
                points: formValues.points !== undefined ? formValues.points : undefined,
                media:
                    formValues?.media !== undefined
                        ? [
                              ...insertIf(formValues?.media, [
                                  { media: formValues?.media, title: '', description: '' }
                              ])
                          ]
                        : undefined,
                title: formValues?.title,
                description: formValues?.description,
                answers: formValues?.answersSticker
                    ? { sticker: formValues?.answersSticker }
                    : undefined,
                locked: formValues?.locked,
                locked_by: formValues.locked_by
            };
        }
        case 'TYPE_MULTIPLE_CHOICE': {
            return {
                type: formValues?.type,
                enabled: formValues?.enabled,
                wall: formValues?.wall,
                timed: formValues?.timed,
                timer: formValues.timer !== undefined ? formValues.timer : undefined,
                points: formValues.points !== undefined ? formValues.points : undefined,
                media:
                    formValues?.media !== undefined
                        ? [
                              ...insertIf(formValues?.media, [
                                  { media: formValues?.media, title: '', description: '' }
                              ])
                          ]
                        : undefined,
                title: formValues?.title,
                description: formValues?.description,
                answers: formValues?.answersMultiple?.map(answer => ({
                    answer: answer?.answer,
                    is_correct: answer.is_correct || false
                })),
                locked: formValues?.locked,
                locked_by: formValues.locked_by
            };
        }

        default:
            return formValues;
    }
};

/**
 * Different types required different answers format used for form initialValues, this func formats answers object depending on type
 * @param answers
 * @param challengeType
 * @return {*|string|{month: *, day: *}|{answer: *, is_correct: boolean}[]|*[]|{name: *, id: *}[]}
 */
const formatAnswersBasedOnType = (answers = [], challengeType) => {
    switch (challengeType) {
        case 'TYPE_TRIVIA': {
            return {
                answersTrivia: answers?.map(answer => ({ name: answer, id: answer }))
            };
        }
        case 'TYPE_MULTIPLE_CHOICE': {
            return {
                answersMultiple: answers.map(({ is_correct: isCorrect = false, answer }) => ({
                    answer,
                    is_correct: isCorrect
                }))
            };
        }
        case 'TYPE_STICKER_PHOTO': {
            return {
                answersSticker: answers?.sticker
            };
        }
        case 'TYPE_QR': {
            return {
                answersQr: answers?.text
            };
        }
        case 'TYPE_DATE': {
            const result = () => {
                switch (answers?.constraint) {
                    case 'DAY_AND_MONTH':
                        return {
                            answersDate: {
                                day: answers?.value?.split('/')[1],
                                month: parseInt(answers?.value?.split('/')[0], 10)
                            }
                        };
                    case 'YEAR':
                        return {
                            answersDate: answers?.value
                        };
                    default: {
                        const d = new Date(answers?.value);

                        return {
                            answersDate: answers?.value,
                            selectedMonth: d.getMonth() + 1,
                            selectedYear: d.getFullYear()
                        };
                    }
                }
            };

            return result();
        }
        default:
            return answers;
    }
};

export { formatAnswersBasedOnType, formatFormValuesBasedOnType };
