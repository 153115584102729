export const getMediaLibraryItems = state => state?.mediaLibraryGallery?.listing || [];

export const getMediaLibraryItemsFetching = state => state?.mediaLibraryGallery?.fetching || false;

export const getMediaLibraryItemDeleting = state => state?.mediaLibraryGallery?.deleting || false;

export const getMediaLibraryItemsMeta = state => ({
    currentPage: state?.mediaLibraryGallery?.currentPage || Number(1),
    itemsPerPage: state?.mediaLibraryGallery?.itemsPerPage,
    totalItems: state?.mediaLibraryGallery?.totalItems
});
