import React from 'react';
import { Field, reduxForm } from 'redux-form';

import Form, { Text, MultiCreatable } from 'erpcore/components/Form';

const FileEditableMeta = () => {
    return (
        <Form className="image-editor__editable-meta" onSubmit={() => {}}>
            <Form.Row>
                <Field
                    name="title"
                    fieldProps={{
                        label: 'Title',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'image-meta-title' }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="tags"
                    id="tags"
                    fieldProps={{
                        label: 'Tags',
                        options: {
                            endpoint: '/api/media-object-tags',
                            mapData: {
                                value: 'iri',
                                label: 'name'
                            }
                        },
                        menuPlacement: 'top'
                    }}
                    component={MultiCreatable}
                />
            </Form.Row>
        </Form>
    );
};

FileEditableMeta.defaultProps = {};

FileEditableMeta.propTypes = {};

export default reduxForm({
    form: 'fileMeta',
    enableReinitialize: true
})(FileEditableMeta);
