import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import Button from 'erpcore/components/Button';
import Form, { CheckboxGroup, MultiAutocomplete } from 'erpcore/components/Form';
import UnsavedChanges from 'erpcore/components/UnsavedChanges';

const UserPermissionsForm = ({
    handleSubmit,
    submitting,
    pristine,
    invalid,
    onSubmit,
    roles,
    permissionsEndpoint,
    dirty
}) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <UnsavedChanges promptWhen={dirty && !submitting} onSubmit={handleSubmit(onSubmit)} />
            <Form.Row>
                <Field
                    name="roles"
                    id="roles"
                    fieldProps={{
                        label: 'Assigned roles',
                        options: roles
                    }}
                    fieldAttr={{ required: true }}
                    component={CheckboxGroup}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="permissions"
                    id="permissions"
                    fieldProps={{
                        label: 'Additional permissions',
                        options: {
                            endpoint: permissionsEndpoint,
                            mapData: {
                                value: 'iri',
                                label: 'name'
                            }
                        },
                        clearable: true
                    }}
                    component={MultiAutocomplete}
                />
            </Form.Row>
            <Form.Row>
                <Button submit disabled={pristine || invalid} loading={submitting} label="Update" />
            </Form.Row>
        </Form>
    );
};

UserPermissionsForm.defaultProps = {
    onSubmit: () => {},
    submitting: false,
    pristine: false,
    invalid: false,
    roles: [],
    permissionsEndpoint: '/api/permissions?filters[active][equals]=true',
    dirty: false
};

UserPermissionsForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    roles: PropTypes.oneOfType([PropTypes.array]),
    permissionsEndpoint: PropTypes.string,
    dirty: PropTypes.bool
};

export default reduxForm({
    form: 'UserPermissionsForm',
    enableReinitialize: true
})(UserPermissionsForm);
