import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import './Switch.scss';

const Switch = ({ name, value, label, id, onChange, disabled, preventChangeIf }) => {
    const [switchValue, setSwitchValue] = useState(value);

    useEffect(() => {
        setSwitchValue(value);
    }, [value]);

    return (
        <>
            <div className={`input-switch ${disabled ? 'input-switch--disabled' : ''}`}>
                <div className="input-switch__col">
                    <span
                        role="button"
                        aria-label={`${name || ''} switch`}
                        tabIndex={0}
                        onClick={() => {
                            if (!disabled) {
                                onChange(!switchValue);
                                if (!preventChangeIf) {
                                    setSwitchValue(!switchValue);
                                }
                            }
                        }}
                        onKeyPress={() => {
                            if (!disabled) {
                                onChange(!switchValue);
                                if (!preventChangeIf) {
                                    setSwitchValue(!switchValue);
                                }
                            }
                        }}
                        className={`input-switch__toggle ${
                            switchValue
                                ? `input-switch__toggle--active input-switch__toggle--green`
                                : ''
                        }`}
                    />
                    <input
                        type="checkbox"
                        className="input-switch__checkbox"
                        aria-label={`${name} field`}
                        defaultChecked={switchValue}
                        id={id}
                        disabled={disabled}
                        name={name}
                    />
                </div>
                {label && (
                    <div className="input-switch__col">
                        <label
                            role="button" // eslint-disable-line
                            aria-label={label}
                            tabIndex={0}
                            onClick={() => {
                                if (!disabled) {
                                    onChange(!switchValue);
                                    setSwitchValue(!switchValue);
                                }
                            }}
                            onKeyPress={() => {
                                if (!disabled) {
                                    onChange(!switchValue);
                                    setSwitchValue(!switchValue);
                                }
                            }}
                            htmlFor={id}
                            className="input-switch__option input-switch__option--clickable input-switch__option--active"
                        >
                            {label}
                        </label>
                    </div>
                )}
            </div>
        </>
    );
};

Switch.defaultProps = {
    name: 'Switch',
    value: false,
    label: '',
    id: 'Switch',
    disabled: false,
    onChange: () => {},
    preventChangeIf: false
};

Switch.propTypes = {
    name: PropTypes.string,
    value: PropTypes.bool,
    label: PropTypes.string,
    id: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    preventChangeIf: PropTypes.bool
};

export default Switch;
