import React, { useCallback, useState, useEffect } from 'react';
import Button from 'erpcore/components/Button';
import Modal from 'erpcore/components/Modal';
import PropTypes from 'prop-types';
import { Prompt, useHistory } from 'react-router-dom';

const UnsavedChanges = ({ promptWhen, onSubmit }) => {
    const [desiredLocation, setDesiredLocation] = useState(null);
    const [preventRouteTransition, setPreventRouteTransition] = useState(false);
    const [redirectUser, setRedirectUser] = useState(false);
    const [userActionDecided, setUserActionDecided] = useState(false);
    const [opened, setOpened] = useState(false);
    const history = useHistory();

    const handleRedirect = useCallback(() => {
        setUserActionDecided(true);
        setRedirectUser(true);
        setPreventRouteTransition(false);
    }, [setUserActionDecided, setRedirectUser, setPreventRouteTransition]);

    const handlePreventRedirect = useCallback(
        targetedLocation => {
            if (!userActionDecided) {
                setOpened(true);
                setDesiredLocation(targetedLocation.pathname);

                return false;
            }

            return true;
        },
        [setDesiredLocation, setOpened]
    );

    const handleSubmit = useCallback(() => {
        onSubmit();
        handleRedirect();
    }, [onSubmit, handleRedirect]);

    useEffect(() => {
        if (!promptWhen && desiredLocation) {
            setPreventRouteTransition(false);
            setRedirectUser(true);
        } else {
            setPreventRouteTransition(promptWhen);
        }
    }, [promptWhen, desiredLocation]);

    useEffect(() => {
        if (redirectUser && !preventRouteTransition) {
            history.push(desiredLocation);
        }
    }, [redirectUser, desiredLocation, preventRouteTransition]);

    return (
        <>
            <Prompt when={preventRouteTransition} message={handlePreventRedirect} />
            <Modal
                onClose={() => {
                    setOpened(false);
                    setDesiredLocation(null);
                }}
                variation="small"
                opened={opened}
                title="Unsaved changes!"
                subtitle="You've made changes on this page"
            >
                <p>
                    <b>Do you want to save or discard them?</b>
                </p>
                <Button label="Save changes and continue" size="small" onClick={handleSubmit} />
                <Button
                    label="Discard"
                    size="small"
                    variation="secondary"
                    onClick={handleRedirect}
                />
                <Button
                    label="Cancel"
                    size="small"
                    variation="secondary"
                    onClick={() => {
                        setOpened(false);
                        setDesiredLocation(null);
                    }}
                />
            </Modal>
        </>
    );
};

UnsavedChanges.propTypes = {
    promptWhen: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired
};

export default UnsavedChanges;
