import { createSelector } from 'reselect';

export const getDashboards = state => state.dashboards?.dashboards?.data || [];

export const getDashboardByName = (state, name) => {
    if (!name) {
        return {};
    }

    let dashboard = {};

    if (Object.keys(state.dashboards)?.length > 0) {
        Object.keys(state.dashboards).map(key => {
            if (state.dashboards[key]?.name === name) {
                dashboard = state.dashboards[key];
            }

            return dashboard;
        });
    }

    return dashboard || {};
};

export const getDashboardByIri = (state, iri) => {
    if (!iri) {
        return {};
    }

    return state.dashboards[iri] || {};
};

export const getNewWidgetData = state => state.dashboards?.newWidget;

export default createSelector([
    getDashboards,
    getDashboardByName,
    getDashboardByIri,
    getNewWidgetData
]);
