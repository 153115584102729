import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import EntityChallengesListing from 'erpcore/screens/Challenges/screens/EntityChallenges/screens/EntityChallenges';
import EventsEditPageHeader from 'erpcore/screens/Events/screens/EventsEdit/components/EventEditPageHeader';

const EventChallengesListing = () => {
    const match = useRouteMatch();
    const id = match?.params?.id;
    const iri = `/api/events/${id}`;

    const params = {
        'filters[event][equals]': iri,
        include: 'type,type.icon,type.icon.versions,media,media.media'
    };

    return (
        <LayoutManager slot="main" className="main--medium" layoutType="merge">
            <EventsEditPageHeader />
            <PageContent>
                <EntityChallengesListing
                    entityIri={iri}
                    endpoint="/api/event-challenges"
                    endpointParams={params}
                    entity="event"
                    batchRequestEndpoint="/api/batch-requests/event-challenges"
                    batchExportRequestEndpoint={`${iri}/export-challenges`}
                    defaultOrder={{ order: 'ASC', sortable: 'position' }}
                />
            </PageContent>
        </LayoutManager>
    );
};

export default EventChallengesListing;
