import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Form, { Select } from 'erpcore/components/Form';
import { actions as eventParticipantActions } from 'erpcore/screens/Events/screens/EventsEdit/screens/Participants/Participants.reducer';
import { Field, reduxForm } from 'redux-form';

const InlineTeamSelect = ({ id, teams, onUpdate, initialValues }) => {
    const dispatch = useDispatch();
    const { event_team: eventTeam } = { ...initialValues };

    const onSubmit = value => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: eventParticipantActions.START_UPDATING_EVENT_PARTICIPANT,
                formData: { event_team: value },
                id
            })
        )
            .then(() => {
                if (onUpdate && typeof onUpdate === 'function') onUpdate();
            })
            .catch(error => {
                return error;
            });
    };

    return (
        <Form>
            <Form.Row>
                <Field
                    name="event_team"
                    id="event_team"
                    fieldProps={{
                        label: '',
                        options: teams,
                        clearable: false
                    }}
                    onChange={value => {
                        if (value !== eventTeam) onSubmit(value);
                    }}
                    component={Select}
                />
            </Form.Row>
        </Form>
    );
};

InlineTeamSelect.defaultProps = {
    teams: [],
    onUpdate: () => {},
    initialValues: {}
};

InlineTeamSelect.propTypes = {
    id: PropTypes.string.isRequired,
    teams: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onUpdate: PropTypes.func,
    initialValues: PropTypes.oneOfType([PropTypes.object])
};

export default reduxForm({
    enableReinitialize: true
})(InlineTeamSelect);
