import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { reduxForm } from 'redux-form';
import Form from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';
import Svg from 'erpcore/components/Svg';
import Tooltip from 'erpcore/components/Tooltip';
import Modal from 'erpcore/components/Modal';
import { actions as eventActions } from 'erpcore/screens/Events/Events.reducer';

const SendSingleEmail = ({ eventId, participantIri, handleSubmit, submitting, name, onSend }) => {
    const [opened, setOpened] = useState(false);

    const dispatch = useDispatch();

    const handleModal = () => {
        setOpened(!opened);
    };

    const onSubmit = () => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: eventActions.START_SEND_EVENT_INVITE_EMAILS,
                id: eventId,
                payload: [participantIri]
            })
        )
            .then(() => {
                handleModal();
                if (onSend && typeof onSend === 'function') onSend();
            })
            .catch(error => {
                handleModal();
                return error;
            });
    };
    return (
        <Fragment>
            <Tooltip content="Send invitation">
                <button type="button" onClick={handleModal}>
                    <Svg icon="envelope" />
                </button>
            </Tooltip>
            <Modal
                variation="small"
                opened={opened}
                onClose={() => handleModal()}
                title="Send email"
                subtitle={
                    <>
                        Are you sure you want to send email to participant <strong>{name}</strong>?
                    </>
                }
            >
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Button submit loading={submitting} label="Send" />
                    <Button variation="secondary" label="Cancel" onClick={() => handleModal()} />
                </Form>
            </Modal>
        </Fragment>
    );
};

SendSingleEmail.defaultProps = {
    submitting: false,
    eventId: null,
    onSend: () => {}
};

SendSingleEmail.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string.isRequired,
    onSend: PropTypes.func,
    participantIri: PropTypes.string.isRequired
};

export default reduxForm({
    enableReinitialize: true
})(SendSingleEmail);
