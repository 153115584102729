import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { diff } from 'deep-object-diff';
import { dtoForm } from 'erpcore/utils/dto';
import LayoutManager from 'erpcore/utils/LayoutManager';
import ContactCreateEditForm from 'erpcore/screens/Contacts/components/ContactCreateEditForm';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import PageContent from 'erpcore/components/Layout/PageContent';
import { actions as contactsActions } from 'erpcore/screens/Contacts/Contacts.reducer';
import {
    getSingleContactFetching,
    getContactData
} from 'erpcore/screens/Contacts/Contacts.selectors';
import PageLoader from 'erpcore/components/PageLoader';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';

const ContactEdit = ({ match }) => {
    const dispatch = useDispatch();
    const contactIri = `/api/contacts/${match?.params?.id}`;
    const contactData = useSelector(state => dtoForm(getContactData(state, contactIri)) || {});
    const fetching = useSelector(getSingleContactFetching);
    const params = {
        include: 'company'
    };

    const initialValues = {
        first_name: contactData.first_name,
        last_name: contactData.last_name,
        phone: contactData.phone || '+1',
        email: contactData.email,
        company: contactData.company,
        job_title: contactData.job_title
    };

    const pageTitle = () => {
        const { first_name: firstName, last_name: lastName } = { ...contactData };
        if (firstName && lastName) {
            return `Contact - ${firstName} ${lastName}`;
        }

        return `Contact`;
    };

    const fetchContactData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: contactsActions.START_FETCHING_CONTACT,
                iri: contactIri,
                promise: { resolve, reject },
                params
            });
        });
    };

    useEffect(() => {
        fetchContactData();
    }, []);

    const onSubmitEditContactForm = formData => {
        formData = diff(contactData, formData);
        return new Promise((resolve, reject) => {
            dispatch({
                type: contactsActions.START_UPDATE_CONTACT,
                promise: { resolve, reject },
                iri: contactIri,
                formData,
                params
            });
        });
    };

    return (
        <LayoutManager className="main--narrow" slot="main" layoutType="merge">
            <HeadMeta title={pageTitle()} />
            <PageHeader title={pageTitle()} />
            <PageContent>
                {fetching === true && <PageLoader />}
                <ContactCreateEditForm
                    initialValues={initialValues}
                    onSubmit={onSubmitEditContactForm}
                    form="ContactEditForm"
                    submitLabel="Update"
                />
            </PageContent>
        </LayoutManager>
    );
};

ContactEdit.defaultProps = {
    match: {}
};

ContactEdit.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object])
};

export default ContactEdit;
