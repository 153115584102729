import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import Form, { Text, MultiAutocomplete } from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';
import Svg from 'erpcore/components/Svg';
import Tooltip from 'erpcore/components/Tooltip';
import Modal from 'erpcore/components/Modal';
import { actions as eventActions } from 'erpcore/screens/Events/Events.reducer';

const EventSendInvitations = ({
    id,
    form,
    handleSubmit,
    submitting,
    callback,
    initialValues,
    isOpened,
    onClose
}) => {
    const dispatch = useDispatch();
    const [opened, setOpened] = useState(false);

    const formDataRedux = useSelector(state => getFormValues(form)(state));
    const allParticipants =
        formDataRedux?.participants?.length === initialValues?.participants?.length;

    const handleModal = () => {
        setOpened(!opened);
        if (opened && onClose && typeof onClose === 'function') onClose();
    };

    useEffect(() => {
        if (isOpened !== null) setOpened(isOpened);
    }, [isOpened]);

    const onSubmit = formData => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: eventActions.START_SEND_EVENT_INVITE_EMAILS,
                id,
                payload: allParticipants ? '' : formData.participants
            })
        )
            .then(() => {
                callback();
            })
            .catch(error => {
                return error;
            })
            .finally(() => {
                handleModal();
            });
    };

    return (
        <Fragment>
            {isOpened === null && (
                <Tooltip content="Send invitation">
                    <button type="button" onClick={handleModal}>
                        <Svg icon="envelope" />
                    </button>
                </Tooltip>
            )}
            {opened && (
                <Modal
                    variation="large"
                    opened={opened}
                    onClose={handleModal}
                    title={
                        allParticipants
                            ? 'Send mail to all participants'
                            : 'Send mail to selected participants'
                    }
                >
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Row>
                            <Field
                                name="name"
                                id="name"
                                fieldProps={{
                                    label: 'Event name'
                                }}
                                fieldAttr={{ disabled: true }}
                                component={Text}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="participants"
                                id="participants"
                                fieldProps={{
                                    label: 'Participants',
                                    options: {
                                        endpoint: `api/event-participants?filters[event.id][equals]=${id}`,
                                        mapData: {
                                            value: 'iri',
                                            label: 'email'
                                        }
                                    }
                                }}
                                component={MultiAutocomplete}
                            />
                        </Form.Row>
                        {allParticipants && (
                            <Form.Row>
                                <p>
                                    <span className="color--red">Warning:</span> This action will
                                    send all email to all participants of this event.
                                </p>
                            </Form.Row>
                        )}
                        <Button submit loading={submitting} label="Send mails" />
                        <Button variation="secondary" label="Cancel" onClick={handleModal} />
                    </Form>
                </Modal>
            )}
        </Fragment>
    );
};

EventSendInvitations.defaultProps = {
    submitting: false,
    callback: () => {},
    isOpened: null,
    onClose: () => {}
};

EventSendInvitations.propTypes = {
    form: PropTypes.string.isRequired,
    initialValues: PropTypes.oneOfType([PropTypes.object]).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    callback: PropTypes.func,
    isOpened: PropTypes.bool,
    onClose: PropTypes.func
};

export default reduxForm({
    enableReinitialize: true
})(EventSendInvitations);
