import React, { useState, useEffect } from 'react';
import LayoutManager from 'erpcore/utils/LayoutManager';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import PageNotifications from 'erpcore/components/Layout/PageNotifications';
import PageContent from 'erpcore/components/Layout/PageContent';
import ChallengeCreateEditForm from 'erpcore/screens/Challenges/components/ChallengeCreateEditForm';
import { actions as challengeActions } from 'erpcore/screens/Challenges/Challenges.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { formatFormValuesBasedOnType } from 'erpcore/screens/Challenges/Challenges.utils';
import { useHistory } from 'react-router-dom';
import { getFormValues, reset } from 'redux-form';
import { getChallengeTypes } from 'erpcore/screens/Challenges/Challenges.selectors';

const ChallengeCreate = () => {
    const [selectedType, setSelectedType] = useState(null);
    const dispatch = useDispatch();
    const title = 'Create new challenge';
    const history = useHistory();
    const formValues = useSelector(state => getFormValues('ChallengeCreateEditForm')(state));
    const challengeTypes = useSelector(getChallengeTypes);
    const { type } = { ...formValues };

    useEffect(() => {
        if (type) {
            const identifier = challengeTypes?.data?.find(
                challengeType => challengeType?.iri === type
            );

            setSelectedType(identifier);
        }
    }, [type]);

    const onSubmit = ({
        formData,
        challengeType,
        preventEditRedirect = false,
        addAnother = false
    }) => {
        const values = formatFormValuesBasedOnType(formData, challengeType);

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                formData: values,
                type: challengeActions.START_CREATE_CHALLENGE
            });
        })
            .then(() => {
                if (!preventEditRedirect && !addAnother) {
                    history.push(`/challenges`);
                }
                if (addAnother) {
                    dispatch(reset('ChallengeCreateEditForm'));
                }
            })
            .catch(err => err);
    };

    return (
        <LayoutManager className="main--narrow" slot="main" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title} />
            <PageNotifications />
            <PageContent>
                <ChallengeCreateEditForm
                    form="ChallengeCreateEditForm"
                    onSubmit={onSubmit}
                    submitLabel="Create"
                    wallDisabled={!selectedType?.wallable}
                />
            </PageContent>
        </LayoutManager>
    );
};

export default ChallengeCreate;
