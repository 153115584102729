import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import Svg from 'erpcore/components/Svg';
import { generateKey } from 'erpcore/utils/utils';
import React from 'react';
import PropTypes from 'prop-types';

const SortableDragHandle = SortableHandle(() => (
    <span className="table__body-data--drag">
        <Svg className="table__body-data--drag-icon" icon="drag" />
    </span>
));

const SortableItem = ({ mobileSchema, mobileOpened, item, handleMobileOpened, index }) => {
    return (
        <div
            index={index}
            key={generateKey(item.iri)}
            className={`table-mobile__list-item ${
                mobileOpened.includes(item.iri) ? 'table-mobile__list-item--active' : ''
            }`}
        >
            <ul className="table-mobile__list-header">
                <SortableDragHandle />
                <li className="table-mobile__list-title">{item[mobileSchema.title[0].field]}</li>
                {mobileSchema.status.length > 0 && (
                    <li className="table-mobile__list-status">
                        {item[mobileSchema.status[0].field]}
                    </li>
                )}
                {(mobileSchema.table.length > 0 || item.actions) && (
                    <li className="table-mobile__list-arrow">
                        <Svg icon="arrowRight" />
                        <button
                            type="button"
                            className="table-mobile__btn"
                            onClick={() => handleMobileOpened(item.iri)}
                            onKeyPress={() => handleMobileOpened(item.iri)}
                        />
                    </li>
                )}
            </ul>
            {mobileSchema.subtitle.length > 0 && (
                <ul className="table-mobile__list-subtitle">
                    {mobileSchema.subtitle.map(subtitleItem => {
                        return (
                            <li
                                key={generateKey(subtitleItem.field)}
                                className="table-mobile__list-subtitle-item"
                            >
                                {item[subtitleItem.field]}
                            </li>
                        );
                    })}
                </ul>
            )}
            <div className="table-mobile__additional">
                {mobileSchema.table.length > 0 && (
                    <table className="table-mobile__table">
                        <tbody>
                            {mobileSchema.table.map(tableItem => {
                                return (
                                    <tr
                                        key={generateKey(tableItem.field)}
                                        className="table-mobile__table-row"
                                    >
                                        <td className="table-mobile__table-col-left">
                                            {tableItem.title}:
                                        </td>
                                        <td className="table-mobile__table-col-right">
                                            {item[tableItem.field]}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                )}
                {item.actions && <div className="table-mobile__actions">{item.actions}</div>}
            </div>
        </div>
    );
};

SortableItem.defaultProps = {
    mobileSchema: {},
    mobileOpened: false,
    item: {},
    handleMobileOpened: () => {}
};

SortableItem.propTypes = {
    mobileSchema: PropTypes.oneOfType([PropTypes.array]),
    mobileOpened: PropTypes.bool,
    item: PropTypes.oneOfType([PropTypes.object]),
    handleMobileOpened: PropTypes.func,
    index: PropTypes.number.isRequired
};

export default SortableElement(SortableItem);
