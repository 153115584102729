import React from 'react';
import PropTypes from 'prop-types';

import './Switch.scss';

const Switch = ({ input, meta, fieldProps, fieldAttr }) => {
    const { name, value = false, onChange } = { ...input };
    const { label } = { ...fieldProps };
    const { id } = { ...fieldAttr };
    const { touched, error } = { ...meta };

    return (
        <>
            <div className="input-switch">
                <div className="input-switch__col">
                    <button
                        type="button"
                        aria-label={`${name || ''} switch`}
                        onClick={() => onChange(!value)}
                        disabled={!!fieldAttr?.disabled}
                        className={`input-switch__toggle ${
                            value ? `input-switch__toggle--active input-switch__toggle--green` : ''
                        }`}
                    />
                    <input
                        type="checkbox"
                        className="input-switch__checkbox"
                        aria-label={`${name} field`}
                        defaultChecked={value}
                        id={id}
                        name={name}
                        disabled={!!fieldAttr?.disabled}
                    />
                </div>
                {label && (
                    <div className="input-switch__col">
                        <label // eslint-disable-line
                            role="button" // eslint-disable-line
                            aria-label={label}
                            tabIndex={0}
                            onClick={!fieldAttr?.disabled ? () => onChange(!value) : null}
                            // onKeyPress={() => onChange(!value)}
                            htmlFor={id}
                            className="input-switch__option input-switch__option--clickable input-switch__option--active"
                        >
                            {label}
                        </label>
                    </div>
                )}
            </div>
            {touched && error && (
                <span className="input-switch__error">{error.message ? error.message : error}</span>
            )}
        </>
    );
};

Switch.defaultProps = {
    input: {},
    meta: {},
    fieldProps: {},
    fieldAttr: {}
};

Switch.propTypes = {
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object])
};

export default Switch;
