import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import Listing from 'erpcore/components/Listing';
import TableActions from 'erpcore/components/Listing/components/TableActions';
import Button from 'erpcore/components/Button';
import Svg from 'erpcore/components/Svg';
import ContactsDelete from 'erpcore/screens/Contacts/components/ContactsDelete';
import Tooltip from 'erpcore/components/Tooltip';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import {
    getListingFetching,
    getListingResponse
} from 'erpcore/components/Listing/Listing.selectors';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
// import { actions as contactsActions } from 'erpcore/screens/Contacts/Contacts.reducer'; // temp removed import/export

const ContactsListing = () => {
    const reducerName = 'contacts';
    const actionName = 'CONTACTS';

    const dispatch = useDispatch();
    // const [isExporting, setIsExporting] = useState(false); // temp removed import/export
    const listing = useSelector(state => getListingResponse(state, reducerName));
    const listingFetching = useSelector(state => getListingFetching(state, reducerName));
    const title = 'Contacts';

    const fetchContacts = params => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: listingActions.START_FETCHING_LISTING,
                params,
                entity: actionName,
                name: reducerName,
                endpoint: 'api/contacts?include=company'
            });
        }).catch(error => {
            return error;
        });
    };

    // temp removed import/export
    /*
    const exportContacts = () => {
        setIsExporting(true);
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: contactsActions.START_EXPORTING_CONTACTS
            });
        })
            .then(response => {
                setIsExporting(false);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `contacts-export.csv`);
                link.click();
            })
            .catch(error => {
                setIsExporting(false);
                return error;
            });
    };
    */

    const renderCompany = company => {
        if (company) {
            if (!company?.deleted) {
                return (
                    <NavLink to={`/companies/${company?.id}/edit/general-info`}>
                        {company?.name}
                    </NavLink>
                );
            }
            return company?.name;
        }

        return null;
    };

    const tableData = () => {
        const table = {};
        table.data = [];

        table.filters = [
            {
                name: 'company',
                label: 'Company',
                defaultOperator: 'in',
                filterFields: {
                    value: {
                        component: 'multiautocomplete',
                        fieldProps: {
                            label: 'Company',
                            options: {
                                endpoint: `/api/companies`,
                                mapData: {
                                    value: 'iri',
                                    label: 'name'
                                }
                            },
                            clearable: false
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            }
        ];

        table.schema = [
            {
                title: 'Name',
                field: 'name',
                sortable: 'firstName',
                mobile: 'title'
            },
            {
                title: 'Job title',
                field: 'jobTitle'
            },
            {
                title: 'Phone',
                field: 'phone'
            },
            {
                title: 'Email',
                field: 'email'
            },
            {
                title: 'Company',
                field: 'company',
                sortable: 'company.name'
            },
            {
                title: 'Actions',
                field: 'actions'
            }
        ];

        table.defaultSort = {
            order: 'ASC',
            sortable: 'lastName'
        };

        if (listing.data) {
            listing.data.map(row => {
                const {
                    id,
                    iri,
                    first_name: firstName,
                    last_name: lastName,
                    job_title: jobTitle,
                    phone,
                    email,
                    company
                } = { ...row };

                return table.data.push({
                    id,
                    iri,
                    name: `${firstName || ''} ${lastName || ''}`,
                    jobTitle,
                    phone,
                    email,
                    company: renderCompany(company),
                    actions: (
                        <TableActions>
                            <TableActions.Action>
                                <Tooltip content="Edit contact">
                                    <NavLink to={`/contacts/${id}/edit`}>
                                        <Svg icon="edit" />
                                    </NavLink>
                                </Tooltip>
                            </TableActions.Action>
                            <TableActions.Action>
                                <ContactsDelete
                                    id={id}
                                    key={`ContactsDelete ${id}`}
                                    name={`${firstName || ''} ${lastName || ''}`}
                                />
                            </TableActions.Action>
                        </TableActions>
                    )
                });
            });
        }

        return table;
    };

    return (
        <LayoutManager slot="main" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title}>
                <Button href="/contacts/create" label="Create new" />
            </PageHeader>
            <Listing
                name={reducerName}
                reducerName={reducerName}
                loading={listingFetching}
                meta={listing?.meta}
                table={tableData()}
                onListingConfigUpdate={params => fetchContacts(params)}
            >
                {/* temp removed import/export */}
                {/*
                <Button
                    onClick={exportContacts}
                    size="small"
                    label="Export"
                    variation="secondary"
                    loading={isExporting}
                />
                <Button size="small" label="Import" variation="secondary" />
                */}
            </Listing>
        </LayoutManager>
    );
};

export default ContactsListing;
