import React from 'react';
import PropTypes from 'prop-types';
import Notification from 'erpcore/components/Notification';

/**
 * Notification Manager - Activity Challenges Notifications
 * @return {Node} Returns Notification component
 */
// eslint-disable-next-line no-unused-vars
const ActivityChallengesNotifications = ({ identifier, code, detail, notificationData }) => {
    switch (code) {
        case 'activitychallenge.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Template challenge successfully created!"
                    type="success"
                    expire="3500"
                />
            );
        case 'activitychallenge.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Template challenge successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'activitychallenge.itemSuccessfulyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Template challenge successfully deleted!"
                    type="success"
                    expire="3500"
                />
            );
        case 'challenge.collectionSuccessfullyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Template challenge positions successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'challenge.bulkActions.challengesSuccessfullyDelete':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Deleting challenges is set to queue, it might take some time to complete. Try refreshing the page couple of times."
                    type="success"
                    expire="5500"
                />
            );
        case 'challenge.bulkActions.challengesSuccessfullyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Challenges successfully updated!"
                    type="success"
                    expire="5500"
                />
            );
        default:
            return null;
    }
};

ActivityChallengesNotifications.defaultProps = {
    identifier: null,
    code: null,
    detail: null,
    notificationData: null
};

ActivityChallengesNotifications.propTypes = {
    identifier: PropTypes.string,
    code: PropTypes.string,
    detail: PropTypes.string,
    notificationData: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])
};

export default ActivityChallengesNotifications;
