import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { useDispatch } from 'react-redux';
import Svg from 'erpcore/components/Svg';
import Tooltip from 'erpcore/components/Tooltip';
import Modal from 'erpcore/components/Modal';
import Form from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';
import { actions as mediaLibraryActions } from '../../MediaLibrary.reducer';

const MediaLibraryDelete = ({ iri, onComplete, handleSubmit, submitting }) => {
    const dispatch = useDispatch();
    const [modalOpened, setModalOpened] = useState(false);

    const handleModal = () => {
        setModalOpened(!modalOpened);
    };

    const onSubmit = () => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: mediaLibraryActions.START_DELETING_MEDIA_LIBRARY_ITEM,
                iri
            })
        )
            .then(() => {
                if (onComplete && typeof onComplete === 'function') onComplete();
            })
            .catch(error => {
                return error;
            })
            .finally(() => {
                handleModal();
            });
    };

    return (
        <>
            <Tooltip content="Delete media">
                <button type="button" onClick={() => handleModal()}>
                    <Svg icon="remove" />
                </button>
            </Tooltip>
            {modalOpened && (
                <Modal
                    variation="small"
                    opened={modalOpened}
                    onClose={handleModal}
                    title="Delete media"
                    subtitle={
                        <>
                            <span className="color--red">Warning:</span> Deleting this media object
                            will remove it from everywhere where it is used. This action is
                            irreversible. <br />
                            <br />
                            Are you sure?
                        </>
                    }
                >
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Button submit loading={submitting} label="Delete" />
                        <Button variation="secondary" label="Cancel" onClick={handleModal} />
                    </Form>
                </Modal>
            )}
        </>
    );
};

MediaLibraryDelete.defaultProps = {
    onComplete: () => {},
    submitting: false
};

MediaLibraryDelete.propTypes = {
    iri: PropTypes.string.isRequired,
    onComplete: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool
};

export default reduxForm({
    enableReinitialize: true
})(MediaLibraryDelete);
