import React from 'react';
import PropTypes from 'prop-types';
import Notification from 'erpcore/components/Notification';

/**
 * Notification Manager - Challenges Notifications
 * @return {Node} Returns Notification component
 */
// eslint-disable-next-line no-unused-vars
const EventsNotification = ({ identifier, code, detail, notificationData }) => {
    switch (code) {
        case 'event.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Event successfully created!"
                    type="success"
                    expire="3500"
                />
            );
        case 'event.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Event successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'event.itemSuccessfulyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Event successfully deleted!"
                    type="success"
                    expire="3500"
                />
            );
        case 'eventnotification.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Event notification successfully created!"
                    type="success"
                    expire="3500"
                />
            );
        case 'eventnotification.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Event notification successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'eventnotification.itemSuccessfulyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Event notification successfully deleted!"
                    type="success"
                    expire="3500"
                />
            );

        case 'eventnotification.singleEventsStopped':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Event successfully stopped!"
                    type="success"
                    expire="3500"
                />
            );

        case 'eventNotification.emailInvitationSentAll':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Email invitations sent to all participants!"
                    type="success"
                    expire="3500"
                />
            );

        case 'eventNotification.emailInvitationSent':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Email invitations sent to selected participants!"
                    type="success"
                    expire="3500"
                />
            );

        case 'event.duplicated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Event successfully duplicated!"
                    type="success"
                    expire="3500"
                />
            );

        case 'eventparticipant.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Event participant successfully created!"
                    type="success"
                    expire="3500"
                />
            );

        case 'eventparticipant.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Event participant successfully updated!"
                    type="success"
                    expire="3500"
                />
            );

        case 'eventparticipant.itemTeamLeadUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Event participant successfully added as a team leader!"
                    type="success"
                    expire="3500"
                />
            );

        case 'eventparticipant.itemSuccessfulyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Event participant successfully deleted!"
                    type="success"
                    expire="3500"
                />
            );

        case 'eventparticipant.importSuccess':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Event participants successfully imported!"
                    type="success"
                    expire="3500"
                />
            );

        case 'eventteam.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Event team successfully created!"
                    type="success"
                    expire="3500"
                />
            );

        case 'eventteam.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Event team successfully updated!"
                    type="success"
                    expire="3500"
                />
            );

        case 'eventteam.itemSuccessfulyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Event team successfully deleted!"
                    type="success"
                    expire="3500"
                />
            );

        case 'event.teamsReshuffled':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Event team successfully reshuffled!"
                    type="success"
                    expire="3500"
                />
            );

        case 'event.started':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="The event has successfully started!"
                    type="success"
                    expire="3500"
                />
            );
        case 'event.eventClickPause':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="The event has successfully paused!"
                    type="success"
                    expire="3500"
                />
            );
        case 'event.eventClickResume':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="The event has successfully resumed!"
                    type="success"
                    expire="3500"
                />
            );
        case 'event.eventClickComplete':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="The event has successfully completed!"
                    type="success"
                    expire="3500"
                />
            );

        case 'eventchallenge.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Event challenge successfully created!"
                    type="success"
                    expire="3500"
                />
            );
        case 'eventchallenge.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Event challenge successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'eventchallenge.itemSuccessfulyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Event challenge successfully removed!"
                    type="success"
                    expire="3500"
                />
            );
        case 'event.createdNotificationSent':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Welcome email sent!"
                    type="success"
                    expire="3500"
                />
            );
        case 'event.createdNotificationFailed':
            return (
                <Notification
                    identifier={identifier}
                    title="Warning"
                    text="The email was not sent. Please check are all requirements fulfilled."
                    type="warning"
                    expire="3500"
                />
            );
        default:
            return null;
    }
};

EventsNotification.defaultProps = {
    identifier: null,
    code: null,
    detail: null,
    notificationData: null
};

EventsNotification.propTypes = {
    identifier: PropTypes.string,
    code: PropTypes.string,
    detail: PropTypes.string,
    notificationData: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])
};

export default EventsNotification;
