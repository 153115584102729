import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import Modal from 'erpcore/components/Modal';
import Form, { Text } from 'erpcore/components/Form';
import { valueValidation } from 'erpcore/components/Form/Form.utils';
import Button from 'erpcore/components/Button';
import Svg from 'erpcore/components/Svg';
import Tooltip from 'erpcore/components/Tooltip';
import { actions as eventTeamActions } from '../../Teams.reducer';

const AddEditTeamModal = ({
    id,
    isEdit,
    isOpened,
    eventIri,
    onClose,
    onComplete,
    handleSubmit,
    submitting,
    pristine,
    invalid
}) => {
    const dispatch = useDispatch();
    const [opened, setOpened] = useState(false);

    const handleModal = () => {
        setOpened(!opened);
        if (opened && onClose && typeof onClose === 'function') onClose();
    };

    useEffect(() => {
        setOpened(isOpened);
    }, [isOpened, setOpened]);

    const onSubmit = formData => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: isEdit
                    ? eventTeamActions.START_EVENT_TEAM_UPDATE
                    : eventTeamActions.START_EVENT_ADD_TEAM,
                id,
                eventIri,
                formData
            })
        )
            .then(() => {
                handleModal();
                if (onComplete && typeof onComplete === 'function') onComplete();
            })
            .catch(error => {
                handleModal();
                return error;
            });
    };

    return (
        <>
            {isEdit && (
                <Tooltip content="Edit team">
                    <button type="button" onClick={() => handleModal()}>
                        <Svg icon="edit" />
                    </button>
                </Tooltip>
            )}
            <Modal
                opened={opened}
                variation="medium"
                onClose={() => handleModal()}
                title={isEdit ? 'Edit team' : 'Add new team'}
            >
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Row>
                        <Field
                            name="name"
                            id="name"
                            fieldProps={{
                                label: 'Name',
                                clearable: true
                            }}
                            component={Text}
                            fieldAttr={{ required: true }}
                            validate={valueValidation([{ validator: 'required' }])}
                        />
                    </Form.Row>
                    <Button
                        submit
                        disabled={pristine || invalid}
                        loading={submitting}
                        label={isEdit ? 'Update' : 'Add'}
                    />
                    <Button variation="secondary" label="Cancel" onClick={() => handleModal()} />
                </Form>
            </Modal>
        </>
    );
};

AddEditTeamModal.defaultProps = {
    id: null,
    isOpened: false,
    submitting: false,
    pristine: false,
    invalid: false,
    isEdit: false,
    onClose: null,
    onComplete: () => {}
};

AddEditTeamModal.propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isOpened: PropTypes.bool,
    eventIri: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    isEdit: PropTypes.bool,
    onComplete: PropTypes.func
};

export default reduxForm({
    enableReinitialize: true
})(AddEditTeamModal);
