import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';
import { SubmissionError } from 'redux-form';
import { getIdFromIri } from 'erpcore/utils/dto';

import LayoutManager from 'erpcore/utils/LayoutManager';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import PageContent from 'erpcore/components/Layout/PageContent';
import UserCreateForm from 'erpcore/screens/Users/components/UserCreateForm';

import { actions as usersActions } from 'erpcore/screens/Users/Users.reducer';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import './UserCreate.scss';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';

const UserCreate = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const title = 'Add new user';

    const onSubmit = ({ formData, preventEditRedirect = false }) => {
        if (formData?.userTypes) {
            formData.roles = [formData.userTypes];
            delete formData.userTypes;
        }

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: usersActions.START_CREATE_USER,
                formData
            });
        })
            .then(response => {
                if (!preventEditRedirect) {
                    history.push(`/users/${getIdFromIri(response?.data?.id)}/edit/details`);
                }
            })
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    };

    // Fetch Roles
    const fetchRoles = () => {
        const params = { pagination: false };

        dispatch({
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'ROLES',
            name: 'roles',
            endpoint: 'api/roles'
        });
    };

    /*
     * Effects
     */
    useEffect(() => {
        fetchRoles();
    }, []);

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title} />
            <PageContent>
                <UserCreateForm onSubmit={onSubmit} form="UserCreateForm" submitLabel="Create" />
            </PageContent>
        </LayoutManager>
    );
};

export default UserCreate;
