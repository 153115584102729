import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import PageContent from 'erpcore/components/Layout/PageContent';
import CustomersEditTabs from 'erpcore/screens/Customers/components/CustomersEditTabs';
import Button from 'erpcore/components/Button';
import Listing from 'erpcore/components/Listing';
import { dtoForm } from 'erpcore/utils/dto';
import { useSelector, useDispatch } from 'react-redux';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import CustomersContactsCreate from 'erpcore/screens/Customers/components/CustomerContactsCreate';
import CustomersContactEdit from 'erpcore/screens/Customers/components/CustomerContactEdit';
import {
    getListingFetching,
    getListingResponse
} from 'erpcore/components/Listing/Listing.selectors';
import TableActions from 'erpcore/components/Listing/components/TableActions';

import CustomersContactDelete from 'erpcore/screens/Customers/components/CustomerContactDelete';
import { actions as customerActions } from 'erpcore/screens/Customers/Customers.reducer';
import { getCustomerData } from 'erpcore/screens/Customers/Customers.selectors';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';

const CustomersContacts = ({ match }) => {
    const reducerName = 'customerContacts';
    const actionName = 'CUSTOMER_CONTACTS';
    const dispatch = useDispatch();
    const customerIri = `/api/companies/${match?.params?.id}`;

    const listing = useSelector(state => getListingResponse(state, reducerName));
    const listingFetching = useSelector(state => getListingFetching(state, reducerName));
    const customerData = useSelector(state => getCustomerData(state, customerIri));

    const pageTitle = () => {
        if (customerData?.name) {
            return `Customer - ${customerData.name}`;
        }

        return `Customer`;
    };

    const fetchCustomerContacts = params => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: listingActions.START_FETCHING_LISTING,
                entity: actionName,
                params,
                promise: { resolve, reject },
                name: reducerName,
                endpoint: `api/contacts?filters[company][equals]=${customerIri}`
            });
        }).catch(error => ({ error }));
    };

    const fetchCustomerData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: customerActions.START_FETCHING_CUSTOMER,
                iri: customerIri,
                promise: { resolve, reject }
            });
        }).catch(error => ({ error }));
    };

    useEffect(() => {
        fetchCustomerContacts();
        fetchCustomerData();
    }, []);

    const tableData = () => {
        const table = {};
        table.data = [];

        table.schema = [
            {
                title: 'Name',
                field: 'name',
                sortable: 'lastName'
            },
            {
                title: 'Job title',
                field: 'jobTitle'
            },
            {
                title: 'Phone',
                field: 'phone'
            },
            {
                title: 'Email',
                field: 'email'
            },
            {
                title: 'Actions',
                field: 'actions'
            }
        ];

        if (listing.data) {
            listing.data.map(row => {
                const {
                    id,
                    iri,
                    first_name: firstName,
                    last_name: lastName,
                    job_title: jobTitle,
                    phone,
                    email,
                    company
                } = { ...row };

                return table.data.push({
                    id,
                    iri,
                    name: `${firstName || ''} ${lastName || ''}`,
                    jobTitle,
                    phone,
                    email,

                    actions: (
                        <TableActions>
                            <TableActions.Action>
                                <CustomersContactEdit
                                    initialValues={{ ...dtoForm(row), phone: phone || '+1' }}
                                    id={id}
                                    key={`CustomersContactEdit ${id}`}
                                    callback={fetchCustomerContacts}
                                />
                            </TableActions.Action>
                            <TableActions.Action>
                                <CustomersContactDelete
                                    name={`${firstName || ''} ${lastName || ''}`}
                                    id={id}
                                    customerIri={company?.iri}
                                    key={`CustomersContactDelete ${id}`}
                                />
                            </TableActions.Action>
                        </TableActions>
                    )
                });
            });
        }

        return table;
    };

    return (
        <LayoutManager className="main--narrow" slot="main" layoutType="merge">
            <HeadMeta title={pageTitle()} />
            <PageHeader title={pageTitle()}>
                <Button href="/companies/create" label="Create customer" />
            </PageHeader>
            <PageContent>
                <CustomersEditTabs />
                <Listing
                    hideFilters
                    hideSearch
                    name={reducerName}
                    reducerName={reducerName}
                    loading={listingFetching}
                    meta={listing?.meta}
                    table={tableData()}
                    onListingConfigUpdate={params => fetchCustomerContacts(params)}
                >
                    <CustomersContactsCreate
                        customerIri={customerIri}
                        callback={fetchCustomerContacts}
                    />
                </Listing>
            </PageContent>
        </LayoutManager>
    );
};

CustomersContacts.defaultProps = {
    match: {}
};

CustomersContacts.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object])
};

export default CustomersContacts;
