import React from 'react';
import Form, { Media } from 'erpcore/components/Form';
import { Field } from 'redux-form';
import { valueValidation } from 'erpcore/components/Form/Form.utils';

const ChallengeTypeStickerPhoto = () => {
    return (
        <>
            <Form.Row>
                <Field
                    name="answersSticker"
                    fieldProps={{
                        label: 'Answers',
                        placeholder: 'Add new answer'
                    }}
                    fieldAttr={{ required: true, id: 'answersSticker' }}
                    component={Media}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
        </>
    );
};

export default ChallengeTypeStickerPhoto;
