import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode';

const useQRCode = ({ value }) => {
    const [imageUrl, setImageUrl] = useState(null);

    useEffect(() => {
        if (value) {
            QRCode.toDataURL(value, {
                width: 800,
                type: 'image/png'
            })
                .then(url => {
                    setImageUrl(url);
                })
                .catch(err => {
                    console.error(err);
                });
        }
    }, [value]);

    return { imageUrl };
};

useQRCode.defaultProps = {
    value: ''
};

useQRCode.propTypes = {
    value: PropTypes.string
};

export default useQRCode;
