import { put, call, takeLatest } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as eventTeamActions } from './Teams.reducer';

/**
 *
 * @param promise
 * @param eventIri
 * @param formData
 * @returns {Generator<SimpleEffect<"CALL", CallEffectDescriptor<RT | RT | RT>>|SimpleEffect<"PUT", PutEffectDescriptor<{type: string}>>|SimpleEffect<"PUT", PutEffectDescriptor<{response: *, type: string}>>|SimpleEffect<"PUT", PutEffectDescriptor<{endpoint: string, name: string, type: string, params: *, entity: string}>>|AxiosPromise<any>|SimpleEffect<"SELECT", SelectEffectDescriptor>, void, *>}
 */
export function* eventCreateTeam({ promise, eventIri, formData }) {
    try {
        const createAPI = yield restClient.post('/api/event-teams', {
            event: eventIri,
            ...formData
        });
        yield put({
            type: eventTeamActions.EVENT_ADD_TEAM_SUCCESS
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: eventTeamActions.EVENT_ADD_TEAM_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 *
 * @param promise
 * @param id
 * @param formData
 * @returns {Generator<SimpleEffect<"CALL", CallEffectDescriptor<RT | RT | RT>>|SimpleEffect<"PUT", PutEffectDescriptor<{type: string}>>|SimpleEffect<"PUT", PutEffectDescriptor<{response: *, type: string}>>|SimpleEffect<"PUT", PutEffectDescriptor<{endpoint: string, name: string, type: string, params: *, entity: string}>>|AxiosPromise<any>|SimpleEffect<"SELECT", SelectEffectDescriptor>, void, *>}
 */
export function* eventUpdateTeam({ promise, id, formData }) {
    try {
        const updateAPI = yield restClient.put(`/api/event-teams/${id}`, formData);
        yield put({
            type: eventTeamActions.EVENT_TEAM_UPDATE_SUCCESS
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: eventTeamActions.EVENT_TEAM_UPDATE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 *
 * @param promise
 * @param id
 * @returns {Generator<SimpleEffect<"CALL", CallEffectDescriptor<RT | RT | RT>>|SimpleEffect<"PUT", PutEffectDescriptor<{type: string}>>|SimpleEffect<"PUT", PutEffectDescriptor<{response: *, type: string}>>|SimpleEffect<"PUT", PutEffectDescriptor<{endpoint: string, name: string, type: string, params: *, entity: string}>>|AxiosPromise<any>|SimpleEffect<"SELECT", SelectEffectDescriptor>, void, *>}
 */
export function* deleteEventTeam({ promise, id }) {
    try {
        const deleteAPI = yield restClient.delete(`/api/event-teams/${id}`);
        yield put({
            type: eventTeamActions.EVENT_TEAM_DELETE_SUCCESS
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: eventTeamActions.EVENT_TEAM_DELETE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 *
 * @param promise
 * @param eventIri
 * @param teamSize
 * @returns {Generator<SimpleEffect<"CALL", CallEffectDescriptor<RT | RT | RT>>|SimpleEffect<"PUT", PutEffectDescriptor<{type: string}>>|SimpleEffect<"PUT", PutEffectDescriptor<{response: *, type: string}>>|SimpleEffect<"PUT", PutEffectDescriptor<{endpoint: string, name: string, type: string, params: *, entity: string}>>|AxiosPromise<any>|SimpleEffect<"SELECT", SelectEffectDescriptor>, void, *>}
 */
export function* eventShuffleTeams({ promise, eventIri, teamSize }) {
    try {
        const shuffleAPI = yield restClient.post(`${eventIri}/reshuffle-teams`, { teamSize });
        yield put({
            type: eventTeamActions.RESHUFFLE_EVENT_TEAM_SUCCCESS
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: shuffleAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: eventTeamActions.RESHUFFLE_EVENT_TEAM_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export const eventTeamSaga = [
    takeLatest(eventTeamActions.START_EVENT_ADD_TEAM, eventCreateTeam),
    takeLatest(eventTeamActions.START_EVENT_TEAM_UPDATE, eventUpdateTeam),
    takeLatest(eventTeamActions.START_DELETE_EVENT_TEAM, deleteEventTeam),
    takeLatest(eventTeamActions.START_RESHUFFLE_EVENT_TEAM, eventShuffleTeams)
];
