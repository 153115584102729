export const actions = {
    START_FETCHING_ENTITY_NOTIFICATIONS: 'START_FETCHING_ENTITY_NOTIFICATIONS',
    FETCHING_SUCCESSFUL_ENTITY_NOTIFICATIONS: 'FETCHING_SUCCESSFUL_ENTITY_NOTIFICATIONS',
    FETCHING_FAILED_ENTITY_NOTIFICATIONS: 'FETCHING_FAILED_ENTITY_NOTIFICATIONS',

    START_DELETE_ENTITY_NOTIFICATION: 'START_DELETE_ENTITY_NOTIFICATION',
    DELETE_ENTITY_NOTIFICATION_SUCCESSFUL: 'DELETE_ENTITY_NOTIFICATION_SUCCESSFUL',
    DELETE_ENTITY_NOTIFICATION_FAILED: 'DELETE_ENTITY_NOTIFICATION_FAILED',

    START_CREATE_ENTITY_NOTIFICATION: 'START_CREATE_ENTITY_NOTIFICATION',
    CREATE_ENTITY_NOTIFICATION_SUCCESSFUL: 'CREATE_ENTITY_NOTIFICATION_SUCCESSFUL',
    CREATE_ENTITY_NOTIFICATION_FAILED: 'CREATE_ENTITY_NOTIFICATION_FAILED',

    START_FETCHING_ENTITY_NOTIFICATION: 'START_FETCHING_ENTITY_NOTIFICATION',
    FETCH_ENTITY_NOTIFICATION_SUCCESSFUL: 'FETCH_ENTITY_NOTIFICATION_SUCCESSFUL',
    FETCH_ENTITY_NOTIFICATION_FAILED: 'FETCH_ENTITY_NOTIFICATION_FAILED',
    STORE_ENTITY_NOTIFICATION_DATA: 'STORE_ENTITY_NOTIFICATION_DATA',

    START_UPDATE_ENTITY_NOTIFICATION: 'START_UPDATE_ENTITY_NOTIFICATION',
    UPDATE_ENTITY_NOTIFICATION_SUCCESSFUL: 'UPDATE_ENTITY_NOTIFICATION_SUCCESSFUL',
    UPDATE_ENTITY_NOTIFICATION_FAILED: 'UPDATE_ENTITY_NOTIFICATION_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: [],
    singleFetching: false,
    singleError: null,
    deleteSingleError: null,
    isPositionUpdating: false
};

export default (state = initialState, { type, response, iri }) => {
    switch (type) {
        case actions.START_FETCHING_ENTITY_NOTIFICATIONS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.START_FETCHING_ENTITY_NOTIFICATION: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCH_ENTITY_NOTIFICATION_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.STORE_ENTITY_NOTIFICATION_DATA: {
            return {
                ...state,
                [iri]: response?.data
            };
        }
        case actions.FETCH_ENTITY_NOTIFICATION_FAILED: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCHING_SUCCESSFUL_ENTITY_NOTIFICATIONS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_ENTITY_NOTIFICATIONS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.DELETE_ENTITY_NOTIFICATION_FAILED: {
            return {
                ...state,
                deleteSingleError: response
            };
        }

        case actions.UPDATE_ENTITY_NOTIFICATION_FAILED: {
            return {
                ...state,
                singleError: response
            };
        }

        default:
            return state;
    }
};
