import React from 'react';
import PropTypes from 'prop-types';
import FileUploader from 'erpcore/components/Form/components/FileUploader';
import styles from './MediaUpload.module.scss';

const MediaUpload = ({ allowedExtensions, isMulti, onComplete }) => {
    return (
        <div className={styles.main}>
            <FileUploader
                fieldProps={{
                    isMulti,
                    allowedExtensions,
                    maxImageCount: Infinity,
                    showUploaded: false,
                    onAllComplete: () => {
                        if (onComplete && typeof onComplete === 'function') onComplete();
                    }
                }}
            />
        </div>
    );
};

MediaUpload.defaultProps = {
    allowedExtensions: [],
    isMulti: true,
    onComplete: () => {}
};

MediaUpload.propTypes = {
    allowedExtensions: PropTypes.oneOfType([PropTypes.array]),
    isMulti: PropTypes.bool,
    onComplete: PropTypes.func
};

export default MediaUpload;
