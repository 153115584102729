import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Svg from 'erpcore/components/Svg';
import Tooltip from 'erpcore/components/Tooltip';
import Modal from 'erpcore/components/Modal';
import { actions as entityChallengesActions } from 'erpcore/screens/Challenges/screens/EntityChallenges/EntityChallenges.reducer';
import ChallengeCreateEditForm from 'erpcore/screens/Challenges/components/ChallengeCreateEditForm';
import {
    formatAnswersBasedOnType,
    formatFormValuesBasedOnType
} from 'erpcore/screens/Challenges/Challenges.utils';
import { diff } from 'deep-object-diff';
import { getEntityChallengeData } from 'erpcore/screens/Challenges/screens/EntityChallenges/EntityChallenges.selectors';

const EntityChallengeEdit = ({
    callback,
    entityChallengeIri,
    entity,
    form,
    wallDisabled,
    generateLockedByEndpoint,
    wallTooltipContent
}) => {
    const dispatch = useDispatch();
    const [opened, setOpened] = useState(false);
    const entityChallenge = entityChallengeIri;
    const entityChallengeData = useSelector(state =>
        getEntityChallengeData(state, entityChallenge)
    );

    const {
        enabled,
        locked,
        locked_by: lockedBy,
        _type: type,
        answers,
        timer,
        points,
        title,
        wall,
        description,
        media = [],
        timed
    } = {
        ...entityChallengeData
    };

    const getTitle = () => {
        const entityLabel = entity === 'activity' ? 'template' : entity;
        return `Edit ${entityLabel} challenge`;
    };

    const fetchSingleEntityChallenge = () => {
        const params = {
            include: 'type,media,media.media'
        };

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                iri: entityChallenge,
                type: entityChallengesActions.START_FETCHING_ENTITY_CHALLENGE,
                params
            });
        });
    };

    useEffect(() => {
        if (opened) fetchSingleEntityChallenge();
    }, [opened]);

    const [mediaItem] = media;

    const imageIri = mediaItem?.media?.iri || undefined;

    const initialValues = {
        enabled,
        type: type?.iri,
        ...formatAnswersBasedOnType(answers, type?._type),
        timer,
        points,
        title,
        wall,
        description,
        media: imageIri,
        timed,
        constraint: answers?.constraint,
        locked,
        locked_by: lockedBy?.length > 0 ? lockedBy : null
    };

    const handleModal = () => {
        setOpened(!opened);
    };

    const onSubmit = ({ formData, challengeType }) => {
        const difference = diff(initialValues, formData);
        const values = { ...difference };

        if (values?.type) {
            values.answers = null;
        }

        if (difference?.answersTrivia) {
            values.answersTrivia = formData.answersTrivia;
        }

        if (difference?.answersMultiple) {
            values.answersMultiple = formData.answersMultiple;
        }

        if (difference?.answersDate) {
            values.answersDate = formData.answersDate;
            values.constraint = formData.constraint;
        }

        if (difference?.answersSticker) {
            values.answersSticker = formData.answersSticker;
        }

        if (difference?.answersQr) {
            values.answersQr = formData.answersQr;
        }

        if (
            challengeType === 'TYPE_DATE' &&
            (difference.constraint || difference.day || difference.month)
        ) {
            values.constraint = formData.constraint;
        }

        if (difference.locked_by) {
            values.locked_by = formData.locked_by;
        }

        const formatedValues = formatFormValuesBasedOnType(values, challengeType);

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                formData: formatedValues,
                type: entityChallengesActions.START_UPDATE_ENTITY_CHALLENGE,
                iri: entityChallenge
            });
        })
            .then(() => {
                handleModal();
                callback();
            })
            .catch(err => err);
    };

    return (
        <Fragment>
            <Tooltip content={getTitle()}>
                <button type="button" onClick={() => handleModal()}>
                    <Svg icon="edit" />
                </button>
            </Tooltip>

            {opened && (
                <Modal variation="large" opened={opened} onClose={handleModal} title={getTitle()}>
                    <ChallengeCreateEditForm
                        wallTooltipContent={wallTooltipContent}
                        form={form}
                        data={entityChallengeData}
                        initialValues={initialValues}
                        submitLabel="Submit"
                        entity={entity}
                        wallDisabled={wallDisabled}
                        onCancel={handleModal}
                        settings={{
                            formLayout: entity,
                            lockedByEndpoint: generateLockedByEndpoint()
                        }}
                        onSubmit={onSubmit}
                    />
                </Modal>
            )}
        </Fragment>
    );
};

EntityChallengeEdit.defaultProps = {
    callback: () => {},
    form: null,
    wallDisabled: false,
    wallTooltipContent: 'This challenge type cannot be walled'
};

EntityChallengeEdit.propTypes = {
    callback: PropTypes.func,
    entityChallengeIri: PropTypes.string.isRequired,
    entity: PropTypes.string.isRequired,
    form: PropTypes.string,
    wallDisabled: PropTypes.bool,
    generateLockedByEndpoint: PropTypes.func.isRequired,
    wallTooltipContent: PropTypes.string
};

export default EntityChallengeEdit;
