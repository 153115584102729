export const actions = {
    FETCH_MEDIA_LIBRARY_GALLERY: 'FETCH_MEDIA_LIBRARY_GALLERY',
    FETCHING_MEDIA_LIBRARY_GALLERY_SUCCESS: 'FETCHING_MEDIA_LIBRARY_GALLERY_SUCCESS',
    FETCHING_MEDIA_LIBRARY_GALLERY_FAILED: 'FETCHING_MEDIA_LIBRARY_GALLERY_FAILED',

    START_DELETING_MEDIA_LIBRARY_GALLERY_ITEM: 'START_DELETING_MEDIA_LIBRARY_GALLERY_ITEM',
    DELETE_MEDIA_LIBRARY_GALLERY_ITEM_SUCCESS: 'DELETE_MEDIA_LIBRARY_GALLERY_ITEM_SUCCESS',
    DELETE_MEDIA_LIBRARY_GALLERY_ITEM_FAILED: 'DELETE_MEDIA_LIBRARY_GALLERY_ITEM_FAILED',

    SET_MEDIA_LIBRARY_GALLERY_META: 'SET_MEDIA_LIBRARY_GALLERY_META',

    FETCH_MORE_MEDIA_LIBRARY_GALLERY: 'FETCH_MORE_MEDIA_LIBRARY_GALLERY',
    FETCHING_MORE_MEDIA_LIBRARY_GALLERY_SUCCESS: 'FETCHING_MORE_MEDIA_LIBRARY_GALLERY_SUCCESS',
    FETCHING_MORE_MEDIA_LIBRARY_GALLERY_FAILED: 'FETCHING_MORE_MEDIA_LIBRARY_GALLERY_FAILED'
};

export const initialState = {
    listing: [],
    fetching: false,
    deleting: false,
    currentPage: 1,
    itemsPerPage: null,
    totalItems: null
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.FETCH_MEDIA_LIBRARY_GALLERY: {
            return {
                ...state,
                fetching: true,
                currentPage: Number(initialState.currentPage)
            };
        }
        case actions.FETCHING_MEDIA_LIBRARY_GALLERY_SUCCESS: {
            return {
                ...state,
                fetching: false,
                listing: [...response]
            };
        }
        case actions.FETCHING_MEDIA_LIBRARY_GALLERY_FAILED: {
            return {
                ...state,
                fetching: false
            };
        }
        case actions.START_DELETING_MEDIA_LIBRARY_GALLERY_ITEM: {
            return {
                ...state,
                deleting: true
            };
        }
        case actions.DELETE_MEDIA_LIBRARY_GALLERY_ITEM_SUCCESS: {
            return {
                ...state,
                deleting: false
            };
        }
        case actions.DELETE_MEDIA_LIBRARY_GALLERY_ITEM_FAILED: {
            return {
                ...state,
                deleting: false
            };
        }
        case actions.SET_MEDIA_LIBRARY_GALLERY_META: {
            return {
                ...state,
                currentPage: response?.currentPage,
                itemsPerPage: response?.itemsPerPage,
                totalItems: response?.totalItems
            };
        }
        case actions.FETCHING_MORE_MEDIA_LIBRARY_GALLERY_SUCCESS: {
            const currentListing = state.listing;
            return {
                ...state,
                listing: [...currentListing, ...response]
            };
        }
        default:
            return state;
    }
};
