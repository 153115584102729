import React from 'react';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import ActivityEditPageHeader from 'erpcore/screens/Activities/screens/ActivitiesEdit/components/ActivityEditPageHeader';
import ActivitiesCreateEditForm from 'erpcore/screens/Activities/components/ActivitiesCreateEditForm';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleActivityData } from 'erpcore/screens/Activities/Activities.selectors';
import { diff } from 'deep-object-diff';
import { actions as activityActions } from 'erpcore/screens/Activities/Activities.reducer';

const General = () => {
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const id = match?.params?.id;
    const iri = `/api/activities/${id}`;
    const activityData = useSelector(state => getSingleActivityData(state, iri));
    const { name, description, image, _type: type, details_url: detailsURL } = { ...activityData };

    const initialValues = {
        name,
        description,
        image: image?.iri,
        type: type?.iri,
        details_url: detailsURL
    };

    const onSubmit = ({ formData }) => {
        const difference = diff(initialValues, formData);

        return new Promise((resolve, reject) => {
            dispatch({
                type: activityActions.START_UPDATE_ACTIVITY,
                iri,
                promise: { resolve, reject },
                formData: difference
            });
        });
    };

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <ActivityEditPageHeader />
            <PageContent>
                <ActivitiesCreateEditForm
                    onSubmit={onSubmit}
                    form="ProjectEditForm-projectIri"
                    initialValues={initialValues}
                    submitLabel="Update"
                />
            </PageContent>
        </LayoutManager>
    );
};

export default General;
