import React from 'react';
import { reduxForm, Field } from 'redux-form';
import Form, { Text } from 'erpcore/components/Form';
import PropTypes from 'prop-types';
import Button from 'erpcore/components/Button';
import { valueValidation } from 'erpcore/components/Form/Form.utils';
import './EventViewEditPointsForm.scss';

const EventViewEditPointsForm = ({
    onSubmit,
    invalid,
    pristine,
    submitting,
    handleSubmit,
    onCancel
}) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)} className="event-view-edit-points">
            <Form.Row>
                <Field
                    name="points"
                    fieldAttr={{ id: 'points', required: true }}
                    fieldProps={{ label: '' }}
                    component={Text}
                    parse={parseInt}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Button
                loading={submitting}
                submit
                disabled={invalid || pristine}
                size="small"
                label="Edit points"
            />
            <Button size="small" label="cancel" variation="secondary" onClick={onCancel} />
        </Form>
    );
};

EventViewEditPointsForm.defaultProps = {
    onSubmit: () => {},
    invalid: false,
    pristine: false,
    submitting: false,
    handleSubmit: () => {},
    onCancel: () => {}
};

EventViewEditPointsForm.propTypes = {
    onSubmit: PropTypes.func,
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    handleSubmit: PropTypes.func,
    onCancel: PropTypes.func
};

export default reduxForm({ form: 'EventViewEditPointsForm', enableReinitialize: true })(
    EventViewEditPointsForm
);
