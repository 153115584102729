import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Svg from 'erpcore/components/Svg';
import Tooltip from 'erpcore/components/Tooltip';
import Modal from 'erpcore/components/Modal';
import ActivityTypeCreateEditForm from 'erpcore/screens/Settings/ActivityTypes/components/ActivityTypeCreateEditForm';
import { diff } from 'deep-object-diff';
import { actions as activityTypesActions } from 'erpcore/screens/Settings/ActivityTypes/ActivityTypes.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { getQueryParams } from 'erpcore/components/Listing/Listing.selectors';

const ActivityTypesEdit = ({ form, id, data, callback }) => {
    const [openedModal, setOpenedModal] = useState(false);
    const listingParams = useSelector(state =>
        getQueryParams(state, { name: 'settingsActivityTypes' })
    );
    const dispatch = useDispatch();
    const iri = `/api/activity-types/${id}`;
    const initialValues = { name: data?.name };

    const handleModal = () => {
        setOpenedModal(!openedModal);
    };

    const onSubmit = formData => {
        const difference = diff(initialValues, formData);

        return new Promise((resolve, reject) => {
            dispatch({
                type: activityTypesActions.START_UPDATE_ACTIVITY_TYPE,
                iri,
                promise: { resolve, reject },
                formData: difference
            });
        })
            .then(() => {
                callback(listingParams);
            })
            .catch(error => {
                return error;
            })
            .finally(() => {
                handleModal();
            });
    };

    return (
        <>
            <Tooltip content="Edit template type">
                <button
                    type="button"
                    onClick={() => {
                        handleModal();
                    }}
                >
                    <Svg icon="edit" />
                </button>
            </Tooltip>
            <Modal
                title="Edit template type"
                opened={openedModal}
                onClose={() => {
                    handleModal();
                }}
                variation="medium"
            >
                <ActivityTypeCreateEditForm
                    form={form}
                    onSubmit={onSubmit}
                    onCancel={handleModal}
                    initialValues={initialValues}
                />
            </Modal>
        </>
    );
};

ActivityTypesEdit.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    data: PropTypes.oneOfType([PropTypes.object]).isRequired,
    callback: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired
};

export default ActivityTypesEdit;
