import React, { useState, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-fine-uploader/dropzone';
import FileInput from 'react-fine-uploader/file-input';
import enviromentVariables from 'erpcore/utils/enviromentVariables';
import FineUploaderTraditional from 'fine-uploader-wrappers';
import ElementLoader from 'erpcore/components/ElementLoader';
import Notification from 'erpcore/components/Notification';
import Svg from 'erpcore/components/Svg';
import GridItem from 'erpcore/components/GridItem';
import './FileUploader.scss';
import {
    formatBytes,
    getFileExtension,
    getImageSrcFromMediaObject
} from 'erpcore/components/ImageManager';
import isImage from 'is-image';
import Tooltip from 'erpcore/components/Tooltip';

const FileUploaderNew = ({ input, fieldProps, hideDropZoneOnUpload }) => {
    const [error, setError] = useState(null);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [submittedFiles, setSubmittefFiles] = useState([]);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [uploadHidden, setUploadHidden] = useState(false);

    const {
        allowedExtensions,
        endpoint = '/api/media-objects',
        headers = {},
        inputName = 'file',
        params = {},
        label,
        showUploaded = true,
        maxImageCount = Infinity,
        isMulti = false,
        gridView = false
    } = fieldProps;

    let maxFiles = Infinity;

    if (isMulti) {
        maxFiles = maxImageCount < 0 ? Infinity : maxImageCount;
    } else {
        maxFiles = 1;
    }

    const { name: inputFieldName } = input;

    useEffect(() => {
        if (uploadedFile) {
            setUploadedFiles([...uploadedFiles, { ...uploadedFile }]);
        }
    }, [uploadedFile]);

    useEffect(() => {
        if (input && typeof input?.onChange === 'function') {
            const mappedData = uploadedFiles.reduce((acc, item) => {
                if (item?.response?.id) {
                    return [...acc, item?.response?.id];
                }
                return [...acc];
            }, []);
            input.onChange(mappedData.length > 0 ? mappedData : '');
        }
    }, [uploadedFiles]);

    const saveSubmittedFiles = useCallback(uploader => {
        const newSubmittedFiles = uploader.methods.getUploads({
            status: ['submitting', 'submitted', 'uploading']
        });
        setSubmittefFiles(newSubmittedFiles);
    }, []);

    const uploader = useMemo(() => {
        if (hideDropZoneOnUpload) {
            if (Number(maxFiles - uploadedFiles.length) === 0) {
                setUploadHidden(true);
            } else {
                setUploadHidden(false);
            }
        }

        return new FineUploaderTraditional({
            options: {
                request: {
                    method: 'POST',
                    omitDefaultParams: true,
                    forceMultipart: false,
                    requireSuccessJson: false,
                    params,
                    endpoint: `${enviromentVariables.REST_API}${endpoint}`,
                    customHeaders: {
                        Authorization: `Bearer ${localStorage.token}`,
                        accept: 'application/json, text/plain, */*',
                        ...headers
                    },
                    inputName
                },
                validation: {
                    allowedExtensions,
                    itemLimit: Number(maxFiles - uploadedFiles.length),
                    sizeLimit: 500000000
                },
                maxSize: 1,
                sizeError: 'The file should be 10 MB or less.',
                typeError: 'Wrong file format. Valid format(s): {extensions}.',
                callbacks: {
                    onError: (id, name, errorReason) => {
                        return errorReason ? setError(errorReason) : setError(null);
                    },
                    onSubmitted: id => {
                        const submittedDataFile = uploader.methods.getFile(id);
                        uploader.methods.setParams({
                            meta: JSON.stringify({
                                filename: submittedDataFile.name,
                                type: submittedDataFile.type
                            })
                        });
                        saveSubmittedFiles(uploader);
                    },
                    onComplete: (id, name, responseJSON) => {
                        const { onComplete } = fieldProps;
                        saveSubmittedFiles(uploader);
                        setUploadedFile({
                            id: responseJSON?.data?.id || id,
                            response: responseJSON.data
                        });
                        if (onComplete && typeof onComplete === 'function')
                            onComplete(responseJSON);
                    },
                    onAllComplete: () => {
                        const { onAllComplete } = fieldProps;
                        if (onAllComplete && typeof onAllComplete === 'function') onAllComplete();
                    },
                    onStatusChange: (id, oldStatus, newStatus) => {
                        if (newStatus === 'upload successful') setError(null);
                        if (newStatus === 'canceled') saveSubmittedFiles(uploader);
                    }
                },
                cors: {
                    expected: true,
                    allowXdr: false
                },
                autoUpload: true,
                multiple: isMulti
            }
        });
    }, [fieldProps, uploadedFiles]);

    // region Methods

    const cancelItem = (ev, id) => {
        ev.preventDefault();
        uploader.methods.cancel(id);
    };

    const deleteItem = (ev, id) => {
        ev.preventDefault();

        // filter out item to delete then set to state
        const filterDeletedItem = uploadedFiles.filter(item => item.id !== id);
        setUploadedFiles(filterDeletedItem);

        // show upload zone
        if (filterDeletedItem.length === 0) setUploadHidden(false);
    };

    // endregion

    return (
        <div className="fileuploader">
            {!uploadHidden && (
                <Dropzone
                    multiple
                    name={inputFieldName}
                    uploader={uploader}
                    className="fileuploader__droparea"
                    dropActiveClassName="fileuploader__droparea--active"
                >
                    <div className="fileuploader__droparea-inner">
                        <Svg icon="upload" className="fileuploader__icon" />
                        <p className="fileuploader__text">
                            Drag and drop file here
                            <br />
                            or
                        </p>
                        <FileInput
                            multiple
                            uploader={uploader}
                            className="button button--secondary button--small"
                        >
                            Browse files
                        </FileInput>
                    </div>
                </Dropzone>
            )}
            {!!error &&
                error instanceof Array &&
                error.map(singleError => (
                    <div className="fileuploader__error">
                        <Notification
                            title="Error!"
                            text={
                                singleError?.message &&
                                singleError?.data &&
                                singleError?.exception ? (
                                    <p>
                                        {singleError?.message} <br /> {singleError?.data} <br />{' '}
                                        {singleError?.exception}
                                    </p>
                                ) : (
                                    singleError?.message
                                )
                            }
                            type="warning"
                        />
                    </div>
                ))}

            {!!error && !(error instanceof Array) && (
                <div className="fileuploader__error">
                    <Notification title="Error!" text={error} type="warning" />
                </div>
            )}
            {label && <p className="fileuploader__label">{label}</p>}
            {submittedFiles.length > 0 && (
                <div className="fileuploader__filelist">
                    <h2 className="fileuploader__filelist-title">
                        <ElementLoader />
                        Uploading files...
                    </h2>
                    <ul className="fileuploader__filelist-items">
                        {submittedFiles.map(file => {
                            return (
                                <li
                                    key={`submitted__${file.id}`}
                                    className="fileuploader__filelist-item"
                                >
                                    <span className="fileuploader__filelist-name">{file.name}</span>
                                    <button
                                        type="button"
                                        onClick={ev => cancelItem(ev, file.id)}
                                        className="fileuploader__filelist-remove"
                                    >
                                        <Svg icon="remove" />
                                    </button>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}
            {showUploaded && uploadedFiles.length > 0 && (
                <div className="fileuploader__filelist">
                    {!uploadHidden && (
                        <h2 className="fileuploader__filelist-title">Uploaded files</h2>
                    )}
                    {gridView ? (
                        <div className="fileuploader__filelist-grid">
                            <div className="table-grid__row">
                                <div className="table-grid__row-flex">
                                    {uploadedFiles.map(file => {
                                        const { content_url: contentUrl } = {
                                            ...file?.response?.attributes
                                        };
                                        const { filename, size } = {
                                            ...file?.response?.attributes?.meta
                                        };
                                        const fileExtension = getFileExtension(
                                            filename
                                        ).toUpperCase();
                                        const smallImageUrl = getImageSrcFromMediaObject(
                                            file?.response?.attributes,
                                            'small'
                                        );
                                        return (
                                            <div
                                                className="table-grid__col"
                                                key={`uploaded__${file?.response?.id}`}
                                            >
                                                <GridItem
                                                    title={filename}
                                                    subtitle={formatBytes(size)}
                                                    bottom={fileExtension.toLowerCase() || ' '}
                                                    extension={fileExtension.toLowerCase()}
                                                    background={
                                                        isImage(contentUrl) ? smallImageUrl : null
                                                    }
                                                >
                                                    <ul>
                                                        <li className="table-actions__item">
                                                            <Tooltip content="Remove media">
                                                                <button
                                                                    type="button"
                                                                    onClick={ev =>
                                                                        deleteItem(ev, file.id)
                                                                    }
                                                                >
                                                                    <Svg icon="remove" />
                                                                </button>
                                                            </Tooltip>
                                                        </li>
                                                    </ul>
                                                </GridItem>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <ul className="fileuploader__filelist-items">
                            {uploadedFiles.map(file => {
                                return (
                                    <li
                                        key={`uploaded__${file?.response?.id}`}
                                        className={`fileuploader__filelist-item ${
                                            file.deleting
                                                ? 'fileuploader__filelist-item--deleting'
                                                : ''
                                        }`}
                                    >
                                        <span className="fileuploader__filelist-name">
                                            {file.response?.attributes?.meta?.filename}
                                        </span>
                                        <button
                                            type="button"
                                            onClick={ev => deleteItem(ev, file?.id)}
                                            className="fileuploader__filelist-remove"
                                        >
                                            <Svg icon="remove" />
                                        </button>
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                </div>
            )}
        </div>
    );
};

FileUploaderNew.defaultProps = {
    input: {},
    fieldProps: {},
    hideDropZoneOnUpload: false
};

FileUploaderNew.propTypes = {
    input: PropTypes.oneOfType([PropTypes.object]),
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    hideDropZoneOnUpload: PropTypes.bool
};

export default FileUploaderNew;
