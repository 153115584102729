import eas from 'erpcore/assets/svg/eas.svg';
import ban from 'erpcore/assets/svg/ban-solid.svg';
import bugReport from 'erpcore/assets/svg/bugReport.svg';
import approve from 'erpcore/assets/svg/approve.svg';
import calculatedAmount from 'erpcore/assets/svg/calculatedAmount.svg';
import cancel from 'erpcore/assets/svg/cancel.svg';
import chat from 'erpcore/assets/svg/chat.svg';
import copy from 'erpcore/assets/svg/copy.svg';
import easPowered from 'erpcore/assets/svg/easPowered.svg';
import calendar from 'erpcore/assets/svg/calendar.svg';
import challengeTrivia from 'erpcore/assets/svg/challenge-trivia.svg';
import challengeText from 'erpcore/assets/svg/challenge-text.svg';
import challengeQR from 'erpcore/assets/svg/challenge-qr.svg';
import challengeDate from 'erpcore/assets/svg/challenge-date.svg';
import challengeMultipleChoice from 'erpcore/assets/svg/challenge-multiple.svg';
import challengeSticker from 'erpcore/assets/svg/challenge-sticker.svg';
import challengeMedia from 'erpcore/assets/svg/challenge-media.svg';
import crop from 'erpcore/assets/svg/crop.svg';
import completion from 'erpcore/assets/svg/completion.svg';
import copyLink from 'erpcore/assets/svg/copy-link.svg';
import columns from 'erpcore/assets/svg/columns.svg';
import dashboard from 'erpcore/assets/svg/dashboard.svg';
import date from 'erpcore/assets/svg/date.svg';
import dollar from 'erpcore/assets/svg/dollar.svg';
import drag from 'erpcore/assets/svg/drag.svg';
import download from 'erpcore/assets/svg/download.svg';
import globe from 'erpcore/assets/svg/globe.svg';
import hoursBillable from 'erpcore/assets/svg/hoursBillable.svg';
import hoursLogged from 'erpcore/assets/svg/hoursLogged.svg';
import reject from 'erpcore/assets/svg/reject.svg';
import remove from 'erpcore/assets/svg/remove.svg';
import edit from 'erpcore/assets/svg/edit.svg';
import editSquare from 'erpcore/assets/svg/editSquare.svg';
import envelope from 'erpcore/assets/svg/envelope.svg';
import favorites from 'erpcore/assets/svg/favorites.svg';
import favoritesFull from 'erpcore/assets/svg/favoritesFull.svg';
import filter from 'erpcore/assets/svg/filter.svg';
import flipHorizontal from 'erpcore/assets/svg/flipHorizontal.svg';
import flipVertical from 'erpcore/assets/svg/flipVertical.svg';
import group from 'erpcore/assets/svg/group.svg';
import help from 'erpcore/assets/svg/help.svg';
import joinEvent from 'erpcore/assets/svg/join-event.svg';
import link from 'erpcore/assets/svg/link.svg';
import linkExternal from 'erpcore/assets/svg/linkExternal.svg';
import location from 'erpcore/assets/svg/location.svg';
import lock from 'erpcore/assets/svg/lock.svg';
import locked from 'erpcore/assets/svg/locked.svg';
import mail from 'erpcore/assets/svg/mail.svg';
import menu from 'erpcore/assets/svg/menu.svg';
import minus from 'erpcore/assets/svg/minus.svg';
import minusCircle from 'erpcore/assets/svg/minusCircle.svg';
import minusRound from 'erpcore/assets/svg/minusRound.svg';
import minusScale from 'erpcore/assets/svg/minusScale.svg';
import move from 'erpcore/assets/svg/move.svg';
import money from 'erpcore/assets/svg/money.svg';
import notificationError from 'erpcore/assets/svg/notificationError.svg';
import notificationSuccess from 'erpcore/assets/svg/notificationSuccess.svg';
import notificationWarning from 'erpcore/assets/svg/notificationWarning.svg';
import outputValue from 'erpcore/assets/svg/outputValue.svg';
import passHide from 'erpcore/assets/svg/passHide.svg';
import passShow from 'erpcore/assets/svg/passShow.svg';
import percent from 'erpcore/assets/svg/percent.svg';
import props from 'erpcore/assets/svg/props.svg';
import pause from 'erpcore/assets/svg/pause.svg';
import pauseEvent from 'erpcore/assets/svg/pause-event.svg';
import play from 'erpcore/assets/svg/play.svg';
import plus from 'erpcore/assets/svg/plus.svg';
import plusRound from 'erpcore/assets/svg/plusRound.svg';
import plusScale from 'erpcore/assets/svg/plusScale.svg';
import profile from 'erpcore/assets/svg/profile.svg';
import proTip1 from 'erpcore/assets/svg/proTip1.svg';
import rotateLeft from 'erpcore/assets/svg/rotateLeft.svg';
import rotateRight from 'erpcore/assets/svg/rotateRight.svg';
import signOut from 'erpcore/assets/svg/signOut.svg';
import search from 'erpcore/assets/svg/search.svg';
import settings from 'erpcore/assets/svg/settings.svg';
import sorting from 'erpcore/assets/svg/sorting.svg';
import starEmpty from 'erpcore/assets/svg/starEmpty.svg';
import starFull from 'erpcore/assets/svg/starFull.svg';
import statistics from 'erpcore/assets/svg/statistics.svg';
import start from 'erpcore/assets/svg/start.svg';
import stop from 'erpcore/assets/svg/stop.svg';
import stopwatch from 'erpcore/assets/svg/stopwatch.svg';
import tag from 'erpcore/assets/svg/tag.svg';
import tie from 'erpcore/assets/svg/tie.svg';
import time from 'erpcore/assets/svg/time.svg';
import upload from 'erpcore/assets/svg/upload.svg';
import elementLoader from 'erpcore/assets/svg/elementLoader.svg';
import close from 'erpcore/assets/svg/close.svg';
import arrowDown from 'erpcore/assets/svg/arrowDown.svg';
import arrowLeft from 'erpcore/assets/svg/arrowLeft.svg';
import arrowRight from 'erpcore/assets/svg/arrowRight.svg';
import checkmark from 'erpcore/assets/svg/checkmark.svg';
import action from 'erpcore/assets/svg/action.svg';
import notFound from 'erpcore/assets/svg/notFound.svg';
import file from 'erpcore/assets/svg/file.svg';
import arrowDownRight from 'erpcore/assets/svg/arrowDownRight.svg';
import x from 'erpcore/assets/svg/x.svg';
import myZoneLogo from 'erpcore/assets/svg/myZoneLogo.svg';
import MyZoneColorLogo from 'erpcore/assets/svg/MyZoneColorLogo.svg';
import MyZoneErpHorizontalWhite from 'erpcore/assets/svg/MyZoneErpHorizontalWhite.svg';
import myZoneTicketingBlack from 'erpcore/assets/svg/myZoneTicketingBlack.svg';
import myZoneTicketingColor from 'erpcore/assets/svg/myZoneTicketingColor.svg';
import myZoneTicketingWhite from 'erpcore/assets/svg/myZoneTicketingWhite.svg';
import myZoneTicketingHorizontalWhite from 'erpcore/assets/svg/myZoneTicketingHorizontalWhite.svg';
import leader from 'erpcore/assets/svg/leader.svg';
import endEvent from 'erpcore/assets/svg/endEvent.svg';
import completeEvent from 'erpcore/assets/svg/completeEvent.svg';
import summary from 'erpcore/assets/svg/summary.svg';
import wall from 'erpcore/assets/svg/wall.svg';
import thGrid from 'erpcore/assets/svg/thGrid.svg';
import thList from 'erpcore/assets/svg/thList.svg';

export default {
    summary,
    wall,
    approve,
    ban,
    bugReport,
    eas,
    easPowered,
    calendar,
    challengeDate,
    challengeMedia,
    challengeMultipleChoice,
    challengeQR,
    challengeSticker,
    challengeText,
    challengeTrivia,
    calculatedAmount,
    cancel,
    chat,
    completion,
    columns,
    copy,
    copyLink,
    crop,
    date,
    dollar,
    drag,
    dashboard,
    download,
    globe,
    hoursBillable,
    hoursLogged,
    joinEvent,
    reject,
    remove,
    edit,
    editSquare,
    envelope,
    favorites,
    favoritesFull,
    filter,
    flipHorizontal,
    flipVertical,
    group,
    help,
    link,
    linkExternal,
    location,
    lock,
    locked,
    mail,
    menu,
    minus,
    minusCircle,
    minusRound,
    minusScale,
    money,
    move,
    notificationError,
    notificationSuccess,
    notificationWarning,
    outputValue,
    percent,
    profile,
    props,
    passHide,
    passShow,
    pause,
    pauseEvent,
    play,
    plus,
    plusRound,
    plusScale,
    proTip1,
    rotateLeft,
    rotateRight,
    signOut,
    search,
    settings,
    sorting,
    starEmpty,
    starFull,
    statistics,
    start,
    stop,
    stopwatch,
    tag,
    tie,
    time,
    upload,
    elementLoader,
    close,
    arrowDown,
    arrowLeft,
    arrowRight,
    checkmark,
    action,
    notFound,
    file,
    myZoneLogo,
    MyZoneColorLogo,
    arrowDownRight,
    MyZoneErpHorizontalWhite,
    myZoneTicketingBlack,
    myZoneTicketingColor,
    myZoneTicketingWhite,
    myZoneTicketingHorizontalWhite,
    x,
    leader,
    endEvent,
    completeEvent,
    thGrid,
    thList
};
