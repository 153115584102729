import React from 'react';
import { Field } from 'redux-form';
import Form, { Text } from 'erpcore/components/Form';
import PropTypes from 'prop-types';
import { valueValidation } from 'erpcore/components/Form/Form.utils';
import EntityContentItemForm from 'erpcore/screens/EntityContent/components/EntityContentItemForm';
import Collapse from 'erpcore/components/Collapse';

const EntityContentRepeaterItem = ({ member, data }) => {
    const { isNewRepeaterItem } = {
        ...data
    };

    if (!isNewRepeaterItem) {
        return (
            <Collapse>
                <Collapse.Visible>
                    <Form.Row>
                        <Field
                            name={`${member}.title`}
                            fieldProps={{
                                label: 'Title',
                                clearable: true
                            }}
                            fieldAttr={{ id: `${member}.title`, required: true }}
                            component={Text}
                            validate={valueValidation([{ validator: 'required' }])}
                        />
                    </Form.Row>
                </Collapse.Visible>

                <Collapse.Expandable>
                    <EntityContentItemForm member={member} />
                </Collapse.Expandable>
            </Collapse>
        );
    }

    return (
        <>
            <Form.Row>
                <Field
                    name={`${member}.title`}
                    fieldProps={{
                        label: 'Title',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member}.title`, required: true }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>

            <EntityContentItemForm member={member} />
        </>
    );
};

EntityContentRepeaterItem.defaultProps = {
    member: null,
    data: {}
};

EntityContentRepeaterItem.propTypes = {
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    data: PropTypes.oneOfType([PropTypes.object])
};

export default EntityContentRepeaterItem;
