import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { useDispatch } from 'react-redux';
import Form from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';
import Svg from 'erpcore/components/Svg';
import Tooltip from 'erpcore/components/Tooltip';
import Modal from 'erpcore/components/Modal';
import { actions as eventActions } from 'erpcore/screens/Events/Events.reducer';

const EventDelete = ({ id, handleSubmit, submitting, eventName, callback }) => {
    const dispatch = useDispatch();
    const [opened, setOpened] = useState(false);
    const eventIri = `api/events/${id}`;

    const handleModal = () => {
        setOpened(!opened);
    };

    const onSubmit = () => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: eventActions.START_DELETE_EVENT,
                iri: eventIri
            })
        )
            .then(() => {
                callback();
            })
            .catch(error => {
                return error;
            })
            .finally(() => {
                handleModal();
            });
    };

    return (
        <Fragment>
            <Tooltip content="Delete event">
                <button type="button" onClick={handleModal}>
                    <Svg icon="remove" />
                </button>
            </Tooltip>
            {opened && (
                <Modal
                    variation="small"
                    opened={opened}
                    onClose={handleModal}
                    title="Delete event"
                    subtitle={
                        <>
                            Are you sure you want to delete event <strong>{eventName}</strong>?
                        </>
                    }
                >
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Button submit loading={submitting} label="Delete" />
                        <Button variation="secondary" label="Cancel" onClick={handleModal} />
                    </Form>
                </Modal>
            )}
        </Fragment>
    );
};

EventDelete.defaultProps = {
    submitting: false,
    id: null,
    eventName: null,
    callback: () => {}
};

EventDelete.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    eventName: PropTypes.string,
    callback: PropTypes.func
};

export default reduxForm({
    form: 'CustomerDeleteForm',
    enableReinitialize: true
})(EventDelete);
