import { put, call, takeLatest } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as eventParticipantActions } from './Participants.reducer';

/**
 * Event Create Participant
 * @param promise
 * @param formData
 * @returns {Generator<SimpleEffect<"CALL", CallEffectDescriptor<RT | RT | RT>>|SimpleEffect<"PUT", PutEffectDescriptor<{type: string}>>|SimpleEffect<"PUT", PutEffectDescriptor<{response: *, type: string}>>|SimpleEffect<"PUT", PutEffectDescriptor<{endpoint: string, name: string, type: string, params: *, entity: string}>>|AxiosPromise<any>|SimpleEffect<"SELECT", SelectEffectDescriptor>, void, *>}
 */
export function* eventCreateParticipant({ promise, formData }) {
    try {
        const createAPI = yield restClient.post('/api/event-participants', formData);
        yield put({
            type: eventParticipantActions.EVENT_PARTICIPANT_CREATING_SUCCESS
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: eventParticipantActions.EVENT_PARTICIPANT_CREATING_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 *
 * @param promise
 * @param id
 * @param formData
 * @returns {Generator<SimpleEffect<"CALL", CallEffectDescriptor<RT | RT | RT>>|SimpleEffect<"PUT", PutEffectDescriptor<{type: string}>>|SimpleEffect<"PUT", PutEffectDescriptor<{response: *, type: string}>>|SimpleEffect<"PUT", PutEffectDescriptor<{endpoint: string, name: string, type: string, params: *, entity: string}>>|AxiosPromise<any>|SimpleEffect<"SELECT", SelectEffectDescriptor>, void, *>}
 */
export function* eventUpdateParticipant({ promise, id, formData }) {
    try {
        const updateAPI = yield restClient.put(`/api/event-participants/${id}`, formData);
        yield put({
            type: eventParticipantActions.EVENT_PARTICIPANT_UPDATING_SUCCESS
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: eventParticipantActions.EVENT_PARTICIPANT_UPDATING_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 *
 * @param promise
 * @param id
 * @returns {Generator<SimpleEffect<"CALL", CallEffectDescriptor<RT | RT | RT>>|SimpleEffect<"PUT", PutEffectDescriptor<{type: string}>>|SimpleEffect<"PUT", PutEffectDescriptor<{response: *, type: string}>>|SimpleEffect<"PUT", PutEffectDescriptor<{endpoint: string, name: string, type: string, params: *, entity: string}>>|AxiosPromise<any>|SimpleEffect<"SELECT", SelectEffectDescriptor>, void, *>}
 */
export function* eventMakeParticipantTeamLeader({ promise, id }) {
    try {
        yield restClient.put(`/api/event-participants/${id}`, {
            team_leader: true
        });
        yield put({
            type: eventParticipantActions.PARTICIPANT_TEAM_LEADER_SUCCESS
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: { code: 'eventparticipant.itemTeamLeadUpdated' }
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: eventParticipantActions.PARTICIPANT_TEAM_LEADER_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 *
 * @param promise
 * @param id
 * @returns {Generator<SimpleEffect<"CALL", CallEffectDescriptor<RT | RT | RT>>|SimpleEffect<"PUT", PutEffectDescriptor<{type: string}>>|SimpleEffect<"PUT", PutEffectDescriptor<{response: *, type: string}>>|SimpleEffect<"PUT", PutEffectDescriptor<{endpoint: string, name: string, type: string, params: *, entity: string}>>|AxiosPromise<any>|SimpleEffect<"SELECT", SelectEffectDescriptor>, void, *>}
 */
export function* deleteEventParticipant({ promise, id }) {
    try {
        const deleteAPI = yield restClient.delete(`/api/event-participants/${id}`);
        yield put({
            type: eventParticipantActions.EVENT_PARTICIPANT_DELETE_SUCCESS
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: eventParticipantActions.EVENT_PARTICIPANT_DELETE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export const eventParticipantSaga = [
    takeLatest(eventParticipantActions.START_CREATING_EVENT_PARTICIPANT, eventCreateParticipant),
    takeLatest(eventParticipantActions.START_UPDATING_EVENT_PARTICIPANT, eventUpdateParticipant),
    takeLatest(
        eventParticipantActions.START_MAKING_PARTICIPANT_TEAM_LEADER,
        eventMakeParticipantTeamLeader
    ),
    takeLatest(eventParticipantActions.START_DELETING_EVENT_PARTICIPANT, deleteEventParticipant)
];
