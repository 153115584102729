import React from 'react';
import PropTypes from 'prop-types';
import Tabs from 'erpcore/components/Tabs';

const ActivityEditTabs = ({ id }) => {
    const links = [
        {
            link: `/activities/${id}/edit/general`,
            label: 'General'
        },
        {
            link: `/activities/${id}/edit/settings`,
            label: 'Settings'
        },
        {
            link: `/activities/${id}/edit/challenges`,
            label: 'Challenges'
        },
        {
            link: `/activities/${id}/edit/notifications`,
            label: 'Notifications'
        },
        {
            link: `/activities/${id}/edit/content`,
            label: 'Content'
        },
        {
            link: `/activities/${id}/edit/guidelines`,
            label: 'Event Guidelines'
        }
    ];

    return <Tabs links={links} />;
};

ActivityEditTabs.defaultProps = {
    id: 0
};

ActivityEditTabs.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default ActivityEditTabs;
