import React from 'react';
import LayoutManager from 'erpcore/utils/LayoutManager';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import PageNotifications from 'erpcore/components/Layout/PageNotifications';
import PageContent from 'erpcore/components/Layout/PageContent';
import { actions as activityActions } from 'erpcore/screens/Activities/Activities.reducer';
import { reset, SubmissionError } from 'redux-form';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';
import { useDispatch } from 'react-redux';
import ActivitiesCreateEditForm from 'erpcore/screens/Activities/components/ActivitiesCreateEditForm';
import { useHistory } from 'react-router-dom';
import { getIdFromIri } from 'erpcore/utils/dto';

const ActivitiesCreate = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const title = 'Template create';

    const onSubmit = ({ formData, preventEditRedirect = false }) => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: activityActions.START_CREATE_ACTIVITY,
                promise: { resolve, reject },
                formData
            });
        })
            .then(response => {
                if (response?.data?.id && !preventEditRedirect) {
                    history.push(`/activities/${getIdFromIri(response?.data?.id)}/edit/general`);
                } else {
                    dispatch(reset('ActivitiesCreateEditForm'));
                }
            })
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    };

    return (
        <LayoutManager className="main--narrow" slot="main" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title} />
            <PageNotifications />
            <PageContent>
                <ActivitiesCreateEditForm
                    form="ActivitiesCreateEditForm"
                    onSubmit={onSubmit}
                    submitLabel="Create"
                    onCancel={() => history.push('/activities')}
                />
            </PageContent>
        </LayoutManager>
    );
};

export default ActivitiesCreate;
