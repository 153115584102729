import allColors from 'erpcore/assets/scss/exports/colors.module.scss';

const colors = [
    allColors.sky,
    allColors.apple,
    allColors.lavander,
    allColors.neon,
    allColors.sun,
    allColors.orange,
    allColors.tomato,
    allColors['light-blue'],
    allColors.red
];

export default colors;
