import React, { useEffect } from 'react';
import Form, { MultiAutocomplete, SwitchCard, Text } from 'erpcore/components/Form';
import { Field, getFormValues, change } from 'redux-form';
import PropTypes from 'prop-types';
import './ChallengeTypeSettings.scss';
import { parseInteger } from 'erpcore/utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { valueValidation } from 'erpcore/components/Form/Form.utils';
import Tooltip from 'erpcore/components/Tooltip';

const ChallengeTypeSettings = ({ settings, data, wallDisabled, form, wallTooltipContent }) => {
    const { formLayout, lockedByEndpoint } = { ...settings };
    const formValues = useSelector(state => getFormValues(form)(state));

    const dispatch = useDispatch();

    useEffect(() => {
        if (!formValues?.timed) {
            dispatch(change(form, 'timer', null));
        }
    }, [formValues?.timed]);

    return (
        <>
            <Form.SectionTitleSmall>Settings</Form.SectionTitleSmall>
            {(formLayout === 'activity' || formLayout === 'event') && (
                <Form.Row>
                    <Field
                        name="enabled"
                        fieldAttr={{ id: 'enabled', disabled: false }}
                        fieldProps={{
                            clearable: true,
                            label: 'Enabled'
                        }}
                        component={SwitchCard}
                    />
                </Form.Row>
            )}
            <Form.Row>
                {wallDisabled ? (
                    <Tooltip
                        className="challenge-settings__wall-tooltip"
                        content={wallTooltipContent}
                    >
                        <Field
                            name="wall"
                            fieldAttr={{ id: 'wall', disabled: wallDisabled }}
                            fieldProps={{
                                clearable: true,
                                label: 'Wall'
                            }}
                            component={SwitchCard}
                        />
                    </Tooltip>
                ) : (
                    <Field
                        name="wall"
                        fieldAttr={{ id: 'wall', disabled: wallDisabled }}
                        fieldProps={{
                            clearable: true,
                            label: 'Wall'
                        }}
                        component={SwitchCard}
                    />
                )}
            </Form.Row>

            <Form.Row>
                <Field
                    name="points"
                    fieldAttr={{ id: 'points', required: true }}
                    fieldProps={{ label: 'Points' }}
                    component={Text}
                    parse={parseInteger}
                    validate={valueValidation([
                        {
                            validator: 'required'
                        }
                    ])}
                />
            </Form.Row>

            {(formLayout === 'activity' || formLayout === 'event') && (
                <div
                    className={`${
                        formValues?.locked
                            ? 'challenge-settings__switch-card'
                            : 'challenge-settings__switch-locks'
                    } `}
                >
                    <Field
                        name="locked"
                        fieldAttr={{ id: 'locked', disabled: false }}
                        fieldProps={{
                            clearable: true,
                            label: 'Locks',
                            className: formValues?.locked ? 'challenge-settings__switch' : ''
                        }}
                        component={SwitchCard}
                    />
                    {lockedByEndpoint && formValues?.locked && (
                        <Field
                            name="locked_by"
                            fieldAttr={{ id: 'locked_by', required: formValues?.locked }}
                            fieldProps={{
                                label: 'Locked by',
                                options: {
                                    endpoint: `${lockedByEndpoint}&filters[id][not_in][]=${data?.iri}`,
                                    mapData: {
                                        label: 'title',
                                        value: 'iri'
                                    }
                                }
                            }}
                            component={MultiAutocomplete}
                            validate={valueValidation([
                                {
                                    validator: 'required'
                                }
                            ])}
                        />
                    )}
                </div>
            )}

            {(formLayout === 'activity' || formLayout === 'event') && (
                <div className={`${formValues?.timed ? 'challenge-settings__switch-card' : ''}`}>
                    <Form.Row>
                        <Field
                            name="timed"
                            fieldAttr={{
                                id: 'timed'
                            }}
                            fieldProps={{
                                clearable: true,
                                className: formValues?.timed ? 'challenge-settings__switch' : '',
                                label: 'Timer '
                            }}
                            component={SwitchCard}
                        />
                        {formValues?.timed && (
                            <Form.Row>
                                <Field
                                    name="timer"
                                    fieldAttr={{
                                        id: 'timer',
                                        required: formValues?.timed
                                    }}
                                    fieldProps={{ label: 'Timer in sec' }}
                                    component={Text}
                                    parse={parseInteger}
                                    validate={valueValidation([
                                        {
                                            validator: 'required'
                                        }
                                    ])}
                                />
                            </Form.Row>
                        )}
                    </Form.Row>
                </div>
            )}

            {formLayout !== 'activity' && formLayout !== 'event' && (
                <Form.Row>
                    <Field
                        name="timer"
                        fieldAttr={{
                            id: 'timer'
                        }}
                        fieldProps={{ label: 'Timer in sec' }}
                        component={Text}
                        parse={parseInteger}
                    />
                </Form.Row>
            )}
        </>
    );
};

ChallengeTypeSettings.defaultProps = {
    settings: {},
    data: {},
    wallDisabled: false,
    wallTooltipContent: 'This challenge type cannot be walled'
};

ChallengeTypeSettings.propTypes = {
    settings: PropTypes.oneOfType([PropTypes.object]),
    data: PropTypes.oneOfType([PropTypes.object]),
    wallDisabled: PropTypes.bool,
    form: PropTypes.string.isRequired,
    wallTooltipContent: PropTypes.string
};

export default ChallengeTypeSettings;
