import React from 'react';
import LayoutManager from 'erpcore/utils/LayoutManager';
import { useSelector, useDispatch } from 'react-redux';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import Button from 'erpcore/components/Button';
import TableActions from 'erpcore/components/Listing/components/TableActions';
import Listing from 'erpcore/components/Listing';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import {
    getListingFetching,
    getListingResponse
} from 'erpcore/components/Listing/Listing.selectors';
import CustomerDelete from 'erpcore/screens/Customers/components/CustomerDelete';
import { getCheckedCustomers } from 'erpcore/screens/Customers/Customers.selectors';
import Tooltip from 'erpcore/components/Tooltip';
import { NavLink } from 'react-router-dom';
import Svg from 'erpcore/components/Svg';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';

const CustomersListing = () => {
    const reducerName = 'customers';
    const actionName = 'CUSTOMERS';

    const dispatch = useDispatch();
    const listing = useSelector(state => getListingResponse(state, reducerName));
    const checked = useSelector(state => getCheckedCustomers(state, reducerName));
    const listingFetching = useSelector(state => getListingFetching(state, reducerName));
    const title = 'Customers';

    const fetchCustomers = params => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                entity: actionName,
                type: listingActions.START_FETCHING_LISTING,
                name: reducerName,
                params,
                endpoint: 'api/companies?include=country,city'
            });
        }).catch(error => {
            return error;
        });
    };

    const tableData = () => {
        const table = {};
        table.data = [];

        table.filters = [
            {
                name: 'city',
                label: 'City',
                defaultOperator: 'in',
                filterFields: {
                    value: {
                        component: 'multiautocomplete',
                        fieldProps: {
                            label: 'City',
                            options: {
                                endpoint: `/api/cities`,
                                mapData: {
                                    value: 'iri',
                                    label: 'name'
                                }
                            },
                            clearable: false
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'state',
                label: 'State',
                defaultOperator: 'in',
                filterFields: {
                    value: {
                        component: 'multiautocomplete',
                        fieldProps: {
                            label: 'State',
                            options: {
                                endpoint: `/api/states`,
                                mapData: {
                                    value: 'iri',
                                    label: 'name'
                                }
                            },
                            clearable: false
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'country',
                label: 'Country',
                defaultOperator: 'in',
                filterFields: {
                    value: {
                        component: 'multiautocomplete',
                        fieldProps: {
                            label: 'Country',
                            options: {
                                endpoint: `/api/countries`,
                                mapData: {
                                    value: 'iri',
                                    label: 'name'
                                }
                            },
                            clearable: false
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'deleted',
                label: 'Archived',
                defaultOperator: 'equals',
                filterFields: {
                    defaultValue: '1'
                }
            }
        ];

        table.schema = [
            {
                title: 'Customer name',
                field: 'name',
                sortable: 'name'
            },
            {
                title: 'City',
                field: 'city'
            },
            {
                title: 'Country',
                field: 'country'
            },
            {
                title: 'Address',
                field: 'address'
            },
            {
                title: 'Actions',
                field: 'actions'
            }
        ];

        table.defaultSort = {
            order: 'ASC',
            sortable: 'name'
        };

        if (listing.data) {
            listing.data.map(row => {
                const {
                    id,
                    iri,
                    name: customerName,
                    city,
                    country,
                    full_address: fullAddress,
                    deleted
                } = {
                    ...row
                };

                return table.data.push({
                    id,
                    iri,
                    name: customerName,
                    city: city?.name,
                    country: country?.name,
                    address: fullAddress,

                    actions: (
                        <TableActions>
                            <TableActions.Action>
                                <Tooltip content="Edit customer">
                                    <NavLink to={`/companies/${id}/edit/general-info`}>
                                        <Svg icon="edit" />
                                    </NavLink>
                                </Tooltip>
                            </TableActions.Action>
                            {!deleted && (
                                <TableActions.Action>
                                    <CustomerDelete
                                        customerName={customerName}
                                        id={id}
                                        key={`CustomerDelete ${id}`}
                                    />
                                </TableActions.Action>
                            )}
                        </TableActions>
                    )
                });
            });
        }

        return table;
    };

    return (
        <LayoutManager slot="main" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title}>
                <Button href="/companies/create" label="Create customer" />
            </PageHeader>
            <Listing
                name={reducerName}
                reducerName={reducerName}
                loading={listingFetching}
                meta={listing?.meta}
                table={tableData()}
                onListingConfigUpdate={params => fetchCustomers(params)}
                ordersListingBulkActions={checked?.length > 0 ? checked.length > 0 : false}
            >
                {/* temp removed import/export */}
                {/*
                <Button size="small" variation="secondary" label="Export" />
                <Button size="small" variation="secondary" label="Import" />
                */}
            </Listing>
        </LayoutManager>
    );
};

export default CustomersListing;
