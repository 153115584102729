import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import { useRouteMatch } from 'react-router-dom';
import { actions as eventsActions } from 'erpcore/screens/Events/Events.reducer';
import {
    getSingleEventData,
    getSingleEventFetching
} from 'erpcore/screens/Events/Events.selectors';
import PageLoader from 'erpcore/components/PageLoader';
import EventViewTabs from 'erpcore/screens/Events/screens/EventsView/components/EventViewTabs';
import Button from 'erpcore/components/Button';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { getPermissionsAccessForEntities, hasPermission } from 'erpcore/utils/RolesManager';

const EventViewPageHeader = () => {
    const dispatch = useDispatch();
    const meData = useSelector(getMeData) || {};
    const hasPermissionsAccessForEntities = getPermissionsAccessForEntities(meData);
    const isUserAdmin = hasPermission('CAN_MANAGE_COMMON_EVENT', meData);
    const match = useRouteMatch();
    const id = match?.params?.id;
    const iri = `/api/events/${id}`;
    const fetching = useSelector(getSingleEventFetching);
    const eventData = useSelector(state => getSingleEventData(state, iri));
    const { name } = { ...eventData };

    const getEventData = () => {
        const params = {
            include:
                'teams,coordinators,company,activity,eventChallenges,image,image.versions,teams.participants,participants.image,participants.image.versions'
        };

        return new Promise((resolve, reject) => {
            dispatch({
                type: eventsActions.START_FETCHING_EVENT,
                iri,
                promise: { resolve, reject },
                params
            });
        });
    };

    useEffect(() => {
        getEventData();
    }, []);

    return (
        <>
            {fetching && <PageLoader />}
            <HeadMeta title={name} />
            <PageHeader title={name}>
                {eventData?.status !== 'completed' &&
                    !!id &&
                    hasPermissionsAccessForEntities.events && (
                        <Button href={`/events/${id}/edit/general`} label="Edit event" />
                    )}
                {eventData?.status === 'completed' && !!id && !!isUserAdmin && (
                    <Button href={`/events/${id}/edit/settings`} label="Edit event" />
                )}
            </PageHeader>
            <EventViewTabs id={id} />
        </>
    );
};

export default EventViewPageHeader;
