import React, { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Modal from 'erpcore/components/Modal';
import Widget from 'erpcore/components/Widget';
import {
    getWidgetData,
    getWidgetDataFetching
} from 'erpcore/screens/Dashboard/DashboardWidgets.selectors';
import EventsWidgetForm from './EventsWidgetForm';
import EventsWidgetStats from './EventsWidgetStats';
import EventsWidgetListing from './EventsWidgetListing';
import { actions as eventWidgetActions } from './EventsWidget.reducer';
import styles from './EventsWidget.module.scss';
import { getDashboardByName } from '../../Dashboard.selectors';

const EventsWidget = ({ widgetData }) => {
    const dispatch = useDispatch();
    const dashboardData = useSelector(state => getDashboardByName(state, 'Dashboard'));

    const { iri } = { ...dashboardData };

    const [modalOpened, setModalOpened] = useState(false);

    const { draggable, newWidget, id: widgetId, name: widgetTitle } = {
        ...widgetData
    };

    const fetching = useSelector(state => getWidgetDataFetching(state, widgetId));
    const eventsData = useSelector(state => getWidgetData(state, widgetId)) || { data: null };

    const initialWidgetSettingsValues = useMemo(() => {
        return newWidget
            ? {
                  ...widgetData,
                  ...{
                      name: 'Events',
                      layout: {
                          lg: {
                              i: widgetId,
                              x: 0,
                              y: 1000,
                              w: 12,
                              h: 8,
                              minW: 6,
                              maxW: 12,
                              minH: 6,
                              static: false,
                              isResizable: true
                          },
                          md: {
                              i: widgetId,
                              x: 0,
                              y: 1000,
                              w: 6,
                              h: 6,
                              static: false,
                              isResizable: true
                          }
                      },
                      draggable: true,
                      department: null
                  }
              }
            : { ...widgetData, dashboardIri: iri };
    }, [widgetData, newWidget, widgetId]);

    const widgetModal = useMemo(() => {
        return (
            <Modal
                variation="medium"
                opened={modalOpened}
                onClose={newWidget ? null : () => setModalOpened(false)}
                title={newWidget ? 'Add Events widget' : 'Edit Events widget settings'}
            >
                <EventsWidgetForm
                    initialValues={initialWidgetSettingsValues}
                    form={`EventsWidgetSettingsForm-${widgetId}`}
                    handleModal={() => {
                        setModalOpened(false);
                    }}
                />
            </Modal>
        );
    }, [newWidget, modalOpened, initialWidgetSettingsValues, widgetId]);

    const dates = useMemo(() => {
        // const testDate = '1/21/2021';
        const testDate = '';

        // get current date
        const dateNow = testDate ? moment(testDate) : moment();

        // generate date format from last two weeks
        const dateStart = moment(dateNow).subtract(14, 'days');

        // generate date with tomorrow
        const dateEnd = moment(dateNow).add(1, 'days');

        return {
            now: moment(dateNow),
            start: dateStart,
            end: dateEnd
        };
    }, []);

    const fetchData = useCallback(() => {
        const dateFormat = 'YYYY-MM-DD[T]HH:mm:ssZ';
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: eventWidgetActions.START_FETCHING_WIDGET_EVENTS,
                widgetId,
                dateStart: moment(dates.start).format(dateFormat),
                dateNow: moment(dates.now).format(dateFormat)
            });
        });
    }, [widgetId, dates]);

    useEffect(() => {
        if (!newWidget)
            fetchData().catch(error => {
                return error;
            });
    }, []);

    useEffect(() => {
        setModalOpened(newWidget);
    }, [newWidget]);

    return newWidget ? (
        widgetModal
    ) : (
        <Widget
            title={widgetTitle || 'Events'}
            subtitle={<Link to="/events">View all events</Link>}
            draggable={draggable}
            className={styles.main}
        >
            {widgetModal}
            <div className={styles.inner}>
                <EventsWidgetStats
                    data={[
                        {
                            id: 'eventWidgetStats__active',
                            label: 'Active events',
                            value: eventsData?.stats?.active_events_count || 0
                        },
                        {
                            id: 'eventWidgetStats__upcoming',
                            label: 'Upcoming events',
                            value: eventsData?.stats?.upcoming_events_count || 0
                        },
                        {
                            id: 'eventWidgetStats__paused',
                            label: 'Paused events',
                            value: eventsData?.stats?.paused_events_count || 0
                        }
                    ]}
                />
                <EventsWidgetListing
                    fetching={fetching}
                    events={eventsData?.listing?.data}
                    dates={dates}
                />
            </div>
        </Widget>
    );
};

EventsWidget.defaultProps = {
    widgetData: {}
};

EventsWidget.propTypes = {
    widgetData: PropTypes.oneOfType([PropTypes.object])
};

export default EventsWidget;
