import React, { useEffect } from 'react';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import ActivityEditPageHeader from 'erpcore/screens/Activities/screens/ActivitiesEdit/components/ActivityEditPageHeader';
import { useRouteMatch } from 'react-router-dom';
import EntityContent from 'erpcore/screens/EntityContent/screens/EntityContent';
import { actions as activityActions } from 'erpcore/screens/Activities/Activities.reducer';
import { useDispatch } from 'react-redux';

const ActivityContent = () => {
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const id = match?.params?.id;
    const iri = `/api/activities/${id}`;

    const fetchActivityData = () => {
        const params = {
            include: 'contents,contents.items,contents.items.media,contents.items.media.media'
        };

        return new Promise((resolve, reject) => {
            dispatch({
                type: activityActions.START_FETCHING_ACTIVITY,
                iri,
                promise: { resolve, reject },
                params
            });
        });
    };

    useEffect(() => {
        fetchActivityData();
    }, []);

    return (
        <LayoutManager slot="main" className="main--medium" layoutType="merge">
            <ActivityEditPageHeader />
            <PageContent>
                <EntityContent resource="activities" entityIri={iri} callback={fetchActivityData} />
            </PageContent>
        </LayoutManager>
    );
};

export default ActivityContent;
