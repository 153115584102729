export const getSingleEventFetching = state => state?.events?.singleFetching;

export const getSingleEventData = (state, iri) => state?.events?.[iri] || {};

export const getEventLeaderboards = state => state?.events?.leaderboards || {};

export const getEventLeaderboardsFetching = state => state?.events?.leaderboardsFetching || false;

export const getEventFeedsFetching = state => state?.events?.eventFeedsFetching || false;

export const getEventFeedsData = state => state?.events?.eventFeeds || {};

export const getDuplicateEventPatching = state => state?.events?.duplicateEventPatching || false;

export const getSendingEventWelcomeEmail = state =>
    state?.events?.sendingEventWelcomeEmail || false;
