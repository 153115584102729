import React, { useCallback, useEffect, useState } from 'react';
import { reduxForm, Field, getFormValues } from 'redux-form';
import Form, {
    Text,
    Autocomplete,
    DateTime,
    MultiAutocomplete,
    Media,
    Timelog,
    Checkbox,
    Textarea,
    SwitchCard
} from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';
import PropTypes from 'prop-types';
import { valueValidation } from 'erpcore/components/Form/Form.utils';
import CustomerCreateActionModal from 'erpcore/screens/Customers/components/CustomerCreateActionModal';
import CustomerEditActionModal from 'erpcore/screens/Customers/components/CustomerEditActionModal';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleEventData } from 'erpcore/screens/Events/Events.selectors';
import UnsavedChanges from 'erpcore/components/UnsavedChanges';
import CoordinatorCreateModal from 'erpcore/screens/Events/screens/EventsEdit/components/CoordinatorCreateModal';
import restClient from 'erpcore/api/restClient';
import dto from 'erpcore/utils/dto';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';

const EventEditGeneralForm = ({
    onSubmit,
    handleSubmit,
    submitting,
    invalid,
    pristine,
    initialValues,
    eventIri,
    form,
    dirty
}) => {
    const eventData = useSelector(state => getSingleEventData(state, eventIri));
    const { activity } = { ...initialValues };
    const formDataRedux = useSelector(state => getFormValues(form)(state));

    const {
        activity: formDataActivity,
        timezone,
        starts_at: startsAt,
        is_self_hosted: isSelfHosted
    } = {
        ...formDataRedux
    };
    const { started_at: startedAt } = { ...eventData };
    const [filteredUsersPerUserTypes, setFilteredUsersPerUserTypes] = useState([]);
    const dispatch = useDispatch();

    const fetchUsers = useCallback(async () => {
        try {
            const userTypesAPI = await restClient.get('/api/user-types');
            const userTypeDTO = dto(userTypesAPI?.data?.data);
            setFilteredUsersPerUserTypes(
                userTypeDTO?.reduce((acc, curr) => {
                    if (
                        curr?.attributes?.attribute === 'CAN_USER_TYPE_EVENT_COORDINATOR' ||
                        curr?.attributes?.attribute === 'CAN_USER_TYPE_CUSTOMER_COORDINATOR' ||
                        curr?.attributes?.attribute === 'CAN_USER_TYPE_OUTBACK_ADMIN'
                    ) {
                        acc.push(`filters[roles][in][]=${curr?.id}`);
                    }
                    return acc;
                }, []) || []
            );
        } catch (error) {
            dispatch({
                type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                response: error?.response?.data
            });
        }
    }, []);

    useEffect(() => {
        fetchUsers();
    }, []);

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <UnsavedChanges promptWhen={dirty && !submitting} onSubmit={handleSubmit(onSubmit)} />
            <Form.Row>
                <Field
                    name="name"
                    id="name"
                    fieldProps={{
                        label: 'Event name',
                        clearable: true
                    }}
                    fieldAttr={{ required: true }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="activity"
                    id="activity"
                    fieldProps={{
                        label: 'Template',
                        options: {
                            endpoint: '/api/activities',
                            mapData: {
                                value: 'iri',
                                label: 'name'
                            }
                        },
                        clearable: true
                    }}
                    component={Autocomplete}
                />
            </Form.Row>
            {activity && formDataActivity !== activity && (
                <Form.Row>
                    <Field
                        name="confirmActivity"
                        id="confirmActivity"
                        fieldProps={{
                            label:
                                'Confirm that this will update the Challenges, Notifications, and Content',
                            value: true
                        }}
                        fieldAttr={{ required: true }}
                        component={Checkbox}
                        validate={valueValidation([{ validator: 'required' }])}
                    />
                </Form.Row>
            )}
            <Form.Row>
                <Field
                    name="description"
                    id="description"
                    fieldProps={{
                        label: 'Description',
                        clearable: true
                    }}
                    component={Textarea}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="company"
                    id="company"
                    fieldProps={{
                        label: 'Customer',
                        options: {
                            endpoint: '/api/companies',
                            mapData: {
                                value: 'iri',
                                label: 'name'
                            }
                        },
                        clearable: true,
                        actionButton: {
                            edit: {
                                component: <CustomerEditActionModal formName={form} />
                            },
                            create: {
                                component: <CustomerCreateActionModal formName={form} />
                            }
                        }
                    }}
                    fieldAttr={{ required: true }}
                    component={Autocomplete}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="is_self_hosted"
                    id="is_self_hosted"
                    fieldProps={{
                        label: 'Self-hosted',
                        description: ''
                    }}
                    component={SwitchCard}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="starts_at"
                    id="starts_at"
                    fieldProps={{
                        label: isSelfHosted ? 'Start date' : 'Start date and time',
                        helperText:
                            isSelfHosted && startsAt ? 'automatically starts at 12:01 AM' : null,
                        clearable: true,
                        showTimeSelect: !isSelfHosted
                    }}
                    outputNoTimeZone
                    component={DateTime}
                    fieldAttr={{ required: !!isSelfHosted || (!!timezone && !startsAt) }}
                    validate={valueValidation([
                        { validator: isSelfHosted || timezone ? 'required' : null }
                    ])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="timezone"
                    id="timezone"
                    fieldProps={{
                        label: 'Timezone',
                        options: {
                            endpoint: '/api/timezones?order_by[sort_order]=ASC&pagination=false',
                            mapData: {
                                value: 'iri',
                                label: 'title'
                            }
                        },
                        clearable: true
                    }}
                    fieldAttr={{ required: !!isSelfHosted || (!timezone && !!startsAt) }}
                    component={Autocomplete}
                    validate={valueValidation([
                        { validator: isSelfHosted || startsAt ? 'required' : null }
                    ])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="duration"
                    id="duration"
                    fieldProps={{
                        label: 'Duration',
                        clearable: true,
                        helperText: startedAt
                            ? 'The coordinator can add duration before starting event in this form, if you want to add/deduct time again you can do it on event side.'
                            : ''
                    }}
                    fieldAttr={{
                        disabled: startedAt
                    }}
                    component={Timelog}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="coordinators"
                    id="coordinators"
                    fieldProps={{
                        label: 'Coordinators',
                        options: filteredUsersPerUserTypes && {
                            endpoint: `/api/users?${filteredUsersPerUserTypes.join('&')}`,
                            mapData: {
                                value: 'iri',
                                label: '{first_name} {last_name} ({email})'
                            }
                        },
                        clearable: true,
                        actionButton: {
                            create: {
                                component: <CoordinatorCreateModal formName={form} />
                            }
                        }
                    }}
                    fieldAttr={{ required: true }}
                    component={MultiAutocomplete}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>

            <Form.Row>
                <Field
                    name="managers"
                    fieldProps={{
                        label: 'Managers',
                        options: filteredUsersPerUserTypes && {
                            endpoint: `/api/users?${filteredUsersPerUserTypes.join('&')}`,
                            mapData: {
                                value: 'iri',
                                label: '{first_name} {last_name} ({email})'
                            }
                        },
                        clearable: true
                    }}
                    fieldAttr={{ id: 'managers' }}
                    component={MultiAutocomplete}
                />
            </Form.Row>

            <Form.SectionTitle>Event Image</Form.SectionTitle>

            <Form.Row>
                <Field
                    name="image"
                    id="image"
                    fieldProps={{
                        label: 'Image',
                        clearable: true
                    }}
                    component={Media}
                    allowedFileTypes={['jpg', 'jpeg', 'png', 'gif']}
                />
            </Form.Row>

            <Button disabled={invalid || pristine} submit loading={submitting} label="Save" />
        </Form>
    );
};

EventEditGeneralForm.defaultProps = {
    onSubmit: () => {},
    handleSubmit: () => {},
    submitting: false,
    invalid: false,
    pristine: false,
    initialValues: {},
    form: 'EventEditGeneralForm',
    dirty: false
};

EventEditGeneralForm.propTypes = {
    onSubmit: PropTypes.func,
    handleSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    initialValues: PropTypes.oneOfType([PropTypes.object]),
    eventIri: PropTypes.string.isRequired,
    form: PropTypes.string,
    dirty: PropTypes.bool
};

export default reduxForm({ enableReinitialize: true })(EventEditGeneralForm);
