import React from 'react';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import EventsEditPageHeader from 'erpcore/screens/Events/screens/EventsEdit/components/EventEditPageHeader';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleEventData } from 'erpcore/screens/Events/Events.selectors';
import { diff } from 'deep-object-diff';
import { dtoIgnoreTimezone } from 'erpcore/utils/utils';
import { actions as eventActions } from 'erpcore/screens/Events/Events.reducer';
import EventEditGeneralForm from 'erpcore/screens/Events/screens/EventsEdit/components/EventEditGeneralForm';
import { reset } from 'redux-form';

const General = () => {
    const form = 'EventEditGeneralForm';
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const id = match?.params?.id;
    const iri = `/api/events/${id}`;
    const activityData = useSelector(state => getSingleEventData(state, iri));
    const {
        name,
        description,
        activity,
        company,
        is_self_hosted,
        starts_at_local: startsAtLocal,
        timezone,
        coordinators,
        managers,
        duration,
        image
    } = {
        ...activityData
    };

    const startsAt = dtoIgnoreTimezone(startsAtLocal);

    const initialValues = {
        name,
        description,
        activity: activity?.iri,
        company: company?.iri,
        is_self_hosted: !!is_self_hosted,
        starts_at: startsAt,
        timezone: timezone?.iri,
        coordinators: coordinators?.map(coordinator => coordinator?.iri) || [],
        managers: managers?.map(manager => manager?.iri) || [],
        duration:
            duration === null || duration === undefined || duration === 0 ? undefined : duration,
        image: image ? image.iri : ''
    };

    const onSubmit = formValues => {
        const difference = diff(initialValues, formValues);
        difference.coordinators = formValues.coordinators || [];
        difference.managers = formValues.managers || [];
        difference.duration =
            formValues?.duration === '' || formValues?.duration === 0 ? null : formValues?.duration;

        return new Promise((resolve, reject) => {
            dispatch({
                type: eventActions.START_UPDATE_EVENT,
                iri,
                promise: { resolve, reject },
                formData: difference
            });
        }).then(() => {
            dispatch(reset(form));
        });
    };

    return (
        <LayoutManager slot="main" className="main--medium" layoutType="merge">
            <EventsEditPageHeader />
            <PageContent>
                <EventEditGeneralForm
                    form={form}
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    eventIri={iri}
                />
            </PageContent>
        </LayoutManager>
    );
};

export default General;
