import React, { useCallback } from 'react';
import LayoutManager from 'erpcore/utils/LayoutManager';
import { useSelector, useDispatch } from 'react-redux';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import Button from 'erpcore/components/Button';
import TableActions from 'erpcore/components/Listing/components/TableActions';
import Listing from 'erpcore/components/Listing';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { actions as eventsActions } from 'erpcore/screens/Events/Events.reducer';
import { hasPermission, getPermissionsAccessForEntities } from 'erpcore/utils/RolesManager';
import {
    getListingFetching,
    getListingResponse,
    getQueryParams
} from 'erpcore/components/Listing/Listing.selectors';
import EventDelete from 'erpcore/screens/Events/components/EventDelete';
import EventStop from 'erpcore/screens/Events/components/EventStop';
import EventSendInvitations from 'erpcore/screens/Events/components/EventSendInvitations';
import Tooltip from 'erpcore/components/Tooltip';
import { NavLink, useHistory } from 'react-router-dom';
import Svg from 'erpcore/components/Svg';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import imagePlaceholder from 'erpcore/assets/images/image-placeholder.png';
import moment from 'moment-timezone';
import StatusBadge from 'erpcore/components/StatusBadge';
import {
    statusBadges,
    filterStatus,
    filterState,
    stateBadges
} from 'erpcore/screens/Events/Events.data';
import './EventsListing.scss';
import { getImageSrcFromMediaObject } from 'erpcore/components/ImageManager';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import {
    getDuplicateEventPatching,
    getSendingEventWelcomeEmail
} from 'erpcore/screens/Events/Events.selectors';
import ElementLoader from 'erpcore/components/ElementLoader';
import { formatDate } from 'erpcore/utils/utils';

const EventsListing = () => {
    const reducerName = 'events';
    const actionName = 'EVENTS';
    const dispatch = useDispatch();
    const history = useHistory();
    const meData = useSelector(getMeData) || {};
    const hasPermissionsAccessForEntities = getPermissionsAccessForEntities(meData);
    const isUserAdmin = hasPermission('CAN_MANAGE_COMMON_EVENT', meData);
    const listing = useSelector(state => getListingResponse(state, reducerName));
    const listingFetching = useSelector(state => getListingFetching(state, reducerName));
    const duplicateEventFetching = useSelector(getDuplicateEventPatching);

    const listingParams = useSelector(state =>
        getQueryParams(state, {
            name: reducerName
        })
    );
    const { filter } = { ...listingParams };
    const { starts_at: start } = { ...filter };
    const title = 'Events';

    const fetchEvents = useCallback(
        params => {
            return new Promise((resolve, reject) => {
                dispatch({
                    promise: { resolve, reject },
                    entity: actionName,
                    type: listingActions.START_FETCHING_LISTING,
                    name: reducerName,
                    params: {
                        ...params
                    },
                    endpoint: 'api/events?include=teams,image,image.versions'
                });
            }).catch(error => {
                return error;
            });
        },
        [listingParams]
    );

    const sendingWelcomeEmail = useSelector(getSendingEventWelcomeEmail);
    const sendWelcomeEmail = eventIri => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: eventsActions.SEND_WELCOME_EMAIL,
                eventIri,
                promise: { resolve, reject }
            });
        }).then(() => {
            fetchEvents(listingParams);
        });
    };

    const duplicateEvent = eventId => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: eventsActions.START_DUPLICATE_EVENT,
                eventId
            });
        })
            .then(() => {
                fetchEvents(listingParams);
            })
            .catch(error => {
                return error;
            });
    };

    const tableData = () => {
        const table = {};
        table.data = [];

        table.filters = [
            {
                name: 'status',
                label: 'Status',
                defaultOperator: 'equals',
                filterFields: {
                    value: {
                        component: 'select',
                        fieldProps: {
                            label: 'Status',
                            options: filterStatus,
                            clearable: false
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'state',
                label: 'State',
                defaultOperator: 'equals',
                filterFields: {
                    value: {
                        component: 'select',
                        fieldProps: {
                            label: 'State',
                            options: filterState,
                            clearable: false
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'starts_at',
                label: 'Event Date',
                defaultOperator: 'between',
                defaultDelimiter: '-',
                filterFields: {
                    value: {
                        component: 'dateRange',
                        fieldProps: {
                            label: !start?.length ? 'Event date' : 'Date range already selected',
                            clearable: false,
                            arraySupport: true,
                            dateFormat: 'M/D/YYYY hh:mm:ss'
                        },
                        fieldAttr: { disabled: !!start?.length },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'created_at',
                label: 'Created Date',
                defaultOperator: 'between',
                defaultDelimiter: '-',
                filterFields: {
                    value: {
                        component: 'dateRange',
                        fieldProps: {
                            label: !start?.length ? 'Created date' : 'Date range already selected',
                            clearable: false,
                            arraySupport: true,
                            dateFormat: 'M/D/YYYY'
                        },
                        fieldAttr: { disabled: !!start?.length },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'activity.type',
                label: 'Activity Type',
                defaultOperator: 'equals',
                filterFields: {
                    value: {
                        component: 'autocomplete',
                        fieldProps: {
                            label: 'Type',
                            options: {
                                endpoint: `/api/activity-types`,
                                mapData: {
                                    value: 'iri',
                                    label: 'name'
                                }
                            },
                            clearable: false
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            }
        ];

        table.defaultSort = {
            order: 'DESC',
            sortable: 'id'
        };

        table.schema = [
            {
                title: 'Name',
                field: 'name',
                mobile: 'title'
            },
            {
                title: 'Created At',
                field: 'created_at'
            },
            {
                title: 'Start date',
                field: 'start_date',
                sortable: 'startsAt'
            },
            {
                title: 'Group Size',
                field: 'number_of_participants'
            },
            {
                title: 'Est. Teams',
                field: 'number_of_teams'
            },
            {
                title: (
                    <span
                        style={{
                            whiteSpace: 'nowrap'
                        }}
                    >
                        # Teams
                    </span>
                ),
                field: 'teams'
            },
            /*
            {
                title: 'Rating',
                field: 'rating',
                sortable: 'rating'
            },
            */
            {
                title: 'Status',
                field: 'status',
                mobile: 'status',
                sortable: 'status'
            },
            {
                title: 'State',
                field: 'state',
                mobile: 'state',
                sortable: 'state'
            },
            {
                title: 'Actions',
                field: 'actions'
            }
        ];

        if (listing.data) {
            listing.data.map(row => {
                const {
                    id,
                    iri,
                    image,
                    teams,
                    name: eventName,
                    starts_at: startsAt,
                    status,
                    state,
                    activity,
                    // rating,
                    participants,
                    settings,
                    started_at: startedAt,
                    ended_at: endedAt,
                    is_self_hosted: isSelfHosted,
                    number_of_participants,
                    number_of_teams,
                    meta,
                    created_at
                } = {
                    ...row
                };

                const {
                    emailAccess,
                    welcome_email_receivers: welcomeEmailReceivers = []
                } = settings;
                const {
                    welcome_email_sent: welcomeEmailSent,
                    welcome_email_sent_at: welcomeEmailSentAt
                } = meta || {};
                const smallImageUrl = getImageSrcFromMediaObject(image, 'small');

                return table.data.push({
                    id,
                    iri,
                    name: (
                        <div className="events-listing__name">
                            <div
                                className={`activities-listing__name-image ${
                                    !smallImageUrl
                                        ? 'activities-listing__name-image--placeholder'
                                        : ''
                                }`}
                            >
                                <img src={smallImageUrl || imagePlaceholder} alt={title} />
                            </div>
                            <div className="events-listing__name-content">
                                <h4>
                                    {welcomeEmailSent !== undefined && (
                                        <Tooltip
                                            content={
                                                welcomeEmailSent
                                                    ? `Welcome email sent${welcomeEmailSentAt &&
                                                          ` at ${formatDate(
                                                              welcomeEmailSentAt,
                                                              'YYYY-MM-DD hh:mm AA'
                                                          )}`}`
                                                    : 'Welcome email not sent'
                                            }
                                        >
                                            <span
                                                className={`events-listing__welcome-email-badge ${
                                                    welcomeEmailSent
                                                        ? 'events-listing__welcome-email-badge--apple'
                                                        : 'events-listing__welcome-email-badge--red'
                                                }`}
                                            >
                                                <Svg icon="envelope" />
                                            </span>
                                        </Tooltip>
                                    )}
                                    {eventName}
                                </h4>
                                <p>{activity?.name}</p>
                            </div>
                        </div>
                    ),
                    created_at: moment(created_at).format('MMM Do, h:mm a'),
                    start_date: (
                        <>
                            {startsAt ? moment(startsAt).format('MMM Do, h:mm a') : '-'}
                            {!!isSelfHosted && (
                                <>
                                    <br />
                                    <strong>
                                        <small>(Self-hosted)</small>
                                    </strong>
                                </>
                            )}
                        </>
                    ),
                    number_of_participants: number_of_participants || '-',
                    number_of_teams: number_of_teams || '-',
                    teams: teams?.length || '-',
                    status: (
                        <StatusBadge
                            type={statusBadges[status]?.type || status}
                            text={statusBadges[status]?.label || status}
                        />
                    ),
                    state: !!state && (
                        <StatusBadge
                            type={stateBadges[state]?.type || state}
                            text={stateBadges[state]?.label || state}
                        />
                    ),
                    // rating: <StarRating rating={`${rating / 2}`} />,
                    actions: (
                        <TableActions>
                            {status !== 'completed' && status !== 'draft' && startedAt && (
                                <TableActions.Action>
                                    <EventStop
                                        eventName={eventName}
                                        id={id}
                                        key={`EventStop ${id}`}
                                        callback={fetchEvents}
                                        endedAt={endedAt}
                                    />
                                </TableActions.Action>
                            )}

                            {status !== 'completed' &&
                                emailAccess &&
                                participants &&
                                participants.length > 0 && (
                                    <TableActions.Action>
                                        <EventSendInvitations
                                            id={id}
                                            form={`eventParticipants-${id}`}
                                            initialValues={{
                                                name: eventName,
                                                participants: participants.map(
                                                    participant => participant.iri
                                                )
                                            }}
                                        />
                                    </TableActions.Action>
                                )}

                            {!!hasPermissionsAccessForEntities?.events && (
                                <TableActions.Action>
                                    <Tooltip
                                        content={
                                            welcomeEmailReceivers?.length === 0
                                                ? 'To send welcome email please add receivers on event settings page.'
                                                : 'Send welcome email'
                                        }
                                    >
                                        <button
                                            type="button"
                                            onClick={() => {
                                                if (welcomeEmailReceivers?.length === 0)
                                                    history.push(`/events/${id}/edit/settings`);
                                                else sendWelcomeEmail(iri);
                                            }}
                                        >
                                            {sendingWelcomeEmail && <ElementLoader overlay />}
                                            <Svg icon="envelope" />
                                        </button>
                                    </Tooltip>
                                </TableActions.Action>
                            )}

                            {!!hasPermissionsAccessForEntities?.events && (
                                <TableActions.Action>
                                    <Tooltip content="Duplicate event">
                                        <button type="button" onClick={() => duplicateEvent(id)}>
                                            <Svg icon="copy" />
                                        </button>
                                    </Tooltip>
                                </TableActions.Action>
                            )}

                            {status !== 'completed' && !!hasPermissionsAccessForEntities?.events && (
                                <TableActions.Action>
                                    <Tooltip content="Edit event">
                                        <NavLink to={`/events/${id}/edit/general`}>
                                            <Svg icon="edit" />
                                        </NavLink>
                                    </Tooltip>
                                </TableActions.Action>
                            )}

                            {status === 'completed' && !!isUserAdmin && (
                                <TableActions.Action>
                                    <Tooltip content="Edit event">
                                        <NavLink to={`/events/${id}/edit/settings`}>
                                            <Svg icon="edit" />
                                        </NavLink>
                                    </Tooltip>
                                </TableActions.Action>
                            )}

                            <TableActions.Action>
                                <Tooltip content="View event">
                                    <NavLink to={`/events/${id}/view/event-info`}>
                                        <Svg icon="passShow" />
                                    </NavLink>
                                </Tooltip>
                            </TableActions.Action>

                            {!!hasPermissionsAccessForEntities?.events && (
                                <TableActions.Action>
                                    <EventDelete
                                        id={id}
                                        eventName={eventName}
                                        key={`EventDelete ${id}`}
                                        callback={() => fetchEvents(listingParams)}
                                    />
                                </TableActions.Action>
                            )}
                        </TableActions>
                    )
                });
            });
        }

        return table;
    };

    return (
        <LayoutManager slot="main" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title}>
                {hasPermission('CAN_MANAGE_COMMON_EVENT', meData) && (
                    <Button href="/events/create" label="Create event" />
                )}
            </PageHeader>
            <Listing
                name={reducerName}
                reducerName={reducerName}
                loading={listingFetching || duplicateEventFetching}
                meta={listing?.meta}
                table={tableData()}
                onListingConfigUpdate={params => fetchEvents(params)}
            />
        </LayoutManager>
    );
};

export default EventsListing;
