import { createSelector } from 'reselect';

export const getUserData = (state, iri) => state.users?.[iri];

export const getSingleUserFetching = state => state.users?.singleFetching;

export const getInvitesListingResponse = state => state.users?.listingInvitesResponse;

export const getAddNewUserPerEventPermissions = state => state.users?.addNewUserPerEventPermissions;

export const getUserEstimatedHoursToLogData = (state, iri) =>
    state.users?.estimatedHoursToLog?.[iri];

export const getUserEstimatedHoursToLogFetching = state =>
    state.users?.estimatedHoursToLog?.fetching;

export default createSelector([
    getUserData,
    getSingleUserFetching,
    getInvitesListingResponse,
    getAddNewUserPerEventPermissions,
    getUserEstimatedHoursToLogData,
    getUserEstimatedHoursToLogFetching
]);
