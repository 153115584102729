import React from 'react';
import { Field, formValueSelector } from 'redux-form';
import Form, { Media, Textarea, Text } from 'erpcore/components/Form';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getYoutubeId } from 'erpcore/utils/utils';

const EntityContentItemMediaRepeaterItem = ({ member }) => {
    const selector = formValueSelector('EntityContentForm');
    const formData = useSelector(state => selector(state, member));

    return (
        <>
            <Form.Columns>
                <Form.GridColumn size={3}>
                    <Field
                        name={`${member}.media`}
                        fieldProps={{
                            label: 'Media',
                            clearable: true
                        }}
                        fieldAttr={{ id: `${member}.media` }}
                        allowedFileTypes={[
                            'png',
                            'svg',
                            'jpg',
                            'jpeg',
                            'gif',
                            'mp4',
                            'pdf',
                            'xlsx',
                            'doc',
                            'docx',
                            'webm'
                        ]}
                        component={Media}
                    />
                    <p className="color--mid-grey font-label">Max file size is 500Mb.</p>
                </Form.GridColumn>

                <Form.GridColumn size={9}>
                    <Field
                        name={`${member}.description`}
                        fieldProps={{
                            label: 'Description',
                            clearable: true
                        }}
                        fieldAttr={{ id: `${member}.description` }}
                        component={Textarea}
                    />
                </Form.GridColumn>
            </Form.Columns>

            <Form.Row>
                <Field
                    name={`${member}.youtube_u_r_l`}
                    fieldProps={{
                        label: `Youtube link`,
                        clearable: true,
                        helperText: <span>Put youtube link in here</span>
                    }}
                    fieldAttr={{ id: `${member}.youtube_u_r_l` }}
                    component={Text}
                />
            </Form.Row>

            {formData?.youtube_u_r_l && (
                <Form.Row>
                    <iframe
                        width="100%"
                        height="300"
                        src={`https://www.youtube.com/embed/${getYoutubeId(
                            formData?.youtube_u_r_l
                        )}`}
                        title="Youtube video"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                </Form.Row>
            )}
        </>
    );
};

EntityContentItemMediaRepeaterItem.defaultProps = {
    member: null
};

EntityContentItemMediaRepeaterItem.propTypes = {
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default EntityContentItemMediaRepeaterItem;
