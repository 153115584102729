import { takeLatest, put, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import dto from 'erpcore/utils/dto';
import { actions as eventsActions } from './Events.reducer';

/**
 * Delete Single Event
 * @param  {Object} id ID of a Event
 * @return {Object} Response from API
 */
export function* deleteSingleEvent({ promise, iri }) {
    try {
        const deleteSingleEventAPI = yield restClient.delete(iri);
        yield put({
            type: eventsActions.DELETE_EVENT_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteSingleEventAPI?.data
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: eventsActions.DELETE_EVENT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Create Event
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createEvent({ promise, formData }) {
    try {
        const createEventAPI = yield restClient.post(`/api/events`, formData);
        yield put({
            type: eventsActions.CREATE_EVENT_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createEventAPI?.data
        });
        yield call(promise.resolve, createEventAPI?.data);
    } catch (error) {
        yield put({
            type: eventsActions.CREATE_EVENT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Event
 * @param  {Object} promise
 * @param {string} event Iri
 * @return {Object} response from API
 */
export function* fetchSingleEvent({ promise, iri, params }) {
    try {
        const fetchSingleEventAPI = yield restClient.get(iri, { params });
        yield put({
            type: eventsActions.FETCH_EVENT_SUCCESSFUL
        });
        yield put({
            type: eventsActions.STORE_EVENT_DATA,
            iri,
            response: dto(fetchSingleEventAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: eventsActions.FETCH_EVENT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Event Leaderboard
 * @param  {Object} promise
 * @param {string} event Iri
 * @return {Object} response from API
 */
export function* fetchLeaderboard({ promise, iri, params }) {
    try {
        const fetchSingleEventLeaderboardAPI = yield restClient.get(
            `/api/event-teams?filters[event][equals]=${iri}`,
            { params }
        );
        yield put({
            type: eventsActions.FETCHING_SUCCESSFUL_LEADERBOARDS,
            response: dto(fetchSingleEventLeaderboardAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: eventsActions.FETCHING_FAILED_LEADERBOARDS
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Event Feeds
 * @param  {Object} promise
 * @param {string} event Iri
 * @return {Object} response from API
 */
export function* fetchEventFeeds({ promise, params }) {
    try {
        const fetchSingleEventLeaderboardAPI = yield restClient.get(`/api/completed-challenges`, {
            params
        });
        yield put({
            type: eventsActions.FETCHING_SUCCESSFUL_EVENT_FEEDS,
            response: dto(fetchSingleEventLeaderboardAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: eventsActions.FETCHING_FAILED_EVENT_FEEDS
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Single Event data
 * @param promise
 * @param formData {object}
 * @param iri {string}
 * @param notification {string}
 * @returns {Generator<SimpleEffect<"PUT", PutEffectDescriptor<{iri, response: Object, type: string}>>|SimpleEffect<"PUT", PutEffectDescriptor<{response: *, type: string}>>|SimpleEffect<"CALL", CallEffectDescriptor<RT | RT | RT>>|AxiosPromise<any>|SimpleEffect<"PUT", PutEffectDescriptor<{type: string}>>, void, *>}
 */
export function* updateSingleEvent({ promise, formData, iri, notification }) {
    try {
        const updateSingleEventAPI = yield restClient.put(iri, formData);

        yield put({
            type: eventsActions.UPDATE_EVENT_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: notification || updateSingleEventAPI?.data
        });
        yield put({
            type: eventsActions.STORE_EVENT_DATA,
            iri,
            response: dto(updateSingleEventAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: eventsActions.UPDATE_EVENT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Stop Single Event
 * @param promise
 * @param eventIri
 * @returns {Generator<SimpleEffect<"PUT", PutEffectDescriptor<{response: *, type: string}>>|SimpleEffect<"PUT", PutEffectDescriptor<{type: string}>>|SimpleEffect<"CALL", CallEffectDescriptor<RT | RT | RT>>, void, *>}
 */
export function* endSingleEvent({ promise, eventIri }) {
    try {
        yield restClient.put(`${eventIri}/end`, {
            headers: {
                'Content-type': 'application/json'
            }
        });
        yield put({
            type: eventsActions.END_EVENT_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: { code: 'eventnotification.singleEventsStopped' }
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: eventsActions.END_EVENT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Start Single Event
 * @param promise
 * @param iri
 * @returns {Generator<SimpleEffect<"PUT", PutEffectDescriptor<{response: *, type: string}>>|SimpleEffect<"PUT", PutEffectDescriptor<{type: string}>>|SimpleEffect<"CALL", CallEffectDescriptor<RT | RT | RT>>, void, *>}
 */
export function* startSingleEvent({ promise, iri }) {
    try {
        const startSingleEventAPI = yield restClient.put(
            `${iri}/start`,
            {},
            {
                headers: { 'Content-type': 'application/json' }
            }
        );
        yield put({
            type: eventsActions.START_EVENT_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: startSingleEventAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: eventsActions.START_EVENT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Send email invitations
 * @param promise
 * @param id
 * @param payload
 * @returns {Generator<SimpleEffect<"CALL", CallEffectDescriptor<RT | RT | RT>>|SimpleEffect<"PUT", PutEffectDescriptor<{response: *, type: string}>>|AxiosPromise<any>|SimpleEffect<"PUT", PutEffectDescriptor<{type: string}>>, void, *>}
 */
export function* sendEventEmailInvitations({ promise, id, payload }) {
    try {
        yield restClient.post(`api/events/${id}/send-invitations`, payload);
        yield put({
            type: eventsActions.SEND_EVENT_INVITE_EMAILS_SUCCESS
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: {
                code: payload
                    ? 'eventNotification.emailInvitationSent'
                    : 'eventNotification.emailInvitationSentAll'
            }
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: eventsActions.SEND_EVENT_INVITE_EMAILS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Send email invitations
 * @param promise
 * @param eventId
 * @returns {Generator<SimpleEffect<"CALL", CallEffectDescriptor<RT | RT | RT>>|SimpleEffect<"PUT", PutEffectDescriptor<{response: *, type: string}>>|AxiosPromise<any>|SimpleEffect<"PUT", PutEffectDescriptor<{type: string}>>, void, *>}
 */
export function* duplicateEvent({ promise, eventId }) {
    try {
        const duplicateEventAPI = yield restClient.post(`/api/events/${eventId}/duplicate`);
        yield put({
            type: eventsActions.DUPLICATE_EVENT_SUCCESS
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: duplicateEventAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: eventsActions.DUPLICATE_EVENT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Event feed
 * @param  {Object} id ID of event feed
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* updateEventFeed({ promise, formData, iri }) {
    try {
        const updateEventFeedAPI = yield restClient.put(iri, formData);
        yield put({
            type: eventsActions.UPDATE_SUCCESSFUL_EVENT_FEED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateEventFeedAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: eventsActions.UPDATE_FAILED_EVENT_FEED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Completes an event
 * @param {Object} promise Promises
 * @return {Object} Response from API
 */
export function* completeEvent({ promise, eventIri }) {
    try {
        yield restClient.put(eventIri, {
            status: 'completed'
        });
        yield put({
            type: eventsActions.COMPLETE_EVENT_SUCCESSFUL
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: eventsActions.COMPLETE_EVENT_FAILED
        });
        const errorData = error?.response?.data || error;
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: errorData
        });
        yield call(promise.reject, errorData);
    }
}

/**
 * Sends activity details email
 * @param {Object} promise Promises
 * @return {Object} Response from API
 */
export function* sendWelcomeEmail({ promise, eventIri }) {
    try {
        const notification = yield restClient.post(`${eventIri}/send-welcome-email`);
        const event = yield restClient.get(eventIri);
        const eventDTO = dto(event?.data);
        yield put({
            type: eventsActions.SEND_WELCOME_EMAIL_SUCCESS
        });
        if (eventDTO?.data?.meta?.welcome_email_sent) {
            yield put({
                type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                response: notification.data
            });
        } else {
            yield put({
                type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                response: { code: 'event.createdNotificationFailed' }
            });
        }
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: eventsActions.SEND_WELCOME_EMAIL_FAILED
        });
        const errorData = error?.response?.data || error;
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: errorData
        });
        yield call(promise.reject, errorData);
    }
}

/**
 * Register action to watcher
 */
export const eventsSaga = [
    takeLatest(eventsActions.START_DELETE_EVENT, deleteSingleEvent),
    takeLatest(eventsActions.START_CREATE_EVENT, createEvent),
    takeLatest(eventsActions.START_FETCHING_EVENT, fetchSingleEvent),
    takeLatest(eventsActions.START_UPDATE_EVENT, updateSingleEvent),
    takeLatest(eventsActions.START_FETCHING_LEADERBOARDS, fetchLeaderboard),
    takeLatest(eventsActions.START_FETCHING_EVENT_FEEDS, fetchEventFeeds),
    takeLatest(eventsActions.START_UPDATE_EVENT_FEED, updateEventFeed),
    takeLatest(eventsActions.END_EVENT, endSingleEvent),
    takeLatest(eventsActions.START_EVENT, startSingleEvent),
    takeLatest(eventsActions.START_SEND_EVENT_INVITE_EMAILS, sendEventEmailInvitations),
    takeLatest(eventsActions.START_DUPLICATE_EVENT, duplicateEvent),
    takeLatest(eventsActions.COMPLETE_EVENT, completeEvent),
    takeLatest(eventsActions.SEND_WELCOME_EMAIL, sendWelcomeEmail)
];
