import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TableActions from 'erpcore/components/Listing/components/TableActions';
import Listing from 'erpcore/components/Listing';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import {
    getListingFetching,
    getListingResponse
} from 'erpcore/components/Listing/Listing.selectors';
import EntityNotificationDelete from 'erpcore/screens/EntityNotifications/components/EntityNotificationsDelete';
import EntityNotificationAdd from 'erpcore/screens/EntityNotifications/components/EntityNotificationAdd';
import { entityNotificationsTriggerTypes } from 'erpcore/screens/EntityNotifications/EntityNotifications.data';
import { utc } from 'moment-timezone';
import EntityNotificationEdit from 'erpcore/screens/EntityNotifications/components/EntityNotificationsEdit';
import { useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import './EntityNotificationsListing.scss';

const EntityNotificationsListing = ({ endpoint, endpointParams, entity, entityIri }) => {
    const reducerName = 'entityNotifications';
    const actionName = 'ENTITY_NOTIFICATIONS';
    const match = useRouteMatch();
    const id = match?.params?.id;
    const dispatch = useDispatch();
    const listing = useSelector(state => getListingResponse(state, reducerName));
    const listingFetching = useSelector(state => getListingFetching(state, reducerName));

    const fetchEntityNotifications = params => {
        params = {
            ...params,
            ...endpointParams
        };

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                entity: actionName,
                type: listingActions.START_FETCHING_LISTING,
                name: reducerName,
                params,
                endpoint
            });
        }).catch(error => {
            return error;
        });
    };

    const formatUnit = (time, unit) => {
        switch (unit) {
            case 'seconds':
                if (time % 60 !== 0) {
                    return `${utc(time * 1000).format('s')} seconds`;
                }
                return '';
            case 'minutes':
                if (time % 3600 !== 0) {
                    return `${utc(time * 1000).format('m')} minutes`;
                }
                return '';
            case 'hours':
                return `${utc(time * 1000).format('h')} hours`;
            case 'days':
                return `${Math.floor(time / 86400)} days`;
            default:
                return utc(time * 1000).format('s');
        }
    };

    const formatTime = time => {
        switch (true) {
            case time < 60:
                return formatUnit(time, 'seconds');
            case time < 3600:
                return `${formatUnit(time, 'minutes')} ${formatUnit(time, 'seconds')}`;
            case time / 3600 < 24:
                return `${formatUnit(time, 'hours')} ${formatUnit(time, 'minutes')} ${formatUnit(
                    time,
                    'seconds'
                )}`;
            default:
                return `${formatUnit(time, 'days')} ${formatUnit(time, 'hours')}`;
        }
    };

    const tableData = () => {
        const table = {};
        table.data = [];

        table.filters = [
            {
                name: 'type',
                label: 'Trigger',
                defaultOperator: 'equals',
                filterFields: {
                    value: {
                        component: 'select',
                        fieldProps: {
                            label: 'Type',
                            options: entityNotificationsTriggerTypes,
                            clearable: false
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            }
        ];

        table.schema = [
            {
                title: 'Message',
                field: 'message'
            },
            {
                title: 'Time',
                field: 'time'
            },
            {
                title: 'Trigger',
                field: 'trigger'
            },
            {
                title: 'Actions',
                field: 'actions'
            }
        ];

        if (listing.data) {
            listing.data.map(row => {
                const {
                    id: notificationID,
                    iri: notificationIri,
                    content,
                    time,
                    _type: trigger
                } = {
                    ...row
                };

                const findTriggerType = entityNotificationsTriggerTypes.find(
                    triggerType => triggerType?.value === trigger
                );

                return table.data.push({
                    id: notificationID,
                    iri: notificationIri,
                    message: (
                        <span className="entity__notifications-table-column-message">
                            {content}
                        </span>
                    ),
                    time: time ? formatTime(time) : '-',
                    trigger: findTriggerType?.label || '-',
                    actions: (
                        <TableActions>
                            <TableActions.Action>
                                <EntityNotificationEdit
                                    data={row}
                                    callback={fetchEntityNotifications}
                                    key={`EntityNotificationsEdit-${id}`}
                                />
                            </TableActions.Action>

                            <TableActions.Action>
                                <EntityNotificationDelete
                                    notificationIri={notificationIri}
                                    key={`EntityNotificationDelete-${id}`}
                                    callback={fetchEntityNotifications}
                                />
                            </TableActions.Action>
                        </TableActions>
                    )
                });
            });
        }

        return table;
    };

    return (
        <Listing
            name={reducerName}
            reducerName={reducerName}
            loading={listingFetching}
            meta={listing?.meta}
            table={tableData()}
            onListingConfigUpdate={params => fetchEntityNotifications(params)}
            className="entity__notifications"
        >
            <EntityNotificationAdd
                endpoint={endpoint}
                entity={entity}
                entityIri={entityIri}
                callback={fetchEntityNotifications}
            />
        </Listing>
    );
};

EntityNotificationsListing.propTypes = {
    endpoint: PropTypes.string.isRequired,
    endpointParams: PropTypes.string.isRequired,
    entityIri: PropTypes.string.isRequired,
    entity: PropTypes.string.isRequired
};

export default EntityNotificationsListing;
