import React, { useEffect } from 'react';
import { actions as eventActions } from 'erpcore/screens/Events/Events.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import {
    getSingleEventData,
    getSingleEventFetching
} from 'erpcore/screens/Events/Events.selectors';
import './EventViewLeaderboards.scss';
import Listing from 'erpcore/components/Listing';
import { HorizontalLineChart } from 'erpcore/components/Charts';
import { useOrderTeamsByPoints } from 'erpcore/utils/orderTeamsByPoints';

const EventViewLeaderboards = () => {
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const id = match?.params?.id;
    const eventIri = `/api/events/${id}`;
    const reducerName = 'leaderboards';
    const eventFetching = useSelector(getSingleEventFetching);
    const eventData = useSelector(state => getSingleEventData(state, eventIri));
    const { event_challenges: eventChallenges, teams } = { ...eventData };

    const { all: allTeams } = useOrderTeamsByPoints(teams);

    useEffect(() => {
        return () => {
            dispatch({
                type: eventActions.CLEAR_EVENT_STATE
            });
        };
    }, []);

    const tableData = () => {
        const table = {};
        table.data = [];

        table.filters = [];

        table.schema = [
            {
                title: 'No.',
                field: 'number'
            },
            {
                title: 'Team achievements',
                field: 'achievements'
            },
            {
                title: 'Team lead',
                field: 'team_lead',
                align: 'right'
            }
        ];

        if (allTeams?.length) {
            allTeams.map(row => {
                const {
                    id: leaderboardID,
                    iri,
                    name,
                    participants,
                    points,
                    completed_challenges_count,
                    calculatedRank
                } = {
                    ...row
                };

                const teamLeader = participants?.find(({ team_leader: leader }) => leader) || null;
                const { first_name: firstName, last_name: lastName } = { ...teamLeader };

                return table.data.push({
                    id: leaderboardID,
                    iri,
                    number: calculatedRank,
                    achievements: (
                        <div className="event-view-leaderboards__achievements">
                            <div className="event-view-leaderboards__achievements-image" />
                            <div className="event-view-leaderboards__achievements-content">
                                <div className="event-view-leaderboards__achievements-info">
                                    <div className="event-view-leaderboards__achievements-team">
                                        <h4>{name}</h4>
                                        <span>({participants?.length || 0})</span>
                                    </div>
                                    {points && <span>{points} points</span>}
                                </div>
                                <div className="event-view-leaderboards__achievements-status">
                                    <span>
                                        {completed_challenges_count || 0}/{eventChallenges?.length}
                                    </span>
                                    <div className="event-view-leaderboards__achievements-line">
                                        <HorizontalLineChart
                                            data={{
                                                datasets: [
                                                    {
                                                        key: `lineChart__${leaderboardID}`,
                                                        backgroundColor: 'rgba(100, 100, 100, 0.2)',
                                                        data: [eventChallenges?.length],
                                                        label: 'Number of event challenges'
                                                    },
                                                    {
                                                        key: `lineChart__${leaderboardID}`,
                                                        backgroundColor: completed_challenges_count
                                                            ? '#8dc875'
                                                            : '#dedede',
                                                        data: [completed_challenges_count],
                                                        label: 'Completed Challenges'
                                                    }
                                                ]
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ),
                    team_lead: teamLeader ? `${firstName} ${lastName}` : '-'
                });
            });
        }

        return table;
    };

    return (
        <div className="event-view-leaderboards">
            <Listing
                hideHeader
                name={reducerName}
                reducerName={reducerName}
                loading={eventFetching}
                table={tableData()}
            />
        </div>
    );
};

export default EventViewLeaderboards;
