import React from 'react';
import { useSelector } from 'react-redux';
import { getSingleEventData } from 'erpcore/screens/Events/Events.selectors';
import { useRouteMatch } from 'react-router-dom';
import './EventViewInfo.scss';
import StatusBadge from 'erpcore/components/StatusBadge';
import { statusBadges } from 'erpcore/screens/Events/Events.data';
import moment from 'moment-timezone';
import { formatHours } from 'erpcore/utils/utils';

const EventViewInfo = () => {
    const match = useRouteMatch();
    const id = match?.params?.id;
    const iri = `/api/events/${id}`;
    const eventData = useSelector(state => getSingleEventData(state, iri));
    const { company, activity, status, starts_at: startsAt, duration, paused } = { ...eventData };

    return (
        <div className="event-view-info">
            <div className="event-view-info__item">
                <p>Customer</p>
                <h3>{company?.name}</h3>
            </div>
            <div className="event-view-info__item">
                <p>Time and duration</p>
                <h3>
                    {!!startsAt && moment(startsAt).format('ll - LT')}
                    {!!duration && ` - ${formatHours(duration)}`}
                </h3>
            </div>

            <div className="event-view-info__item">
                <p>Activity</p>
                <h3>{activity?.name || '-'}</h3>
            </div>

            <div className="event-view-info__item">
                <p>Event status</p>
                <h3>
                    {paused && status === 'active' ? (
                        <StatusBadge
                            type={statusBadges.paused.type}
                            text={statusBadges.paused.label}
                        />
                    ) : (
                        <StatusBadge
                            type={statusBadges[status]?.type || status}
                            text={statusBadges[status]?.label || status}
                        />
                    )}
                </h3>
            </div>
        </div>
    );
};

export default EventViewInfo;
