import ImageManager, {
    getImageVersion,
    getFileExtension,
    getPurgedFallbackOrContentUrl,
    getImageSrcFromMediaObject,
    formatBytes,
    isVideo
} from './ImageManager';

export {
    getImageVersion,
    getFileExtension,
    getPurgedFallbackOrContentUrl,
    getImageSrcFromMediaObject,
    formatBytes,
    isVideo
};
export default ImageManager;
