import React from 'react';
import Tabs from 'erpcore/components/Tabs';

const MyAccountTabs = () => {
    const links = [
        {
            link: `/my-account`,
            label: 'Profile info'
        }
    ];

    return <Tabs links={links} />;
};

export default MyAccountTabs;
