import { takeLatest, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';

import { actions as guidelineActions } from 'erpcore/screens/Settings/EventGuidelines/EventGuidelines.reducer';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import dto from 'erpcore/utils/dto';
import { getQueryParams } from 'erpcore/components/Listing/Listing.selectors';

/**
 * Create Event Guideline
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createGuideline({ promise, formData }) {
    try {
        const createGuidelineAPI = yield restClient.post('api/guidelines', formData);
        yield put({
            type: guidelineActions.CREATE_GUIDELINE_SUCCESFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createGuidelineAPI?.data
        });
        yield call(promise.resolve, createGuidelineAPI?.data);
    } catch (error) {
        yield put({
            type: guidelineActions.CREATE_GUIDELINE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Event Guideline
 * @param  {Object} promise
 * @return {string} id Event Guideline id
 */
export function* fetchEventGuidelines({ promise, iri }) {
    try {
        const fetchGuidelineAPI = yield restClient.get(iri);
        yield put({
            type: guidelineActions.FETCHING_GUIDELINE_SUCCESSFUL
        });

        yield put({
            type: guidelineActions.STORE_GUIDELINE_DATA,
            iri,
            response: dto(fetchGuidelineAPI?.data)
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: guidelineActions.FETCHING_GUIDELINE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Event Guideline data
 * @param  {Object} id id of Event Guideline
 * @return {Object} Response from API
 */
export function* updateEventGuideline({ promise, iri, formData }) {
    try {
        const updateGuidelineAPI = yield restClient.patch(iri, formData);
        yield put({
            type: guidelineActions.UPDATE_GUIDELINE_SUCCESSFUL
        });
        yield put({
            type: guidelineActions.STORE_GUIDELINE_DATA,
            iri,
            response: dto(updateGuidelineAPI?.data)
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: (updateGuidelineAPI?.data?.code && updateGuidelineAPI?.data) || {
                code: 'guideline.itemSuccessfulyUpdated'
            }
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: guidelineActions.UPDATE_GUIDELINE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Delete Event Guideline
 * @param  {Object} guidelineIri iri of Event Guideline
 * @return {Object} Response from API
 */
export function* deleteEventGuideline({ promise, guidelineIri }) {
    try {
        const deleteGuidelineAPI = yield restClient.delete(guidelineIri);
        yield put({
            type: guidelineActions.DELETE_GUIDELINE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: (deleteGuidelineAPI?.data?.code && deleteGuidelineAPI?.data) || {
                code: 'guideline.itemSuccessfulyRemoved'
            }
        });

        const params = yield select(getQueryParams, { name: 'guidelines' });

        yield put({
            promise,
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'GUIDELINES',
            name: 'guidelines',
            endpoint: 'api/guidelines'
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: guidelineActions.DELETE_GUIDELINE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
const guidelinesSaga = [
    takeLatest(guidelineActions.START_CREATE_GUIDELINE, createGuideline),
    takeLatest(guidelineActions.START_FETCHING_GUIDELINE, fetchEventGuidelines),
    takeLatest(guidelineActions.START_UPDATE_GUIDELINE, updateEventGuideline),
    takeLatest(guidelineActions.START_DELETE_GUIDELINE, deleteEventGuideline)
];

export default guidelinesSaga;
