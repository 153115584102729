import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import ElementLoader from 'erpcore/components/ElementLoader';

import {
    getImage,
    getImageFetchingData,
    getImageUrl
} from 'erpcore/components/ImageManager/ImageManager.selectors';
import { actions as imageManagerActions } from 'erpcore/components/ImageManager/ImageManager.reducer';

const Image = ({ iri, version, width, height, alt, className, fallback, onLoad }) => {
    const dispatch = useDispatch();

    iri = iri?.split(';')[0];

    const image = useSelector(state => getImage(state, iri));

    const imageUrl = useSelector(state => getImageUrl(state, iri, version, 'image'));

    const imageFetchingData = useSelector(state => getImageFetchingData(state));

    const mounted = useRef(true);

    const fetchImage = useCallback(() => {
        return new Promise((resolve, reject) => {
            if (iri && image?.iri !== iri && !imageFetchingData?.[iri]) {
                dispatch({
                    promise: { resolve, reject },
                    type: imageManagerActions.START_FETCHING_IMAGE,
                    iri
                });
            }
        });
    }, [iri, image?.iri, imageFetchingData?.[iri]]);

    useEffect(() => {
        if (iri) {
            fetchImage(iri);
        }
    }, [iri]);

    useEffect(() => {
        return () => {
            mounted.current = false;
        };
    });

    const handleOnLoad = useCallback(() => {
        setTimeout(() => {
            if (!!onLoad && mounted.current) {
                onLoad();
            }
        }, 500);
    }, [onLoad]);

    if (imageUrl) {
        return (
            <img
                onLoad={handleOnLoad}
                className={className}
                src={imageUrl}
                alt={alt}
                width={width}
                height={height}
            />
        );
    }

    return fallback;
};

Image.defaultProps = {
    iri: null,
    version: null,
    width: 100,
    height: 100,
    alt: 'alt',
    className: null,
    fallback: <ElementLoader />,
    onLoad: () => {}
};
Image.propTypes = {
    iri: PropTypes.string,
    version: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    alt: PropTypes.string,
    className: PropTypes.string,
    fallback: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.object,
        PropTypes.node
    ]),
    onLoad: PropTypes.func
};

export default Image;
