import React, { useCallback } from 'react';
import Button from 'erpcore/components/Button';
import './EventViewActions.scss';
import { useDispatch, useSelector } from 'react-redux';
import { actions as notificationActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { useRouteMatch } from 'react-router-dom';
import { getSingleEventData } from 'erpcore/screens/Events/Events.selectors';
import {
    actions as eventsActions,
    actions as eventActions
} from 'erpcore/screens/Events/Events.reducer';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import useQRCode from 'erpcore/components/useQRCode';

const EventViewActions = () => {
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const id = match?.params?.id;
    const iri = `/api/events/${id}`;
    const eventData = useSelector(state => getSingleEventData(state, iri));
    const meData = useSelector(getMeData);
    const { imageUrl: QRCodeImageUrl } = useQRCode({
        value: eventData?.hash ? `${process.env.REACT_APP_GAME_LINK}/join/${eventData?.hash}` : null
    });
    const { status, paused, hash, running, ended_at: endedAt } = { ...eventData };

    const onCopyEventLink = async () => {
        try {
            await navigator.clipboard.writeText(`${process.env.REACT_APP_GAME_LINK}/join/${hash}`);
            dispatch({
                type: notificationActions.ADD_FLOATING_NOTIFICATION,
                response: {
                    code: 'copiedToClipboard'
                }
            });
        } catch (e) {
            dispatch({
                type: notificationActions.ADD_FLOATING_NOTIFICATION,
                response: {
                    code: 'failedToCopyToClipboard'
                }
            });
        }
    };

    const getEventData = () => {
        const params = {
            include:
                'teams,coordinators,company,activity,eventChallenges,image,image.versions,teams.completedChallenges'
        };

        return new Promise((resolve, reject) => {
            dispatch({
                type: eventsActions.START_FETCHING_EVENT,
                iri,
                promise: { resolve, reject },
                params
            });
        });
    };

    const updateEvent = (values, notification = null) => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                formData: values,
                type: eventActions.START_UPDATE_EVENT,
                iri,
                notification
            });
        })
            .then(() => {
                getEventData();
            })
            .catch(err => err);
    };

    const endEvent = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: eventActions.END_EVENT,
                eventIri: iri
            });
        })
            .then(() => {
                getEventData();
            })
            .catch(err => err);
    };

    const getLinkForEvent = item => {
        return `${process.env.REACT_APP_GAME_LINK}/overview/${item}/${hash}`;
    };

    const handleJoinEvent = useCallback(() => {
        window.open(
            `${process.env.REACT_APP_GAME_LINK}/coordinator-join/${hash}/${meData?.hash}`,
            '_blank'
        );
    }, [hash, meData.hash]);

    return (
        <div className="event-view-actions event-view-actions--flex">
            <div className="event-view-actions__col">
                {status !== 'completed' && eventData?.hash && (
                    <Button
                        iconName="joinEvent"
                        label="Join event"
                        variation="link"
                        onClick={handleJoinEvent}
                        external
                    />
                )}
                {status !== 'completed' && eventData?.hash && (
                    <Button
                        iconName="copyLink"
                        label="Copy event link"
                        variation="link"
                        onClick={onCopyEventLink}
                    />
                )}
                {status === 'active' && running && !paused && !endedAt && (
                    <Button
                        iconName="pauseEvent"
                        label="Pause event"
                        variation="link"
                        onClick={() =>
                            updateEvent({ paused: true }, { code: 'event.eventClickPause' })
                        }
                    />
                )}
                {status === 'active' && running && paused && !endedAt && (
                    <Button
                        iconName="start"
                        label="Resume event"
                        variation="link"
                        onClick={() =>
                            updateEvent({ paused: false }, { code: 'event.eventClickResume' })
                        }
                    />
                )}
                {status === 'active' && endedAt && (
                    <Button
                        iconName="stop"
                        label="Complete event"
                        variation="link"
                        onClick={() =>
                            updateEvent(
                                { status: 'completed', paused: true },
                                { code: 'event.eventClickComplete' }
                            )
                        }
                    />
                )}
                {status === 'active' && running && !endedAt && (
                    <Button iconName="stop" label="End event" variation="link" onClick={endEvent} />
                )}
                {(!!eventData?.is_self_hosted || status === 'completed') && (
                    <Button
                        iconName="summary"
                        label="Event Summary"
                        variation="link"
                        href={getLinkForEvent('summary')}
                        external
                    />
                )}
                {(!!eventData?.is_self_hosted || status === 'completed') && (
                    <Button
                        iconName="wall"
                        label="Event Wall"
                        variation="link"
                        href={getLinkForEvent('wall')}
                        external
                    />
                )}
                {status !== 'completed' && QRCodeImageUrl && (
                    <Button
                        iconName="download"
                        label="Download QR Code"
                        variation="link"
                        href={QRCodeImageUrl}
                        download={`join-event-${id || '-'}-qr-code.png`}
                    />
                )}
            </div>
            {status !== 'completed' && QRCodeImageUrl && (
                <div className="event-view-actions__col">
                    <img
                        className="event-view-actions__qr-code"
                        src={QRCodeImageUrl}
                        alt="qr code"
                    />
                </div>
            )}
        </div>
    );
};

export default EventViewActions;
