import React from 'react';
import PropTypes from 'prop-types';
import Form, { Text, Autocomplete, Phone, Email } from 'erpcore/components/Form';
import { Field, reduxForm } from 'redux-form';
import Button from 'erpcore/components/Button';

const CustomerContactsCreateEditForm = ({
    handleSubmit,
    onSubmit,
    pristine,
    invalid,
    submitting,
    submitLabel
}) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
                <Field
                    name="first_name"
                    id="first_name"
                    fieldProps={{
                        label: 'First name',
                        clearable: true
                    }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="last_name"
                    id="last_name"
                    fieldProps={{
                        label: 'Last name',
                        clearable: true
                    }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="phone"
                    id="phone"
                    fieldProps={{
                        label: 'Phone',
                        country: 'CA'
                    }}
                    component={Phone}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="email"
                    id="email"
                    fieldProps={{
                        label: 'Email',
                        clearable: true
                    }}
                    component={Email}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="company"
                    id="company"
                    fieldProps={{
                        label: 'Customer',
                        options: {
                            endpoint: '/api/companies',
                            mapData: {
                                value: 'iri',
                                label: 'name'
                            }
                        }
                    }}
                    fieldAttr={{ disabled: true }}
                    component={Autocomplete}
                />
            </Form.Row>

            <Form.Row>
                <Field
                    name="job_title"
                    id="job_title"
                    fieldProps={{
                        label: 'Job title',
                        clearable: true
                    }}
                    component={Text}
                />
            </Form.Row>

            <Form.Row>
                <Button
                    submit
                    disabled={pristine || invalid}
                    loading={submitting}
                    label={submitLabel}
                />
            </Form.Row>
        </Form>
    );
};

CustomerContactsCreateEditForm.defaultProps = {
    submitting: false,
    pristine: false,
    invalid: false,
    submitLabel: null
};

CustomerContactsCreateEditForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
    submitLabel: PropTypes.string
};

export default reduxForm({
    form: 'CustomerContactsCreateEditForm',
    enableReinitialize: true
})(CustomerContactsCreateEditForm);
