import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';

import ElementLoader from 'erpcore/components/ElementLoader';
import Form, { Switch } from 'erpcore/components/Form';
import './PermissionActiveToggle.scss';
import { actions as permissionsActions } from 'erpcore/screens/Settings/Permissions/Permissions.reducer';

const PermissionActiveToggle = ({ form, initialValues, permissionIri }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const formValues = useSelector(state => getFormValues(form)(state));
    const { active } = { ...formValues };

    const onChange = isActive => {
        setLoading(true);

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: permissionsActions.START_UPDATE_PERMISSION,
                permissionIri,
                formData: { active: isActive }
            });
        }).then(() => {
            setLoading(false);
        });
    };

    useEffect(() => {
        if (active !== undefined && active !== initialValues?.active) {
            onChange(active);
        }
    }, [active]);

    return (
        <Form className="form--permissions-toggle">
            {loading && <ElementLoader overlay />}
            <Field
                name="active"
                fieldAttr={{
                    id: 'active'
                }}
                component={Switch}
            />
        </Form>
    );
};

PermissionActiveToggle.defaultProps = {
    permissionIri: null,
    form: 'PermissionActiveToggleForm',
    initialValues: {}
};

PermissionActiveToggle.propTypes = {
    permissionIri: PropTypes.string,
    initialValues: PropTypes.oneOfType([PropTypes.object]),
    form: PropTypes.string
};

export default reduxForm({
    form: 'PermissionActiveToggleForm',
    enableReinitialize: true
})(PermissionActiveToggle);
