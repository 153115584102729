import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Svg from 'erpcore/components/Svg';
import Tooltip from 'erpcore/components/Tooltip';
import MediaFileManager from 'erpcore/components/ImageManager/MediaFileManager.jsx';

const MediaLibraryFileEdit = ({ iri, onSave }) => {
    const [modalOpened, setModalOpened] = useState(false);

    const handleModal = () => {
        setModalOpened(!modalOpened);
    };

    return (
        <>
            <Tooltip content="Edit media">
                <button type="button" onClick={() => handleModal()}>
                    <Svg icon="edit" />
                </button>
            </Tooltip>
            {modalOpened && (
                <MediaFileManager
                    fileIri={iri}
                    opened={modalOpened}
                    onClose={() => setModalOpened(false)}
                    onSave={() => {
                        if (onSave && typeof onSave === 'function') onSave();
                    }}
                />
            )}
        </>
    );
};

MediaLibraryFileEdit.defaultProps = {
    iri: null,
    onSave: () => {}
};

MediaLibraryFileEdit.propTypes = {
    iri: PropTypes.string,
    onSave: PropTypes.func
};

export default MediaLibraryFileEdit;
