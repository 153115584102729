import React, { useCallback, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import isImage from 'is-image';
import moment from 'moment-timezone';
import Svg from 'erpcore/components/Svg';
import Notification from 'erpcore/components/Notification';
import { useDispatch, useSelector } from 'react-redux';
import {
    getFileExtension,
    getImageSrcFromMediaObject,
    formatBytes
} from 'erpcore/components/ImageManager';
import Search from 'erpcore/components/Listing/components/Search';
import InfiniteScroll from 'react-infinite-scroll-component';
import Button from 'erpcore/components/Button';
import ElementLoader from 'erpcore/components/ElementLoader';
import Tag from 'erpcore/components/Tag';
import { actions as imageManagerActions } from 'erpcore/components/ImageManager/ImageManager.reducer';
import { actions as mediaLibraryActions } from './MediaLibraryGallery.reducer';
import MediaLibraryEdit from '../MediaLibraryEdit';
import MediaLibraryDelete from '../MediaLibraryDelete';
import {
    getMediaLibraryItems,
    getMediaLibraryItemsFetching,
    getMediaLibraryItemsMeta
} from './MediaLibraryGallery.selectors';
import styles from './MediaLibraryGallery.module.scss';

const MediaLibraryGallery = ({ allowedFileTypes, limit, onSubmit }) => {
    const dispatch = useDispatch();

    const [disabled, setDisabled] = useState(true);
    const [itemLimitReached, setItemLimitReach] = useState(false);
    const [selected, setSelected] = useState({});
    const [queryParams, setQueryParams] = useState({});

    const mediaLibraryItems = useSelector(state => getMediaLibraryItems(state));
    const mediaLibraryFetching = useSelector(state => getMediaLibraryItemsFetching(state));
    const mediaLibraryMeta = useSelector(state => getMediaLibraryItemsMeta(state));

    const itemLimit = limit < 0 ? 1 : limit;
    const selectedItemsCount = Object.keys(selected).length;
    const hasSelected = selectedItemsCount > 0;

    // region Functions

    const fetchImages = useCallback(
        (query = '') => {
            return new Promise((resolve, reject) => {
                dispatch({
                    promise: { resolve, reject },
                    type: mediaLibraryActions.FETCH_MEDIA_LIBRARY_GALLERY,
                    allowedFileTypes,
                    query
                });
            })
                .then(() => {
                    setSelected({});
                })
                .catch(error => {
                    return error;
                })
                .finally(() => {
                    setQueryParams({ q: query });
                });
        },
        [allowedFileTypes]
    );

    const onChangeSearch = useCallback(params => {
        return fetchImages(params?.q || '');
    }, []);

    const handleItemClick = useCallback(
        mediaItem => {
            const allSelectedItems = { ...selected };
            // handle only if limit is 1
            if (itemLimit === 1) {
                setSelected({ [mediaItem.id]: mediaItem });
            }
            // remove item if exists
            else if (allSelectedItems[mediaItem.id]) {
                delete allSelectedItems[mediaItem.id];
                setSelected({ ...allSelectedItems });
            }
            // set new items
            else {
                setSelected({ ...allSelectedItems, [mediaItem.id]: mediaItem });
            }

            // dispatch to redux
            if (!allSelectedItems[mediaItem.id]) {
                dispatch({
                    type: imageManagerActions.UPDATE_IMAGE_SUCCESSFUL,
                    iri: mediaItem.iri,
                    response: mediaItem
                });
            }
        },
        [selected]
    );

    const handleButtonClick = useCallback(
        items => {
            if (!disabled) {
                const formData = {
                    files: Object.values(items).reduce((acc, { iri }) => {
                        return [...acc, iri];
                    }, [])
                };
                if (onSubmit && typeof onSubmit === 'function' && !disabled)
                    onSubmit(formData, items);
            }
        },
        [onSubmit, disabled]
    );

    /**
     * current page {number}
     * query {string}
     * @type {function(*=, *=): Promise<void>}
     */
    const fetchMoreImages = useCallback(
        (page, query = '') => {
            const newPage = Number(page) + 1;
            return new Promise((resolve, reject) => {
                dispatch({
                    promise: { resolve, reject },
                    type: mediaLibraryActions.FETCH_MORE_MEDIA_LIBRARY_GALLERY,
                    page: newPage,
                    allowedFileTypes,
                    query
                });
            }).catch(error => {
                return error;
            });
        },
        [allowedFileTypes]
    );

    // endregion

    // region Effects

    useEffect(() => {
        fetchImages();
    }, [allowedFileTypes]);

    useEffect(() => {
        if (Object.keys(selected).length > 0 && Object.keys(selected).length <= itemLimit) {
            setDisabled(false);
            setItemLimitReach(false);
        } else if (Object.keys(selected).length > itemLimit) {
            setDisabled(true);
            setItemLimitReach(true);
        } else {
            setDisabled(true);
        }
    }, [selected]);

    // endregion

    // region Memos & markup

    const hasMoreItems = useMemo(() => {
        const { itemsPerPage, totalItems, currentPage } = { ...mediaLibraryMeta };
        return Number(totalItems) / Number(itemsPerPage) >= Number(currentPage);
    }, [mediaLibraryMeta]);

    const currentPage = useMemo(() => {
        const { currentPage: currentPageNumber } = { ...mediaLibraryMeta };
        return Number(currentPageNumber);
    }, [mediaLibraryMeta]);

    const mediaListingItems = useMemo(() => {
        return mediaLibraryItems.map((media, index) => {
            const { content_url: contentUrl } = media;
            const itemIsImage = isImage(contentUrl);
            const fileExtension = getFileExtension(contentUrl);
            const smallImageUrl = getImageSrcFromMediaObject(media, 'small');
            const style = smallImageUrl ? { backgroundImage: `url('${smallImageUrl}')` } : '';

            const fileTypeNotAllowed =
                allowedFileTypes?.length &&
                !allowedFileTypes.includes((fileExtension || '').toLowerCase());

            return (
                <div
                    className={`${styles.gallery__col} ${
                        hasSelected ? styles['gallery__col--is-4'] : ''
                    }`}
                    key={`mediaLibraryGallery__${media.id || index}`}
                >
                    <button
                        disabled={!!fileTypeNotAllowed}
                        type="button"
                        onClick={() => handleItemClick(media)}
                        className={`${styles.item} ${itemIsImage ? styles['is-image'] : ''} ${
                            selected[media.id] ? styles.item__active : ''
                        }`}
                        data-file-type={fileExtension.toLowerCase()}
                        style={{ ...style }}
                    >
                        {media?.meta?.filename && !itemIsImage && (
                            <span className={styles.item__title}>{media.meta.filename}</span>
                        )}
                        <Svg
                            icon="checkmark"
                            className={`${styles.checkmark} ${
                                selected[media.id] ? styles.checkmark__active : ''
                            }`}
                        />
                    </button>
                </div>
            );
        });
    }, [mediaLibraryItems, hasSelected, selected]);

    const mediaSidebarSelected = useMemo(() => {
        return hasSelected ? (
            <div className={styles.gallery__right}>
                <div className={styles.sidebar}>
                    {selectedItemsCount > 1 ? (
                        <span className={styles.sidebar__title}>
                            {selectedItemsCount} media selected
                        </span>
                    ) : (
                        <span className={styles.sidebar__title}>Media details</span>
                    )}
                    <ul className={styles.sidebar__list}>
                        {Object.values(selected).map((item, index) => {
                            const { content_url: contentUrl } = item;
                            const itemIsImage = isImage(contentUrl);
                            const fileExtension = getFileExtension(contentUrl);
                            const smallImageUrl = getImageSrcFromMediaObject(item, 'small');
                            const style = smallImageUrl
                                ? { backgroundImage: `url('${smallImageUrl}')` }
                                : '';
                            return (
                                <li
                                    title={item?.meta?.filename}
                                    key={`mediaLibraryGallerySelected__${item.id || index}`}
                                >
                                    <div
                                        className={`${styles.item} ${
                                            itemIsImage ? styles['is-image'] : ''
                                        }`}
                                        data-file-type={fileExtension.toLowerCase()}
                                        style={{ ...style }}
                                    >
                                        {item?.meta?.filename && !itemIsImage && (
                                            <span className={styles.item__title}>
                                                <span>{item.meta.filename}</span>
                                            </span>
                                        )}
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                    {selectedItemsCount === 1 &&
                        Object.values(selected).map((item, index) => {
                            const itemIsImage = isImage(item.content_url);
                            return (
                                <div key={`mediaLibraryGallerySelected__${item.id || index}`}>
                                    <ul className={styles.sidebar__details}>
                                        {item.meta?.filename && (
                                            <li>
                                                <strong>File name:</strong>{' '}
                                                <span>{item.meta.filename}</span>
                                            </li>
                                        )}
                                        {item.meta?.caption && (
                                            <li>
                                                <strong>Caption:</strong>{' '}
                                                <span>{item.meta.caption}</span>
                                            </li>
                                        )}
                                        <li>
                                            <strong>Uploaded on:</strong>{' '}
                                            {moment(item.created_at).format('MM/DD/YYYY')}
                                        </li>
                                        {item.meta?.size && (
                                            <li>
                                                <strong>File size (original):</strong>{' '}
                                                <span>{formatBytes(item.meta.size)}</span>
                                            </li>
                                        )}
                                        {item.meta?.width && item.meta?.height && (
                                            <li>
                                                <strong>Dimensions:</strong>{' '}
                                                <span>
                                                    {item.meta.width} x {item.meta.height}
                                                </span>
                                            </li>
                                        )}
                                        {!!item.tags?.length && (
                                            <li>
                                                <strong
                                                    style={{
                                                        display: 'block',
                                                        marginBottom: '4px'
                                                    }}
                                                >
                                                    Tags:
                                                </strong>{' '}
                                                <Tag.Wrapper>
                                                    {item.tags.map(tag => (
                                                        <Tag>{tag?.name}</Tag>
                                                    ))}
                                                </Tag.Wrapper>
                                            </li>
                                        )}
                                    </ul>
                                    <ul className={styles.sidebar__actions}>
                                        {itemIsImage && item.iri && (
                                            <li>
                                                <MediaLibraryEdit
                                                    imageIri={item.iri}
                                                    onSaveImage={() => {
                                                        fetchImages();
                                                    }}
                                                />
                                            </li>
                                        )}
                                        <li>
                                            <MediaLibraryDelete
                                                iri={item.iri}
                                                onComplete={() => {
                                                    fetchImages();
                                                }}
                                            />
                                        </li>
                                    </ul>
                                </div>
                            );
                        })}
                </div>
            </div>
        ) : (
            ''
        );
    }, [hasSelected, selectedItemsCount, selected]);

    // endregion

    return (
        <>
            <div className={styles.search}>
                <Search onChangeSearch={onChangeSearch} queryParams={queryParams} />
            </div>
            {itemLimit > 1 && itemLimitReached && (
                <Notification
                    title="Warning!"
                    text={`Max allowed items: ${limit < 0 ? 1 : limit}`}
                    type="warning"
                />
            )}
            {!mediaLibraryFetching && mediaListingItems.length > 0 && (
                <div className={styles.gallery}>
                    <div className={styles.gallery__left}>
                        <div className={styles.gallery__row}>
                            <div
                                id="mediaLibraryGalleryScrollable"
                                className={styles.gallery__listing}
                            >
                                <InfiniteScroll
                                    scrollableTarget="mediaLibraryGalleryScrollable"
                                    className={styles.gallery__infinite}
                                    dataLength={mediaListingItems.length}
                                    next={() => {
                                        return fetchMoreImages(currentPage, queryParams?.q);
                                    }}
                                    hasMore={hasMoreItems}
                                    loader={
                                        <div className={styles.gallery__loader}>
                                            <ElementLoader />
                                        </div>
                                    }
                                >
                                    {mediaListingItems}
                                </InfiniteScroll>
                            </div>
                        </div>
                    </div>
                    {mediaSidebarSelected}
                </div>
            )}
            {mediaLibraryFetching ? (
                <div className={styles.loader}>
                    <ElementLoader />
                </div>
            ) : (
                <Button
                    disabled={disabled}
                    label="Insert media"
                    onClick={() => handleButtonClick(selected)}
                />
            )}
        </>
    );
};

MediaLibraryGallery.defaultProps = {
    allowedFileTypes: null,
    limit: -1,
    onSubmit: () => {}
};

MediaLibraryGallery.propTypes = {
    allowedFileTypes: PropTypes.oneOfType([PropTypes.array]),
    limit: PropTypes.number,
    onSubmit: PropTypes.func
};

export default MediaLibraryGallery;
