import React, { useEffect } from 'react';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import EventsEditPageHeader from 'erpcore/screens/Events/screens/EventsEdit/components/EventEditPageHeader';
import { useRouteMatch } from 'react-router-dom';
import EntityContent from 'erpcore/screens/EntityContent/screens/EntityContent';
import { actions as eventActions } from 'erpcore/screens/Events/Events.reducer';
import { useDispatch } from 'react-redux';

const EventContent = () => {
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const id = match?.params?.id;
    const iri = `/api/events/${id}`;

    const fetchEventsData = () => {
        const params = {
            include: 'contents,contents.items,contents.items.media,contents.items.media.media'
        };

        return new Promise((resolve, reject) => {
            dispatch({
                type: eventActions.START_FETCHING_EVENT,
                iri,
                promise: { resolve, reject },
                params
            });
        });
    };

    useEffect(() => {
        fetchEventsData();
    }, []);

    return (
        <LayoutManager slot="main" className="main--medium" layoutType="merge">
            <EventsEditPageHeader />
            <PageContent>
                <EntityContent resource="events" entityIri={iri} callback={fetchEventsData} />
            </PageContent>
        </LayoutManager>
    );
};

export default EventContent;
