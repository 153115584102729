import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Form, { Text, Media, Select, TextEditor } from 'erpcore/components/Form';
import { valueValidation } from 'erpcore/components/Form/Form.utils';
import {
    Field,
    getFormInitialValues,
    getFormValues,
    hasSubmitSucceeded,
    reduxForm
} from 'redux-form';
import Button from 'erpcore/components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { actions as challengesActions } from 'erpcore/screens/Challenges/Challenges.reducer';
import {
    getChallengeTypes,
    getChallengeTypesFetching
} from 'erpcore/screens/Challenges/Challenges.selectors';
import ElementLoader from 'erpcore/components/ElementLoader';
import ChallengeTypeTrivia from 'erpcore/screens/Challenges/components/ChallangeTypeTrivia';
import './ChallengeCreateEditForm.scss';
import ChallengeTypePhoto from 'erpcore/screens/Challenges/components/ChallengeTypePhoto';
import ChallengeTypeMultipleChoice from 'erpcore/screens/Challenges/components/ChallengeTypeMultipleChoice';
import ChallengeTypeText from 'erpcore/screens/Challenges/components/ChallengeTypeText';
import ChallengeTypeStickerPhoto from 'erpcore/screens/Challenges/components/ChallengeTypeStickerPhoto';
import ChallengeTypeQRCode from 'erpcore/screens/Challenges/components/ChallengeTypeQRCode';
import ChallengeTypeDate from 'erpcore/screens/Challenges/components/ChallengeTypeDate';
import ChallengeTypeSettings from 'erpcore/screens/Challenges/components/ChallengeTypeSettings';
import ChallengeTypeVideo from 'erpcore/screens/Challenges/components/ChallengeTypeVideo';
import UnsavedChanges from 'erpcore/components/UnsavedChanges';
import { diff } from 'deep-object-diff';

const ChallengeCreateEditForm = ({
    handleSubmit,
    onSubmit,
    pristine,
    invalid,
    submitting,
    submitLabel,
    onCancel,
    form,
    settings,
    entity,
    data,
    wallDisabled,
    wallTooltipContent
}) => {
    const [selectedType, setSelectedType] = useState(null);
    const dispatch = useDispatch();
    const formValues = useSelector(state => getFormValues(form)(state));
    const challengeTypes = useSelector(getChallengeTypes);
    const challengeTypesFetching = useSelector(getChallengeTypesFetching);
    const hasSubmitted = useSelector(state => hasSubmitSucceeded(form)(state));
    const initialValues = useSelector(state => getFormInitialValues(form)(state));

    const checkIfFormIsDirty = () => {
        const difference = diff(initialValues, formValues);
        const keys = Object.keys(difference);

        keys.forEach(key => {
            if (difference[key] === null || difference[key] === '') {
                delete difference[key];
            }
        });

        delete difference?.included;

        if (Object.keys(difference)?.length) {
            return true;
        }

        return false;
    };

    const { type } = { ...formValues };

    const fetchChallengeTypes = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: challengesActions.START_FETCHING_CHALLENGE_TYPES
            });
        });
    };

    const options =
        challengeTypes?.data?.map(({ name: label, iri: value }) => ({ label, value })) || [];

    useEffect(() => {
        fetchChallengeTypes();
    }, []);

    const getTypeIdentifier = challengeType => {
        const match = challengeTypes?.data?.find(item => item?.iri === challengeType);

        if (match) return match;

        return null;
    };

    useEffect(() => {
        const identifier = getTypeIdentifier(type);

        setSelectedType(identifier);
    }, [type, challengeTypes]);

    return (
        <Form
            className="challenge-form"
            onSubmit={handleSubmit(formData =>
                onSubmit({
                    formData,
                    challengeType: selectedType?._type,
                    preventEditRedirect: false
                })
            )}
        >
            <UnsavedChanges
                promptWhen={checkIfFormIsDirty() && !hasSubmitted && !submitting}
                onSubmit={handleSubmit(formData =>
                    onSubmit({
                        formData,
                        challengeType: selectedType?._type,
                        preventEditRedirect: true
                    })
                )}
            />
            <Form.SectionTitleSmall>General</Form.SectionTitleSmall>
            <Form.Row>
                <Field
                    name="title"
                    id="title"
                    fieldProps={{
                        label: 'Title',
                        clearable: true
                    }}
                    fieldAttr={{ required: true }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>

            <Form.Columns>
                <Form.GridColumn size={3}>
                    <Field
                        name="media"
                        id="media"
                        component={Media}
                        useLightbox
                        useImageManager
                        allowedFileTypes={['jpg', 'jpeg', 'png', 'gif']}
                    />
                </Form.GridColumn>
                <Form.GridColumn size={9}>
                    <Field
                        fieldAttr={{ required: true }}
                        fieldProps={{
                            label: 'Description',
                            clearable: true
                        }}
                        name="description"
                        id="description"
                        component={TextEditor}
                        validate={valueValidation([{ validator: 'required' }])}
                    />
                </Form.GridColumn>
            </Form.Columns>

            <div className="challenge-form__type">
                {challengeTypesFetching && <ElementLoader overlay />}
                <Form.SectionTitleSmall>Type</Form.SectionTitleSmall>
                <Form.Row>
                    <Field
                        name="type"
                        id="type"
                        fieldProps={{
                            label: 'Complete by',
                            options,
                            clearable: true
                        }}
                        fieldAttr={{
                            required: true
                        }}
                        component={Select}
                        validate={valueValidation([{ validator: 'required' }])}
                    />
                </Form.Row>

                {selectedType?._type === 'TYPE_TRIVIA' && <ChallengeTypeTrivia />}
                {selectedType?._type === 'TYPE_PHOTO' && <ChallengeTypePhoto />}
                {selectedType?._type === 'TYPE_VIDEO' && <ChallengeTypeVideo />}
                {selectedType?._type === 'TYPE_MULTIPLE_CHOICE' && (
                    <ChallengeTypeMultipleChoice form={form} />
                )}
                {selectedType?._type === 'TYPE_TEXT' && <ChallengeTypeText />}
                {selectedType?._type === 'TYPE_STICKER_PHOTO' && <ChallengeTypeStickerPhoto />}
                {selectedType?._type === 'TYPE_QR' && (
                    <ChallengeTypeQRCode data={data} entity={entity} form={form} />
                )}
                {selectedType?._type === 'TYPE_DATE' && <ChallengeTypeDate form={form} />}

                <ChallengeTypeSettings
                    data={data}
                    wallDisabled={wallDisabled || !selectedType?.wallable}
                    settings={settings}
                    form={form}
                    selectedType={selectedType}
                    wallTooltipContent={wallTooltipContent}
                />
            </div>

            <Button
                submit
                disabled={pristine || invalid}
                loading={submitting}
                label={submitLabel}
            />

            {submitLabel === 'Create' && (
                <Button
                    disabled={pristine || invalid}
                    loading={submitting}
                    label="Create and add another"
                    variation="secondary"
                    onClick={handleSubmit(formData =>
                        onSubmit({
                            formData,
                            challengeType: selectedType?._type,
                            preventEditRedirect: true,
                            addAnother: true
                        })
                    )}
                />
            )}

            {onCancel && <Button variation="secondary" label="Cancel" onClick={() => onCancel()} />}
        </Form>
    );
};

ChallengeCreateEditForm.defaultProps = {
    submitting: false,
    pristine: false,
    invalid: false,
    submitLabel: null,
    onCancel: null,
    form: 'ChallengeCreateEditForm',
    settings: {},
    wallDisabled: null,
    wallTooltipContent: 'This challenge type cannot be walled'
};

ChallengeCreateEditForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
    submitLabel: PropTypes.string,
    onCancel: PropTypes.func,
    form: PropTypes.string,
    settings: PropTypes.oneOfType([PropTypes.object]),
    entity: PropTypes.string.isRequired,
    data: PropTypes.oneOfType([PropTypes.object]).isRequired,
    wallDisabled: PropTypes.bool,
    wallTooltipContent: PropTypes.string
};

export default reduxForm({
    enableReinitialize: true
})(ChallengeCreateEditForm);
