import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'erpcore/components/Modal';
import Button from 'erpcore/components/Button';
import { getMediaLibraryItemDeleting } from '../MediaLibraryGallery/MediaLibraryGallery.selectors';
import { actions as mediaLibraryActions } from '../MediaLibraryGallery/MediaLibraryGallery.reducer';

const MediaLibraryDelete = ({ iri, onComplete }) => {
    const dispatch = useDispatch();
    const [modalOpened, setModalOpened] = useState(false);

    const itemDeleting = useSelector(state => getMediaLibraryItemDeleting(state));

    const handleModal = () => {
        setModalOpened(!modalOpened);
    };

    const onSubmit = () => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: mediaLibraryActions.START_DELETING_MEDIA_LIBRARY_GALLERY_ITEM,
                iri
            })
        )
            .then(() => {
                if (onComplete && typeof onComplete === 'function') onComplete();
            })
            .catch(error => {
                return error;
            })
            .finally(() => {
                handleModal();
            });
    };

    return (
        <>
            <Button label="Delete" variation="secondary" onClick={() => handleModal()} />
            {modalOpened && (
                <Modal
                    variation="small"
                    opened={modalOpened}
                    onClose={handleModal}
                    title="Delete media"
                    subtitle={
                        <>
                            <span className="color--red">Warning:</span> Deleting this media object
                            will remove it from everywhere where it is used. This action is
                            irreversible. <br />
                            <br />
                            Are you sure?
                        </>
                    }
                >
                    <Button
                        type="button"
                        loading={itemDeleting}
                        onClick={() => onSubmit()}
                        label="Delete"
                    />
                    <Button variation="secondary" label="Cancel" onClick={handleModal} />
                </Modal>
            )}
        </>
    );
};

MediaLibraryDelete.defaultProps = {
    onComplete: () => {}
};

MediaLibraryDelete.propTypes = {
    iri: PropTypes.string.isRequired,
    onComplete: PropTypes.func
};

export default MediaLibraryDelete;
