import { takeLatest, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import { getQueryParams } from 'erpcore/components/Listing/Listing.selectors';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import dto from 'erpcore/utils/dto';
import { actions as customerActions } from './Customers.reducer';

const customerListingIncludeString = 'include=country,city';

/**
 * Delete Single Customer
 * @param  {Object} id ID of a Customer
 * @return {Object} Response from API
 */
export function* deleteSingleCustomer({ promise, iri }) {
    try {
        const deleteSingleCustomerAPI = yield restClient.delete(iri);
        yield put({
            type: customerActions.DELETE_CUSTOMER_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteSingleCustomerAPI?.data
        });

        const params = yield select(getQueryParams, { name: 'customers' });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'CUSTOMERS',
            name: 'customers',
            endpoint: `api/companies?${customerListingIncludeString}`
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: customerActions.DELETE_CUSTOMER_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Create Customer
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createCustomer({ promise, formData }) {
    try {
        const createCustomerAPI = yield restClient.post(`/api/companies`, formData);
        yield put({
            type: customerActions.CREATE_CUSTOMER_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createCustomerAPI?.data
        });
        yield call(promise.resolve, createCustomerAPI?.data);
    } catch (error) {
        yield put({
            type: customerActions.CREATE_CUSTOMER_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Customer
 * @param  {Object} promise
 * @param {string} customer Iri
 * @return {Object} response from API
 */
export function* fetchSingleCustomer({ promise, iri, params }) {
    try {
        const fetchSingleCustomerAPI = yield restClient.get(iri, { params });
        yield put({
            type: customerActions.FETCH_CUSTOMER_SUCCESSFUL
        });
        yield put({
            type: customerActions.STORE_CUSTOMER_DATA,
            iri,
            response: dto(fetchSingleCustomerAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: customerActions.FETCH_CUSTOMER_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Single Customer data
 * @param  {Object} id ID of an User
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* updateSingleCustomer({ promise, formData, iri }) {
    try {
        const updateSingleCustomerAPI = yield restClient.put(iri, formData);
        yield put({
            type: customerActions.UPDATE_CUSTOMER_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleCustomerAPI?.data
        });
        yield put({
            type: customerActions.STORE_CUSTOMER_DATA,
            iri,
            response: dto(updateSingleCustomerAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: customerActions.UPDATE_CUSTOMER_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Bulk Actions - Delete Customer
 * @param  {Object} iri of the companies
 * @return {Object} Response from API
 */
export function* deleteCustomersBulkAction({ promise, formData }) {
    try {
        formData.method = 'DELETE';
        yield restClient.post('api/batch-requests', formData);
        yield put({
            type: customerActions.BULK_ACTIONS_DELETE_CUSTOMERS_SUCCESS
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: { code: 'companies.itemSuccessfulyRemoved' }
        });
        yield put({
            type: listingActions.UPDATE_BULK_ACTIONS_IRIS,
            response: [],
            name: 'customers'
        });

        const params = yield select(getQueryParams, { name: 'customers' });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'CUSTOMERS',
            name: 'customers',
            endpoint: `api/companies?${customerListingIncludeString}`
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: customerActions.BULK_ACTIONS_DELETE_CUSTOMERS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export const customersSaga = [
    takeLatest(customerActions.START_DELETE_CUSTOMER, deleteSingleCustomer),
    takeLatest(customerActions.START_CREATE_CUSTOMER, createCustomer),
    takeLatest(customerActions.START_FETCHING_CUSTOMER, fetchSingleCustomer),
    takeLatest(customerActions.START_UPDATE_CUSTOMER, updateSingleCustomer),
    takeLatest(customerActions.START_BULK_ACTIONS_DELETE_CUSTOMERS, deleteCustomersBulkAction)
];
