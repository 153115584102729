import React, { useState } from 'react';
import Modal from 'erpcore/components/Modal';
import Button from 'erpcore/components/Button';
import { actions as entityyNotificationActions } from 'erpcore/screens/EntityNotifications/EntityNotifications.reducer';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import EntityyNotificationAddEditForm from '../EntityNotificationsAddEditForm';

const EntityNotificationAdd = ({ callback, entityIri, entity, endpoint }) => {
    const [opened, setOpened] = useState(false);
    const dispatch = useDispatch();

    const handleModal = () => {
        setOpened(!opened);
    };

    const formatTimeValue = (time, unit) => {
        if (unit === 'hours') {
            return parseInt(time, 10) * 60 * 60;
        }

        if (unit === 'minutes') {
            return parseInt(time, 10) * 60;
        }

        if (unit === 'seconds') {
            return parseInt(time, 10);
        }

        return 0;
    };

    const onSubmit = formValues => {
        const { content, type, time, unit } = { ...formValues };

        const formData = {
            content,
            type,
            [entity]: entityIri,
            ...(type === 'AT_THE_END' ? {} : { time: formatTimeValue(time, unit) })
        };

        return new Promise((resolve, reject) => {
            dispatch({
                type: entityyNotificationActions.START_CREATE_ENTITY_NOTIFICATION,
                promise: { resolve, reject },
                formData,
                endpoint
            });
        })
            .then(() => {
                handleModal();
                callback();
            })
            .catch(err => err);
    };

    return (
        <>
            <Button label="Add notification" onClick={handleModal} variation="secondary" />

            {opened && (
                <Modal
                    variation="medium"
                    opened={opened}
                    onClose={handleModal}
                    title="New notification"
                >
                    <EntityyNotificationAddEditForm onSubmit={onSubmit} onCancel={handleModal} />
                </Modal>
            )}
        </>
    );
};

EntityNotificationAdd.defaultProps = {
    callback: () => {}
};

EntityNotificationAdd.propTypes = {
    callback: PropTypes.func,
    endpoint: PropTypes.string.isRequired,
    entity: PropTypes.string.isRequired,
    entityIri: PropTypes.string.isRequired
};

export default EntityNotificationAdd;
