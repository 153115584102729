import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, getFormValues, updateSyncErrors, change, FieldArray } from 'redux-form';
import Form, {
    Text,
    Switch,
    SwitchCard,
    Media,
    ColorPicker,
    TagsInput,
    Textarea,
    Repeater
} from 'erpcore/components/Form';
import { valueValidation } from 'erpcore/components/Form/Form.utils';
import { parseInteger } from 'erpcore/utils/utils';
import UnsavedChanges from 'erpcore/components/UnsavedChanges';
import Button from 'erpcore/components/Button';
import Tooltip from 'erpcore/components/Tooltip';
import list from 'erpcore/assets/svg/list.svg';
import { useRouteMatch } from 'react-router-dom';
import { getSingleEventData } from 'erpcore/screens/Events/Events.selectors';
import { hasPermission } from 'erpcore/utils/RolesManager';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import styles from './SettingsForm.module.scss';

const WelcomeEmailRepeaterItem = ({ member }) => {
    return (
        <>
            <Form.Row>
                <Field
                    name={`${member}.first_name`}
                    fieldProps={{
                        label: 'First name',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member}.first_name`, required: true }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
                <Field
                    name={`${member}.last_name`}
                    fieldProps={{
                        label: 'Last name',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member}.last_name`, required: true }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
                <Field
                    name={`${member}.email`}
                    fieldProps={{
                        label: 'Email',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member}.email`, required: true }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }, { validator: 'email' }])}
                />
            </Form.Row>
        </>
    );
};

WelcomeEmailRepeaterItem.defaultProps = {
    member: null
};

WelcomeEmailRepeaterItem.propTypes = {
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

const formName = 'SettingsForm';

const SettingsForm = ({
    eventStarted,
    onSubmit,
    handleSubmit,
    submitting,
    invalid,
    pristine,
    dirty,
    onChangeChatMessage
}) => {
    const formValues = useSelector(state => getFormValues(formName)(state));
    const { single_participant_team } = { ...formValues };
    const singleParticipantTeamRef = useRef(single_participant_team);
    const { eventSummary, chatMessage, welcomeChatMessage, teamSize } = { ...formValues?.settings };
    const match = useRouteMatch();
    const eventId = match?.params?.id;
    const iri = `/api/events/${eventId}`;
    const dispatch = useDispatch();

    const fallbackTeamSize = useRef(teamSize);

    const eventData = useSelector(state => getSingleEventData(state, iri));
    const { activity } = { ...eventData };

    const meData = useSelector(getMeData) || {};
    const isUserAdmin = hasPermission('CAN_MANAGE_COMMON_EVENT', meData);

    const isDisabled = eventData?.status === 'completed';

    useEffect(() => {
        if (chatMessage?.includes('%activityName%') && !activity && welcomeChatMessage) {
            dispatch(
                updateSyncErrors(formName, {
                    settings: {
                        chatMessage:
                            '%activityName% tag is not available because the activity is not set'
                    }
                })
            );
        }
    }, [chatMessage, activity, welcomeChatMessage]);

    useEffect(() => {
        if (!singleParticipantTeamRef.current) {
            fallbackTeamSize.current = teamSize;
        }
    }, [teamSize]);

    useEffect(() => {
        if (single_participant_team !== singleParticipantTeamRef.current) {
            if (!!single_participant_team && teamSize !== 1) {
                dispatch(change(formName, 'settings.teamSize', 1));
            } else if (!single_participant_team && teamSize !== fallbackTeamSize.current) {
                dispatch(change(formName, 'settings.teamSize', fallbackTeamSize.current));
            }
        }

        singleParticipantTeamRef.current = single_participant_team;
    }, [single_participant_team, teamSize]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <UnsavedChanges promptWhen={dirty && !submitting} onSubmit={handleSubmit(onSubmit)} />
            <Form.Row>
                <Field
                    name="single_participant_team"
                    id="single_participant_team"
                    fieldProps={{
                        label: 'Only team leader will log in',
                        description:
                            !single_participant_team && teamSize === 1 ? (
                                <span style={{ color: 'red' }}>
                                    It is highly recommended to turn this option &quot;On&quot; if
                                    the default team size is one!
                                </span>
                            ) : (
                                ''
                            )
                    }}
                    fieldAttr={{
                        disabled: !!eventStarted || !!isDisabled
                    }}
                    component={SwitchCard}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="settings.teamSize"
                    id="settings.teamSize"
                    fieldProps={{
                        label: 'Default team size',
                        clearable: true
                    }}
                    parse={parseInteger}
                    fieldAttr={{
                        required: true,
                        disabled: !!single_participant_team || !!isDisabled
                    }}
                    component={Text}
                    validate={valueValidation([
                        { validator: 'required' },
                        { validator: 'numericality' }
                    ])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="number_of_participants"
                    id="number_of_participants"
                    fieldProps={{
                        label: 'Group Size',
                        clearable: false
                    }}
                    parse={parseInteger}
                    fieldAttr={{ required: true, disabled: !!isDisabled }}
                    component={Text}
                    validate={valueValidation([
                        { validator: 'required' },
                        { validator: 'numericality' }
                    ])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="number_of_teams"
                    id="number_of_teams"
                    fieldProps={{
                        label: 'Est. Teams',
                        clearable: false
                    }}
                    parse={parseInteger}
                    fieldAttr={{ required: true, disabled: !!isDisabled }}
                    component={Text}
                    validate={valueValidation([
                        { validator: 'required' },
                        { validator: 'numericality' }
                    ])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="settings.leaderboardVisibility"
                    id="settings.leaderboardVisibility"
                    fieldProps={{
                        label: 'Leaderboard visible',
                        description:
                            'Turn on if you would like participants to be able to see the leaderboard within the lobby during the event.'
                    }}
                    fieldAttr={{ disabled: !!isDisabled }}
                    component={SwitchCard}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="settings.enableHelpRequest"
                    id="settings.enableHelpRequest"
                    fieldProps={{
                        label: 'Enable help requests',
                        description:
                            'Turn on if you would like participants to be able to send help requests.'
                    }}
                    fieldAttr={{ disabled: !!isDisabled }}
                    component={SwitchCard}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="settings.emailAccess"
                    id="settings.emailAccess"
                    fieldProps={{
                        label: 'Email required for access',
                        description:
                            'If this option is turned on, all participants will be required to enter their email when joining the event.'
                    }}
                    fieldAttr={{ disabled: !!isDisabled }}
                    component={SwitchCard}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="settings.eventSummary"
                    id="settings.eventSummary"
                    fieldProps={{
                        label: 'Send Summary Email to Additional People'
                    }}
                    fieldAttr={{ disabled: !!isDisabled }}
                    component={SwitchCard}
                >
                    {eventSummary && (
                        <div className={styles['event-summary-mail']}>
                            <Form.Row>
                                <Field
                                    name="settings.sendSummaryAdditional"
                                    id="settings.sendSummaryAdditional"
                                    fieldProps={{
                                        label: 'Additional Emails',
                                        placeholder: 'Add Additional Email',
                                        helperText: "Press 'Enter' or 'Tab' to add email"
                                    }}
                                    fieldAttr={{
                                        id: 'settings.sendSummaryAdditional',
                                        required: true,
                                        disabled: !!isDisabled
                                    }}
                                    component={TagsInput}
                                    validate={valueValidation([{ validator: 'required' }])}
                                />
                            </Form.Row>
                        </div>
                    )}
                </Field>
            </Form.Row>
            <div className={styles['welcome-email']}>
                <Form.SectionTitle>Welcome Email</Form.SectionTitle>
                <Form.Row>
                    <Field
                        name="settings.send_welcome_email"
                        id="send_welcome_email"
                        fieldProps={{
                            label: 'Automatically Send Welcome Email'
                        }}
                        fieldAttr={{ disabled: !!isDisabled }}
                        component={Switch}
                    />
                </Form.Row>
                <Form.Row>
                    <FieldArray
                        name="settings.welcome_email_receivers"
                        component={Repeater}
                        RepeatableItem={WelcomeEmailRepeaterItem}
                        canSort={false}
                        canSortNewItems={false}
                        canAddMultiple
                        canSave={false}
                        uniqueIdentifier="id"
                        addNewLabel="Add Welcome Email Receiver"
                        newItemInitialValues={{
                            id: (Math.random() + 1).toString(36)
                        }}
                    />
                </Form.Row>
            </div>
            <Form.Row>
                <Field
                    name="settings.custom_challenges_url"
                    id="settings.custom_challenges_url"
                    fieldProps={{
                        label: 'Custom challenges URLs',
                        placeholder: 'Add challenges URL',
                        helperText: "Press 'Enter' or 'Tab' to add url"
                    }}
                    fieldAttr={{
                        id: 'settings.custom_challenges_url',
                        disabled: !!isDisabled
                    }}
                    component={TagsInput}
                />
            </Form.Row>
            <div className={styles['switch-group']}>
                <Field
                    name="settings.welcomeChatMessage"
                    id="settings.welcomeChatMessage"
                    fieldProps={{
                        label: 'Welcome message',
                        className: styles['switch-group__main']
                    }}
                    fieldAttr={{ disabled: !!isDisabled }}
                    component={SwitchCard}
                />
                {formValues?.settings?.welcomeChatMessage && (
                    <div className={styles['switch-group__chat']}>
                        <Field
                            name="settings.chatMessage"
                            id="settings.chatMessage"
                            fieldProps={{
                                label: 'Message'
                            }}
                            fieldAttr={{ disabled: !!isDisabled }}
                            component={Textarea}
                        />
                        <ul>
                            <li>
                                Company name: <b>%companyName%</b>
                            </li>
                            <li>
                                Activity name: <b>%activityName%</b>
                            </li>
                            <li>
                                Event name: <b>%eventName%</b>
                            </li>
                        </ul>
                        <Tooltip
                            content={onChangeChatMessage(formValues?.settings?.chatMessage)}
                            className={styles['switch-group__chat-preview']}
                            direction="left"
                        >
                            <img src={list} alt="chat preview" />
                        </Tooltip>
                    </div>
                )}
            </div>
            <Form.Row>
                <Field
                    name="settings.anonymize"
                    id="settings.anonymize"
                    fieldProps={{
                        label: 'Anonymize customer information after event',
                        description:
                            'After event is completed, all customer information collected during logging in to event (first name, last name & email) will be obfuscated. Example: john.doe@company.com will be replaced with j***.d**@c******.com'
                    }}
                    fieldAttr={{ disabled: !!isDisabled }}
                    component={SwitchCard}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="settings.video"
                    id="settings.video"
                    fieldProps={{
                        label: 'Use video conferencing',
                        description: 'Toggle to enable video & audio within the event interface.'
                    }}
                    fieldAttr={{ disabled: !!isDisabled }}
                    component={SwitchCard}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="settings.chat"
                    id="settings.chat"
                    fieldProps={{
                        label: 'Use chat',
                        description: 'Toggle to enable chat within the event interface.'
                    }}
                    fieldAttr={{ disabled: !!isDisabled }}
                    component={SwitchCard}
                />
            </Form.Row>
            <div className={styles['switch-group']}>
                <Field
                    name="settings.wall"
                    id="settings.wall"
                    fieldProps={{
                        label: 'Wall',
                        description:
                            'Turn on to enable the game feed to be accessible to participants after the event has completed. The feed will contain uploaded images, video content, and text from challenges that are set to be used for the wall.',
                        className: styles['switch-group__main']
                    }}
                    fieldAttr={{ disabled: !isUserAdmin && !!isDisabled }}
                    component={SwitchCard}
                />
                {formValues?.settings?.wall && (
                    <div className={styles['switch-group__password']}>
                        <Field
                            name="settings.wallPasswordOption"
                            id="settings.wallPasswordOption"
                            fieldProps={{
                                label: 'Password protected'
                            }}
                            fieldAttr={{ disabled: !isUserAdmin && !!isDisabled }}
                            component={Switch}
                        />
                        {formValues?.settings?.wallPasswordOption && (
                            <div className={styles['switch-group__password-field']}>
                                <Field
                                    name="settings.wallPassword"
                                    id="settings.wallPassword"
                                    fieldProps={{
                                        label: 'Password'
                                    }}
                                    fieldAttr={{ disabled: !isUserAdmin && !!isDisabled }}
                                    component={Text}
                                    validate={valueValidation([{ validator: 'required' }])}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
            <Form.Row>
                <Field
                    name="settings.lockedChanges"
                    id="settings.lockedChanges"
                    fieldProps={{
                        label: 'Locked challenges are visible'
                    }}
                    fieldAttr={{ disabled: !!isDisabled }}
                    component={SwitchCard}
                />
            </Form.Row>
            <br />
            <Form.Row>
                <Field
                    name="settings.validateLockedByChallenges"
                    id="settings.validateLockedByChallenges"
                    fieldProps={{
                        label: 'Enable validation for locked rounds',
                        description:
                            'Turn on if you would like participants to enter exact answer to unlock rounds.'
                    }}
                    fieldAttr={{ disabled: !!isDisabled }}
                    component={SwitchCard}
                />
            </Form.Row>
            <br />
            <Form.Section>
                <Form.Row>
                    <Form.SectionTitle>Customer logo override</Form.SectionTitle>
                </Form.Row>
                <Form.Row>
                    <Form.Columns>
                        <Form.GridColumn size={7}>
                            <Form.Label>Logo upload</Form.Label>
                            <Field
                                name="settings.customerLogo"
                                id="settings.customerLogo"
                                component={Media}
                                canDelete={!isDisabled}
                                canAddImage={!isDisabled}
                                useImageManager={!isDisabled}
                            />
                        </Form.GridColumn>
                        <Form.GridColumn size={7}>
                            <Form.Label>Logo inverted upload</Form.Label>
                            <Field
                                name="settings.customerLogoInverted"
                                id="settings.customerLogoInverted"
                                component={Media}
                                canDelete={!isDisabled}
                                canAddImage={!isDisabled}
                                useImageManager={!isDisabled}
                            />
                        </Form.GridColumn>
                    </Form.Columns>
                </Form.Row>
            </Form.Section>
            <br />
            <Form.Section>
                <Form.Row>
                    <Form.SectionTitle>Whitelabel logo</Form.SectionTitle>
                </Form.Row>
                <Form.Row>
                    <Form.Columns>
                        <Form.GridColumn size={8}>
                            <Form.Label>Logo upload</Form.Label>
                            <Field
                                name="settings.whitelabelLogo"
                                id="settings.whitelabelLogo"
                                component={Media}
                                canDelete={!isDisabled}
                                canAddImage={!isDisabled}
                                useImageManager={!isDisabled}
                            />
                        </Form.GridColumn>
                    </Form.Columns>
                </Form.Row>
            </Form.Section>
            <br />
            <Form.Row>
                <Form.Label>Primary color</Form.Label>
                <Field
                    name="settings.primaryColor"
                    id="settings.primaryColor"
                    fieldProps={{
                        label: 'Choose color',
                        clearable: true
                    }}
                    fieldAttr={{ disabled: !!isDisabled }}
                    component={ColorPicker}
                />
            </Form.Row>
            <Button disabled={invalid || pristine} submit loading={submitting} label="Save" />
        </Form>
    );
};

SettingsForm.defaultProps = {
    eventStarted: false,
    onSubmit: () => {},
    handleSubmit: () => {},
    submitting: false,
    invalid: false,
    pristine: false,
    dirty: false,
    onChangeChatMessage: () => {}
};

SettingsForm.propTypes = {
    eventStarted: PropTypes.bool,
    onSubmit: PropTypes.func,
    handleSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    dirty: PropTypes.bool,
    onChangeChatMessage: PropTypes.func
};

export default reduxForm({ form: formName, enableReinitialize: true })(SettingsForm);
