import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import LayoutManager from 'erpcore/utils/LayoutManager';
import { diff } from 'deep-object-diff';
import CustomersCreateEditForm from 'erpcore/screens/Customers/components/CustomerCreateEditForm';
import Button from 'erpcore/components/Button';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import PageContent from 'erpcore/components/Layout/PageContent';
import { actions as customersActions } from 'erpcore/screens/Customers/Customers.reducer';
import CustomersEditTabs from 'erpcore/screens/Customers/components/CustomersEditTabs';
import { dtoForm } from 'erpcore/utils/dto';
import {
    getSingleCustomerFetching,
    getCustomerData
} from 'erpcore/screens/Customers/Customers.selectors';
import PageLoader from 'erpcore/components/PageLoader';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';

import { setLocationData } from 'erpcore/components/Form/components/Location/dto';

const CustomersEdit = ({ match }) => {
    const dispatch = useDispatch();
    const customersIri = `/api/companies/${match?.params?.id}`;
    const customerData = dtoForm(useSelector(state => getCustomerData(state, customersIri)));
    const fetching = useSelector(getSingleCustomerFetching);
    const params = {
        include: 'city,country,state,accountManager,logo'
    };
    const {
        name,
        email,
        phone,
        website,
        account_manager: accountManager,
        full_address: address,
        street,
        country,
        state,
        city,
        zip,
        latitude,
        longitude,
        logo
    } = { ...customerData };

    const initialValues = {
        name,
        email,
        phone: phone || '+1',
        website,
        account_manager: accountManager,
        location: {
            full_address: address,
            street,
            country,
            state,
            city,
            zip,
            latitude,
            longitude
        },
        logo
    };

    const pageTitle = () => {
        if (customerData?.name) {
            return `Customer - ${customerData.name}`;
        }

        return `Customer`;
    };

    const fetchCustomerData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: customersActions.START_FETCHING_CUSTOMER,
                iri: customersIri,
                promise: { resolve, reject },
                params
            });
        });
    };

    useEffect(() => {
        fetchCustomerData();
    }, []);

    const onSubmitEditCustomerForm = ({ formData }) => {
        formData = diff(customerData, formData);

        const { location } = { ...formData };

        if (location) {
            formData = setLocationData('location', formData);
        }

        return new Promise((resolve, reject) => {
            dispatch({
                type: customersActions.START_UPDATE_CUSTOMER,
                promise: { resolve, reject },
                iri: customersIri,
                formData
            });
        });
    };

    return (
        <LayoutManager className="main--narrow" slot="main" layoutType="merge">
            <HeadMeta title={pageTitle()} />
            <PageHeader title={pageTitle()}>
                <Button href="/companies/create" label="Create customer" />
            </PageHeader>
            <PageContent>
                {fetching === true && <PageLoader />}
                <CustomersEditTabs />
                <CustomersCreateEditForm
                    initialValues={initialValues}
                    onSubmit={onSubmitEditCustomerForm}
                    form="CustomersEditForm"
                    submitLabel="Update"
                />
            </PageContent>
        </LayoutManager>
    );
};

CustomersEdit.defaultProps = {
    match: {}
};

CustomersEdit.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object])
};

export default CustomersEdit;
