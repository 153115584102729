import React, { useState, useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import LayoutManager from 'erpcore/utils/LayoutManager';
import EventsEditPageHeader from 'erpcore/screens/Events/screens/EventsEdit/components/EventEditPageHeader';
import PageContent from 'erpcore/components/Layout/PageContent';
import { useDispatch, useSelector } from 'react-redux';
import {
    getListingFetching,
    getListingResponse,
    getQueryParams
} from 'erpcore/components/Listing/Listing.selectors';
import { getSingleEventData } from 'erpcore/screens/Events/Events.selectors';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import TableActions from 'erpcore/components/Listing/components/TableActions';
import Listing from 'erpcore/components/Listing';
import ButtonDropdown from 'erpcore/components/ButtonDropdown';
import { insertIf } from 'erpcore/utils/utils';
import AddEditTeamModal from './components/AddEditTeamModal';
import DeleteTeam from './components/DeleteTeam';
import ReshuffleTeamsModal from './components/ReshuffleTeamsModal';

const Teams = () => {
    const match = useRouteMatch();
    const dispatch = useDispatch();

    const [addTeamModalOpened, setAddTeamModal] = useState(false);
    const [reshuffleModalOpened, setReshuffleModal] = useState(false);

    const eventId = match?.params?.id;
    const eventIri = `/api/events/${eventId}`;

    const reducerName = 'eventTeams';
    const actionName = 'EVENT_TEAMS';

    const listing = useSelector(state => getListingResponse(state, reducerName));
    const listingFetching = useSelector(state => getListingFetching(state, reducerName));
    const listingParams = useSelector(state =>
        getQueryParams(state, {
            name: reducerName
        })
    );
    const eventData = useSelector(state => getSingleEventData(state, eventIri));
    const { running, started_at: startedAt } = { ...eventData };

    const fetchParticipants = useCallback(
        params => {
            return new Promise((resolve, reject) => {
                dispatch({
                    promise: { resolve, reject },
                    entity: actionName,
                    type: listingActions.START_FETCHING_LISTING,
                    name: reducerName,
                    params: {
                        ...listingParams,
                        ...params
                    },
                    endpoint: `/api/event-teams?filters[event][equals]=${eventIri}`
                });
            }).catch(error => {
                return error;
            });
        },
        [listingParams]
    );

    const handleAddTeamModal = () => {
        setAddTeamModal(!addTeamModalOpened);
    };

    const handleReshuffleModal = () => {
        setReshuffleModal(!reshuffleModalOpened);
    };

    const tableData = () => {
        const table = {};
        table.data = [];

        table.filters = [];

        table.schema = [
            {
                title: 'Team name',
                field: 'name',
                sortable: 'name'
            },
            {
                title: 'Participants',
                field: 'participants'
            },
            {
                title: 'Actions',
                field: 'actions'
            }
        ];

        if (listing.data) {
            listing.data.map(row => {
                const { id, iri, name, participants } = {
                    ...row
                };
                return table.data.push({
                    id,
                    iri,
                    name,
                    participants: participants ? participants.length : '0',
                    actions: (
                        <TableActions>
                            <TableActions.Action>
                                <AddEditTeamModal
                                    isEdit
                                    form={`editEventTeam-${id}`}
                                    id={id}
                                    name={name}
                                    eventIri={eventIri}
                                    initialValues={{
                                        name
                                    }}
                                    onComplete={() => fetchParticipants(listingParams)}
                                />
                            </TableActions.Action>
                            <TableActions.Action>
                                <DeleteTeam
                                    form={`deleteEventTeam-${id}`}
                                    id={id}
                                    name={name}
                                    onDelete={() => fetchParticipants(listingParams)}
                                />
                            </TableActions.Action>
                        </TableActions>
                    )
                });
            });
        }

        return table;
    };

    return (
        <LayoutManager slot="main" layoutType="merge" className="main--medium">
            <EventsEditPageHeader />
            <PageContent>
                <Listing
                    hideFilters
                    name={reducerName}
                    reducerName={reducerName}
                    loading={listingFetching}
                    meta={listing?.meta}
                    table={tableData()}
                    onListingConfigUpdate={params => fetchParticipants(params)}
                >
                    <ButtonDropdown
                        placeholder="Actions"
                        triggerActionOnOptionSelection
                        options={[
                            {
                                id: 'createEventTeam',
                                label: 'Add new team',
                                onClick: () => handleAddTeamModal()
                            },
                            ...insertIf(!(running && startedAt), [
                                {
                                    id: 'reshuffleEventTeam',
                                    label: 'Reshuffle teams',
                                    onClick: () => handleReshuffleModal()
                                }
                            ])
                        ]}
                    />
                </Listing>
                <AddEditTeamModal
                    form="AddTeamModalForm"
                    eventIri={eventIri}
                    isOpened={addTeamModalOpened}
                    onClose={() => handleAddTeamModal()}
                    onComplete={() => fetchParticipants(listingParams)}
                />
                <ReshuffleTeamsModal
                    form="ReshuffleTeamsForm"
                    eventIri={eventIri}
                    isOpened={reshuffleModalOpened}
                    onClose={() => handleReshuffleModal()}
                    onComplete={() => fetchParticipants(listingParams)}
                />
            </PageContent>
        </LayoutManager>
    );
};

export default Teams;
