import React from 'react';
import PropTypes from 'prop-types';

import styles from './NumberField.module.scss';

const NumberField = ({ input, meta, fieldProps, fieldAttr }) => {
    const { name, value, onChange } = { ...input };
    const { min, max } = { ...fieldProps };

    /**
     *
     * @param operator {string}
     */
    const onValueChange = newValue => {
        let theValue = Number.isNaN(parseInt(newValue, 10)) ? null : parseInt(newValue, 10);

        if ((theValue || theValue === 0) && theValue >= max) {
            theValue = max;
        }

        if ((theValue || theValue === 0) && theValue <= min) {
            theValue = min;
        }

        onChange(theValue);
    };

    return (
        <div
            className={`${styles['input-number']} ${
                fieldAttr.required === true ? styles['input-number--required'] : ''
            }`}
        >
            {fieldProps.label && (
                <span className={`${styles['input-number__label']}`}>{fieldProps.label}</span>
            )}
            <div className={`${styles['input-number__content']}`}>
                <div className={`${styles['input-number__col']} ${styles['is-btn']}`}>
                    <button
                        type="button"
                        aria-label={`Decrease ${input.name} field`}
                        tabIndex={0}
                        onClick={() => onValueChange(value - 1)}
                        onKeyPress={() => onValueChange(value - 1)}
                        className={`${styles['input-number__btn']}`}
                        disabled={fieldAttr.disabled || false}
                    >
                        -
                    </button>
                </div>
                <div className={`${styles['input-number__col']}`}>
                    <input
                        type="number"
                        className={`${styles['input-number__field']}`}
                        aria-label={`${name}`}
                        id={fieldAttr.id ? fieldAttr.id : ''}
                        name={fieldAttr.name ? fieldAttr.name : ''}
                        onChange={event => onValueChange(event.currentTarget?.value)}
                        value={value}
                        disabled={fieldAttr.disabled || false}
                    />
                </div>
                <div className={`${styles['input-number__col']} ${styles['is-btn']}`}>
                    <button
                        type="button"
                        aria-label={`Increase ${name} field`}
                        tabIndex={0}
                        onClick={() => onValueChange(value + 1)}
                        onKeyPress={() => onValueChange(value + 1)}
                        className={`${styles['input-number__btn']}`}
                        disabled={fieldAttr.disabled || false}
                    >
                        +
                    </button>
                </div>
            </div>
            {meta.touched && meta.error && (
                <span className={`${styles['input-number__error']}`}>
                    {meta.error.message ? meta.error.message : meta.error}
                </span>
            )}
        </div>
    );
};

NumberField.defaultProps = {
    fieldProps: {
        min: 0,
        max: 9007199254740992,
        label: ''
    },
    fieldAttr: {},
    // field: {},
    input: {},
    meta: {}
};

NumberField.propTypes = {
    fieldProps: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number,
        label: PropTypes.string
    }),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    // field: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object])
};

export default NumberField;
