import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Responsive, WidthProvider } from 'react-grid-layout';

import ButtonDropdown from 'erpcore/components/ButtonDropdown';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import PageContent from 'erpcore/components/Layout/PageContent';
// Widgets - register them to widgetsComponents object
import UserWidget from 'erpcore/screens/Dashboard/components/UserWidget';
import EventsWidget from 'erpcore/screens/Dashboard/components/EventsWidget';

import { actions as dashboardsActions } from 'erpcore/screens/Dashboard/Dashboard.reducer';
import {
    getDashboardByName,
    getNewWidgetData
} from 'erpcore/screens/Dashboard/Dashboard.selectors';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const ResponsiveGridLayout = WidthProvider(Responsive);

const Dashboard = () => {
    const name = 'Dashboard'; // to be changed in the future to the routes

    const dispatch = useDispatch();

    const dashboardData = useSelector(state => getDashboardByName(state, name));
    const { iri: dashboardIri, config: dashboardConfig } = { ...dashboardData };
    const { widgets: dashboardWidgets } = { ...dashboardConfig };

    // region List of available widgets
    const widgetsComponents = {
        UserWidget,
        EventsWidget
    };
    // endregion

    // region New widgets

    const newWidgetData = useSelector(state => getNewWidgetData(state));

    useEffect(() => {
        if (dashboardIri && !dashboardWidgets) {
            const initialWidgetValues = {
                userWidget: {
                    id: 'userWidget',
                    name: 'User widget',
                    type: 'UserWidget',
                    layout: {
                        lg: {
                            i: 'userWidget',
                            x: 0,
                            y: 0,
                            w: 12,
                            h: 3,
                            minW: 12,
                            maxW: 12,
                            minH: 3,
                            static: false,
                            isResizable: false
                        },
                        md: {
                            i: 'userWidget',
                            x: 0,
                            y: 0,
                            w: 12,
                            h: 3,
                            minH: 3,
                            static: false,
                            isResizable: false
                        }
                    },
                    draggable: true
                },
                events: {
                    id: 'events',
                    name: 'Events',
                    type: 'EventsWidget',
                    layout: {
                        lg: {
                            i: 'events',
                            x: 0,
                            y: 1000,
                            w: 12,
                            h: 8,
                            minW: 6,
                            maxW: 12,
                            minH: 6,
                            static: false,
                            isResizable: true
                        },
                        md: {
                            i: 'events',
                            x: 0,
                            y: 1000,
                            w: 6,
                            h: 6,
                            static: false,
                            isResizable: true
                        }
                    },
                    draggable: true,
                    department: null
                }
            };

            dispatch({
                type: dashboardsActions.ADD_WIDGET_TO_DASHBOARD,
                iri: dashboardIri,
                response: initialWidgetValues
            });
        }
    }, [dashboardData]);

    const renderNewWidget = () => {
        if (!newWidgetData) {
            return null;
        }

        const WidgetComponent = widgetsComponents[newWidgetData.type];

        return React.cloneElement(<WidgetComponent />, { widgetData: newWidgetData });
    };

    // endregion

    // get Layouts
    const layouts = { lg: [], md: [] };
    const dashboardWidgetsIdList = dashboardWidgets ? Object.keys(dashboardWidgets) : [];

    if (dashboardWidgetsIdList.length > 0) {
        dashboardWidgetsIdList.map(dashboardWidgetId => {
            layouts.lg.push(dashboardWidgets[dashboardWidgetId]?.layout?.lg);
            layouts.md.push(dashboardWidgets[dashboardWidgetId]?.layout?.md);

            return layouts;
        });
    }

    const transformRawLayoutData = rawLayouts => {
        const transformedLayout = {};
        transformedLayout.config = { ...dashboardConfig };
        const { widgets: transformedLayoutWidgets } = { ...transformedLayout.config };

        if (!transformedLayoutWidgets) {
            return transformedLayout;
        }

        if (transformedLayoutWidgets && Object.keys(transformedLayoutWidgets)?.length < 1) {
            transformedLayout.config.widgets = { ...dashboardWidgets };
        }

        if (Object.keys(rawLayouts)?.length > 0) {
            Object.keys(rawLayouts).map(key => {
                if (rawLayouts[key]?.length > 0) {
                    rawLayouts[key].map(layoutItem => {
                        const widgetId = layoutItem.i;

                        if (transformedLayoutWidgets[widgetId]) {
                            transformedLayoutWidgets[widgetId].layout[key] = { ...layoutItem };
                        }

                        return transformedLayout;
                    });
                }

                return transformedLayout;
            });
        }

        return transformedLayout;
    };

    const fetchDashboards = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: dashboardsActions.START_FETCHING_DASHBOARDS
            });
        }).catch(error => {
            throw error;
        });
    };

    useEffect(() => {
        fetchDashboards();
    }, []);

    const updateDashboard = data => {
        if (data?.config && Object.keys(data?.config)?.length > 0) {
            return new Promise((resolve, reject) => {
                dispatch({
                    promise: { resolve, reject },
                    type: dashboardsActions.START_UPDATE_DASHBOARD,
                    iri: dashboardIri,
                    data
                });
            }).catch(error => {
                throw error;
            });
        }

        return null;
    };

    const saveLayout = rawLayouts => {
        const transformedLayout = transformRawLayoutData(rawLayouts);

        if (
            !transformedLayout ||
            (transformedLayout &&
                Object.keys(transformedLayout).length === 0 &&
                transformedLayout.constructor === Object) ||
            !dashboardIri
        ) {
            return null;
        }

        return updateDashboard(transformedLayout);
    };

    const addItem = widgetComponentName => {
        const widgetId = Date.now().toString();

        const newWidgetValues = {
            id: widgetId,
            name: widgetComponentName,
            type: widgetComponentName,
            layout: {},
            newWidget: true,
            dashboardIri
        };

        dispatch({
            type: dashboardsActions.ADD_NEW_WIDGET,
            response: newWidgetValues
        });
    };

    const widgetsOptionsList = () => {
        const list = [];

        const hasWidgetEvents = Object.values(dashboardWidgets || {}).find(
            item => item.type === 'EventsWidget'
        );

        if (!hasWidgetEvents) {
            list.push({
                id: 'events',
                label: 'Events',
                onClick: () => {
                    addItem('EventsWidget');
                }
            });
        }

        return list;
    };

    // Renders
    const renderWidgets = () => {
        const widgets = [];
        if (!dashboardWidgets) {
            return widgets;
        }

        if (
            Object.keys(dashboardWidgets)?.length === 0 &&
            dashboardWidgets.constructor === Object
        ) {
            return widgets;
        }

        Object.keys(dashboardWidgets).map(key => {
            if (!dashboardWidgets[key]) {
                return widgets;
            }

            const WidgetComponent = widgetsComponents[dashboardWidgets[key]?.type];

            if (WidgetComponent) {
                widgets.push(
                    <div key={dashboardWidgets[key]?.layout?.lg?.i}>
                        {React.cloneElement(<WidgetComponent />, {
                            widgetData: dashboardWidgets[key]
                        })}
                    </div>
                );
            }

            return widgets;
        });

        return widgets;
    };

    const widgetsOptionsListArray = widgetsOptionsList();

    return (
        <LayoutManager slot="main" layoutType="merge">
            <HeadMeta title={name} />
            <PageHeader title={name}>
                {widgetsOptionsListArray?.length > 0 && (
                    <ButtonDropdown
                        placeholder="Add widget"
                        triggerActionOnOptionSelection
                        options={widgetsOptionsListArray}
                    />
                )}
            </PageHeader>

            <PageContent>
                {renderNewWidget()}
                <ResponsiveGridLayout
                    className="layout"
                    layouts={layouts}
                    breakpoints={{ lg: 703, md: 0 }}
                    cols={{ lg: 12, md: 1 }}
                    onLayoutChange={(_currentLayout, allLayouts) => {
                        return saveLayout(allLayouts);
                    }}
                    rowHeight={50}
                    margin={[24, 24]}
                >
                    {renderWidgets()}
                </ResponsiveGridLayout>
            </PageContent>
        </LayoutManager>
    );
};

export default Dashboard;
