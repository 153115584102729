import { put, call, takeLatest } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as activityTypeActions } from 'erpcore/screens/Settings/ActivityTypes/ActivityTypes.reducer';
import dto from 'erpcore/utils/dto';

/**
 * Delete Single Activity
 * @param  {Object} id ID of a Activity
 * @return {Object} Response from API
 */
export function* deleteSingleActivityType({ promise, iri }) {
    try {
        const deleteSingleActivityTypeAPI = yield restClient.delete(iri);
        yield put({
            type: activityTypeActions.DELETE_ACTIVITY_TYPE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteSingleActivityTypeAPI?.data
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: activityTypeActions.DELETE_ACTIVITY_TYPE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Single Activity data
 * @param  {String} Activity
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* updateSingleActivityType({ promise, formData, iri }) {
    try {
        const updateSingleActivityTypeAPI = yield restClient.put(iri, formData);
        yield put({
            type: activityTypeActions.UPDATE_ACTIVITY_TYPE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleActivityTypeAPI?.data
        });
        yield put({
            type: activityTypeActions.STORE_ACTIVITY_TYPE_DATA,
            iri,
            response: dto(updateSingleActivityTypeAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: activityTypeActions.UPDATE_ACTIVITY_TYPE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Create Activity
 * @param promise
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createActivityType({ promise, formData }) {
    try {
        const createActivityTypeAPI = yield restClient.post(`/api/activity-types`, formData);
        yield put({
            type: activityTypeActions.CREATE_ACTIVITY_TYPE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createActivityTypeAPI?.data
        });
        yield call(promise.resolve, createActivityTypeAPI?.data);
    } catch (error) {
        yield put({
            type: activityTypeActions.CREATE_ACTIVITY_TYPE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export const activityTypesSaga = [
    takeLatest(activityTypeActions.START_DELETE_ACTIVITY_TYPE, deleteSingleActivityType),
    takeLatest(activityTypeActions.START_UPDATE_ACTIVITY_TYPE, updateSingleActivityType),
    takeLatest(activityTypeActions.START_CREATE_ACTIVITY_TYPE, createActivityType)
];
