import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { reduxForm } from 'redux-form';
import Form from 'erpcore/components/Form';

import Button from 'erpcore/components/Button';
import Modal from 'erpcore/components/Modal';
import Svg from 'erpcore/components/Svg';
import Tooltip from 'erpcore/components/Tooltip';

import { actions as guidelinesActions } from 'erpcore/screens/Settings/EventGuidelines/EventGuidelines.reducer';

const EventGuidelineDelete = ({ guidelineData, handleSubmit, submitting }) => {
    const dispatch = useDispatch();
    const [opened, setOpened] = useState(false);

    const onSubmit = () => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: guidelinesActions.START_DELETE_GUIDELINE,
                guidelineIri: guidelineData?.iri
            })
        )
            .then(() => {
                setOpened(false);
            })
            .catch(error => {
                return error;
            });
    };

    const handleModal = () => setOpened(!opened);

    return (
        <Fragment>
            <Tooltip content="Delete event guideline">
                <button type="button" onClick={handleModal}>
                    <Svg icon="remove" />
                </button>
            </Tooltip>
            <Modal
                variation="medium"
                opened={opened}
                onClose={handleModal}
                title="Delete Event Guideline"
                subtitle={
                    <Fragment>
                        Are you sure you want to delete{' '}
                        <em>{guidelineData && guidelineData?.title}?</em>
                    </Fragment>
                }
            >
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Button submit loading={submitting} label="Delete" />
                    <Button variation="secondary" label="Cancel" onClick={handleModal} />
                </Form>
            </Modal>
        </Fragment>
    );
};

EventGuidelineDelete.defaultProps = {
    submitting: false,
    guidelineData: {}
};

EventGuidelineDelete.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    guidelineData: PropTypes.oneOfType([PropTypes.object])
};

export default reduxForm({
    enableReinitialize: false
})(EventGuidelineDelete);
