import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';

import ElementLoader from 'erpcore/components/ElementLoader';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageHeader from 'erpcore/components/Layout/PageHeader';

import {
    getEventGuidelineFetching,
    getGuidelineData
} from 'erpcore/screens/Settings/EventGuidelines/EventGuidelines.selectors';

import { actions as guidelinesActions } from 'erpcore/screens/Settings/EventGuidelines/EventGuidelines.reducer';
import { dtoForm } from 'erpcore/utils/dto';
import EventGuidelineForm from 'erpcore/screens/Settings/EventGuidelines/components/EventGuidelineForm';

const EventGuidelineEdit = ({ match }) => {
    const dispatch = useDispatch();
    const guidelineIri = `/api/guidelines/${match?.params?.id}`;
    const guidelineData = dtoForm(useSelector(state => getGuidelineData(state, guidelineIri)));
    const fetching = useSelector(getEventGuidelineFetching);

    const fetchGuidelineData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: guidelinesActions.START_FETCHING_GUIDELINE,
                iri: guidelineIri
            });
        }).catch(error => ({ error }));
    };

    useEffect(() => {
        fetchGuidelineData();
    }, []);

    const onSubmitGuidelineEditForm = formData => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: guidelinesActions.START_UPDATE_GUIDELINE,
                iri: guidelineIri,
                formData
            });
        }).catch(error => {
            throw new SubmissionError(reduxFormErrorMapper(error));
        });
    };

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <HeadMeta title={`Event Guideline Edit - ${guidelineData?.title}`} />
            <PageHeader title={`Event Guideline Edit - ${guidelineData?.title}`} />
            <PageContent>
                <PageContent.Section>
                    {fetching === true && <ElementLoader overlay />}
                    <EventGuidelineForm
                        onSubmit={onSubmitGuidelineEditForm}
                        form={`EventGuidelineEditForm-${guidelineIri}`}
                        initialValues={guidelineData}
                        submitLabel="Update"
                    />
                </PageContent.Section>
            </PageContent>
        </LayoutManager>
    );
};

EventGuidelineEdit.defaultProps = {
    match: {}
};

EventGuidelineEdit.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object])
};

export default withRouter(EventGuidelineEdit);
