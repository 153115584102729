import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import LayoutManager from 'erpcore/utils/LayoutManager';
import Listing from 'erpcore/components/Listing';
import {
    getListingFetching,
    getListingResponse
} from 'erpcore/components/Listing/Listing.selectors';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import TableActions from 'erpcore/components/Listing/components/TableActions';
import { getImageSrcFromMediaObject } from 'erpcore/components/ImageManager';
import ChallengeTypeEditModal from './components/ChallengeTypeEditModal';

const ChallengeTypes = () => {
    const title = 'Challenge Types';

    const listingReducerName = 'settingsChallengeTypes';
    const listingActionName = 'SETTINGS_CHALLENGE_TYPES';
    const listingEndpoint = 'api/challenge-types?include=icon,icon.versions';

    const dispatch = useDispatch();
    const listing = useSelector(state => getListingResponse(state, listingReducerName));
    const listingFetching = useSelector(state => getListingFetching(state, listingReducerName));

    const fetchChallengeTypes = params => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                params,
                type: listingActions.START_FETCHING_LISTING,
                name: listingReducerName,
                entity: listingActionName,
                endpoint: listingEndpoint
            });
        }).catch(error => {
            return error;
        });
    };

    const tableData = () => {
        const table = {};
        table.data = [];

        table.schema = [
            {
                title: 'Icon',
                field: 'icon',
                align: 'smallicon'
            },
            {
                title: 'Label',
                field: 'name',
                mobile: 'title'
            },
            {
                title: 'Actions',
                field: 'actions'
            }
        ];

        table.defaultSort = {
            order: 'ASC',
            sortable: 'name'
        };

        if (listing?.data?.length > 0) {
            listing.data.map(row => {
                const { id, iri, name, icon } = { ...row };
                const { content_url: contentURL, iri: iconIri } = { ...icon };
                return table.data.push({
                    id,
                    iri,
                    icon: contentURL ? (
                        <div className="listing__small-icon">
                            <span
                                style={{
                                    backgroundImage: `url('${getImageSrcFromMediaObject(
                                        icon,
                                        'small'
                                    )}')`
                                }}
                            />
                        </div>
                    ) : (
                        ''
                    ),
                    name,
                    actions: (
                        <TableActions>
                            <TableActions.Action>
                                <ChallengeTypeEditModal
                                    iri={iri}
                                    media={iconIri || ''}
                                    type={name}
                                    refreshList={fetchChallengeTypes}
                                    form={`ChangeTypeEditModal__${id}`}
                                    initialValues={{ icon: iconIri || null }}
                                />
                            </TableActions.Action>
                        </TableActions>
                    )
                });
            });
        }

        return table;
    };

    return (
        <LayoutManager slot="main" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title} />
            <Listing
                hideHeader
                name={listingReducerName}
                reducerName={listingReducerName}
                loading={listingFetching}
                meta={listing?.meta}
                table={tableData()}
                onListingConfigUpdate={params => fetchChallengeTypes(params)}
            />
        </LayoutManager>
    );
};

export default ChallengeTypes;
