export const getEntityChallengesListingPositionUpdate = state =>
    state?.entityChallenges?.isPositionUpdating;

export const getEntityChallengeData = (state, iri) => state?.entityChallenges?.[iri] || {};

export const getEntityData = (state, iri, resource = null) => {
    if (resource) {
        return state?.[resource]?.[iri] || null;
    }

    return state?.entityChallenges?.[iri];
};

export const getEntityDataFetching = (state, resource) =>
    state?.[resource]?.singleFetching || false;
