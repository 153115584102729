export const actions = {
    START_FETCHING_EVENT_TEAMS: 'START_FETCHING_EVENT_TEAMS',
    FETCHING_SUCCESSFUL_EVENT_TEAMS: 'FETCHING_SUCCESSFUL_EVENT_TEAMS',
    FETCHING_FAILED_EVENT_TEAMS: 'FETCHING_FAILED_EVENT_TEAMS',

    START_EVENT_ADD_TEAM: 'START_EVENT_ADD_TEAM',
    EVENT_ADD_TEAM_SUCCESS: 'EVENT_ADD_TEAM_SUCCESS',
    EVENT_ADD_TEAM_FAILED: 'EVENT_ADD_TEAM_FAILED',

    START_EVENT_TEAM_UPDATE: 'START_EVENT_TEAM_UPDATE',
    EVENT_TEAM_UPDATE_SUCCESS: 'EVENT_TEAM_UPDATE_SUCCESS',
    EVENT_TEAM_UPDATE_FAILED: 'EVENT_TEAM_UPDATE_FAILED',

    START_DELETE_EVENT_TEAM: 'START_DELETE_EVENT_TEAM',
    EVENT_TEAM_DELETE_SUCCESS: 'EVENT_TEAM_DELETE_SUCCESS',
    EVENT_TEAM_DELETE_FAILED: 'EVENT_TEAM_DELETE_FAILED',

    START_RESHUFFLE_EVENT_TEAM: 'START_RESHUFFLE_EVENT_TEAM',
    RESHUFFLE_EVENT_TEAM_SUCCCESS: 'RESHUFFLE_EVENT_TEAM_SUCCCESS',
    RESHUFFLE_EVENT_TEAM_FAILED: 'RESHUFFLE_EVENT_TEAM_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: []
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_FETCHING_EVENT_TEAMS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_EVENT_TEAMS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_EVENT_TEAMS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        default:
            return state;
    }
};
