import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { diff } from 'deep-object-diff';
import isEmpty from 'lodash/isEmpty';
import Button from 'erpcore/components/Button';
import ElementLoader from 'erpcore/components/ElementLoader';
import Modal from 'erpcore/components/Modal';
import Tooltip from 'erpcore/components/Tooltip';
import './ImageManager.cropperjs.scss';
import './ImageManager.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
    getImage,
    getImageFetching,
    getImageCreating,
    getImageDeleting,
    getImageUpdating
} from 'erpcore/components/ImageManager/ImageManager.selectors';
import { actions as imageManagerActions } from 'erpcore/components/ImageManager/ImageManager.reducer';
import { getFormValues, destroy } from 'redux-form';
import FileEditableMeta from 'erpcore/components/ImageManager/components/FileEditableMeta';

export const getFileExtension = (filename = '') => {
    return /[.]/.exec(filename)?.[0] ? /[^.]+$/.exec(filename)?.[0] : '';
};

export const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    // const k = 1024;
    const k = 1000;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

const MediaFileManager = ({ fileIri, opened, onClose, onSave }) => {
    const dispatch = useDispatch();
    const metaFormValues = useSelector(state => getFormValues(`fileMeta-${fileIri}`)(state)) || {};
    const imageData = useSelector(state => getImage(state, fileIri));
    const fetching = useSelector(state => getImageFetching(state));
    const creating = useSelector(state => getImageCreating(state));
    const deleting = useSelector(state => getImageDeleting(state));
    const updating = useSelector(state => getImageUpdating(state));

    const loading = fetching || creating || deleting || updating;

    const imageEditableMetaInitialValues = {};
    if (imageData?.meta?.alt) {
        imageEditableMetaInitialValues.alt = imageData.meta.alt;
    }
    if (imageData?.meta?.title) {
        imageEditableMetaInitialValues.title = imageData.meta.title;
    }
    if (imageData?.meta?.caption) {
        imageEditableMetaInitialValues.caption = imageData.meta.caption;
    }
    if (imageData?.tags?.length) {
        imageEditableMetaInitialValues.tags = imageData.tags.map(item => {
            return typeof item === 'object' ? item?.iri : item;
        });
    }

    const destroyForm = () => {
        dispatch(destroy(['currentImageVersion']));
    };

    const fetchImageData = iri => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: imageManagerActions.START_FETCHING_IMAGE,
                iri
            });
        }).catch(error => ({ error }));
    };

    const updateOriginalImage = (iri, data) => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: imageManagerActions.START_UPDATE_IMAGE,
                formData: data,
                iri
            });
        }).catch(error => ({ error }));
    };

    const onModalClose = () => {
        destroyForm();
        onClose();
    };

    const updateFile = async () => {
        if (!isEmpty(diff(imageEditableMetaInitialValues, metaFormValues))) {
            const originalImageMetaData = { ...imageData?.meta };

            if (!isEmpty(metaFormValues)) {
                Object.keys(metaFormValues).forEach(key => {
                    if (key !== 'tags') {
                        originalImageMetaData[key] = metaFormValues[key];
                    }
                });
            }

            const originalImageData = {
                meta: originalImageMetaData
            };

            if ('tags' in metaFormValues) {
                originalImageData.tags = metaFormValues.tags;
            }

            updateOriginalImage(fileIri, originalImageData).finally(() => {
                fetchImageData(fileIri);
                onSave();
            });
        }
    };

    const isSaveEnabled = () => {
        let enabled = false;

        if (!isEmpty(diff(imageEditableMetaInitialValues, metaFormValues))) {
            enabled = true;
        }

        return enabled;
    };

    const renderMeta = () => {
        return (
            <div className="image-editor__meta">
                {!!imageData?.meta?.filename && (
                    <p className="image-editor__meta-item">
                        <span className="image-editor__meta-item-title">File name</span>{' '}
                        {imageData.meta.filename}
                    </p>
                )}
                {!!imageData.created_at && (
                    <p className="image-editor__meta-item">
                        <span className="image-editor__meta-item-title">Uploaded on:</span>{' '}
                        <Tooltip
                            zIndex={10010}
                            content={moment(imageData.created_at).format('MMMM Do YYYY, h:mm A')}
                        >
                            {moment(imageData.created_at).format('M/D/YYYY')}
                        </Tooltip>
                    </p>
                )}
                {!!imageData?.meta?.size && (
                    <p className="image-editor__meta-item">
                        <span className="image-editor__meta-item-title">File size:</span>{' '}
                        {formatBytes(imageData.meta.size)}
                    </p>
                )}
            </div>
        );
    };

    useEffect(() => {
        if (fileIri) {
            fetchImageData(fileIri);
        }
    }, [fileIri]);

    return (
        <Modal root="body" opened={opened} onClose={() => onModalClose()} title="Edit File">
            {!!opened && (
                <div className="image-editor">
                    {!!imageData && (
                        <>
                            {renderMeta()}
                            <div className="image-editor__controls">
                                <FileEditableMeta
                                    form={`fileMeta-${fileIri}`}
                                    initialValues={imageEditableMetaInitialValues}
                                />
                            </div>
                            <div className="image-editor__actions">
                                <Button
                                    onClick={() => updateFile()}
                                    label="Save"
                                    disabled={!isSaveEnabled()}
                                    className="image-editor__controls-button"
                                />
                                <Button
                                    onClick={() => onModalClose()}
                                    variation="secondary"
                                    label="Cancel"
                                />
                            </div>
                        </>
                    )}

                    {!!(!imageData || !!loading) && (
                        <div className="image-editor__loader">
                            <ElementLoader />
                        </div>
                    )}
                </div>
            )}
        </Modal>
    );
};

MediaFileManager.defaultProps = {
    fileIri: null,
    opened: false,
    onClose: () => {},
    onSave: () => {}
};

MediaFileManager.propTypes = {
    fileIri: PropTypes.string,
    opened: PropTypes.bool,
    onClose: PropTypes.func,
    onSave: PropTypes.func
};

export default MediaFileManager;
