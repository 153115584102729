import React from 'react';
import PropTypes from 'prop-types';
import styles from './GridItem.module.scss';

const GridItem = ({ title, subtitle, bottom, background, extension, children }) => {
    return (
        <div
            className={`${styles.main} ${background ? styles['is-image'] : ''}`}
            style={{ backgroundImage: `url('${background}')` }}
            data-file-type={extension.toLowerCase()}
            data-is-image={background ? '1' : '0'}
        >
            <div className={styles.mainContainer}>
                <div className={styles.mainTop}>
                    {title && (
                        <h3 title={title} className={styles.title}>
                            {title}
                        </h3>
                    )}
                    {subtitle && <span>{subtitle}</span>}
                </div>
                {children && <div className={styles.actions}>{children}</div>}
                {bottom && <div className={styles.mainBottom}>{bottom}</div>}
            </div>
        </div>
    );
};

GridItem.defaultProps = {
    title: '',
    subtitle: '',
    bottom: null,
    background: '',
    extension: '',
    children: null
};

GridItem.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    bottom: PropTypes.any,
    background: PropTypes.string,
    extension: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.object])
};

const GridItemMemo = React.memo(GridItem);

export default GridItemMemo;
