import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Svg from 'erpcore/components/Svg';
import Tooltip from 'erpcore/components/Tooltip';
import ImageManager from 'erpcore/components/ImageManager';

const MediaLibraryEdit = ({ imageIri, onSaveImage }) => {
    const [modalOpened, setModalOpened] = useState(false);

    const handleModal = () => {
        setModalOpened(!modalOpened);
    };

    return (
        <>
            <Tooltip content="Edit media">
                <button type="button" onClick={() => handleModal()}>
                    <Svg icon="edit" />
                </button>
            </Tooltip>
            {modalOpened && (
                <ImageManager
                    imageIri={imageIri}
                    opened={modalOpened}
                    onClose={() => setModalOpened(false)}
                    onSaveImage={() => {
                        if (onSaveImage && typeof onSaveImage === 'function') onSaveImage();
                    }}
                />
            )}
        </>
    );
};

MediaLibraryEdit.defaultProps = {
    imageIri: null,
    onSaveImage: () => {}
};

MediaLibraryEdit.propTypes = {
    imageIri: PropTypes.string,
    onSaveImage: PropTypes.func
};

export default MediaLibraryEdit;
