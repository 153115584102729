import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';

import Button from 'erpcore/components/Button';
import Form, { Text } from 'erpcore/components/Form';

import { actions as dashboardsActions } from 'erpcore/screens/Dashboard/Dashboard.reducer';
import { getDashboardByIri } from 'erpcore/screens/Dashboard/Dashboard.selectors';

import { valueValidation } from 'erpcore/components/Form/Form.utils';

const EventsWidgetForm = ({ handleSubmit, submitting, initialValues, handleModal }) => {
    const dispatch = useDispatch();

    const { dashboardIri, id: widgetId, newWidget } = { ...initialValues };

    const dashboardData = useSelector(state => getDashboardByIri(state, dashboardIri));

    const onSubmitAddNewWidget = formData => {
        const response = {
            [widgetId]: { ...formData, ...{ newWidget: false } }
        };

        dispatch({
            type: dashboardsActions.ADD_WIDGET_TO_DASHBOARD,
            iri: dashboardIri,
            response
        });

        dispatch({
            type: dashboardsActions.REMOVE_NEW_WIDGET
        });
    };

    const cancelNewWidget = () => {
        dispatch({
            type: dashboardsActions.REMOVE_NEW_WIDGET
        });
    };

    const onSubmitEditWidgetSettings = formData => {
        const data = {
            config: { widgets: { ...dashboardData?.config?.widgets, ...{ [widgetId]: formData } } }
        };

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: dashboardsActions.START_UPDATE_DASHBOARD,
                iri: dashboardIri,
                data
            });
        })
            .then(() => handleModal())
            .catch(error => {
                throw error;
            });
    };

    const removeWidget = () => {
        dispatch({
            type: dashboardsActions.REMOVE_WIDGET_FROM_DASHBOARD,
            iri: dashboardIri,
            widgetId
        });
    };

    return (
        <Form onSubmit={handleSubmit(onSubmitAddNewWidget)}>
            <Form.Row>
                <Field
                    name="name"
                    fieldProps={{
                        label: 'Widget title',
                        clearable: true
                    }}
                    component={Text}
                    fieldAttr={{ id: 'widgetTitle', required: true }}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            {newWidget ? (
                <>
                    <Button
                        loading={submitting}
                        label="Add widget"
                        onClick={handleSubmit(onSubmitAddNewWidget)}
                    />
                    <Button
                        variation="secondary"
                        label="Cancel"
                        onClick={() => cancelNewWidget()}
                    />
                </>
            ) : (
                <>
                    <Button
                        loading={submitting}
                        label="Update"
                        onClick={handleSubmit(onSubmitEditWidgetSettings)}
                    />
                    <Button variation="secondary" label="Cancel" onClick={handleModal} />
                    <Button variation="tertiary" label="Remove widget" onClick={removeWidget} />
                </>
            )}
        </Form>
    );
};

EventsWidgetForm.defaultProps = {
    submitting: false,
    initialValues: {},
    handleModal: () => {}
};

EventsWidgetForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    initialValues: PropTypes.oneOfType([PropTypes.object]),
    handleModal: PropTypes.func
};

export default reduxForm({
    enableReinitialize: true
})(EventsWidgetForm);
