import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Notification from 'erpcore/components/Notification';

// Notifications
import getDefaultNotifications from './notifications/default';
import getAuthNotifications from './notifications/auth';
import getActivityNotifications from './notifications/activities';
import getActivityChallengesNotifications from './notifications/activityChallenges';
import getActivityTypesNotifications from './notifications/activityTypes';
import getActivityNotificationNotifications from './notifications/activityNotifications';
import getActivityContentNotifications from './notifications/activityContent';
import getListingNotifications from './notifications/listing';
import getMyAccountNotifications from './notifications/myAccount';
import getChallengesNotifications from './notifications/challenges';
import getCustomerNotifications from './notifications/customer';
import getContactsNotifications from './notifications/contacts';
import getCurrencyNotifications from './notifications/currency';
import getEventNotifications from './notifications/events';
import getMediaNotifications from './notifications/media';
import getRolesAndPermissionsNotifications from './notifications/rolesAndPermissions';
import getUsersNotifications from './notifications/users';
import getGuidelinesNotifications from './notifications/guidelines';

/**
 * Notification Manager
 * @return {Node} Returns Notification component
 */
const NotificationManager = ({ code, identifier, detail, notificationData }) => {
    const forwardData = { code, identifier, detail, notificationData };

    // Default
    const defaultNotifications = getDefaultNotifications(forwardData);
    if (defaultNotifications) {
        return defaultNotifications;
    }

    // Auth
    const authNotifications = getAuthNotifications(forwardData);
    if (authNotifications) {
        return authNotifications;
    }

    // Activity Challenges
    const activityChallenges = getActivityChallengesNotifications(forwardData);
    if (activityChallenges) {
        return activityChallenges;
    }

    // Activity Types
    const activityTypes = getActivityTypesNotifications(forwardData);
    if (activityTypes) {
        return activityTypes;
    }

    // Activity Notfication
    const activityNotification = getActivityNotificationNotifications(forwardData);
    if (activityNotification) {
        return activityNotification;
    }

    // Activity Content
    const activityContent = getActivityContentNotifications(forwardData);
    if (activityContent) {
        return activityContent;
    }

    // Activities
    const activityNotifications = getActivityNotifications(forwardData);
    if (activityNotifications) {
        return activityNotifications;
    }

    // Listing
    const listingNotifications = getListingNotifications(forwardData);
    if (listingNotifications) {
        return listingNotifications;
    }

    // MyAccount
    const myAccountNotifications = getMyAccountNotifications(forwardData);
    if (myAccountNotifications) {
        return myAccountNotifications;
    }

    // Users
    const usersNotifications = getUsersNotifications(forwardData);
    if (usersNotifications) {
        return usersNotifications;
    }

    // Media
    const mediaNotifications = getMediaNotifications(forwardData);
    if (mediaNotifications) {
        return mediaNotifications;
    }

    // Roles and Permissions
    const rolesAndPermissionsNotifications = getRolesAndPermissionsNotifications(forwardData);
    if (rolesAndPermissionsNotifications) {
        return rolesAndPermissionsNotifications;
    }

    // Challenges
    const challengesNotifications = getChallengesNotifications(forwardData);
    if (challengesNotifications) {
        return challengesNotifications;
    }

    // Customer
    const customersNotifications = getCustomerNotifications(forwardData);
    if (customersNotifications) {
        return customersNotifications;
    }

    // Contacts
    const contactsNotifications = getContactsNotifications(forwardData);
    if (contactsNotifications) {
        return contactsNotifications;
    }

    // Currency
    const currencyNotifications = getCurrencyNotifications(forwardData);
    if (currencyNotifications) {
        return currencyNotifications;
    }

    // Events
    const eventsNotifications = getEventNotifications(forwardData);
    if (eventsNotifications) {
        return eventsNotifications;
    }

    // Guidelines
    const guidelinesNotifications = getGuidelinesNotifications(forwardData);
    if (guidelinesNotifications) {
        return guidelinesNotifications;
    }

    return (
        <Notification
            identifier={identifier}
            title="Warning"
            text={
                <Fragment>
                    <strong>{code}</strong> - This is the message missing Notification case!
                    {!!detail && (
                        <Fragment>
                            <br />
                            {detail}
                        </Fragment>
                    )}
                </Fragment>
            }
            type="warning"
        />
    );
};

NotificationManager.defaultProps = {
    code: null,
    identifier: null,
    detail: null,
    notificationData: null
};

NotificationManager.propTypes = {
    code: PropTypes.string,
    identifier: PropTypes.string,
    detail: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    notificationData: PropTypes.oneOfType([PropTypes.object])
};

export default NotificationManager;
