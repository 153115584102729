import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import Form, { Creatable, Text } from 'erpcore/components/Form';

const LocationDetails = ({ shouldHide, fieldName, disabled }) => {
    return (
        // This should be more elegant solution, will work for now since we cant invest more time into this
        // Autocompletes need to initialize so initialValues would be passed in it to recieve IRI
        // Otherwise it could just have value of string like "Canada" and it will throw error that that is invalid country, which is.
        <div style={{ display: shouldHide ? 'none' : 'block' }}>
            <Form.Row>
                <Field
                    name={`${fieldName}.street`}
                    id={`${fieldName}Street`}
                    fieldProps={{
                        clearable: true,
                        label: 'Street line'
                    }}
                    fieldAttr={{ disabled }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name={`${fieldName}.country`}
                    id={`${fieldName}Country`}
                    fieldProps={{
                        label: 'Country',
                        options: {
                            endpoint: '/api/countries',
                            mapData: {
                                value: 'iri',
                                label: 'name'
                            }
                        }
                    }}
                    fieldAttr={{ disabled }}
                    component={Creatable}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name={`${fieldName}.city`}
                    id={`${fieldName}City`}
                    fieldProps={{
                        label: 'City',
                        options: {
                            endpoint: '/api/cities',
                            mapData: {
                                value: 'iri',
                                label: 'name'
                            }
                        }
                    }}
                    fieldAttr={{ disabled }}
                    component={Creatable}
                />
                <Field
                    name={`${fieldName}.zip`}
                    id={`${fieldName}Zip`}
                    fieldProps={{
                        label: 'ZIP'
                    }}
                    fieldAttr={{ disabled }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name={`${fieldName}.state`}
                    id={`${fieldName}State`}
                    key="LocationFieldState"
                    fieldProps={{
                        label: 'State',
                        options: {
                            endpoint: '/api/states',
                            mapData: {
                                value: 'iri',
                                label: 'name'
                            }
                        }
                    }}
                    fieldAttr={{
                        disabled
                    }}
                    component={Creatable}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name={`${fieldName}.latitude`}
                    id={`${fieldName}Lat`}
                    fieldProps={{
                        label: 'Latitude'
                    }}
                    fieldAttr={{ disabled: true }}
                    component={Text}
                />
                <Field
                    name={`${fieldName}.longitude`}
                    id={`${fieldName}Lng`}
                    fieldProps={{
                        label: 'Longitude'
                    }}
                    fieldAttr={{ disabled: true }}
                    component={Text}
                />
            </Form.Row>
        </div>
    );
};

LocationDetails.defaultProps = {
    fieldName: null,
    disabled: false,
    shouldHide: false
};

LocationDetails.propTypes = {
    fieldName: PropTypes.string,
    disabled: PropTypes.bool,
    shouldHide: PropTypes.bool
};

export default LocationDetails;
