import React, { useCallback, useMemo, useState } from 'react';
import LayoutManager from 'erpcore/utils/LayoutManager';
import { useSelector, useDispatch } from 'react-redux';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import Button from 'erpcore/components/Button';
import TableActions from 'erpcore/components/Listing/components/TableActions';
import Listing from 'erpcore/components/Listing';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import {
    getListingFetching,
    getListingResponse,
    getQueryParams
} from 'erpcore/components/Listing/Listing.selectors';
import ChallengeDelete from 'erpcore/screens/Challenges/components/ChallengeDelete';
import Tooltip from 'erpcore/components/Tooltip';
import { NavLink } from 'react-router-dom';
import Svg from 'erpcore/components/Svg';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import imagePlaceholder from 'erpcore/assets/images/image-placeholder.png';
import './ChallengesListing.scss';
import ButtonDropdown from 'erpcore/components/ButtonDropdown';
import restClient from 'erpcore/api/restClient';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import PageLoader from 'erpcore/components/PageLoader';
import { getImageSrcFromMediaObject } from 'erpcore/components/ImageManager';
import EntityChallengeImportModal from 'erpcore/screens/Challenges/screens/EntityChallenges/components/EntityChallengeImportModal';
import EntityChallengeBulkExport from 'erpcore/screens/Challenges/screens/EntityChallenges/components/EntityChallengeBulkExport';
import EntityChallengeBulkDelete from 'erpcore/screens/Challenges/screens/EntityChallenges/components/EntityChallengeBulkDelete';

const ChallengesListing = () => {
    const [exporting, setExporting] = useState(false);
    const [importChallengesModalState, setImportChallengesModalState] = useState(false);
    const reducerName = 'challenges';
    const actionName = 'CHALLENGES';
    const dispatch = useDispatch();
    const listing = useSelector(state => getListingResponse(state, reducerName));
    const listingFetching = useSelector(state => getListingFetching(state, reducerName));
    const listingParams = useSelector(state => getQueryParams(state, { name: reducerName }));
    const title = 'Challenges';
    const handleImportChallengesModal = () => {
        setImportChallengesModalState(!importChallengesModalState);
    };

    const exportChallenges = useCallback(async () => {
        setExporting(true);

        try {
            const file = await restClient.get(`/api/challenges/export`, {
                responseType: 'arraybuffer'
            });

            const url = window.URL.createObjectURL(
                new Blob([file.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                })
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `challenges-export.xlsx`);
            link.click();
        } catch (e) {
            dispatch({
                type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                response: {
                    code: 'generalError',
                    detail: 'Unexpected error occured while exporting challenges.'
                }
            });
        } finally {
            setExporting(false);
        }
    }, [setExporting]);

    const options = useMemo(
        () => [
            {
                label: 'Import Challenges',
                id: 'import-challenge',
                onClick: handleImportChallengesModal
            },
            {
                label: 'Export all challenges',
                id: 'export-all-challenges',
                onClick: exportChallenges
            }
        ],
        []
    );

    const fetchChallenges = params => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                entity: actionName,
                type: listingActions.START_FETCHING_LISTING,
                name: reducerName,
                params,
                endpoint: 'api/challenges?include=type,media,media.media'
            });
        }).catch(error => {
            return error;
        });
    };

    const tableData = () => {
        const table = {};
        table.data = [];

        table.filters = [
            {
                name: 'type',
                label: 'Type',
                defaultOperator: 'equals',
                filterFields: {
                    value: {
                        component: 'autocomplete',
                        fieldProps: {
                            label: 'Type',
                            options: {
                                endpoint: `/api/challenge-types`,
                                mapData: {
                                    value: 'iri',
                                    label: 'name'
                                }
                            },
                            clearable: false
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            }
        ];

        table.bulkActions = {
            name: reducerName,
            actions: [
                {
                    label: `Delete selected challenges`,
                    renderComponent: (
                        <EntityChallengeBulkDelete
                            batchRequestEndpoint="/api/batch-requests/challenges"
                            reducerName={reducerName}
                            callback={() => fetchChallenges(listingParams)}
                        />
                    )
                },
                {
                    label: `Export selected challenges`,
                    renderComponent: (
                        <EntityChallengeBulkExport
                            batchRequestEndpoint="api/challenges/export"
                            reducerName={reducerName}
                        />
                    )
                }
            ]
        };

        table.schema = [
            {
                title: 'Name',
                field: 'name',
                sortable: 'title',
                mobile: 'title'
            },
            {
                title: 'Points',
                field: 'points'
            },
            {
                title: 'Description',
                field: 'description'
            },
            {
                title: 'Actions',
                field: 'actions'
            }
        ];

        table.defaultSort = {
            order: 'DESC',
            sortable: 'created_at'
        };

        if (listing.data) {
            listing.data.map(row => {
                const {
                    id,
                    iri,
                    title: challengeTitle,
                    media_image_versions: versions,
                    deleted,
                    _type: type,
                    description,
                    points
                } = {
                    ...row
                };
                const smallImage = versions?.[0]?.find(version => version.name === 'small');
                const imageSrc = getImageSrcFromMediaObject(smallImage);

                return table.data.push({
                    id,
                    iri,
                    name: (
                        <div className="challenges-listing__name">
                            <div
                                className={`challenges-listing__name-image ${
                                    !imageSrc ? 'challenges-listing__name-image--placeholder' : ''
                                }`}
                            >
                                <img src={imageSrc || imagePlaceholder} alt={title} />
                            </div>
                            <div className="challenges-listing__name-content">
                                <h4>{challengeTitle}</h4>
                                <p>{type?.name}</p>
                            </div>
                        </div>
                    ),
                    points,
                    description: (
                        <div
                            className="challenges-listing__name-inner-html"
                            dangerouslySetInnerHTML={{
                                __html: description
                            }}
                        />
                    ),

                    actions: (
                        <TableActions>
                            <TableActions.Action>
                                <Tooltip content="Edit challenge">
                                    <NavLink to={`/challenges/${id}/edit`}>
                                        <Svg icon="edit" />
                                    </NavLink>
                                </Tooltip>
                            </TableActions.Action>
                            {!deleted && (
                                <TableActions.Action>
                                    <ChallengeDelete
                                        callback={() => fetchChallenges(listingParams)}
                                        id={id}
                                        key={`ChallengeDelete ${id}`}
                                    />
                                </TableActions.Action>
                            )}
                        </TableActions>
                    )
                });
            });
        }

        return table;
    };

    return (
        <LayoutManager slot="main" layoutType="merge">
            <HeadMeta title={title} />
            {exporting && <PageLoader />}
            <PageHeader title={title}>
                <Button href="/challenges/create" label="Create challenge" />
            </PageHeader>
            <Listing
                className="challenges-listing"
                name={reducerName}
                reducerName={reducerName}
                loading={listingFetching}
                meta={listing?.meta}
                table={tableData()}
                onListingConfigUpdate={params => fetchChallenges(params)}
            >
                <ButtonDropdown
                    triggerActionOnOptionSelection
                    placeholder="Actions"
                    options={options}
                    variation="primary"
                />
            </Listing>

            <EntityChallengeImportModal
                modalOpened={importChallengesModalState}
                handleModal={handleImportChallengesModal}
                entity="challenges"
                callback={fetchChallenges}
            />
        </LayoutManager>
    );
};

export default ChallengesListing;
