import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import './DateTime.scss';
import Input from '../Input';

/**
 *
 * react-datepicker formatting: https://date-fns.org/v2.16.1/docs/format
 * moment formatting: https://momentjs.com/docs/#/displaying/
 *
 * @param input
 * @param meta
 * @param fieldProps
 * @param fieldAttr
 * @param field
 * @param outputNoTimeZone {boolean} if true; value is saved as formatted string,
 *                                   otherwise value is saved as Date string with timezone
 * @return {*}
 * @constructor
 */
const DateTime = ({ input, meta, fieldProps, fieldAttr, field, outputNoTimeZone }) => {
    //  Preparing specific date format
    let dateFormat = 'M/d/yyyy'; // react-datepicker formatting
    let dateFormatMoment = outputNoTimeZone ? 'M/D/YYYY' : ''; // moment formatting
    if (fieldProps?.showTimeSelect) {
        dateFormat = 'M/d/yyyy   h:mm aa'; // react-datepicker formatting
        dateFormatMoment = outputNoTimeZone ? 'YYYY-MM-DD HH:mm:ss' : ''; // moment formatting

        if (fieldProps?.showTimeSelectOnly) {
            dateFormat = 'h:mm aa'; // react-datepicker formatting
            dateFormatMoment = outputNoTimeZone ? 'h:mm a' : ''; // moment formatting
        }
    } else if (fieldProps?.showYearOnly) {
        dateFormat = 'yyyy'; // react-datepicker formatting
        dateFormatMoment = 'YYYY'; // moment formatting

        fieldProps.showYearPicker = true;
        if (!fieldProps?.yearItemNumber) {
            fieldProps.yearItemNumber = 9;
        }
    }

    //  Passing custom input gives plugin console error. This is a 3rd party issue
    const CustomInput = props => {
        const { fieldAttr: inputFieldAttr } = props;
        return (
            <input
                className="input__field"
                autoComplete="off"
                onClick={props?.onClick}
                value={props?.value}
                {...inputFieldAttr}
            />
        );
    };

    return (
        <Input
            fieldProps={{ ...fieldProps, clearValue: null }}
            fieldAttr={fieldAttr}
            field={field}
            input={input}
            meta={meta}
            className="input--datepicker"
        >
            <DatePicker
                className="input__field"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                popperModifiers={{
                    preventOverflow: {
                        enabled: true,
                        escapeWithReference: false,
                        boundariesElement: 'viewport'
                    }
                }}
                {...fieldAttr}
                {...fieldProps}
                onBlur={() => input.onBlur()}
                dateFormat={dateFormat}
                selected={
                    input.value &&
                    Date.parse(input.value) &&
                    Number.isNaN(Date.parse(input.value)) === false
                        ? new Date(input.value)
                        : ''
                }
                // if outputNoTimeZone === true -> value is saved as formatted string, otherwise as Date string with timezone
                onChange={value => input.onChange(moment(value).format(dateFormatMoment))}
                customInput={<CustomInput fieldAttr={fieldAttr} />}
            />
        </Input>
    );
};

DateTime.defaultProps = {
    fieldProps: {},
    fieldAttr: {},
    field: {},
    input: {},
    meta: {},
    outputNoTimeZone: true
};

DateTime.propTypes = {
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    field: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object]),
    outputNoTimeZone: PropTypes.bool
};

export default DateTime;
