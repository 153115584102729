import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Listing from 'erpcore/components/Listing';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import {
    getListingFetching,
    getListingResponse,
    getQueryParams
} from 'erpcore/components/Listing/Listing.selectors';
import { actions as entityChallengesActions } from 'erpcore/screens/Challenges/screens/EntityChallenges/EntityChallenges.reducer';
import EntityChallengeBulkDelete from 'erpcore/screens/Challenges/screens/EntityChallenges/components/EntityChallengeBulkDelete';
import EntityChallengeAddNew from 'erpcore/screens/Challenges/screens/EntityChallenges/components/EntityChallengeAddNew';
import Svg from 'erpcore/components/Svg';
import Tooltip from 'erpcore/components/Tooltip';
import EntityChallengeSwitchCard from 'erpcore/screens/Challenges/screens/EntityChallenges/components/EntityChallengeSwitchCard';
import TableActions from 'erpcore/components/Listing/components/TableActions';
import EntityChallengeEdit from 'erpcore/screens/Challenges/screens/EntityChallenges/components/EntityChallengeEdit';
import EntityChallengeDelete from 'erpcore/screens/Challenges/screens/EntityChallenges/components/EntityChallengeDelete';
import EntityChallengeSwitch from 'erpcore/screens/Challenges/screens/EntityChallenges/components/EntityChallengeSwitch';
import ButtonDropdown from 'erpcore/components/ButtonDropdown';
import {
    getEntityChallengesListingPositionUpdate,
    getEntityData,
    getEntityDataFetching
} from 'erpcore/screens/Challenges/screens/EntityChallenges/EntityChallenges.selectors';
import { insertIf } from 'erpcore/utils/utils';
import PropTypes from 'prop-types';
import EntityChallengeImportModal from 'erpcore/screens/Challenges/screens/EntityChallenges/components/EntityChallengeImportModal';
import EntityChallengeBulkExport from 'erpcore/screens/Challenges/screens/EntityChallenges/components/EntityChallengeBulkExport';
import EntityChallengeBulkMediaEdit from 'erpcore/screens/Challenges/screens/EntityChallenges/components/EntityChallengeBulkMediaEdit';
import EntityChallengeBulkMediaDelete from 'erpcore/screens/Challenges/screens/EntityChallenges/components/EntityChallengeBulkMediaDelete';
import './EntityChallenges.scss';
import { getImageSrcFromMediaObject } from 'erpcore/components/ImageManager';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import restClient from 'erpcore/api/restClient';
import PageLoader from 'erpcore/components/PageLoader';
import { getIdFromIri } from 'erpcore/utils/dto';
import { reset } from 'redux-form';

const EntityChallengesListing = ({
    entityIri,
    endpoint,
    endpointParams,
    entity,
    batchRequestEndpoint,
    batchExportRequestEndpoint,
    defaultOrder
}) => {
    const [exporting, setExporting] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [draggable, setDraggable] = useState(null);
    const [addNewModalState, setAddNewModalState] = useState(false);
    const [importChallengesModalState, setImportChallengesModalState] = useState(false);
    const iri = entityIri;
    const reducerName = 'entityChallenges';
    const actionName = 'ENTITY_CHALLENGES';
    const addChallengeFormName = 'EntityChallengeAddNewForm';
    const addNewFormName = 'EntityChallengeNew';

    const listing = useSelector(state => getListingResponse(state, reducerName));
    const listingFetching = useSelector(state => getListingFetching(state, reducerName));
    const listingParams = useSelector(state => getQueryParams(state, { name: reducerName }));
    const positionsUpdate = useSelector(getEntityChallengesListingPositionUpdate);
    const entityData = useSelector(state => getEntityData(state, iri));
    const fetching = useSelector(state => getEntityDataFetching(state, 'entityChallenges'));
    const { enabled_challenges_count: enabledChallengesCount = null, settings } = {
        ...entityData
    };

    const isDisabled = entity === 'event' && entityData?.status === 'completed';

    const { wall: eventHasWall = false } = { ...settings };

    const handleAddNewChallengeModal = () => {
        if (addNewModalState) {
            dispatch(reset(addChallengeFormName));
            dispatch(reset(addNewFormName));
        }

        setAddNewModalState(!addNewModalState);
    };

    const handleImportChallengesModal = () => {
        setImportChallengesModalState(!importChallengesModalState);
    };

    const exportChallenges = useCallback(async () => {
        setExporting(true);

        try {
            const file = await restClient.get(`${entityIri}/export-challenges`, {
                responseType: 'arraybuffer'
            });

            const url = window.URL.createObjectURL(
                new Blob([file.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                })
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `challenges-export-${getIdFromIri(entityIri)}.xlsx`);
            link.click();
        } catch (e) {
            dispatch({
                type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                response: {
                    code: 'generalError',
                    detail: 'Unexpected error occurred while exporting challenges.'
                }
            });
        } finally {
            setExporting(false);
        }
    }, [entityIri, setExporting]);

    const exportChallengesPdf = useCallback(
        async props => {
            const { withAnswers = false } = { ...props };

            setExporting(true);

            try {
                const file = await restClient.get(
                    `${entityIri}/export-challenges-pdf${withAnswers ? '?with_answers=true' : ''}`,
                    {
                        responseType: 'arraybuffer'
                    }
                );

                const url = window.URL.createObjectURL(new Blob([file.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `challenges-export-${getIdFromIri(entityIri)}.pdf`);
                link.click();
            } catch (e) {
                dispatch({
                    type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                    response: {
                        code: 'generalError',
                        detail: 'Unexpected error occurred while exporting challenges.'
                    }
                });
            } finally {
                setExporting(false);
            }
        },
        [entityIri, setExporting]
    );

    const options = useMemo(
        () => [
            ...insertIf(!isDisabled, [
                {
                    label: 'Add challenge',
                    id: 'add-challenge',
                    onClick: handleAddNewChallengeModal
                }
            ]),
            ...insertIf(!isDisabled, [
                {
                    label: 'Import Challenge',
                    id: 'import-challenge',
                    onClick: handleImportChallengesModal
                }
            ]),
            {
                label: 'Export all challenges',
                id: 'export-all-challenges',
                onClick: exportChallenges
            },
            {
                label: 'Export all challenges to PDF',
                id: 'export-all-challenges-pdf',
                onClick: exportChallengesPdf
            },
            {
                label: 'Export all challenges to PDF (with answers)',
                id: 'export-all-challenges-answers-pdf',
                onClick: () => exportChallengesPdf({ withAnswers: true })
            }
        ],
        [isDisabled, handleAddNewChallengeModal, handleImportChallengesModal, exportChallenges]
    );

    const defaultOrderBy = defaultOrder;

    const [enabledCount, setEnabledCount] = useState(null);

    const fetchEntityData = useCallback(() => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: entityChallengesActions.START_FETCHING_ENTITY_DATA,
                iri: entityIri,
                promise: { resolve, reject }
            });
        });
    }, []);

    const fetchEntityChallenges = useCallback(
        (params, withEntityData = false) => {
            params = {
                ...endpointParams,
                ...params,
                pagination: draggable || params.pagination === false ? 'false' : undefined
            };
            return new Promise((resolve, reject) => {
                dispatch({
                    promise: { resolve, reject },
                    type: listingActions.START_FETCHING_LISTING,
                    entity: actionName,
                    name: reducerName,
                    params,
                    endpoint
                });
            })
                .then(() => {
                    if (withEntityData) fetchEntityData();
                })
                .catch(error => {
                    return error;
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [listing, endpointParams, draggable]
    );

    const getImageBasedOnType = challengeType => {
        switch (challengeType) {
            case 'TYPE_TRIVIA': {
                return <Svg icon="challengeTrivia" />;
            }
            case 'TYPE_QR': {
                return <Svg icon="challengeQR" />;
            }
            case 'TYPE_TEXT': {
                return <Svg icon="challengeText" />;
            }
            case 'TYPE_STICKER_PHOTO': {
                return <Svg icon="challengeSticker" />;
            }
            case 'TYPE_DATE': {
                return <Svg icon="challengeDate" />;
            }
            case 'TYPE_PHOTO': {
                return <Svg icon="challengeMedia" />;
            }
            case 'TYPE_VIDEO': {
                return <Svg icon="challengeMedia" />;
            }
            case 'TYPE_MULTIPLE_CHOICE': {
                return <Svg icon="challengeMultipleChoice" />;
            }
            default:
                return null;
        }
    };

    const generateTableDataName = (name, edited) => {
        if (entity === 'event') {
            return (
                <>
                    <span
                        className={`entity-challenges__dot ${
                            edited ? 'entity-challenges__dot--active' : ''
                        }`}
                    />{' '}
                    <span>{name}</span>
                </>
            );
        }
        return name;
    };

    const generateLockedByEndpoint = () => {
        if (entity === 'activity') {
            return `api/activity-challenges?filters[activity][equals]=${entityIri}`;
        }

        if (entity === 'event') {
            return `api/event-challenges?filters[event][equals]=${entityIri}`;
        }

        return undefined;
    };

    useEffect(() => {
        if (draggable !== null) {
            fetchEntityChallenges(listingParams);
            fetchEntityData();
        }
    }, [draggable]);

    useEffect(() => {
        fetchEntityData();
    }, []);

    useEffect(() => {
        setEnabledCount(enabledChallengesCount);
    }, [enabledChallengesCount]);

    const handleOnChange = useCallback(
        (value, field, entityChallengeIri, additionalFormData = {}) => {
            return new Promise((resolve, reject) => {
                dispatch({
                    promise: { resolve, reject },
                    type: entityChallengesActions.START_UPDATE_ENTITY_CHALLENGE,
                    iri: entityChallengeIri,
                    formData: {
                        [field]: value,
                        ...additionalFormData
                    }
                });
            }).then(() => {
                if (field === 'enabled') {
                    if (!value && enabledCount !== 0) {
                        setEnabledCount(enabledCount - 1);
                    } else {
                        setEnabledCount(enabledCount + 1);
                    }
                }
                fetchEntityChallenges(listingParams);
            });
        }
    );

    const generateItemIndex = useCallback(
        newIndex => {
            const orderBy = listing?.meta?.orderBy?.position || defaultOrderBy.order;
            const itemsPerPage = listing?.meta?.itemsPerPage || 10;
            const currentPage = Number(listing?.meta?.currentPage || 1) - 1;
            const totalItems = Number(listing?.meta?.totalItems || 0);
            if (orderBy === 'DESC') {
                return Number(totalItems - newIndex);
            }
            return Number(itemsPerPage * currentPage + newIndex + 1);
        },
        [listing]
    );

    const tableData = () => {
        const table = {};
        table.data = [];

        table.filters = [
            {
                name: 'type',
                label: 'Type',
                defaultOperator: 'equals',
                filterFields: {
                    value: {
                        component: 'autocomplete',
                        fieldProps: {
                            label: 'Type',
                            options: {
                                endpoint: `/api/challenge-types`,
                                mapData: {
                                    value: 'iri',
                                    label: 'name'
                                }
                            },
                            clearable: false
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            }
        ];

        if (!isDisabled && !draggable) {
            table.bulkActions = {
                name: reducerName,
                apiRoute: endpoint,
                actions: [
                    {
                        label: `Delete ${entity} challenges`,
                        renderComponent: (
                            <EntityChallengeBulkDelete
                                entity={entity}
                                batchRequestEndpoint={batchRequestEndpoint}
                                reducerName={reducerName}
                                callback={() => {
                                    setLoading(true);

                                    setTimeout(() => {
                                        fetchEntityChallenges(listingParams, true);
                                    }, 1500);
                                }}
                            />
                        )
                    },
                    {
                        label: `Export ${entity} challenges`,
                        renderComponent: (
                            <EntityChallengeBulkExport
                                entity={entity}
                                batchRequestEndpoint={batchExportRequestEndpoint}
                                reducerName={reducerName}
                            />
                        )
                    },
                    {
                        label: `Set icon`,
                        renderComponent: (
                            <EntityChallengeBulkMediaEdit
                                entity={entity}
                                batchRequestEndpoint={batchRequestEndpoint}
                                reducerName={reducerName}
                                callback={() => {
                                    setLoading(true);

                                    setTimeout(() => {
                                        fetchEntityChallenges(listingParams, true);
                                    }, 1500);
                                }}
                            />
                        )
                    },
                    {
                        label: `Delete icon`,
                        renderComponent: (
                            <EntityChallengeBulkMediaDelete
                                entity={entity}
                                batchRequestEndpoint={batchRequestEndpoint}
                                reducerName={reducerName}
                                callback={() => {
                                    setLoading(true);

                                    setTimeout(() => {
                                        fetchEntityChallenges(listingParams, true);
                                    }, 1500);
                                }}
                            />
                        )
                    }
                ]
            };
        }

        table.schema = [
            {
                title: 'No.',
                field: 'position',
                sortable: 'position',
                mobile: 'status',
                columnWidthRatio: 1
            },
            {
                title: 'Type',
                field: 'type',
                columnWidthRatio: 1
            },
            {
                title: 'Name',
                field: 'name',
                mobile: 'title',
                columnWidthRatio: 5
            },
            {
                title: 'Points',
                field: 'points',
                columnWidthRatio: 1
            },
            {
                title: `Enabled ${enabledCount ? `(${enabledCount})` : ''}`,
                field: 'enabled',
                columnWidthRatio: 1
            },
            {
                title: 'Timer',
                field: 'timer',
                columnWidthRatio: 1
            },
            {
                title: 'Wall',
                field: 'wall',
                columnWidthRatio: 1
            },
            {
                title: 'Locks',
                field: 'locked',
                align: draggable ? 'right' : undefined,
                columnWidthRatio: 1
            },
            ...insertIf(!draggable, [
                {
                    title: 'Actions',
                    field: 'actions'
                }
            ])
        ];

        table.defaultSort = { ...defaultOrderBy };

        if (listing?.data) {
            listing.data.map((row, index) => {
                const {
                    id: entityChallengeID,
                    iri: entityChallengeIri,
                    _type: type,
                    title,
                    enabled,
                    timed,
                    timer,
                    wall,
                    locked,
                    locked_by: lockedBy,
                    activity_challenge: activityChallenge,
                    edited,
                    points
                } = {
                    ...row
                };

                const smallIconImage = getImageSrcFromMediaObject(row?._type.icon, 'small');

                return table.data.push({
                    id: entityChallengeID,
                    iri: entityChallengeIri,
                    position: generateItemIndex(index),
                    activityChallenge,
                    name: generateTableDataName(title, edited),
                    points,
                    type: (
                        <Tooltip content={type?.name}>
                            <span className="entity-challenges__icon">
                                {smallIconImage ? (
                                    <div className="listing__small-icon">
                                        <span
                                            style={{
                                                backgroundImage: `url('${smallIconImage}')`
                                            }}
                                        />
                                    </div>
                                ) : (
                                    getImageBasedOnType(type?._type) || '-'
                                )}
                            </span>
                        </Tooltip>
                    ),
                    enabled:
                        draggable || isDisabled ? (
                            <span style={{ color: enabled ? 'darkgreen' : 'darkred' }}>
                                {enabled ? 'Yes' : ' No'}
                            </span>
                        ) : (
                            <EntityChallengeSwitchCard
                                value={enabled}
                                name="enabled"
                                iri={entityChallengeIri}
                                handleOnChange={handleOnChange}
                            />
                        ),
                    timer:
                        draggable || isDisabled ? (
                            <span style={{ color: timed ? 'darkgreen' : 'darkred' }}>
                                {timed ? 'Yes' : 'No'}
                            </span>
                        ) : (
                            <EntityChallengeSwitchCard
                                value={timed}
                                name="timed"
                                tooltipContent="This challenge type cant be timed!"
                                timer={timer}
                                iri={entityChallengeIri}
                                handleOnChange={handleOnChange}
                            />
                        ),
                    wall: draggable ? (
                        <span style={{ color: wall ? 'darkgreen' : 'darkred' }}>
                            {wall ? 'Yes' : 'No'}
                        </span>
                    ) : (
                        <EntityChallengeSwitchCard
                            disabled={(entity === 'event' && !eventHasWall) || !type?.wallable}
                            tooltipContent={
                                !eventHasWall && entity === 'event'
                                    ? 'Wall has been disabled in settings for this event!'
                                    : 'This challenge type cannot be walled!'
                            }
                            value={wall}
                            name="wall"
                            iri={entityChallengeIri}
                            handleOnChange={handleOnChange}
                        />
                    ),
                    locked:
                        draggable || isDisabled ? (
                            <span style={{ color: locked ? 'darkgreen' : 'darkred' }}>
                                {locked ? 'Yes' : 'No'}
                            </span>
                        ) : (
                            <EntityChallengeSwitchCard
                                value={locked}
                                name="locked"
                                lockedBy={lockedBy}
                                iri={entityChallengeIri}
                                handleOnChange={handleOnChange}
                                generateLockedByEndpoint={generateLockedByEndpoint}
                            />
                        ),
                    actions: !draggable && (
                        <TableActions>
                            <TableActions.Action>
                                <EntityChallengeEdit
                                    wallDisabled={entity === 'event' && !eventHasWall}
                                    wallTooltipContent={
                                        !eventHasWall && entity === 'event'
                                            ? 'Wall has been disabled in settings for this event!'
                                            : 'This challenge type cannot be walled!'
                                    }
                                    entity={entity}
                                    generateLockedByEndpoint={generateLockedByEndpoint}
                                    entityChallengeIri={entityChallengeIri}
                                    key={`EntityChallengeEdit-${entityChallengeID}`}
                                    callback={() => {
                                        fetchEntityChallenges(listingParams);
                                        fetchEntityData();
                                    }}
                                    form={`EntityChallengeEdit-${entityChallengeIri}`}
                                />
                            </TableActions.Action>

                            {!isDisabled && (
                                <TableActions.Action>
                                    <EntityChallengeDelete
                                        entity={entity}
                                        entityChallengeIri={entityChallengeIri}
                                        key={`EntityChallengeDelete ${entityChallengeID}`}
                                        callback={() => {
                                            fetchEntityChallenges(listingParams);
                                            fetchEntityData();
                                        }}
                                    />
                                </TableActions.Action>
                            )}
                        </TableActions>
                    )
                });
            });
        }

        return table;
    };

    const updatePositions = (items, changedItems) => {
        const data = {
            id: parseInt(changedItems?.sourceItem?.id, 10),
            after: parseInt(changedItems?.destinationItem?.id, 10),
            ids: items.map(item => parseInt(item.id, 10))
        };

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: entityChallengesActions.START_UPDATE_ENTITY_CHALLENGE_POSITIONS,
                data,
                endpoint
            });
        }).catch(error => {
            return error;
        });
    };

    return (
        <Listing
            className="entity-challenges"
            name={reducerName}
            reducerName={reducerName}
            loading={listingFetching || positionsUpdate || fetching || loading}
            table={tableData()}
            onListingConfigUpdate={params => fetchEntityChallenges(params)}
            draggable={draggable}
            onDragFinished={(results, changedItems) => updatePositions(results, changedItems)}
            meta={listing?.meta}
        >
            {exporting && <PageLoader />}
            <div className="entity-challenges__right">
                {!isDisabled && (
                    <span>
                        <EntityChallengeSwitch
                            iri={iri}
                            name="draggable_toggle"
                            handleChange={value => {
                                dispatch({
                                    type: entityChallengesActions.CLEAR_STATE
                                });
                                setDraggable(value);
                            }}
                            form="EntityChallengeDraggableToggleSwitch"
                        />
                        Toggle sortable
                    </span>
                )}
                <EntityChallengeAddNew
                    wallDisabled={entity === 'event' && !eventHasWall}
                    wallTooltipContent={
                        !eventHasWall && entity === 'event'
                            ? 'Wall has been disabled in settings for this event!'
                            : 'This challenge type cannot be walled!'
                    }
                    modalOpened={addNewModalState}
                    handleModal={handleAddNewChallengeModal}
                    endpoint={endpoint}
                    entity={entity}
                    entityIri={entityIri}
                    form={addChallengeFormName}
                    addNewFormName={addNewFormName}
                    callback={() => {
                        fetchEntityChallenges(listingParams);
                        fetchEntityData();
                    }}
                />

                <EntityChallengeImportModal
                    modalOpened={importChallengesModalState}
                    handleModal={handleImportChallengesModal}
                    entityIri={entityIri}
                    entity={entity}
                    callback={() => fetchEntityChallenges(listingParams, true)}
                />

                <ButtonDropdown
                    triggerActionOnOptionSelection
                    placeholder="Actions"
                    options={options}
                    variation="primary"
                />
            </div>
        </Listing>
    );
};

EntityChallengesListing.defaultProps = {
    endpointParams: {},
    defaultOrder: { order: 'ASC', sortable: 'position' }
};

EntityChallengesListing.propTypes = {
    entityIri: PropTypes.string.isRequired,
    endpoint: PropTypes.string.isRequired,
    endpointParams: PropTypes.oneOfType([PropTypes.object]),
    entity: PropTypes.string.isRequired,
    batchRequestEndpoint: PropTypes.string.isRequired,
    batchExportRequestEndpoint: PropTypes.func.isRequired,
    defaultOrder: PropTypes.oneOfType([PropTypes.object])
};

export default EntityChallengesListing;
