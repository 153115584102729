import React from 'react';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import ActivityEditPageHeader from 'erpcore/screens/Activities/screens/ActivitiesEdit/components/ActivityEditPageHeader';
import { useRouteMatch } from 'react-router-dom';
import EntityNotificationsListing from 'erpcore/screens/EntityNotifications/screens/EntityNotificationsListing';

const ActivityNotificationsListing = () => {
    const match = useRouteMatch();
    const id = match?.params?.id;
    const iri = `/api/activities/${id}`;

    const params = {
        'filters[activity][equals]': iri,
        'order_by[id]': 'DESC'
    };

    return (
        <LayoutManager slot="main" className="main--medium" layoutType="merge">
            <ActivityEditPageHeader />
            <PageContent>
                <EntityNotificationsListing
                    entityIri={iri}
                    endpointParams={params}
                    endpoint="api/activity-notifications"
                    entity="activity"
                />
            </PageContent>
        </LayoutManager>
    );
};

export default ActivityNotificationsListing;
