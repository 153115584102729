import { takeLatest, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import { getQueryParams } from 'erpcore/components/Listing/Listing.selectors';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as customerContactsActions } from './CustomerContacts.reducer';

const customerContactsListingIncludeString = 'include=company';

/**
 * Delete Single Customer Contact
 * @param  {Object} Iri of a Customer Contact
 * @return {Object} Response from API
 */
export function* deleteCustomerContact({ promise, iri, customerIri }) {
    try {
        const deleteSingleCustomerAPI = yield restClient.delete(iri);
        yield put({
            type: customerContactsActions.DELETE_CUSTOMER_CONTACT_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteSingleCustomerAPI?.data
        });
        const params = yield select(getQueryParams, { name: 'companyContacts' });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'CUSTOMER_CONTACTS',
            name: 'companyContacts',
            endpoint: `api/contacts?filters[company][equals]=${customerIri}&${customerContactsListingIncludeString}`
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: customerContactsActions.DELETE_CUSTOMER_CONTACT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Create Customer Contact
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createCustomerContact({ promise, formData }) {
    try {
        const createCustomerContactAPI = yield restClient.post(`/api/contacts`, formData);
        yield put({
            type: customerContactsActions.CREATE_CUSTOMER_CONTACT_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createCustomerContactAPI?.data
        });
        yield call(promise.resolve, createCustomerContactAPI?.data);
    } catch (error) {
        yield put({
            type: customerContactsActions.CREATE_CUSTOMER_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Customer Contact
 * @param  {Object} iri of company Contact
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* updateCustomerContact({ promise, formData, iri }) {
    try {
        const updateCustomerContactAPI = yield restClient.put(iri, formData);
        yield put({
            type: customerContactsActions.UPDATE_CUSTOMER_CONTACT_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateCustomerContactAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: customerContactsActions.UPDATE_CUSTOMER_CONTACT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export const customerContactsSaga = [
    takeLatest(customerContactsActions.START_DELETE_CUSTOMER_CONTACT, deleteCustomerContact),
    takeLatest(customerContactsActions.START_CREATE_CUSTOMER_CONTACT, createCustomerContact),
    takeLatest(customerContactsActions.START_UPDATE_CUSTOMER_CONTACT, updateCustomerContact)
];
