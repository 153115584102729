import { takeLatest, put, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import dto from 'erpcore/utils/dto';
import { actions as activityActions } from './Activities.reducer';

/**
 * Delete Single Activity
 * @param  {Object} id ID of a Activity
 * @return {Object} Response from API
 */
export function* deleteSingleActivity({ promise, iri }) {
    try {
        const deleteSingleActivityAPI = yield restClient.delete(iri);
        yield put({
            type: activityActions.DELETE_ACTIVITY_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteSingleActivityAPI?.data
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: activityActions.DELETE_ACTIVITY_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Create Activity
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createActivity({ promise, formData }) {
    try {
        const createActivityAPI = yield restClient.post(`/api/activities`, formData);
        yield put({
            type: activityActions.CREATE_ACTIVITY_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createActivityAPI?.data
        });
        yield call(promise.resolve, createActivityAPI?.data);
    } catch (error) {
        yield put({
            type: activityActions.CREATE_ACTIVITY_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Activity
 * @param  {Object} promise
 * @param {string} activity Iri
 * @return {Object} response from API
 */
export function* fetchSingleActivity({ promise, iri, params }) {
    try {
        const fetchSingleActivityAPI = yield restClient.get(iri, { params });
        yield put({
            type: activityActions.FETCH_ACTIVITY_SUCCESSFUL
        });
        yield put({
            type: activityActions.STORE_ACTIVITY_DATA,
            iri,
            response: dto(fetchSingleActivityAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: activityActions.FETCH_ACTIVITY_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Single Activity data
 * @param  {String} Activity
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* updateSingleActivity({ promise, formData, iri }) {
    try {
        const updateSingleActivityAPI = yield restClient.put(iri, formData);
        yield put({
            type: activityActions.UPDATE_ACTIVITY_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleActivityAPI?.data
        });
        yield put({
            type: activityActions.STORE_ACTIVITY_DATA,
            iri,
            response: dto(updateSingleActivityAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: activityActions.UPDATE_ACTIVITY_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

export function* duplicateActivity({ promise, activityId }) {
    try {
        const duplicateActivityAPI = yield restClient.post(
            `/api/activities/${activityId}/duplicate`
        );
        yield put({
            type: activityActions.DUPLICATE_ACTIVITY_SUCCESS
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: duplicateActivityAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: activityActions.DUPLICATE_ACTIVITY_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export const activitiesSaga = [
    takeLatest(activityActions.START_DELETE_ACTIVITY, deleteSingleActivity),
    takeLatest(activityActions.START_CREATE_ACTIVITY, createActivity),
    takeLatest(activityActions.START_FETCHING_ACTIVITY, fetchSingleActivity),
    takeLatest(activityActions.START_DUPLICATE_ACTIVITY, duplicateActivity),
    takeLatest(activityActions.START_UPDATE_ACTIVITY, updateSingleActivity)
];
