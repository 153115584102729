import React from 'react';
import PropTypes from 'prop-types';
import Tabs from 'erpcore/components/Tabs';

const EventEditTabs = ({ id }) => {
    const links = [
        {
            link: `/events/${id}/view/event-info`,
            label: 'Event info'
        },
        {
            link: `/events/${id}/view/submissions`,
            label: 'Submissions'
        }
    ];

    return <Tabs links={links} />;
};

EventEditTabs.defaultProps = {
    id: 0
};

EventEditTabs.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default EventEditTabs;
