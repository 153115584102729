import React from 'react';
import { useDispatch } from 'react-redux';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import PageContent from 'erpcore/components/Layout/PageContent';
import { actions as eventsActions } from 'erpcore/screens/Events/Events.reducer';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import EventCreateForm from 'erpcore/screens/Events/components/EventCreateForm';
import { useHistory } from 'react-router-dom';
import { getIdFromIri } from 'erpcore/utils/dto';

const EventsCreate = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const title = 'New event';

    const initialValues = {
        is_self_hosted: false,
        single_participant_team: true
    };

    const onSubmit = ({ formData, preventEditRedirect = false }) => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: eventsActions.START_CREATE_EVENT,
                formData
            });
        })
            .then(response => {
                if (!preventEditRedirect) {
                    const { data } = { ...response };
                    const { id: iri } = { ...data };

                    history.push(`/events/${getIdFromIri(iri)}/edit/general`);
                }
            })
            .catch(err => err);
    };

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title} />
            <PageContent>
                <EventCreateForm
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    form="EventCreateForm"
                />
            </PageContent>
        </LayoutManager>
    );
};

export default EventsCreate;
