import React from 'react';
import PropTypes from 'prop-types';
import { SortableContainer } from 'react-sortable-hoc';
import 'react-virtualized/styles.css';

const MobileDraggableList = ({ children }) => {
    return <div className="table-mobile__list">{children}</div>;
};

MobileDraggableList.defaultProps = {
    children: {}
};

MobileDraggableList.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node])
};

export default SortableContainer(MobileDraggableList);
