import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

const MediaLibraryTabs = ({ items, onClick }) => {
    const handleClick = useCallback(name => {
        if (onClick && typeof onClick === 'function') onClick(name);
    }, []);

    return (
        items.length > 0 && (
            <nav className="tabs">
                <ul className="tabs__list">
                    {items.map(item => {
                        return (
                            <li key={item.name} className="tabs__list-item">
                                <button
                                    type="button"
                                    onClick={() => handleClick(item.name)}
                                    className={`tabs__link ${
                                        item.isActive ? 'tabs__link--active' : ''
                                    }`}
                                >
                                    {item.label}
                                </button>
                            </li>
                        );
                    })}
                </ul>
            </nav>
        )
    );
};

MediaLibraryTabs.defaultProps = {
    items: [],
    onClick: () => {}
};

MediaLibraryTabs.propTypes = {
    items: PropTypes.oneOfType([PropTypes.array]),
    onClick: PropTypes.func
};

export default React.memo(MediaLibraryTabs);
