import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';
import PropTypes from 'prop-types';
import Modal from 'erpcore/components/Modal';
import { actions as eventParticipantActions } from '../../Participants.reducer';
import ParticipantForm from '../ParticipantForm';

const AddParticipantModal = ({ isOpened, eventIri, onClose, onAdd, hasEmailAccess }) => {
    const dispatch = useDispatch();
    const [opened, setOpened] = useState(false);

    const handleModal = () => {
        setOpened(!opened);
        if (opened && onClose && typeof onClose === 'function') onClose();
    };

    useEffect(() => {
        setOpened(isOpened);
    }, [isOpened, setOpened]);

    const onSubmit = formData => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: eventParticipantActions.START_CREATING_EVENT_PARTICIPANT,
                formData: { event: eventIri, ...formData }
            })
        )
            .then(() => {
                handleModal();
                if (onAdd && typeof onAdd === 'function') onAdd();
            })
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    };

    return (
        <Modal
            opened={opened}
            variation="medium"
            onClose={() => handleModal()}
            title="Create participant"
        >
            <ParticipantForm
                onSubmit={onSubmit}
                form="AddParticipantForm"
                handleModal={handleModal}
                eventIri={eventIri}
                hasEmailAccess={hasEmailAccess}
            />
        </Modal>
    );
};

AddParticipantModal.defaultProps = {
    isOpened: false,
    onAdd: () => {},
    hasEmailAccess: false
};

AddParticipantModal.propTypes = {
    isOpened: PropTypes.bool,
    eventIri: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onAdd: PropTypes.func,
    hasEmailAccess: PropTypes.bool
};

export default AddParticipantModal;
