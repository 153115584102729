import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import EventEditTabs from 'erpcore/screens/Events/screens/EventsEdit/components/EventEditTabs';
import { useRouteMatch } from 'react-router-dom';
import { actions as eventActions } from 'erpcore/screens/Events/Events.reducer';
import {
    getSingleEventData,
    getSingleEventFetching
} from 'erpcore/screens/Events/Events.selectors';
import PageLoader from 'erpcore/components/PageLoader';
import Button from 'erpcore/components/Button';

const EventsEditPageHeader = ({ children }) => {
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const id = match?.params?.id;
    const iri = `/api/events/${id}`;
    const eventInfoLink = `/events/${id}/view/event-info`;
    const fetching = useSelector(getSingleEventFetching);
    const activityData = useSelector(state => getSingleEventData(state, iri));
    const { name } = { ...activityData };

    const getEventData = () => {
        const params = {
            include:
                'activity,company,timezone,coordinators,image,media,participants,teams,guideline,managers'
        };

        return new Promise((resolve, reject) => {
            dispatch({
                type: eventActions.START_FETCHING_EVENT,
                iri,
                promise: { resolve, reject },
                params
            });
        });
    };

    useEffect(() => {
        getEventData();
    }, []);

    const getName = () => {
        if (name) {
            return `Edit event - ${name}`;
        }

        return 'Edit event';
    };

    return (
        <>
            {fetching && <PageLoader />}
            <HeadMeta title={getName()} />
            <PageHeader title={getName()}>
                {children}
                <Button href={eventInfoLink} label="View event" />
            </PageHeader>
            <EventEditTabs id={id} />
        </>
    );
};

EventsEditPageHeader.defaultProps = {
    children: false
};

EventsEditPageHeader.propTypes = {
    children: PropTypes.node
};

export default React.memo(EventsEditPageHeader);
