import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import Form, { MultiAutocomplete } from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';
import { valueValidation } from 'erpcore/components/Form/Form.utils';

const EntityChallengesLockedForm = ({
    pristine,
    invalid,
    submitLabel,
    submitting,
    onSubmit,
    handleSubmit,
    endpoint,
    iri
}) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
                <Field
                    name="locked_by"
                    fieldAttr={{
                        id: 'locked_by',
                        required: true
                    }}
                    fieldProps={{
                        label: 'Locked by:',
                        options: {
                            endpoint: `${endpoint}&filters[id][not_in][]=${iri}`,
                            mapData: {
                                label: 'title',
                                value: 'iri'
                            }
                        }
                    }}
                    component={MultiAutocomplete}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Button
                submit
                disabled={pristine || invalid}
                loading={submitting}
                label={submitLabel}
            />
        </Form>
    );
};

EntityChallengesLockedForm.defaultProps = {
    pristine: false,
    invalid: false,
    submitting: false,
    submitLabel: 'Add',
    onSubmit: () => {}
};

EntityChallengesLockedForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
    submitLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    endpoint: PropTypes.string.isRequired,
    iri: PropTypes.string.isRequired
};

export default reduxForm({
    form: 'EntityChallengesLockedForm',
    enableReinitialize: true
})(EntityChallengesLockedForm);
