import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { change, getFormValues } from 'redux-form';
import Modal from 'erpcore/components/Modal';
import MediaLibraryTabs from 'erpcore/components/Form/components/Media/components/MediaLibraryModal/components/MediaLibraryTabs';
import MediaLibraryUpload from 'erpcore/components/Form/components/Media/components/MediaLibraryModal/components/MediaLibraryUpload';
import MediaLibraryGallery from 'erpcore/components/Form/components/Media/components/MediaLibraryModal/components/MediaLibraryGallery';

const MediaLibraryModal = ({
    input,
    opened,
    onClose,
    allowedFileTypes,
    maxImageCount,
    isMulti
}) => {
    const dispatch = useDispatch();

    const currentFormValues = useSelector(state => getFormValues(input?.form)(state));

    const [isOpened, setOpened] = useState(false);
    const [activeTab, setActiveTab] = useState('files');

    const handleModal = () => {
        setOpened(!isOpened);
        if (isOpened && onClose && typeof onClose === 'function') onClose();
    };

    const tabs = useMemo(
        () => [
            { label: 'Upload files', name: 'files', isActive: activeTab === 'files' },
            { label: 'Media library', name: 'library', isActive: activeTab === 'library' }
        ],
        [activeTab]
    );

    const handleTabSwitch = useCallback(tabName => {
        setActiveTab(tabName);
    }, []);

    const onSubmit = useCallback(
        (currentData, formData) => {
            const inputName = input?.name || input?.id;
            if (!inputName) throw new Error('Missing input ID or NAME!');

            const formName = input?.form;
            if (!formName) throw new Error('Form name missing!');

            const formMediaIris = formData?.files?.reduce((acc, item) => {
                return [
                    ...acc,
                    {
                        iri: item
                    }
                ];
            }, []);

            // merge with existing data if exists and send it back
            const fieldValues =
                formMediaIris.length > 0
                    ? [...(currentData?.[input.id] || ''), ...formMediaIris]
                    : '';

            if (inputName && formName) {
                // change redux form value
                if (isMulti) {
                    dispatch(change(formName, inputName, fieldValues));
                } else {
                    dispatch(change(formName, inputName, fieldValues[0].iri));
                }
                // close modal on complete
                handleModal();
            }
        },
        [input, handleModal, dispatch]
    );

    useEffect(() => {
        setOpened(opened);
    }, [opened]);

    return (
        <Modal
            variation="media"
            opened={isOpened}
            onClose={() => handleModal()}
            title="Insert media"
        >
            <MediaLibraryTabs items={tabs} onClick={handleTabSwitch} />
            {activeTab === 'files' && (
                <MediaLibraryUpload
                    form="__MediaLibraryUpload"
                    onSubmit={formData => onSubmit(currentFormValues, formData)}
                    allowedFileTypes={allowedFileTypes}
                    maxImageCount={maxImageCount}
                    isMulti={isMulti}
                />
            )}
            {activeTab === 'library' && (
                <MediaLibraryGallery
                    limit={maxImageCount}
                    allowedFileTypes={allowedFileTypes}
                    onSubmit={formData => onSubmit(currentFormValues, formData)}
                />
            )}
        </Modal>
    );
};

MediaLibraryModal.defaultProps = {
    input: {},
    opened: false,
    onClose: () => {},
    allowedFileTypes: null,
    maxImageCount: -1,
    isMulti: false
};

MediaLibraryModal.propTypes = {
    input: PropTypes.oneOfType([PropTypes.object]),
    opened: PropTypes.bool,
    onClose: PropTypes.func,
    allowedFileTypes: PropTypes.oneOfType([PropTypes.array]),
    maxImageCount: PropTypes.oneOfType([PropTypes.number]),
    isMulti: PropTypes.bool
};

export default MediaLibraryModal;
