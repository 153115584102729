import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ImageManager from 'erpcore/components/ImageManager';
import Button from 'erpcore/components/Button';

const MediaLibraryEdit = ({ imageIri, onSaveImage }) => {
    const [modalOpened, setModalOpened] = useState(false);

    const handleModal = () => {
        setModalOpened(!modalOpened);
    };

    return (
        <>
            <Button label="Edit" variation="secondary" onClick={() => handleModal()} />
            {modalOpened && (
                <ImageManager
                    imageIri={imageIri}
                    opened={modalOpened}
                    onClose={() => setModalOpened(false)}
                    onSaveImage={() => {
                        if (onSaveImage && typeof onSaveImage === 'function') onSaveImage();
                    }}
                />
            )}
        </>
    );
};

MediaLibraryEdit.defaultProps = {
    imageIri: null,
    onSaveImage: () => {}
};

MediaLibraryEdit.propTypes = {
    imageIri: PropTypes.string,
    onSaveImage: PropTypes.func
};

export default MediaLibraryEdit;
