import React from 'react';
import LayoutManager from 'erpcore/utils/LayoutManager';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import { useDispatch, useSelector } from 'react-redux';
import {
    getListingFetching,
    getListingResponse,
    getQueryParams
} from 'erpcore/components/Listing/Listing.selectors';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import Listing from 'erpcore/components/Listing';
import TableActions from 'erpcore/components/Listing/components/TableActions';
import ActivityTypesDelete from 'erpcore/screens/Settings/ActivityTypes/components/ActivityTypesDelete';
import ActivityTypesEdit from 'erpcore/screens/Settings/ActivityTypes/components/ActivityTypesEdit';
import { actions as activityTypeActions } from 'erpcore/screens/Settings/ActivityTypes/ActivityTypes.reducer';
import { reset } from 'redux-form';
import ActivityTypeCreateEditForm from './components/ActivityTypeCreateEditForm/ActivityTypeCreateEditForm';

const ActivityTypes = () => {
    const title = 'Template Types';
    const dispatch = useDispatch();
    const listingActionName = 'SETTINGS_ACTIVITY_TYPES';
    const listingReducerName = 'settingsActivityTypes';
    const listing = useSelector(state => getListingResponse(state, listingReducerName));
    const listingFetching = useSelector(state => getListingFetching(state, listingReducerName));
    const listingParams = useSelector(state => getQueryParams(state, { name: listingReducerName }));

    const fetchActivityTypes = params => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                params,
                type: listingActions.START_FETCHING_LISTING,
                name: listingReducerName,
                entity: listingActionName,
                endpoint: '/api/activity-types'
            });
        }).catch(error => {
            return error;
        });
    };

    const onCreateActivityType = formData => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: activityTypeActions.START_CREATE_ACTIVITY_TYPE,
                promise: { resolve, reject },
                formData
            });
        }).then(() => {
            dispatch(reset('ActivityTypesCreateEditForm'));
            fetchActivityTypes(listingParams);
        });
    };

    const tableData = () => {
        const table = {};
        table.data = [];

        table.schema = [
            {
                title: 'Template Types',
                field: 'name'
            },
            {
                title: 'Actions',
                field: 'actions'
            }
        ];

        table.defaultSort = {
            order: 'DESC',
            sortable: 'id'
        };

        if (listing?.data?.length > 0) {
            listing.data.map(row => {
                const { id, iri, name } = { ...row };
                return table.data.push({
                    id,
                    iri,
                    name,
                    actions: (
                        <TableActions>
                            <TableActions.Action>
                                <ActivityTypesEdit
                                    id={id}
                                    data={row}
                                    callback={fetchActivityTypes}
                                    form={`ActivityTypeEdit-${iri}`}
                                />
                            </TableActions.Action>
                            <TableActions.Action>
                                <ActivityTypesDelete id={id} callback={fetchActivityTypes} />
                            </TableActions.Action>
                        </TableActions>
                    )
                });
            });
        }

        return table;
    };

    return (
        <LayoutManager slot="main" className="main--medium" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title} />
            <ActivityTypeCreateEditForm inline onSubmit={onCreateActivityType} />
            <Listing
                hideHeader
                name={listingReducerName}
                reducerName={listingReducerName}
                loading={listingFetching}
                meta={listing?.meta}
                table={tableData()}
                onListingConfigUpdate={params => fetchActivityTypes(params)}
            />
        </LayoutManager>
    );
};
export default ActivityTypes;
