import React from 'react';
import Form, { Repeater } from 'erpcore/components/Form';
import { FieldArray, getFormSyncErrors } from 'redux-form';
import MultipleChoiceRepeaterItem from 'erpcore/screens/Challenges/components/ChallengeTypeMultipleChoice/components/MultipleChoiceRepeaterItem';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const ChallengeTypeMultipleChoice = ({ form }) => {
    const formErrors = useSelector(state => getFormSyncErrors(form)(state));
    const { answersMultiple } = { ...formErrors };
    const { _error } = { ...answersMultiple };
    const { correctChoiceError } = { ..._error };

    const validate = values => {
        const errors = {};
        const correctChoices = values?.filter(value => value.is_correct) || null;

        if (correctChoices?.length > 1) {
            errors.correctChoiceError = 'There can only be one correct choice!';
        }

        if (correctChoices && !correctChoices.length) {
            errors.correctChoiceError = 'There must be one correct choice!';
        }

        if (!correctChoices) {
            errors.correctChoiceError = 'Please add choices!';
        }

        if (values?.length === 1) {
            errors.correctChoiceError = 'Minimal 2 choices are required!';
        }

        if (errors && Object.keys(errors)?.length > 0) {
            return errors;
        }

        return null;
    };

    return (
        <>
            <Form.Row>
                <FieldArray
                    name="answersMultiple"
                    component={Repeater}
                    RepeatableItem={MultipleChoiceRepeaterItem}
                    addNewLabel="Add answer option"
                    uniqueIdentifier="iri"
                    data={{
                        form
                    }}
                    canAddMultiple
                    canAdd
                    canSave={false}
                    validate={validate}
                />
            </Form.Row>

            {correctChoiceError && <p className="color--tomato">{correctChoiceError}</p>}
        </>
    );
};

ChallengeTypeMultipleChoice.defaultProps = {
    form: 'ChallengeCreateEditForm'
};

ChallengeTypeMultipleChoice.propTypes = {
    form: PropTypes.string
};

export default ChallengeTypeMultipleChoice;
