import React, { useState, useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import LayoutManager from 'erpcore/utils/LayoutManager';
import EventsEditPageHeader from 'erpcore/screens/Events/screens/EventsEdit/components/EventEditPageHeader';
import PageContent from 'erpcore/components/Layout/PageContent';
import { useDispatch, useSelector } from 'react-redux';
import {
    getListingFetching,
    getListingResponse,
    getQueryParams
} from 'erpcore/components/Listing/Listing.selectors';
import { actions as notificationActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import Svg from 'erpcore/components/Svg';
import TableActions from 'erpcore/components/Listing/components/TableActions';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import Listing from 'erpcore/components/Listing';
import Tooltip from 'erpcore/components/Tooltip';
import { getSingleEventData } from 'erpcore/screens/Events/Events.selectors';
import EventSendInvitations from 'erpcore/screens/Events/components/EventSendInvitations';
import ButtonDropdown from 'erpcore/components/ButtonDropdown';
import SendSingleEmail from './components/SendSingleEmail';
import AddParticipantModal from './components/AddParticipantModal';
import EditParticipantModal from './components/EditParticipantModal';
import InlineTeamSelect from './components/InlineTeamSelect';
import MakeTeamLeadModal from './components/MakeTeamLeadModal';
import DeleteParticipant from './components/DeleteParticipant';
import ImportParticipantsModal from './components/ImportParticipantsModal';
import styles from './Participants.module.scss';

const Participants = () => {
    const match = useRouteMatch();
    const dispatch = useDispatch();
    const [addParticipantModalOpened, setAddParticipantModal] = useState(false);
    const [sendEmailParticipantModal, setSendEmailParticipantModal] = useState(false);
    const [importParticipantModalOpened, setImportParticipantModal] = useState(false);

    const eventId = match?.params?.id;
    const eventIri = `/api/events/${eventId}`;

    const reducerName = 'eventParticipants';
    const actionName = 'EVENT_PARTICIPANTS';

    const listing = useSelector(state => getListingResponse(state, reducerName));
    const listingFetching = useSelector(state => getListingFetching(state, reducerName));
    const listingParams = useSelector(state =>
        getQueryParams(state, {
            name: reducerName
        })
    );
    const eventData = useSelector(state => getSingleEventData(state, eventIri));
    const hasEmailAccess = eventData?.settings?.emailAccess;

    const fetchParticipants = useCallback(
        params => {
            return new Promise((resolve, reject) => {
                dispatch({
                    promise: { resolve, reject },
                    entity: actionName,
                    type: listingActions.START_FETCHING_LISTING,
                    name: reducerName,
                    params: {
                        ...listingParams,
                        ...params
                    },
                    endpoint: `/api/event-participants?filters[event][equals]=${eventIri}&include=eventTeam&order_by[event_team.id]=ASC`
                });
            }).catch(error => {
                return error;
            });
        },
        [listingParams]
    );

    const copyParticipantLink = async participantHash => {
        try {
            await navigator.clipboard.writeText(
                `${process.env.REACT_APP_GAME_LINK}/join/${eventData.hash}/${participantHash}`
            );

            dispatch({
                type: notificationActions.ADD_FLOATING_NOTIFICATION,
                response: {
                    code: 'copiedToClipboard'
                }
            });
        } catch (e) {
            dispatch({
                type: notificationActions.ADD_FLOATING_NOTIFICATION,
                response: {
                    code: 'failedToCopyToClipboard'
                }
            });
        }
    };

    const teamObject = listing?.data.reduce((acc, current) => {
        const { event_team: eventTeam } = { ...current };
        const teamName = eventTeam?.name || null;
        const teamExists = acc.find(team => team.label === teamName);
        if (!teamExists && teamName) {
            return [
                ...acc,
                {
                    value: teamName,
                    label: teamName
                }
            ];
        }
        return [...acc];
    }, []);

    const availableTeams = eventData?.teams?.reduce((acc, current) => {
        if (current.iri && current.name) {
            return [
                ...acc,
                {
                    value: current.iri,
                    label: current.name
                }
            ];
        }
        return [...acc];
    }, []);

    const handleAddParticipantModal = () => {
        setAddParticipantModal(!addParticipantModalOpened);
    };

    const handleSendEmailParticipantModal = () => {
        setSendEmailParticipantModal(!sendEmailParticipantModal);
    };

    const handleImportParticipantModal = () => {
        setImportParticipantModal(!importParticipantModalOpened);
    };

    const generateNameForUser = (leader, firstname, lastname) => {
        if (leader) {
            return (
                <div className={styles.leadUser}>
                    <Svg icon="leader" className={styles.leadUserSvg} />
                    <span>
                        {firstname} {lastname}
                    </span>
                </div>
            );
        }
        return (
            <>
                {firstname} {lastname}
            </>
        );
    };

    const tableData = () => {
        const table = {};
        table.data = [];

        table.filters = [
            {
                name: 'eventTeam.name',
                label: 'Team',
                defaultOperator: 'equals',
                filterFields: {
                    value: {
                        component: 'select',
                        fieldProps: {
                            label: 'Name',
                            options: teamObject,
                            clearable: true
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            }
        ];

        table.schema = [
            {
                title: 'Name',
                field: 'name',
                sortable: 'first_name'
            },
            {
                title: hasEmailAccess ? 'Email' : 'Nickname',
                field: hasEmailAccess ? 'email' : 'nickname'
            },
            {
                title: 'Team',
                field: 'team'
            },
            {
                title: 'Actions',
                field: 'actions'
            }
        ];

        if (listing.data) {
            listing.data.map(row => {
                const {
                    id,
                    iri,
                    email,
                    hash: participantHash,
                    first_name: firstName,
                    last_name: lastName,
                    nickname,
                    event_team: eventTeam,
                    team_leader: teamLeader
                } = {
                    ...row
                };

                return table.data.push({
                    id,
                    iri,
                    email,
                    name: generateNameForUser(teamLeader, firstName, lastName),
                    nickname,
                    team: eventData?.single_participant_team ? (
                        eventTeam?.name
                    ) : (
                        <InlineTeamSelect
                            id={id}
                            form={`participantTeamSelect-${id}`}
                            team={eventTeam?.name}
                            teams={availableTeams}
                            initialValues={{
                                event_team: eventTeam?.iri
                            }}
                            onUpdate={() => fetchParticipants(listingParams)}
                        />
                    ),
                    actions: (
                        <TableActions>
                            <TableActions.Action>
                                <Tooltip content="Copy participant link">
                                    <button
                                        type="button"
                                        onClick={() => copyParticipantLink(participantHash)}
                                    >
                                        <Svg icon="copyLink" />
                                    </button>
                                </Tooltip>
                            </TableActions.Action>
                            {!teamLeader && !eventData?.single_participant_team && (
                                <TableActions.Action>
                                    <MakeTeamLeadModal
                                        id={id}
                                        eventIri={eventIri}
                                        form={`makeTeamLead-${id}`}
                                        firstName={firstName}
                                        lastName={lastName}
                                        teamName={eventTeam?.name || ''}
                                        onUpdate={() => fetchParticipants(listingParams)}
                                    />
                                </TableActions.Action>
                            )}
                            {hasEmailAccess && (
                                <TableActions.Action>
                                    <SendSingleEmail
                                        eventId={eventId}
                                        participantIri={iri}
                                        form={`sendEmailToParticipant-${id}`}
                                        name={`${firstName} ${lastName}`}
                                        onSend={() => fetchParticipants(listingParams)}
                                    />
                                </TableActions.Action>
                            )}
                            <TableActions.Action>
                                <EditParticipantModal
                                    id={id}
                                    eventIri={eventIri}
                                    initialValues={{
                                        first_name: firstName,
                                        last_name: lastName,
                                        nickname,
                                        email,
                                        event_team: eventTeam?.iri
                                    }}
                                    hasEmailAccess={hasEmailAccess}
                                    onUpdate={() => fetchParticipants(listingParams)}
                                />
                            </TableActions.Action>
                            <TableActions.Action>
                                <DeleteParticipant
                                    id={id}
                                    form={`deleteParticipant-${id}`}
                                    name={`${firstName} ${lastName}`}
                                    onDelete={() => fetchParticipants(listingParams)}
                                />
                            </TableActions.Action>
                        </TableActions>
                    )
                });
            });
        }

        return table;
    };

    const dropDownOptions = [
        {
            id: 'createParticipant',
            label: 'Create participant',
            onClick: () => handleAddParticipantModal()
        },
        {
            id: 'importProspects',
            label: 'Import participants',
            onClick: () => handleImportParticipantModal()
        }
    ];

    if (eventData?.participants?.length > 0) {
        dropDownOptions.splice(1, 0, {
            id: 'sendEmailToAll',
            label: 'Send email',
            onClick: () => handleSendEmailParticipantModal()
        });
    }

    return (
        <LayoutManager slot="main" layoutType="merge" className="main--medium">
            <EventsEditPageHeader />
            <PageContent>
                <Listing
                    name={reducerName}
                    reducerName={reducerName}
                    loading={listingFetching}
                    meta={listing?.meta}
                    table={tableData()}
                    onListingConfigUpdate={params => fetchParticipants(params)}
                >
                    {hasEmailAccess && (
                        <ButtonDropdown
                            placeholder="Actions"
                            triggerActionOnOptionSelection
                            options={dropDownOptions}
                        />
                    )}
                </Listing>
                <AddParticipantModal
                    eventIri={eventIri}
                    hasEmailAccess={hasEmailAccess}
                    isOpened={addParticipantModalOpened}
                    onAdd={() => fetchParticipants(listingParams)}
                    onClose={() => {
                        setAddParticipantModal(false);
                    }}
                />
                <EventSendInvitations
                    id={eventId}
                    form={`eventParticipants-${eventId}`}
                    isOpened={sendEmailParticipantModal}
                    onClose={() => {
                        setSendEmailParticipantModal(false);
                    }}
                    initialValues={{
                        name: eventData?.name,
                        participants:
                            eventData?.participants?.map(participant => participant.iri) || []
                    }}
                />
                <ImportParticipantsModal
                    eventIri={eventIri}
                    isOpened={importParticipantModalOpened}
                    onClose={() => {
                        setImportParticipantModal(false);
                    }}
                    onSuccess={() => {
                        fetchParticipants(listingParams);
                    }}
                />
            </PageContent>
        </LayoutManager>
    );
};

export default Participants;
