import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import Form from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';
import Modal from 'erpcore/components/Modal';
import Svg from 'erpcore/components/Svg';
import { actions as commentsActions } from 'erpcore/components/Comments/Comments.reducer';

/**
 *
 * @param {*} props
 */
class CommentDelete extends Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: false
        };

        this.handleConfirmDeleteModal = this.handleConfirmDeleteModal.bind(this);
        this.deleteComment = this.deleteComment.bind(this);
    }

    deleteComment() {
        const { dispatch, entity, params, iri, entityId } = this.props;
        const data = { comment: iri };

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: commentsActions.START_DELETING_SINGLE_COMMENT,
                entity,
                id: entityId,
                params,
                data
            })
        )
            .then(() => {
                this.setState({ opened: false });
            })
            .catch(error => {
                return error;
            });
    }

    handleConfirmDeleteModal() {
        const { opened: isOpen } = this.state;
        this.setState({ opened: !isOpen });
    }

    render() {
        const { handleSubmit, submitting, iri } = this.props;
        const { opened } = this.state;

        return (
            <Fragment>
                <button
                    className="comments__comment-delete"
                    type="button"
                    onClick={this.handleConfirmDeleteModal}
                >
                    <Svg icon="remove" />
                </button>
                <Modal
                    key={`modal-${iri}`}
                    variation="small"
                    opened={opened}
                    onClose={this.handleConfirmDeleteModal}
                    title="Delete comment"
                    subtitle="Are you sure you want to delete this comment?"
                >
                    <Form name="deleteComment" onSubmit={handleSubmit(this.deleteComment)}>
                        <Button submit loading={submitting} label="Delete" />
                        <Button
                            variation="secondary"
                            label="Cancel"
                            onClick={() => this.handleConfirmDeleteModal(iri)}
                        />
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

CommentDelete.defaultProps = {
    submitting: false,
    params: {}
};

CommentDelete.propTypes = {
    entity: PropTypes.string.isRequired,
    iri: PropTypes.string.isRequired,
    entityId: PropTypes.string.isRequired,
    params: PropTypes.oneOfType([PropTypes.object]),
    dispatch: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool
};

export default reduxForm({
    form: 'CommentDeleteForm',
    enableReinitialize: false
})(CommentDelete);
