import React from 'react';
import PropTypes from 'prop-types';
import Notification from 'erpcore/components/Notification';

/**
 * Notification Manager - Listing Notifications
 * @return {Node} Returns Notification component
 */
// eslint-disable-next-line no-unused-vars
const DefaultNotifications = ({ identifier, code, detail, notificationData }) => {
    switch (code) {
        case 'bulkActions.notEnoughItemsSelected':
            return (
                <Notification
                    identifier={identifier}
                    title="Warning"
                    text="You have to select at least 2 items to be able to use bulk actions."
                    type="warning"
                />
            );
        case 'batch.successful':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Batch operation successfully done!"
                    type="success"
                    expire="3500"
                />
            );
        case 'listingNoData':
            return (
                <Notification
                    identifier={identifier}
                    title="No Results"
                    text="There are no results to display!"
                    type="info"
                />
            );
        case 'batchExport.successful':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Export challenges successful."
                    type="success"
                    expire="3500"
                />
            );
        default:
            return null;
    }
};

DefaultNotifications.defaultProps = {
    identifier: null,
    code: null,
    detail: null,
    notificationData: null
};

DefaultNotifications.propTypes = {
    identifier: PropTypes.string,
    code: PropTypes.string,
    detail: PropTypes.string,
    notificationData: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])
};

export default DefaultNotifications;
