import React, { useMemo } from 'react';
import './EventViewTestimonials.scss';
import { useSelector } from 'react-redux';
import { getSingleEventData } from 'erpcore/screens/Events/Events.selectors';
import Notification from 'erpcore/components/Notification';
import { useRouteMatch } from 'react-router-dom';
import Svg from 'erpcore/components/Svg';
import { getImageSrcFromMediaObject } from 'erpcore/components/ImageManager';
import StarRating from 'erpcore/components/StarRating';

const EventViewTestimonials = () => {
    const match = useRouteMatch();
    const id = match?.params?.id;
    const eventIri = `/api/events/${id}`;

    const eventData = useSelector(state => getSingleEventData(state, eventIri));

    const participantsThatLeftRating = useMemo(() => {
        return eventData?.participants?.filter(participant => participant.rating);
    }, [eventData?.participants]);

    return (
        <div className="event-view-testimonials">
            {participantsThatLeftRating?.length ? (
                participantsThatLeftRating?.map(participant => {
                    const {
                        image,
                        first_name: firstName,
                        last_name: lastName,
                        rating,
                        rating_message: description
                    } = {
                        ...participant
                    };
                    const imageURL = getImageSrcFromMediaObject(image, 'small');

                    return (
                        <div className="event-view-testimonials-single">
                            <div className="event-view-testimonials-single__participant">
                                {imageURL ? (
                                    <div
                                        className="event-view-testimonials-single__participant-image"
                                        style={{ backgroundImage: `url(${imageURL})` }}
                                    />
                                ) : (
                                    <div className="event-view-testimonials-single__participant-image--default">
                                        <Svg icon="profile" />
                                    </div>
                                )}
                                <p>
                                    {firstName} {lastName}
                                </p>
                            </div>
                            <div className="event-view-testimonials-single__content">
                                <StarRating
                                    rating={rating}
                                    starCount={10}
                                    ratingNumberPosition="right"
                                />

                                {description && <p>{description}</p>}
                            </div>
                        </div>
                    );
                })
            ) : (
                <Notification
                    code="listingNoData"
                    title="No Results"
                    text="There are no results to display!"
                    type="info"
                />
            )}
        </div>
    );
};

export default EventViewTestimonials;
