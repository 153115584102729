import React from 'react';
import { useDispatch } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';
import { getIdFromIri } from 'erpcore/utils/dto';

import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import EventGuidelineForm from 'erpcore/screens/Settings/EventGuidelines/components/EventGuidelineForm';

import { actions as guidelinesActions } from 'erpcore/screens/Settings/EventGuidelines/EventGuidelines.reducer';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import { useHistory } from 'react-router-dom';

const GuidelineCreate = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const title = 'Create new event guideline';

    const onSubmit = formData => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: guidelinesActions.START_CREATE_GUIDELINE,
                formData
            });
        })
            .then(response => {
                const { data } = { ...response };
                const { id: iri } = { ...data };

                const id = getIdFromIri(iri) || null;

                if (id) {
                    history.push(`/settings/guidelines/${id}/edit`);
                }
            })
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    };

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title} />
            <PageContent>
                <EventGuidelineForm onSubmit={onSubmit} form="EventGuidelineCreateForm" />
            </PageContent>
        </LayoutManager>
    );
};

export default GuidelineCreate;
