export const actions = {
    START_FETCHING_MEDIA_LIBRARY: 'START_FETCHING_MEDIA_LIBRARY',
    FETCHING_SUCCESSFUL_MEDIA_LIBRARY: 'FETCHING_SUCCESSFUL_MEDIA_LIBRARY',
    FETCHING_FAILED_MEDIA_LIBRARY: 'FETCHING_FAILED_MEDIA_LIBRARY',

    START_DELETING_MEDIA_LIBRARY_ITEM: 'START_DELETING_MEDIA_LIBRARY_ITEM',
    MEDIA_LIBRARY_ITEM_DELETE_SUCCESSFUL: 'MEDIA_LIBRARY_ITEM_DELETE_SUCCESSFUL',
    MEDIA_LIBRARY_ITEM_DELETE_FAILED: 'MEDIA_LIBRARY_ITEM_DELETE_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: []
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_FETCHING_MEDIA_LIBRARY: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_MEDIA_LIBRARY: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_MEDIA_LIBRARY: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        default:
            return state;
    }
};
