import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Svg from 'erpcore/components/Svg';
import Tooltip from 'erpcore/components/Tooltip';
import styles from './MediaItemTags.module.scss';

const MediaItemTags = ({ tags, style }) => {
    const tooltipContent = useMemo(() => {
        if (!tags?.length) {
            return null;
        }

        return (
            <div className={styles.wrapper__tooltip}>
                {tags.map(item => (
                    <div className={styles['wrapper__tooltip-item']}>
                        <Svg icon="tag" className={styles['wrapper__tooltip-item-icon']} />
                        <span className={styles['wrapper__tooltip-item-name']}>{item?.name}</span>
                    </div>
                ))}
            </div>
        );
    }, [tags]);

    if (!tags?.length) {
        return null;
    }

    return (
        <div className={styles.wrapper} style={style}>
            <Tooltip content={tooltipContent}>
                <Svg icon="tag" className={styles.wrapper__icon} />
            </Tooltip>
        </div>
    );
};

MediaItemTags.defaultProps = {
    style: null,
    tags: []
};
MediaItemTags.propTypes = {
    style: PropTypes.oneOfType([PropTypes.object]),
    tags: PropTypes.oneOfType([PropTypes.array])
};

export default MediaItemTags;
