import { takeLatest, put, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import dto from 'erpcore/utils/dto';
import { actions as entityChallengesActions } from './EntityChallenges.reducer';

/**
 * Delete Single Entity Challenge
 * @param  {Object} id ID of a Entity Challenge
 * @return {Object} Response from API
 */
export function* deleteSingleEntityChallenge({ promise, iri }) {
    try {
        const deleteSingleEntityChallengeAPI = yield restClient.delete(iri);
        yield put({
            type: entityChallengesActions.DELETE_ENTITY_CHALLENGE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteSingleEntityChallengeAPI?.data
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: entityChallengesActions.DELETE_ENTITY_CHALLENGE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Create Entity Challenge
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createEntityChallenge({ promise, formData, endpoint }) {
    try {
        const createEntityChallengeAPI = yield restClient.post(endpoint, formData);
        yield put({
            type: entityChallengesActions.CREATE_ENTITY_CHALLENGE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createEntityChallengeAPI?.data
        });
        yield call(promise.resolve, createEntityChallengeAPI?.data);
    } catch (error) {
        yield put({
            type: entityChallengesActions.CREATE_ENTITY_CHALLENGE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Entity Challenge
 * @param  {Object} promise
 * @param {string} activity Iri
 * @return {Object} response from API
 */
export function* fetchSingleEntityChallenge({ promise, iri, params }) {
    try {
        const fetchSingleEntityChallengeAPI = yield restClient.get(iri, { params });
        yield put({
            type: entityChallengesActions.FETCH_ENTITY_CHALLENGE_SUCCESSFUL
        });
        yield put({
            type: entityChallengesActions.STORE_ENTITY_CHALLENGE_DATA,
            iri,
            response: dto(fetchSingleEntityChallengeAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: entityChallengesActions.FETCH_ENTITY_CHALLENGE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Entity Data
 * @param  {Object} promise
 * @param {string} entity Iri
 * @return {Object} response from API
 */
export function* fetchSingleEntityData({ promise, iri, params }) {
    try {
        const fetchSingleEntityDataAPI = yield restClient.get(iri, { params });
        yield put({
            type: entityChallengesActions.FETCHING_ENTITY_DATA_SUCCESSFUL,
            iri,
            response: dto(fetchSingleEntityDataAPI?.data)?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: entityChallengesActions.FETCHING_ENTITY_DATA_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Single Entity Challenge data
 * @param  {String} ActivityChallenge
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* updateSingleEntityChallenge({ promise, formData, iri }) {
    try {
        const updateSingleEntityChallengeAPI = yield restClient.put(iri, formData);
        yield put({
            type: entityChallengesActions.UPDATE_ENTITY_CHALLENGE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleEntityChallengeAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: entityChallengesActions.UPDATE_ENTITY_CHALLENGE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update positions
 * @param  {Array} items
 * @return {Object} Response from API
 */
export function* updateEntityChallengePositions({ promise, data, endpoint }) {
    try {
        const updateQuestionPositionsAPI = yield restClient.patch(`${endpoint}/position`, data);
        yield put({
            type: entityChallengesActions.UPDATE_ENTITY_CHALLENGE_POSITIONS_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateQuestionPositionsAPI?.data
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: entityChallengesActions.UPDATE_ENTITY_CHALLENGE_POSITIONS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update positions
 * @param  {Array} items
 * @return {Object} Response from API
 */
export function* startBatchExport({ promise, endpoint, params, config }) {
    try {
        const exportedData = yield restClient.get(`${endpoint}`, {
            params,
            ...config
        });

        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: {
                code: 'batchExport.successful'
            }
        });

        yield call(promise.resolve, exportedData?.data);
    } catch (error) {
        yield put({
            type: entityChallengesActions.UPDATE_ENTITY_CHALLENGE_POSITIONS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export const entityChallengesSaga = [
    takeLatest(entityChallengesActions.START_DELETE_ENTITY_CHALLENGE, deleteSingleEntityChallenge),
    takeLatest(entityChallengesActions.START_CREATE_ENTITY_CHALLENGE, createEntityChallenge),
    takeLatest(entityChallengesActions.START_FETCHING_ENTITY_CHALLENGE, fetchSingleEntityChallenge),
    takeLatest(entityChallengesActions.START_UPDATE_ENTITY_CHALLENGE, updateSingleEntityChallenge),
    takeLatest(
        entityChallengesActions.START_UPDATE_ENTITY_CHALLENGE_POSITIONS,
        updateEntityChallengePositions
    ),
    takeLatest(entityChallengesActions.START_FETCHING_ENTITY_DATA, fetchSingleEntityData),
    takeLatest(entityChallengesActions.START_BATCH_EXPORT, startBatchExport)
];
