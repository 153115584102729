import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageLoader from 'erpcore/components/PageLoader';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import Comments from 'erpcore/components/Comments';
import Button from 'erpcore/components/Button';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import PageContent from 'erpcore/components/Layout/PageContent';
import CustomersEditTabs from 'erpcore/screens/Customers/components/CustomersEditTabs';
import { actions as customersActions } from 'erpcore/screens/Customers/Customers.reducer';
import {
    getCustomerData,
    getSingleCustomerFetching
} from 'erpcore/screens/Customers/Customers.selectors';
import { dtoForm } from 'erpcore/utils/dto';

const CustomerComments = ({ match }) => {
    const dispatch = useDispatch();
    const customerIri = `/api/companies/${match?.params?.id}`;
    const customerData = dtoForm(useSelector(state => getCustomerData(state, customerIri)));
    const fetching = useSelector(getSingleCustomerFetching);

    const pageTitle = () => {
        if (customerData?.name) {
            return `Customer - ${customerData.name}`;
        }

        return `Customer`;
    };

    const fetchCustomerData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: customersActions.START_FETCHING_CUSTOMER,
                iri: customerIri,
                promise: { resolve, reject }
            });
        });
    };

    useEffect(() => {
        fetchCustomerData();
    }, []);

    return (
        <LayoutManager className="main--narrow" slot="main" layoutType="merge">
            <HeadMeta title={pageTitle()} />
            <PageHeader title={pageTitle()}>
                <Button href="/companies/create" label="Create customer" />
            </PageHeader>
            <CustomersEditTabs />
            {fetching === true && <PageLoader />}
            <PageContent>
                <Comments entity="companies" />
            </PageContent>
        </LayoutManager>
    );
};

CustomerComments.defaultProps = {
    match: {}
};

CustomerComments.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object])
};

export default CustomerComments;
