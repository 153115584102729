export const actions = {
    START_FETCHING_CHALLENGES: 'START_FETCHING_CHALLENGES',
    FETCHING_SUCCESSFUL_CHALLENGES: 'FETCHING_SUCCESSFUL_CHALLENGES',
    FETCHING_FAILED_CHALLENGES: 'FETCHING_FAILED_CHALLENGES',

    START_DELETE_CHALLENGE: 'START_DELETE_CHALLENGE',
    DELETE_CHALLENGE_SUCCESSFUL: 'DELETE_CHALLENGE_SUCCESSFUL',
    DELETE_CHALLENGE_FAILED: 'DELETE_CHALLENGE_FAILED',

    START_CREATE_CHALLENGE: 'START_CREATE_CHALLENGE',
    CREATE_CHALLENGE_SUCCESSFUL: 'CREATE_CHALLENGE_SUCCESSFUL',
    CREATE_CHALLENGE_FAILED: 'CREATE_CHALLENGE_FAILED',

    START_FETCHING_CHALLENGE: 'START_FETCHING_CHALLENGE',
    FETCH_CHALLENGE_SUCCESSFUL: 'FETCH_CHALLENGE_SUCCESSFUL',
    FETCH_CHALLENGE_FAILED: 'FETCH_CHALLENGE_FAILED',
    STORE_CHALLENGE_DATA: 'STORE_CHALLENGE_DATA',

    START_UPDATE_CHALLENGE: 'START_UPDATE_CHALLENGE',
    UPDATE_CHALLENGE_SUCCESSFUL: 'UPDATE_CHALLENGE_SUCCESSFUL',
    UPDATE_CHALLENGE_FAILED: 'UPDATE_CHALLENGE_FAILED',

    START_FETCHING_CHALLENGE_TYPES: 'START_FETCHING_CHALLENGE_TYPES',
    FETCHING_SUCCESSFUL_CHALLENGE_TYPES: 'FETCHING_SUCCESSFUL_CHALLENGE_TYPES',
    FETCHING_FAILED_CHALLENGE_TYPES: 'FETCHING_FAILED_CHALLENGE_TYPES'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: [],
    singleFetching: false,
    singleError: null,
    deleteSingleError: null,
    challengeTypes: [],
    challengeTypesFetching: false
};

export default (state = initialState, { type, response, iri }) => {
    switch (type) {
        case actions.START_FETCHING_CHALLENGES: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.START_FETCHING_CHALLENGE: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCH_CHALLENGE_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.STORE_CHALLENGE_DATA: {
            return { ...state, [iri]: response?.data };
        }
        case actions.FETCH_CHALLENGE_FAILED: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCHING_SUCCESSFUL_CHALLENGES: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_CHALLENGES: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.DELETE_CHALLENGE_FAILED: {
            return {
                ...state,
                deleteSingleError: response
            };
        }

        case actions.UPDATE_CHALLENGE_FAILED: {
            return {
                ...state,
                singleError: response
            };
        }
        case actions.START_FETCHING_CHALLENGE_TYPES: {
            return {
                ...state,
                challengeTypesFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_CHALLENGE_TYPES: {
            return {
                ...state,
                challengeTypesFetching: false,
                challengeTypes: response
            };
        }
        case actions.FETCHING_FAILED_CHALLENGE_TYPES: {
            return {
                ...state,
                challengeTypesFetching: false
            };
        }

        default:
            return state;
    }
};
