import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from 'erpcore/components/Button';
import Modal from 'erpcore/components/Modal';
import { actions as customerContactsActions } from 'erpcore/screens/Customers/screens/CustomerContacts/CustomerContacts.reducer';
import CustomerContactsCreateEditForm from 'erpcore/screens/Customers/components/CustomerContactsCreateEditForm';
import PropTypes from 'prop-types';

const CustomerContactsCreate = ({ customerIri, callback }) => {
    const dispatch = useDispatch();
    const [opened, setOpened] = useState(false);

    const initialValues = {
        company: customerIri,
        phone: '+1'
    };

    const handleModal = () => {
        setOpened(!opened);
    };

    const onSubmit = formData => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: customerContactsActions.START_CREATE_CUSTOMER_CONTACT,
                formData,
                companyIri: customerIri
            })
        )
            .then(() => {
                handleModal();
                callback();
            })
            .catch(error => {
                handleModal();
                return error;
            });
    };

    return (
        <Fragment>
            <Button onClick={handleModal} size="small" variation="secondary" label="Add new" />
            <Modal
                variation="medium"
                opened={opened}
                onClose={() => handleModal()}
                title="Create Contact"
            >
                <CustomerContactsCreateEditForm
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    submitLabel="Create"
                />
            </Modal>
        </Fragment>
    );
};

CustomerContactsCreate.defaultProps = {
    customerIri: null,
    callback: () => {}
};

CustomerContactsCreate.propTypes = {
    customerIri: PropTypes.string,
    callback: PropTypes.func
};

export default CustomerContactsCreate;
