import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'erpcore/components/Modal';
import {
    getBulkActionsModalIsOpened,
    getListingResponse,
    getBulkActionsSelectAllEntries,
    getBulkActionsIris
} from 'erpcore/components/Listing/Listing.selectors';
import { formatNumber } from 'erpcore/utils/utils';

const BulkActionsModal = ({ children, handleModal, title, modalVariation, reducerName }) => {
    const isOpened = useSelector(state => getBulkActionsModalIsOpened(state, reducerName));
    const listingData = useSelector(state => getListingResponse(state, reducerName));
    const bulkActionsIris = useSelector(state => getBulkActionsIris(state, reducerName));
    const selectAllEntries = useSelector(state =>
        getBulkActionsSelectAllEntries(state, reducerName)
    );

    let selectedEntries = 0;
    if (selectAllEntries) {
        const { meta } = { ...listingData };
        selectedEntries = meta?.totalItems || 0;
    } else {
        selectedEntries = bulkActionsIris?.length || 0;
    }

    return (
        <Modal
            className="bulk-actions__modal"
            variation={modalVariation}
            opened={isOpened}
            onClose={() => handleModal()}
            title={title}
        >
            {children}
            <p>
                <em>
                    This will affect <strong>{formatNumber(selectedEntries)} entries</strong>.
                </em>
            </p>
        </Modal>
    );
};

BulkActionsModal.defaultProps = {
    children: null,
    handleModal: () => {},
    title: null,
    modalVariation: 'small'
};

BulkActionsModal.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    handleModal: PropTypes.func,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    modalVariation: PropTypes.string,
    reducerName: PropTypes.string.isRequired
};

export default BulkActionsModal;
