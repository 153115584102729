import React, { Component } from 'react';
import { connect } from 'react-redux';
import unionWith from 'lodash/unionWith';
import debounce from 'lodash/debounce';
import dto, { formatSelectDataForIncluded } from 'erpcore/utils/dto';
import PropTypes from 'prop-types';
import AsyncCreatableSelect from 'react-select/async-creatable';
import restClient from 'erpcore/api/restClient';
import ElementLoader from 'erpcore/components/ElementLoader';
import {
    DropdownIndicator,
    ClearIndicator,
    selectPropsMapper
} from 'erpcore/components/Form/components/Select';
import { getFormValues, change } from 'redux-form';
import { getOptionsByValues } from 'erpcore/components/Form/Form.utils';
import styles from '../Creatable/Creatable.styles';
import Input from '../Input';
import '../Select/Select.scss';

class SelectDropdown extends Component {
    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            labelActive: false,
            loading: false,
            options: [],
            menuIsOpen: false
        };

        this.creatableRef = React.createRef();
        this.updateInput = this.updateInput.bind(this);
        this.pushOptions = this.pushOptions.bind(this);
        this.syncIncluded = this.syncIncluded.bind(this);
    }

    setLoading(loading) {
        this.setState({ loading });
    }

    setMenuVisibility(open = true) {
        this.setState({ menuIsOpen: open });
    }

    /**
     *
     * @param collection
     * @return {Array}
     */
    getSelectedValues(collection = []) {
        const selectedValues = [];
        collection.forEach(item => {
            selectedValues.push(item.value);
        });

        return selectedValues;
    }

    /**
     *
     * @param data
     * @returns {*}
     */
    formatApiValue = data => {
        const { fieldProps } = this.props;
        const formattedOptions = [];
        data.map(item =>
            formattedOptions.push({
                value: item[fieldProps.options.mapData.value],
                label: item[fieldProps.options.mapData.label]
            })
        );
        return formattedOptions;
    };

    /**
     *
     * @param inputValue
     */
    loadOptions = inputValue => {
        const { fieldProps, input } = this.props;
        if (fieldProps.options.endpoint) {
            this.setLoading(true);
            return new Promise(resolve => {
                const searchQueryKey = fieldProps.options.searchQueryKey
                    ? fieldProps.options.searchQueryKey
                    : 'q';

                let { params } = fieldProps.options;
                if (inputValue) {
                    params = { [searchQueryKey]: inputValue, ...fieldProps.options.params };
                }
                if (input.value) {
                    params = { selected: input.value, ...params };
                }

                // temporary fix until backend fixes search rank
                if (params) {
                    params.limit = 20;
                } else {
                    params = { limit: 20 };
                }

                // Do a API call
                restClient
                    .get(fieldProps.options.endpoint, {
                        params
                    })
                    .then(response => {
                        response.data = dto(response.data);
                        const formatedOptions = this.formatApiValue(response.data.data);
                        this.pushOptions(formatedOptions);
                        this.setLoading(false);
                        return resolve(formatedOptions);
                    })
                    .catch(() => resolve());
            });
        }

        return null;
    };

    // eslint-disable-next-line react/sort-comp
    delayedLoadOptions = debounce((inputValue, callback) => {
        this.loadOptions(inputValue)
            .then(result => {
                callback(result);
            })
            .catch(error => callback(error, null));
    }, 350);

    pushOptions(formatedOptions) {
        const { options } = this.state;
        const archivedOptions = unionWith(options, formatedOptions, (a, b) => a.value === b.value);
        this.setState({ options: archivedOptions });
    }

    syncIncluded(option) {
        const { formValues, dispatch, meta } = this.props;
        dispatch(
            change(
                meta.form,
                'included',
                formatSelectDataForIncluded([option], formValues.included)
            )
        );
    }

    updateInput(option) {
        const { input } = this.props;
        input.onChange(option);
    }

    render() {
        const { input, fieldProps, fieldAttr, meta, field } = this.props;
        const { labelActive, options, loading, menuIsOpen } = this.state;

        const { menuPlacement } = fieldProps;

        fieldProps.forceLabelActive = labelActive;

        //  Getting select required value from options
        const fieldValue = getOptionsByValues(input.value, options);

        //  Standardizing props for select
        const mappedConf = selectPropsMapper(fieldProps, fieldAttr);

        return (
            <Input
                fieldProps={fieldProps}
                fieldAttr={fieldAttr}
                field={field}
                input={input}
                meta={meta}
            >
                {loading && (
                    <ElementLoader
                        className={`react-select__loader${
                            fieldAttr.disabled ? ' react-select__loader--disabled' : ''
                        }`}
                    />
                )}
                <AsyncCreatableSelect
                    isMulti
                    ref={this.creatableRef}
                    styles={styles}
                    components={{ DropdownIndicator, ClearIndicator }}
                    onChange={(selectedOptions, actionMeta) => {
                        if (actionMeta && actionMeta.action === 'create-option') {
                            this.setLoading(true);
                            const newOptionLabel =
                                selectedOptions[selectedOptions.length - 1].value;
                            restClient
                                .post(fieldProps.options.endpoint, {
                                    name: newOptionLabel
                                })
                                .then(response => {
                                    const createdOption = {
                                        value: response.data.data.id,
                                        label: response.data.data.attributes.name
                                    };
                                    this.syncIncluded(createdOption);
                                    options.push(createdOption);
                                    this.pushOptions(options);
                                    // remove react-select added option
                                    selectedOptions.pop();
                                    // add API fetched option
                                    selectedOptions.push(createdOption);
                                    // get values from all selected options
                                    const selectedValues = this.getSelectedValues(selectedOptions);
                                    // set input values
                                    input.onChange(selectedValues);
                                    this.setLoading(false);
                                });
                        } else if (selectedOptions && selectedOptions.length) {
                            this.syncIncluded(selectedOptions);
                            // options.push(selectedOptions);
                            this.pushOptions(options);
                            const selectedValues = this.getSelectedValues(selectedOptions);
                            input.onChange(selectedValues);
                        } else {
                            input.onChange([]);
                        }

                        this.setMenuVisibility(true);
                    }}
                    onInputChange={inputValue =>
                        inputValue !== ''
                            ? this.setState({ labelActive: true })
                            : this.setState({ labelActive: false })
                    }
                    onFocus={() => this.setState({ labelActive: true })}
                    placeholder=""
                    isLoading={loading}
                    className={`react-select react-select--multi${
                        loading ? ' react-select--loading' : ''
                    } ${menuPlacement === 'top' ? 'react-select--menu-top' : ''}`}
                    classNamePrefix="react-select"
                    isClearable
                    menuIsOpen={menuIsOpen}
                    onMenuOpen={() => this.setMenuVisibility(true)}
                    onMenuClose={() => this.setMenuVisibility(false)}
                    value={fieldValue}
                    name={input.name}
                    id={input.name}
                    {...mappedConf.fieldAttr}
                    {...mappedConf.fieldProps}
                    defaultOptions
                    loadOptions={this.delayedLoadOptions}
                />
            </Input>
        );
    }
}
SelectDropdown.defaultProps = {
    fieldProps: {},
    fieldAttr: {},
    field: {},
    input: {},
    meta: {},
    formValues: {}
};
SelectDropdown.propTypes = {
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    field: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object]),
    formValues: PropTypes.oneOfType([PropTypes.object]),
    dispatch: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => ({
    formValues: getFormValues(ownProps.meta.form)(state)
});

export default connect(mapStateToProps)(SelectDropdown);
