import React from 'react';
import Form, { Text, Textarea, Media, Creatable } from 'erpcore/components/Form';
import {
    reduxForm,
    Field,
    hasSubmitSucceeded,
    getFormValues,
    getFormInitialValues
} from 'redux-form';
import PropTypes from 'prop-types';
import Button from 'erpcore/components/Button';
import { valueValidation } from 'erpcore/components/Form/Form.utils';
import UnsavedChanges from 'erpcore/components/UnsavedChanges';
import { useSelector } from 'react-redux';
import { diff } from 'deep-object-diff';

const ActivitiesCreateEditForm = ({
    handleSubmit,
    onSubmit,
    pristine,
    invalid,
    submitting,
    submitLabel,
    onCancel,
    form
}) => {
    const hasSubmitted = useSelector(state => hasSubmitSucceeded(form)(state));
    const formValues = useSelector(state => getFormValues(form)(state));
    const initialValues = useSelector(state => getFormInitialValues(form)(state));

    const checkIfFormIsDirty = () => {
        const difference = diff(initialValues, formValues);
        const keys = Object.keys(difference);

        keys.forEach(key => {
            if (difference[key] === null || difference[key] === '') {
                delete difference[key];
            }
        });

        delete difference?.included;

        if (Object.keys(difference)?.length) {
            return true;
        }

        return false;
    };

    return (
        <Form
            onSubmit={handleSubmit(formData => onSubmit({ formData, preventEditRedirect: false }))}
        >
            <UnsavedChanges
                promptWhen={checkIfFormIsDirty() && !hasSubmitted && !submitting}
                onSubmit={handleSubmit(formData =>
                    onSubmit({ formData, preventEditRedirect: true })
                )}
            />
            <Form.Row>
                <Field
                    name="name"
                    fieldProps={{ label: 'Name' }}
                    fieldAttr={{ id: 'name', required: true }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="type"
                    fieldProps={{
                        label: 'Type',
                        options: {
                            endpoint: '/api/activity-types',
                            mapData: {
                                value: 'iri',
                                label: 'name'
                            }
                        }
                    }}
                    fieldAttr={{
                        id: 'type'
                    }}
                    component={Creatable}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="description"
                    fieldProps={{ label: 'Description' }}
                    fieldAttr={{ id: 'description', required: true }}
                    component={Textarea}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="image"
                    fieldProps={{ label: 'Image' }}
                    fieldAttr={{ id: 'image', required: true }}
                    component={Media}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="details_url"
                    fieldProps={{
                        label: 'Resource center',
                        helperText:
                            'Webpage with all their event-specific information, which includes the challenge list with and without answers'
                    }}
                    fieldAttr={{ id: 'details_url' }}
                    component={Text}
                />
            </Form.Row>
            <Button
                submit
                disabled={pristine || invalid}
                loading={submitting}
                label={submitLabel}
            />
            {onCancel && <Button variation="secondary" label="Cancel" onClick={() => onCancel()} />}
        </Form>
    );
};

ActivitiesCreateEditForm.defaultProps = {
    submitting: false,
    pristine: false,
    invalid: false,
    submitLabel: null,
    onCancel: null,
    form: 'ActivitiesCreateEditForm'
};

ActivitiesCreateEditForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
    submitLabel: PropTypes.string,
    onCancel: PropTypes.func,
    form: PropTypes.string
};

export default reduxForm({ form: 'ActivitiesCreateEditForm', enableReinitialize: true })(
    ActivitiesCreateEditForm
);
