import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import './ShowingResults.scss';

const defaultPerPageOptions = [10, 50, 100, 'All results'];

const ShowingResults = ({ meta, onChangeResultsPerPage, perPageOptions }) => {
    const fullOptions = useMemo(() => {
        return perPageOptions?.length ? perPageOptions : defaultPerPageOptions;
    }, [perPageOptions, meta.totalItems]);

    const reducedOptions = useMemo(() => {
        return fullOptions.reduce((accumulator, currentValue, i) => {
            if (i === 0) {
                return [currentValue];
            }

            if (meta.totalItems > currentValue || currentValue === 'All results') {
                return [...accumulator, currentValue];
            }

            return accumulator;
        }, []);
    }, [fullOptions, meta.totalItems]);

    // Do not display block if there are less than 10 results
    if ((meta.totalItems && meta.totalItems <= 10) || !meta.totalItems) {
        return null;
    }

    return (
        <div className="showing-results">
            Showing
            <div className="showing-results__per-page">
                {reducedOptions.map(option => (
                    <button
                        key={option}
                        onClick={() => {
                            if (option !== 'All results')
                                onChangeResultsPerPage({
                                    limit: option,
                                    page: 1,
                                    pagination: true
                                });
                            else
                                onChangeResultsPerPage({
                                    pagination: false,
                                    page: 1,
                                    limit: undefined
                                });
                        }}
                        type="button"
                        className={`showing-results__per-page-button${
                            String(meta.itemsPerPage) === String(option) ||
                            (meta.itemsPerPage === undefined && option === 'All results')
                                ? ' showing-results__per-page-button--active'
                                : ''
                        }`}
                    >
                        {option}
                    </button>
                ))}
            </div>
            <span className="showing-results__per-page-text">results out of {meta.totalItems}</span>
        </div>
    );
};

ShowingResults.defaultProps = {
    onChangeResultsPerPage: () => {},
    meta: {},
    perPageOptions: null
};

ShowingResults.propTypes = {
    onChangeResultsPerPage: PropTypes.func,
    meta: PropTypes.oneOfType([PropTypes.object]),
    perPageOptions: PropTypes.oneOfType([PropTypes.array])
};

export default ShowingResults;
