import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import map from 'lodash/map';
import isFinite from 'lodash/isFinite';
import moment from 'moment-timezone';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageLoader from 'erpcore/components/PageLoader';
import { actions as employmentRecordsActions } from 'erpcore/screens/Users/screens/UserEmploymentRecords/UserEmploymentRecords.reducer';
import { actions as usersActions } from 'erpcore/screens/Users/Users.reducer';
import { getSingleUserFetching, getUserData } from 'erpcore/screens/Users/Users.selectors';
import {
    getEmploymentRecordsListingFetching,
    getEmploymentRecordsPerUser
} from 'erpcore/screens/Users/screens/UserEmploymentRecords/UserEmploymentRecords.selectors';
import EmploymentRecordForm from 'erpcore/screens/Users/components/EmploymentRecordForm';
import UserEditTabs from 'erpcore/screens/Users/components/UserEditTabs';
import UserEditPageHeader from 'erpcore/screens/Users/components/UserEditPageHeader';
import Button from 'erpcore/components/Button';
import Accordion from 'erpcore/components/Accordion';
import EmploymentHistoryRecord from 'erpcore/screens/Users/components/EmploymentHistoryRecord';
import ModalEmploymentRecordEdit from 'erpcore/screens/Users/components/ModalEmploymentRecordEdit';
import Form from 'erpcore/components/Form';
import { SubmissionError } from 'redux-form';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';
import { dtoIgnoreTimezone } from 'erpcore/utils/utils';

const UserEmploymentRecords = ({ match }) => {
    const dispatch = useDispatch();
    const [activeModalIri, setActiveModalIri] = useState(false);
    const userIri = `/api/users/${match?.params?.id}`;
    const userEmploymentRecords = useSelector(state => getEmploymentRecordsPerUser(state, userIri));
    const userEmploymentRecordsFetching = useSelector(state =>
        getEmploymentRecordsListingFetching(state)
    );
    const userDataFetching = useSelector(state => getSingleUserFetching(state));
    const userData = useSelector(state => getUserData(state, userIri)) || {};

    const getValueIfNotDeleted = (entity = null) => {
        if (entity && !entity?.deleted) {
            return entity?.iri;
        }

        return undefined;
    };

    const { reports_to: initialReportsTo, job_title: initialJobTitle } = { ...userData };

    const initialAddValues = {
        user: userIri,
        start_date: moment().format(),
        job_title: getValueIfNotDeleted(initialJobTitle),
        salary: {
            amount: userData?.salary?.amount ? userData.salary.amount : null,
            currency: userData?.salary?.currency || 'CAD'
        },
        salary_type: userData.salary_type,
        salary_payout_cycle: userData.salary_payout_cycle,
        employment_type: userData.employment_type,
        office: getValueIfNotDeleted(userData.office),
        division: getValueIfNotDeleted(userData.division),
        department: getValueIfNotDeleted(userData.department),
        reports_to: getValueIfNotDeleted(initialReportsTo)
    };

    const pageTitle = () => {
        const { first_name: firstName, last_name: lastName } = { ...userData };
        if (firstName || lastName) {
            return `Edit user - ${firstName} ${lastName}`;
        }

        return `Edit user`;
    };

    const fetchUserData = () => {
        const params = {
            include: 'department,jobTitle,office,division,reportsTo'
        };
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: usersActions.START_FETCHING_SINGLE_USER,
                iri: userIri,
                params
            });
        }).catch(error => ({ error }));
    };

    const fetchUserEmploymentRecords = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: employmentRecordsActions.START_FETCHING_EMPLOYMENT_RECORDS_PER_USER,
                userIri
            });
        }).catch(error => ({ error }));
    };

    const handleModal = iri => {
        setActiveModalIri(iri);
    };

    const onSubmitNewRecord = formData => {
        // send ALL data (including initial data)

        if (formData.included) {
            delete formData.included;
        }

        if (formData.start_date === '') {
            formData.start_date = null;
        }

        if (formData.end_date === '') {
            formData.end_date = null;
        }

        const noNullAllowedKeys = ['job_title', 'office', 'division', 'department', 'reports_to'];

        noNullAllowedKeys.forEach(key => {
            if (formData[key] === null || formData[key] === undefined) {
                delete formData[key];
            }
        });

        if (formData?.salary?.amount) {
            if (!isFinite(formData.salary.amount)) {
                // if is not type Number
                formData.salary.amount = parseInt(formData.salary.amount.replace(/[^\d]/g, ''), 10);
            }
        }

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: employmentRecordsActions.START_CREATE_EMPLOYMENT_RECORD,
                formData
            })
        )
            .then(() => {
                // fetchUserData();
                fetchUserEmploymentRecords();
            })
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    };

    /*
     * Effects
     */
    useEffect(() => {
        fetchUserData();
        fetchUserEmploymentRecords();
    }, [userIri]);

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <UserEditPageHeader pageTitle={pageTitle()} />
            <UserEditTabs />
            <PageContent>
                {(!!userDataFetching || !!userEmploymentRecordsFetching) && <PageLoader content />}
                {!!userEmploymentRecords?.data && !!userEmploymentRecords?.data.length && (
                    <>
                        <Form.SectionTitle>Employment history</Form.SectionTitle>
                        {!!userEmploymentRecords?.data && (
                            <Accordion variation="grey">
                                {map(userEmploymentRecords?.data, item => {
                                    const {
                                        iri,
                                        start_date: startDateTemp,
                                        end_date: endDateTemp,
                                        job_title: jobTitle,
                                        employment_type: employmentType,
                                        salary_type: salaryType,
                                        salary_payout_cycle: salaryPayoutCycle,
                                        reports_to: reportsTo,
                                        comment,
                                        contract
                                    } = { ...item };

                                    const startDate = dtoIgnoreTimezone(startDateTemp);
                                    const endDate = dtoIgnoreTimezone(endDateTemp);

                                    const title = `${moment(startDate).format('Do MMM YYYY')}${
                                        endDate ? ` - ${moment(endDate).format('Do MMM YYYY')}` : ''
                                    }${jobTitle?.name ? `, ${jobTitle?.name}` : ''}`;

                                    return (
                                        <Accordion.Item
                                            key={iri}
                                            title={title}
                                            variation="thin"
                                            headerActions={
                                                <Button
                                                    className="accordion-item__header-actions-button"
                                                    iconName="edit"
                                                    label="Edit history record"
                                                    labelOnlyAria
                                                    onClick={() => handleModal(iri)}
                                                    variation="tertiary"
                                                    size="small"
                                                />
                                            }
                                            content={
                                                <EmploymentHistoryRecord
                                                    jobTitle={jobTitle}
                                                    employmentType={employmentType}
                                                    salary={item?.salary}
                                                    salaryType={salaryType}
                                                    salaryPayoutCycle={salaryPayoutCycle}
                                                    office={item?.office}
                                                    division={item?.division}
                                                    department={item?.department}
                                                    reportsTo={reportsTo}
                                                    comment={comment}
                                                    contract={contract}
                                                />
                                            }
                                        />
                                    );
                                })}
                            </Accordion>
                        )}
                    </>
                )}

                <EmploymentRecordForm
                    onSubmit={onSubmitNewRecord}
                    form="EmploymentRecordFormAdd"
                    title="Add new record"
                    subtitle={
                        <p>
                            Employee record is referencing employment terms active during a period
                            of time. Only one employee record can be active at any given point in
                            time.
                            <br />
                            Any Active Employment records will automatically close with End Date
                            applied day before starting a new record.
                            <br />
                            <br />
                        </p>
                    }
                    initialValues={initialAddValues}
                    submitLabel="Add"
                    userId={match?.params?.id}
                />

                <ModalEmploymentRecordEdit
                    activeModalIri={activeModalIri}
                    runAfterUpdate={() => {
                        // fetchUserData();
                        fetchUserEmploymentRecords();
                        return false;
                    }}
                    closeModal={() => handleModal(false)}
                    userIri={userIri}
                />
            </PageContent>
        </LayoutManager>
    );
};

UserEmploymentRecords.defaultProps = {
    match: {}
};

UserEmploymentRecords.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object])
};

export default withRouter(UserEmploymentRecords);
