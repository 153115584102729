import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as entityContentActions } from 'erpcore/screens/EntityContent/EntityContent.reducer';
import PropTypes from 'prop-types';
import PageLoader from 'erpcore/components/PageLoader';
import './EntityContent.scss';
import {
    getEntityData,
    getEntityDataFetching
} from 'erpcore/screens/Challenges/screens/EntityChallenges/EntityChallenges.selectors';
import EntityContentForm from 'erpcore/screens/EntityContent/components/EntityContentForm';

const EntityContent = ({ resource, entityIri, callback }) => {
    const dispatch = useDispatch();
    const entityData = useSelector(state => getEntityData(state, entityIri, resource));
    const entityContentFetching = useSelector(state => getEntityDataFetching(state, resource));

    const initialValues = useMemo(
        () => ({
            contents:
                entityData?.contents
                    ?.sort((a, b) => a.position - b.position)
                    ?.map(entityContent => {
                        return {
                            title: entityContent?.title,
                            id: entityContent?.id,
                            iri: entityContent?.iri,
                            content_items:
                                entityContent?.items
                                    ?.sort((a, b) => a.position - b.position)
                                    ?.map(item => {
                                        return {
                                            title: item?.title,
                                            id: item?.id,
                                            iri: item?.iri,
                                            description: item?.description,
                                            media:
                                                item?.media?.map(
                                                    ({
                                                        description = null,
                                                        id: mediaId,
                                                        iri: mediaIri,
                                                        media,
                                                        youtube_u_r_l
                                                    }) => {
                                                        return {
                                                            description,
                                                            id: mediaId,
                                                            iri: mediaIri,
                                                            media: media?.iri,
                                                            youtube_u_r_l
                                                        };
                                                    }
                                                ) || []
                                        };
                                    }) || []
                        };
                    }) || []
        }),
        [entityData]
    );

    const onSubmit = useCallback(
        formData => {
            const { contents = [] } = { ...formData };

            return new Promise((resolve, reject) => {
                dispatch({
                    type: entityContentActions.START_UPDATE_ENTITY_CONTENT,
                    iri: entityIri,
                    promise: { resolve, reject },
                    config: {
                        headers: {
                            'Content-Type': 'application/ld+json'
                        }
                    },
                    formData: {
                        contents: contents.map(
                            (
                                {
                                    iri: entityContentItemIri = undefined,
                                    title = null,
                                    content_items: items = []
                                },
                                index
                            ) => ({
                                '@id': entityContentItemIri,
                                title,
                                position: index,
                                items: items.map(
                                    (
                                        {
                                            title: entityContentItemMediaTitle,
                                            iri: entityContentItemMediaIri = undefined,
                                            description = null,
                                            media = []
                                        },
                                        entityContentIndex
                                    ) => ({
                                        '@id': entityContentItemMediaIri,
                                        title: entityContentItemMediaTitle,
                                        position: entityContentIndex,
                                        media: media.map(
                                            (
                                                {
                                                    description: mediaDescription = null,
                                                    media: mediaIri,
                                                    youtube_u_r_l
                                                },
                                                entityContentMediaIndex
                                            ) => ({
                                                description: mediaDescription,
                                                media: mediaIri,
                                                position: entityContentMediaIndex,
                                                youtube_u_r_l
                                            })
                                        ),
                                        description
                                    })
                                )
                            })
                        )
                    }
                });
            })
                .then(() => {
                    if (callback) callback();
                })
                .catch(err => err);
        },
        [entityIri]
    );

    return (
        <div className="entity__content">
            {entityContentFetching && <PageLoader />}

            <EntityContentForm initialValues={initialValues} onSubmit={onSubmit} />
        </div>
    );
};

EntityContent.defaultProps = {
    callback: null
};

EntityContent.propTypes = {
    resource: PropTypes.string.isRequired,
    entityIri: PropTypes.string.isRequired,
    callback: PropTypes.func
};

export default EntityContent;
