import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { diff } from 'deep-object-diff';
import Button from 'erpcore/components/Button';
import CustomerContactsCreateEditForm from 'erpcore/screens/Customers/components/CustomerContactsCreateEditForm';
import Modal from 'erpcore/components/Modal';
import Tooltip from 'erpcore/components/Tooltip';
import { actions as customerContactsActions } from 'erpcore/screens/Customers/screens/CustomerContacts/CustomerContacts.reducer';

const CustomerContactsEdit = ({ initialValues, id, callback }) => {
    const dispatch = useDispatch();
    const [opened, setOpened] = useState(false);
    const contactIri = `/api/contacts/${id}`;

    const handleModal = () => {
        setOpened(!opened);
    };

    const onSubmit = formData => {
        formData = diff(initialValues, formData);

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: customerContactsActions.START_UPDATE_CUSTOMER_CONTACT,
                formData,
                iri: contactIri,
                companyIri: initialValues.company
            })
        )
            .then(() => {
                handleModal();
                callback();
            })
            .catch(error => {
                return error;
            });
    };

    return (
        <Fragment>
            <Tooltip content="Edit contact">
                <Button
                    type="button"
                    variation="table-action"
                    iconName="edit"
                    label=""
                    onClick={() => handleModal()}
                />
            </Tooltip>
            <Modal
                variation="medium"
                opened={opened}
                onClose={() => handleModal()}
                title="Edit contact"
            >
                <CustomerContactsCreateEditForm
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    submitLabel="Update"
                    form={`CustomerContactsEditForm-${contactIri}`}
                />
            </Modal>
        </Fragment>
    );
};

CustomerContactsEdit.defaultProps = {
    initialValues: {},
    callback: () => {}
};

CustomerContactsEdit.propTypes = {
    initialValues: PropTypes.oneOfType([PropTypes.object]),
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    callback: PropTypes.func
};

export default CustomerContactsEdit;
