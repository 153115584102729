export const actions = {
    START_FETCHING_WIDGET_EVENTS: 'START_FETCHING_WIDGET_EVENTS',
    FETCHING_SUCCESSFUL_WIDGET_EVENTS: 'FETCHING_SUCCESSFUL_WIDGET_EVENTS',
    FETCHING_FAILED_WIDGET_EVENTS: 'FETCHING_FAILED_WIDGET_EVENTS'
};

export default (state = {}, { type }) => {
    switch (type) {
        default:
            return state;
    }
};
