import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
    EditorState,
    convertToRaw,
    ContentState,
    SelectionState,
    Modifier,
    RichUtils
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import './TextEditor.scss';
import restClient from 'erpcore/api/restClient';
import dto from 'erpcore/utils/dto';

export const projectDefaultToolbar = {
    options: ['inline', 'fontSize', 'list', 'link', 'emoji', 'image'],
    inline: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['bold', 'italic', 'underline']
    },
    image: {
        className: undefined,
        component: undefined,
        popupClassName: undefined,
        urlEnabled: true,
        uploadEnabled: true,
        alignmentEnabled: true,
        uploadCallback: async image => {
            const formData = new FormData();
            formData.append('file', image);

            return new Promise(async (resolve, reject) => {
                try {
                    const request = await restClient.post(`api/media-objects`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });

                    const { data: responseData } = request;

                    if (responseData) {
                        const mediaObject = {
                            ...dto(responseData).data
                        };

                        resolve({
                            data: { link: mediaObject.content_url }
                        });
                    }
                } catch (error) {
                    reject(error?.response?.data || error);
                }
            });
        },
        previewImage: true,
        inputAccept: 'image/gif,image/jpeg,image/jpg,image/png',
        alt: { present: false, mandatory: false },
        defaultSize: {
            height: 'auto',
            width: '250'
        }
    }
};

const TextEditor = ({ meta, fieldProps, fieldAttr, input, useProjectDefaultToolbar }) => {
    const [initialized, setInitialized] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty()); // passed to <Editor />
    const editorStateRef = useRef(editorState); // is in sync with editorState. Use this variable for accessing editor state
    const metaInitialRef = useRef(null);

    const { label } = { ...fieldProps };

    const toolbar =
        fieldProps?.toolbar || useProjectDefaultToolbar
            ? {
                  ...(fieldProps?.toolbar ? fieldProps?.toolbar : null),
                  ...(useProjectDefaultToolbar ? projectDefaultToolbar : null)
              }
            : true;

    const getEditorStateFromValue = value => {
        const blocksFromHtml = htmlToDraft(value || '');
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        return EditorState.createWithContent(contentState);
    };

    const getInputValueFromEditorState = state => {
        if (!state) {
            return '';
        }

        let inputValue = draftToHtml(convertToRaw(state.getCurrentContent()));
        if (String(inputValue).trim() === String('<p></p>').trim()) {
            inputValue = '';
        }
        return inputValue;
    };

    /**
     * Function for clearing content from editor,
     * createEmpty() method should be used only on initialized
     * for issues with caret position, not applicable here
     *
     * https://stackoverflow.com/questions/37463757/how-to-clear-input-field-in-draft-js
     */
    const handleEmptyContent = () => {
        let emptyEditorState = editorState;
        let contentState = emptyEditorState.getCurrentContent();
        const firstBlock = contentState.getFirstBlock();
        const lastBlock = contentState.getLastBlock();
        const allSelected = new SelectionState({
            anchorKey: firstBlock.getKey(),
            anchorOffset: 0,
            focusKey: lastBlock.getKey(),
            focusOffset: lastBlock.getLength(),
            hasFocus: false
        });
        contentState = Modifier.removeRange(contentState, allSelected, 'backward');
        emptyEditorState = EditorState.push(emptyEditorState, contentState, 'remove-range');
        setEditorState(emptyEditorState);
    };

    // <Editor /> content is propagated to input/redux
    // UPDATE DIRECTION: EDITOR -> REDUX
    const onEditorStateChange = newEditorState => {
        setEditorState(newEditorState);
        editorStateRef.current = newEditorState; // even though this ref is in sync with state, it must be updated immediately to be ready for the equal checks on the next tick

        const reduxFormValue = getInputValueFromEditorState(newEditorState);

        input.onChange(reduxFormValue);
    };

    // set editor state from initialValues
    // UPDATE DIRECTION: INITIAL VALUES -> EDITOR
    useEffect(() => {
        if (!initialized) {
            if (meta?.initial && meta?.initial !== metaInitialRef?.current) {
                setEditorState(getEditorStateFromValue(meta?.initial));
            } else {
                handleEmptyContent();
            }
            setInitialized(true);
        }
    }, [initialized, meta?.initial]);

    // set editor state from input/redux value
    // UPDATE DIRECTION: REDUX -> EDITOR
    useEffect(() => {
        // update editor state if input value is different from editor state.
        // input value and editor state ARE equal if change is triggered from <Editor />
        // input value and editor state are NOT equal if change is triggered from outside <Editor />
        if (
            initialized &&
            (input?.value || input?.value === '') &&
            input?.value !== getInputValueFromEditorState(editorStateRef?.current)
        ) {
            if (input.value) {
                setEditorState(getEditorStateFromValue(input.value));
            } else {
                handleEmptyContent();
            }
        }
    }, [input?.value, initialized]);

    useEffect(() => {
        editorStateRef.current = editorState;
    }, [editorState]);

    useEffect(() => {
        metaInitialRef.current = meta?.initial;
    }, [meta?.initial]);

    return (
        <div
            className={`text-editor${meta.touched && meta.error ? ` text-editor--error` : ''}${
                fieldAttr.required === true ? ' text-editor--required' : ''
            } ${fieldAttr?.disabled ? 'text-editor--disabled' : ''}`}
        >
            {label && (
                <div className="input--active">
                    <label htmlFor={input?.name || ''} className="input__label">
                        {label}
                    </label>
                </div>
            )}
            <Editor
                editorState={editorState}
                toolbarClassName="text-editor__toolbar"
                wrapperClassName="text-editor__wrapper"
                editorClassName="text-editor__editor"
                stripPastedStyles
                onEditorStateChange={onEditorStateChange}
                onFocus={() => input.onFocus()}
                onBlur={() => input.onBlur()}
                handleKeyCommand={command => {
                    // https://github.com/facebook/draft-js/issues/1510
                    // https://github.com/facebook/draft-js/issues/1681#issuecomment-371143376

                    const newState = RichUtils.handleKeyCommand(editorState, command);

                    if (newState) {
                        onEditorStateChange(newState);
                        // input.onChange(newState);
                        return 'handled';
                    }

                    return 'not-handled';
                }}
                readOnly={fieldAttr?.disabled}
                toolbar={toolbar}
            />
            {meta.touched && meta.error && (
                <span className="text-editor__error">
                    {meta.error.message ? meta.error.message : meta.error}
                </span>
            )}
        </div>
    );
};

TextEditor.defaultProps = {
    input: {},
    meta: {},
    fieldProps: {},
    fieldAttr: {},
    useProjectDefaultToolbar: true
};

TextEditor.propTypes = {
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object]),
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    useProjectDefaultToolbar: PropTypes.bool
};
export default TextEditor;
