import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { reset, SubmissionError } from 'redux-form';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageNotifications from 'erpcore/components/Layout/PageNotifications';
import CompanyCreateEditForm from 'erpcore/screens/Customers/components/CustomerCreateEditForm';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import PageContent from 'erpcore/components/Layout/PageContent';
import { actions as customerActions } from 'erpcore/screens/Customers/Customers.reducer';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';

import { setLocationData } from 'erpcore/components/Form/components/Location/dto';
import { getIdFromIri } from 'erpcore/utils/dto';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';

const CustomersCreate = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const title = 'Add new customer';
    const initialValues = {
        phone: '+1'
    };

    const onSubmitCreateCustomerForm = ({ formData, preventEditRedirect = false }) => {
        const { location } = { ...formData };

        if (location) {
            formData = setLocationData('location', formData);
        }

        return new Promise((resolve, reject) => {
            dispatch({
                type: customerActions.START_CREATE_CUSTOMER,
                promise: { resolve, reject },
                formData
            });
        })
            .then(response => {
                if (response?.data?.id && !preventEditRedirect) {
                    history.push(
                        `/companies/${getIdFromIri(response?.data?.id)}/edit/general-info`
                    );
                } else {
                    dispatch(reset('CustomersCreateForm'));
                }
            })
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    };

    return (
        <LayoutManager className="main--narrow" slot="main" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title} />
            <PageNotifications />
            <PageContent>
                <CompanyCreateEditForm
                    form="CustomersCreateForm"
                    onSubmit={onSubmitCreateCustomerForm}
                    submitLabel="Create"
                    initialValues={initialValues}
                />
            </PageContent>
        </LayoutManager>
    );
};

export default CustomersCreate;
