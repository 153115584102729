import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import LayoutManager from 'erpcore/utils/LayoutManager';
import EventsEditPageHeader from 'erpcore/screens/Events/screens/EventsEdit/components/EventEditPageHeader';
import SettingsForm from 'erpcore/screens/Events/screens/EventsEdit/screens/Settings/components/SettingsForm';
import PageContent from 'erpcore/components/Layout/PageContent';
import { useDispatch, useSelector } from 'react-redux';
import {
    getSingleEventData,
    getSendingEventWelcomeEmail
} from 'erpcore/screens/Events/Events.selectors';
import { actions as eventActions } from 'erpcore/screens/Events/Events.reducer';
import { insertIf, formatDate } from 'erpcore/utils/utils';
import { cleanRepeaterOutput } from 'erpcore/components/Form/components/Repeater/Repeater.jsx';
import Button from 'erpcore/components/Button';
import Tooltip from 'erpcore/components/Tooltip';
import Svg from 'erpcore/components/Svg';
import './Settings.scss';

const Settings = () => {
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const eventId = match?.params?.id;
    const iri = `/api/events/${eventId}`;

    const eventData = useSelector(state => getSingleEventData(state, iri));

    const sendingWelcomeEmail = useSelector(getSendingEventWelcomeEmail);
    const sendWelcomeEmail = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: eventActions.SEND_WELCOME_EMAIL,
                eventIri: iri,
                promise: { resolve, reject }
            });
        });
    };

    const {
        company,
        settings,
        activity,
        single_participant_team,
        number_of_participants,
        number_of_teams,
        meta
    } = { ...eventData };
    const { sendSummaryTo } = { ...settings };
    const getEventData = () => {
        const params = {
            include: 'activity,company,timezone,coordinators,image,media,participants,teams'
        };

        return new Promise((resolve, reject) => {
            dispatch({
                type: eventActions.START_FETCHING_EVENT,
                iri,
                promise: { resolve, reject },
                params
            });
        });
    };

    const customChallangesURL = !Array.isArray(settings?.custom_challenges_url)
        ? settings?.custom_challenges_url?.split(',')?.map(item => ({ id: item, name: item }))
        : [];

    const initialValues = {
        single_participant_team,
        number_of_participants,
        number_of_teams,
        settings: {
            teamSize: settings?.teamSize || settings?.teamSize === 0 ? settings?.teamSize : 1,
            leaderboardVisibility: eventData?.settings?.leaderboardVisibility || false,
            enableHelpRequest: eventData?.settings?.enableHelpRequest || false,
            emailAccess: eventData?.settings?.emailAccess || false,
            anonymize: eventData?.settings?.anonymize || false,
            video: eventData?.settings?.video || false,
            chat: eventData?.settings?.chat || false,
            wall: eventData?.settings?.wall || false,
            wallPasswordOption: eventData?.settings?.wallPasswordOption || false,
            wallPassword: eventData?.settings?.wallPassword || null,
            eventSummary: eventData?.settings?.eventSummary || false,
            sendSummaryAdditional:
                eventData?.settings?.sendSummaryTo?.length || company?.email
                    ? [
                          ...insertIf(eventData?.settings?.sendSummaryTo === undefined, [
                              { id: company?.email, name: company?.email }
                          ]),
                          ...(sendSummaryTo?.map(email => ({ id: email, name: email })) || [])
                      ]
                    : null,
            lockedChanges: eventData?.settings?.lockedChanges || false,
            validateLockedByChallenges: eventData?.settings?.validateLockedByChallenges || false,
            welcomeChatMessage:
                settings?.welcomeChatMessage === undefined
                    ? activity?.settings?.welcomeChatMessage
                    : settings?.welcomeChatMessage,
            chatMessage:
                settings?.chatMessage === undefined
                    ? activity?.settings?.chatMessage ||
                      `Welcome %companyName% to your %eventName%. %eventName% is fun engaging team building event where you and your team...`
                    : settings?.chatMessage,
            customerLogo: settings?.customerLogo,
            customerLogoInverted: settings?.customerLogoInverted,
            whitelabelLogo: settings?.whitelabelLogo,
            primaryColor: settings?.primaryColor,
            welcome_email_receivers: settings?.welcome_email_receivers || [],
            send_welcome_email: settings?.send_welcome_email || false,
            custom_challenges_url: customChallangesURL
        }
    };

    const onSubmit = formData => {
        const { sendSummaryAdditional = [], chatMessage, ...rest } = {
            ...formData?.settings
        };
        const formDataToSend = Object.assign({}, formData);
        formDataToSend.settings = {
            ...rest,
            chatMessage: rest?.welcomeChatMessage ? chatMessage : undefined,
            sendSummaryTo: rest?.eventSummary
                ? [...sendSummaryAdditional?.map(({ id }) => id)]
                : null,
            welcome_email_receivers: cleanRepeaterOutput(
                formData?.settings?.welcome_email_receivers
            ),
            custom_challenges_url:
                rest?.custom_challenges_url?.map(item => item.name)?.join(',') || []
        };

        return new Promise((resolve, reject) => {
            dispatch({
                type: eventActions.START_UPDATE_EVENT,
                iri,
                promise: { resolve, reject },
                formData: formDataToSend
            });
        }).then(getEventData);
    };

    const replaceTagWithValue = tag => {
        switch (tag) {
            case '%companyName%':
                return company?.name;
            case '%activityName%':
                return activity?.name;
            case '%eventName%':
                return eventData?.name;
            default:
                return tag;
        }
    };

    const chatMessagePreview = chatValues => {
        let message = chatValues;
        const findMatchingTags = message.match(/%.+?%/g);
        if (findMatchingTags) {
            findMatchingTags.forEach(tag => {
                message = message.replace(tag, replaceTagWithValue(tag));
            });
        }
        return message;
    };

    const isUnableToSendWelcomeEmail =
        !settings?.welcome_email_receivers?.length ||
        settings?.welcome_email_receivers?.length === 0;

    return (
        <LayoutManager slot="main" className="main--medium" layoutType="merge">
            <EventsEditPageHeader>
                <div className="event-settings-page-header-actions">
                    {meta?.welcome_email_sent !== undefined && (
                        <Tooltip
                            content={
                                meta?.welcome_email_sent
                                    ? `Welcome email sent${meta?.welcome_email_sent_at &&
                                          ` at ${formatDate(
                                              meta?.welcome_email_sent_at,
                                              'YYYY-MM-DD hh:mm AA'
                                          )}`}`
                                    : 'Welcome email not sent'
                            }
                        >
                            <span
                                className={`welcome-email-sent-badge${
                                    meta?.welcome_email_sent
                                        ? ' welcome-email-sent-badge--apple'
                                        : ' welcome-email-sent-badge--red'
                                }`}
                            >
                                <Svg icon="envelope" />
                            </span>
                        </Tooltip>
                    )}
                    {isUnableToSendWelcomeEmail ? (
                        <Tooltip content='To be able to send welcome email please add receivers on "Welcome Email" section bellow and save changes.'>
                            <Button
                                label={`Send Welcome Emails (${settings?.welcome_email_receivers
                                    ?.length || 0})`}
                                disabled
                                variation="secondary"
                            />
                        </Tooltip>
                    ) : (
                        <Button
                            onClick={() => sendWelcomeEmail()}
                            label={`Send Welcome Emails (${settings?.welcome_email_receivers
                                ?.length || 0})`}
                            loading={sendingWelcomeEmail}
                            variation="secondary"
                        />
                    )}
                </div>
            </EventsEditPageHeader>
            <PageContent>
                <SettingsForm
                    eventStarted={!!eventData?.started_at}
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    onChangeChatMessage={chatMessagePreview}
                />
            </PageContent>
        </LayoutManager>
    );
};

export default Settings;
