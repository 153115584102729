import React from 'react';
import PropTypes from 'prop-types';
import {
    AutoSizer,
    Column,
    Table,
    WindowScroller,
    CellMeasurer,
    CellMeasurerCache
} from 'react-virtualized';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import Svg from 'erpcore/components/Svg';
import 'react-virtualized/styles.css';

const aMeasuredColumnIndex = 0;

let rowParent = null;

const cellParent = {
    invalidateCellSizeAfterRender: ({ rowIndex }) => {
        if (rowParent && typeof rowParent.invalidateCellSizeAfterRender === 'function') {
            rowParent.invalidateCellSizeAfterRender({ columnIndex: 0, rowIndex });
        }
    }
};

const cellCache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 20,
    minHeight: 10
});

const rowCache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 37,
    minHeight: 10
});

const SortableTable = SortableContainer(Table, { withRef: true });

const SortableRowRenderer = SortableElement(props => {
    return (
        <CellMeasurer
            cache={rowCache}
            columnIndex={0}
            key={props.key}
            parent={rowParent}
            rowIndex={props.rowData.position}
        >
            {Table.defaultProps.rowRenderer(props)}
        </CellMeasurer>
    );
});

const SortableDragHandle = SortableHandle(() => (
    <span className="table__body-data--drag">
        <Svg className="table__body-data--drag-icon" icon="drag" />
    </span>
));

const VirtualizedTable = ({ tableList, schema, onDragEnd, onDragStart, isSorting }) => {
    const renderCells = data => {
        const { columnIndex, parent, rowIndex, cellData } = { ...data };

        rowParent = parent;

        return (
            <CellMeasurer
                cache={cellCache}
                columnIndex={columnIndex}
                parent={cellParent}
                rowIndex={rowIndex}
            >
                <div
                    style={{
                        whiteSpace: 'normal',
                        display: 'flex'
                    }}
                >
                    {columnIndex === 0 && <SortableDragHandle />}
                    {cellData}
                </div>
            </CellMeasurer>
        );
    };

    const rowHeight = ({ index }) => {
        const cellCacheRowHeight = cellCache.rowHeight({ index });
        if (cellCache.has(index, aMeasuredColumnIndex)) {
            rowCache.set(index, 0, 20, cellCacheRowHeight);
        }

        return cellCacheRowHeight + 16;
    };

    return (
        <div>
            <WindowScroller>
                {({ height, isScrolling, scrollTop }) => {
                    return (
                        <div>
                            <AutoSizer disableHeight>
                                {({ width }) => (
                                    <SortableTable
                                        getContainer={() => {
                                            const node = document.querySelector(
                                                '.ReactVirtualized__Grid '
                                            );

                                            return node;
                                        }}
                                        autoHeight
                                        gridClassName={`${
                                            isSorting ? 'table__list--is-sorting' : ''
                                        }  table__list-draggable`}
                                        headerHeight={50}
                                        height={height}
                                        isScrolling={isScrolling}
                                        scrollTop={scrollTop}
                                        onSortStart={onDragStart}
                                        deferredMeasurementCache={rowCache}
                                        onSortEnd={props => {
                                            cellCache.clearAll();
                                            onDragEnd(props);
                                        }}
                                        rowClassName="table__list-draggable-row"
                                        rowCount={tableList.length}
                                        rowGetter={({ index }) => tableList[index]}
                                        rowHeight={rowHeight}
                                        rowRenderer={props => <SortableRowRenderer {...props} />}
                                        width={width}
                                        useDragHandle
                                    >
                                        {schema.map(
                                            ({ title, field, align, columnWidthRatio }, index) => {
                                                return (
                                                    <Column
                                                        label={title}
                                                        dataKey={field}
                                                        headerClassName={`${
                                                            align
                                                                ? `table__list-draggable-cell--${align}`
                                                                : ''
                                                        } table__header-cell`}
                                                        className="table__list-draggable-cell"
                                                        cellRenderer={data => renderCells(data)}
                                                        width={
                                                            cellCache.columnWidth(index) *
                                                            columnWidthRatio
                                                        }
                                                    />
                                                );
                                            }
                                        )}
                                    </SortableTable>
                                )}
                            </AutoSizer>
                        </div>
                    );
                }}
            </WindowScroller>
        </div>
    );
};

VirtualizedTable.defaultProps = {
    tableList: [],
    schema: {},
    onDragStart: () => {},
    onDragEnd: () => {},
    isSorting: false
};

VirtualizedTable.propTypes = {
    tableList: PropTypes.oneOfType([PropTypes.array]),
    schema: PropTypes.oneOfType([PropTypes.array]),
    onDragStart: PropTypes.func,
    onDragEnd: PropTypes.func,
    isSorting: PropTypes.bool
};

export default React.memo(VirtualizedTable);
