import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import Form from 'erpcore/components/Form';
import Svg from 'erpcore/components/Svg';
import Tooltip from 'erpcore/components/Tooltip';
import Modal from 'erpcore/components/Modal';
import Button from 'erpcore/components/Button';
import { actions as activityTypeActions } from 'erpcore/screens/Settings/ActivityTypes/ActivityTypes.reducer';
import { getQueryParams } from 'erpcore/components/Listing/Listing.selectors';

const ActivityTypesDelete = ({ id, handleSubmit, submitting, callback }) => {
    const [openedModal, setOpenedModal] = useState(false);
    const dispatch = useDispatch();
    const activityTypeIri = `/api/activity-types/${id}`;
    const listingParams = useSelector(state =>
        getQueryParams(state, { name: 'settingsActivityTypes' })
    );

    const handleModal = () => {
        setOpenedModal(!openedModal);
    };

    const onSubmit = () => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: activityTypeActions.START_DELETE_ACTIVITY_TYPE,
                iri: activityTypeIri
            })
        )
            .then(() => {
                handleModal();
                callback(listingParams);
            })
            .catch(error => {
                handleModal();
                return error;
            });
    };

    return (
        <>
            <Tooltip content="Delete template type">
                <button
                    type="button"
                    onClick={() => {
                        handleModal();
                    }}
                >
                    <Svg icon="remove" />
                </button>
            </Tooltip>
            <Modal
                title="Delete template type"
                opened={openedModal}
                onClose={() => {
                    handleModal();
                }}
                variation="medium"
                subtitle="Are you sure you want to delete this template type?"
            >
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Button submit loading={submitting} label="Delete" />
                    <Button
                        variation="secondary"
                        label="Cancel"
                        onClick={() => {
                            handleModal();
                        }}
                    />
                </Form>
            </Modal>
        </>
    );
};

ActivityTypesDelete.defaultProps = {
    submitting: false,
    handleSubmit: () => {},
    callback: () => {}
};

ActivityTypesDelete.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    handleSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    callback: PropTypes.func
};

export default reduxForm({
    form: 'ActivityTypesDeleteForm'
})(ActivityTypesDelete);
