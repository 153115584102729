import React from 'react';
import Form, { Repeater } from 'erpcore/components/Form';
import { FieldArray } from 'redux-form';
import PropTypes from 'prop-types';
import EntityContentItemMediaRepeaterItem from 'erpcore/screens/EntityContent/components/EntityContentItemMediaRepeaterItem';

const EntityContentItemMediaForm = ({ member }) => {
    return (
        <Form.Row>
            <FieldArray
                name={`${member}.media`}
                component={Repeater}
                RepeatableItem={EntityContentItemMediaRepeaterItem}
                canSort={false}
                canSortNewItems={false}
                canAddMultiple
                canSave={false}
                uniqueIdentifier="iri"
                addNewLabel="Add new file"
            />
        </Form.Row>
    );
};

EntityContentItemMediaForm.defaultProps = {
    member: null
};

EntityContentItemMediaForm.propTypes = {
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default EntityContentItemMediaForm;
