import React, { useEffect } from 'react';
import { change, Field, getFormValues } from 'redux-form';
import Form, { Text, Switch } from 'erpcore/components/Form';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { valueValidation } from 'erpcore/components/Form/Form.utils';
import './MultipleChoiceRepeaterItem.scss';

const MultipleChoiceRepeaterItem = ({ member, data }) => {
    const dispatch = useDispatch();
    const { is_correct: isCorrect, form } = { ...data };
    const formValues = useSelector(state => getFormValues(form)(state));

    useEffect(() => {
        if (
            formValues?.answersMultiple?.length &&
            formValues?.answersMultiple.every(answer => answer.isNewRepeaterItem)
        ) {
            dispatch(change(form, `answersMultiple[0].is_correct`, true));
        }
    }, []);

    return (
        <div className="multiple-choice-repeater-item__columns">
            <Form.Columns>
                <Form.GridColumn size={9}>
                    <Field
                        name={`${member}.answer`}
                        fieldProps={{
                            label: 'Answer',
                            clearable: true
                        }}
                        fieldAttr={{ required: true, id: 'answer', disabled: false }}
                        component={Text}
                        validate={valueValidation([{ validator: 'required' }])}
                    />
                </Form.GridColumn>
                <Form.GridColumn size={2}>
                    <Field
                        name={`${member}.is_correct`}
                        fieldAttr={{
                            id: 'is_correct',
                            disabled: false
                        }}
                        fieldProps={{
                            label: isCorrect ? 'Correct' : 'False',
                            clearable: true
                        }}
                        component={Switch}
                    />
                </Form.GridColumn>
            </Form.Columns>
        </div>
    );
};

MultipleChoiceRepeaterItem.defaultProps = {
    member: null,
    data: {}
};

MultipleChoiceRepeaterItem.propTypes = {
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    data: PropTypes.oneOfType([PropTypes.object])
};

export default MultipleChoiceRepeaterItem;
