import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import Svg from 'erpcore/components/Svg';
import './EventFeedCard.scss';
import StatusBadge from 'erpcore/components/StatusBadge';
import { eventFeedStatusBadges } from 'erpcore/screens/Events/Events.data';
import { actions as eventActions } from 'erpcore/screens/Events/Events.reducer';
import { useDispatch, useSelector } from 'react-redux';
import EventViewEditPointsForm from 'erpcore/screens/Events/screens/EventsView/components/EventViewEditPointsForm';
import ElementLoader from 'erpcore/components/ElementLoader';
import { getFormValues } from 'redux-form';
import Image from 'erpcore/components/Image';
import Video from 'erpcore/components/Video';
import Tooltip from 'erpcore/components/Tooltip';
import { Switch } from 'erpcore/components/Form';

const EventFeedCardContent = ({ data, callback }) => {
    const dispatch = useDispatch();
    const params = useSelector(state => getFormValues('EventFeedFiltersForm')(state));
    const [editPoints, setEditPoints] = useState(false);
    const [updating, setUpdating] = useState(false);

    const {
        completed_at: completedAt,
        event_team: team,
        points,
        id,
        event_challenge: eventChallenge,
        status,
        iri,
        answer: challengeAnswer,
        needs_audit: needsAudit,
        wall
    } = { ...data };

    const { _type: type, points: challengePoints } = { ...eventChallenge };

    const isTypeWallable = !!eventChallenge?._type?.wallable;
    const isChallengeWallTurnedOn = !!eventChallenge?.wall;
    const eventHasWall = !!eventChallenge?.event?.settings?.wall;

    const [wallStatus, setWallStatus] = useState(!!wall);

    const displayAnswerByType = useMemo(() => {
        switch (type?._type) {
            case 'TYPE_TEXT':
                return challengeAnswer !== 'CHALLENGE_TIMED_OUT' ? challengeAnswer : 'No answer.';
            case 'TYPE_TRIVIA':
                return challengeAnswer !== 'CHALLENGE_TIMED_OUT' ? challengeAnswer : 'No answer.';
            case 'TYPE_DATE':
                return challengeAnswer !== 'CHALLENGE_TIMED_OUT' ? challengeAnswer : 'No answer.';
            case 'TYPE_QR':
                return challengeAnswer !== 'CHALLENGE_TIMED_OUT' ? challengeAnswer : 'No answer.';
            case 'TYPE_MULTIPLE_CHOICE': {
                const item = eventChallenge?.answers?.[challengeAnswer];

                if (item) {
                    return item?.answer;
                }

                return 'No answer.';
            }

            case 'TYPE_PHOTO': {
                if (!challengeAnswer?.includes('CHALLENGE_TIMED_OUT')) {
                    const [mediaIri, ...rest] = challengeAnswer?.split(';') || [];
                    const description = rest?.join(';');
                    return (
                        <>
                            <Image iri={mediaIri} width={150} alt={eventChallenge} />
                            {!!description && description !== 'undefined' && <p>{description}</p>}
                        </>
                    );
                }

                return 'No answer.';
            }

            case 'TYPE_VIDEO': {
                if (challengeAnswer !== 'CHALLENGE_TIMED_OUT') {
                    return <Video iri={challengeAnswer} width={250} height={250} />;
                }

                return 'No answer.';
            }

            case 'TYPE_STICKER_PHOTO': {
                if (challengeAnswer !== 'CHALLENGE_TIMED_OUT') {
                    return (
                        <Image iri={challengeAnswer} width={150} height={80} alt={eventChallenge} />
                    );
                }

                return 'No answer.';
            }

            default:
                return challengeAnswer;
        }
    }, [type?._type, challengeAnswer, eventChallenge]);

    const updateShowOnWall = useCallback(
        value => {
            setUpdating(true);
            return new Promise((resolve, reject) => {
                dispatch({
                    type: eventActions.START_UPDATE_EVENT_FEED,
                    formData: {
                        wall: value
                    },
                    promise: { resolve, reject },
                    iri
                });
            }).finally(() => {
                setUpdating(false);
            });
        },
        [iri]
    );

    const updateFeed = useCallback(
        (formData, refetchData = false) => {
            setUpdating(true);
            return new Promise((resolve, reject) => {
                dispatch({
                    type: eventActions.START_UPDATE_EVENT_FEED,
                    formData,
                    promise: { resolve, reject },
                    iri
                });
            })
                .then(() => {
                    setEditPoints(false);
                    if (refetchData) callback(params);
                })
                .catch(err => err)
                .finally(() => setUpdating(false));
        },
        [params, callback]
    );

    const completedAtIsoFormat = useMemo(() => {
        return moment(completedAt).format();
    }, [completedAt]);

    const completedAtHumanReadableAgo = useMemo(() => {
        return moment(completedAt).fromNow();
    }, [completedAt]);

    return (
        <>
            {updating && <ElementLoader overlay />}
            <div className="event-feed-card__info">
                <Tooltip content={completedAtIsoFormat} direction="up">
                    <span className="event-feed-card__info-time">
                        <Svg icon="time" />
                        {completedAtHumanReadableAgo}
                    </span>
                </Tooltip>
                <span className="event-feed-card__info-item">
                    <Svg icon="group" />
                    {team?.name}
                </span>
                <span className="event-feed-card__info-item">
                    <Svg icon="group" />
                    {!editPoints && (
                        <>
                            {challengePoints === points || points === 0 ? (
                                `${points} points`
                            ) : (
                                <div className="event-feed-card__info-points">
                                    <p>{points} points</p>
                                    <p>{challengePoints} points</p>
                                </div>
                            )}
                        </>
                    )}
                    {editPoints && (
                        <EventViewEditPointsForm
                            form={`EventViewEditPoints-${id}`}
                            onSubmit={updateFeed}
                            onCancel={() => setEditPoints(!editPoints)}
                        />
                    )}
                    {/* {!editPoints && ( */}
                    {/*    <button */}
                    {/*        type="button" */}
                    {/*        onClick={() => setEditPoints(!editPoints)} */}
                    {/*        className="event-feed-card__info-edit" */}
                    {/*    > */}
                    {/*        <Svg icon="edit" /> */}
                    {/*    </button> */}
                    {/* )} */}
                </span>
                <span className="event-feed-card__info-item">
                    {eventHasWall && isTypeWallable && isChallengeWallTurnedOn && (
                        <Switch
                            name={`wall-${id}`}
                            id={`wall-${id}`}
                            input={{
                                value: wallStatus,
                                name: 'wall',
                                onChange: state => {
                                    setWallStatus(state);
                                    updateShowOnWall(state);
                                },
                                onBlur: () => {}
                            }}
                            fieldProps={{
                                label: 'Show on wall'
                            }}
                        />
                    )}
                </span>
            </div>
            <div className="event-feed-card__content">
                <div className="event-feed-card__content-status">
                    <StatusBadge
                        type={eventFeedStatusBadges[status]?.type || status}
                        text={eventFeedStatusBadges[status]?.label || status}
                    />
                </div>
                <h3 className="event-feed-card__content-title">
                    <Svg icon="completion" /> Challenge name: {eventChallenge?.title}
                </h3>
                <div
                    className="event-feed-card__content-description"
                    dangerouslySetInnerHTML={{
                        __html: eventChallenge?.description
                    }}
                />
                {challengeAnswer && (
                    <p className="event-feed-card__content-answer">
                        <span
                            className={`event-feed-card__content-badge ${
                                !needsAudit && points !== 0
                                    ? 'event-feed-card__content-badge--correct'
                                    : ''
                            } ${
                                !needsAudit && points === 0
                                    ? 'event-feed-card__content-badge--incorrect'
                                    : ''
                            }`}
                        >
                            {!needsAudit && points !== 0 && <Svg icon="checkmark" />}
                            {!needsAudit && points === 0 && <Svg icon="x" />}
                        </span>{' '}
                        {displayAnswerByType}
                    </p>
                )}
            </div>
        </>
    );
};
EventFeedCardContent.defaultProps = {
    data: {},
    callback: () => {}
};
EventFeedCardContent.propTypes = {
    data: PropTypes.oneOfType([PropTypes.object]),
    callback: PropTypes.func
};

const EventFeedCard = ({ data, callback }) => {
    return (
        <div className="event-feed-card">
            <EventFeedCardContent data={data} callback={callback} />
        </div>
    );
};
EventFeedCard.defaultProps = {
    data: {},
    callback: () => {}
};
EventFeedCard.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.oneOfType([PropTypes.object]),
    callback: PropTypes.func
};

export default EventFeedCard;
