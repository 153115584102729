import React from 'react';
import PropTypes from 'prop-types';
import Notification from 'erpcore/components/Notification';

/**
 * Notification Manager - Challenges Notifications
 * @return {Node} Returns Notification component
 */
// eslint-disable-next-line no-unused-vars
const ChallengesNotification = ({ identifier, code, detail, notificationData }) => {
    switch (code) {
        case 'challenge.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Challenge successfully created!"
                    type="success"
                    expire="3500"
                />
            );
        case 'challenge.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Challenge successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'challenge.itemSuccessfulyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Challenge successfully deleted!"
                    type="success"
                    expire="3500"
                />
            );
        case 'completedchallenge.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Item successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'challengeTypes.iconSuccesfullyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Challenge type successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'import.completed':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Importing challenges is in queue, it might take some time to complete."
                    type="success"
                    expire="5000"
                />
            );
        case 'import.partiallyCompleted':
            return (
                <Notification
                    identifier={identifier}
                    title="Warning"
                    text={detail}
                    type="warning"
                    expire="7500"
                />
            );

        default:
            return null;
    }
};

ChallengesNotification.defaultProps = {
    identifier: null,
    code: null,
    detail: null,
    notificationData: null
};

ChallengesNotification.propTypes = {
    identifier: PropTypes.string,
    code: PropTypes.string,
    detail: PropTypes.string,
    notificationData: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])
};

export default ChallengesNotification;
