import Form, { Switch, SwitchCard, Text, Textarea } from 'erpcore/components/Form';
import { Field, getFormValues, reduxForm } from 'redux-form';
import Button from 'erpcore/components/Button';
import React from 'react';
import PropTypes from 'prop-types';
import UnsavedChanges from 'erpcore/components/UnsavedChanges';
import { useSelector } from 'react-redux';
import { valueValidation } from 'erpcore/components/Form/Form.utils';
import style from './SettingsForm.module.scss';

const SettingsForm = ({ invalid, pristine, handleSubmit, onSubmit, dirty, submitting }) => {
    const formValues = useSelector(state => getFormValues('ActivityEditSettingsForm')(state));

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <UnsavedChanges promptWhen={dirty && !submitting} onSubmit={handleSubmit(onSubmit)} />
            <Form.Row>
                <Field
                    name="settings.enableHelpRequest"
                    id="settings.enableHelpRequest"
                    fieldProps={{
                        label: 'Enable help requests',
                        description:
                            'Turn on if you would like participants to be able to send help requests.'
                    }}
                    component={SwitchCard}
                />
            </Form.Row>

            <div className={style['switch-group']}>
                <Field
                    name="settings.welcomeChatMessage"
                    id="settings.welcomeChatMessage"
                    fieldProps={{
                        label: 'Welcome message',
                        className: style['switch-group__main']
                    }}
                    component={SwitchCard}
                />
                {formValues?.settings?.welcomeChatMessage && (
                    <div className={style['switch-group__chat']}>
                        <Field
                            name="settings.chatMessage"
                            id="settings.chatMessage"
                            fieldProps={{
                                label: 'Message'
                            }}
                            component={Textarea}
                            validate={valueValidation([{ validator: 'required' }])}
                        />
                        <ul>
                            <li>
                                Company name: <b>%companyName%</b>
                            </li>
                            <li>
                                Activity name: <b>%activityName%</b>
                            </li>
                        </ul>
                    </div>
                )}
            </div>

            <Form.Row>
                <Field
                    name="settings.chat"
                    id="settings.chat"
                    fieldProps={{
                        label: 'Use chat',
                        description: 'Toggle to enable chat within the event interface.'
                    }}
                    component={SwitchCard}
                />
            </Form.Row>

            <div className={style['switch-group']}>
                <Field
                    name="settings.wall"
                    id="settings.wall"
                    fieldProps={{
                        label: 'Wall',
                        description:
                            'Turn on to enable the game feed to be accessible to participants after the event has completed. The feed will contain uploaded images, video content, and text from challenges that are set to be used for the wall.',
                        className: style['switch-group__main']
                    }}
                    component={SwitchCard}
                />
                {formValues?.settings?.wall && (
                    <div className={style['switch-group__password']}>
                        <Field
                            name="settings.wallPasswordOption"
                            id="settings.wallPasswordOption"
                            fieldProps={{
                                label: 'Password protected'
                            }}
                            component={Switch}
                        />
                        {formValues?.settings?.wallPasswordOption && (
                            <div className={style['switch-group__password-field']}>
                                <Field
                                    name="settings.wallPassword"
                                    id="settings.wallPassword"
                                    fieldProps={{
                                        label: 'Password'
                                    }}
                                    component={Text}
                                    validate={valueValidation([{ validator: 'required' }])}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>

            <Form.Row>
                <Field
                    name="settings.lockedChanges"
                    fieldAttr={{ id: 'lockedChanges' }}
                    fieldProps={{ label: 'Locked challenges are visible' }}
                    component={SwitchCard}
                />
            </Form.Row>

            <Form.Row>
                <Field
                    name="settings.validateLockedByChallenges"
                    id="settings.validateLockedByChallenges"
                    fieldProps={{
                        label: 'Enable validation for locked rounds',
                        description:
                            'Turn on if you would like participants to enter exact answer to unlock rounds.'
                    }}
                    component={SwitchCard}
                />
            </Form.Row>

            <Button loading={submitting} disabled={invalid || pristine} submit label="Save" />
        </Form>
    );
};

SettingsForm.defaultProps = {
    invalid: false,
    pristine: false,
    handleSubmit: () => {},
    onSubmit: () => {},
    dirty: false,
    submitting: false
};

SettingsForm.propTypes = {
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    handleSubmit: PropTypes.func,
    onSubmit: PropTypes.func,
    dirty: PropTypes.bool,
    submitting: PropTypes.bool
};

export default reduxForm({
    form: 'ActivityEditSettingsForm',
    enableReinitialize: true
})(SettingsForm);
