import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'erpcore/components/Button';
import Modal from 'erpcore/components/Modal';
import UserCreateForm from 'erpcore/screens/Users/components/UserCreateForm';
import { actions as usersActions } from 'erpcore/screens/Users/Users.reducer';
import { change, getFormValues } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import restClient from 'erpcore/api/restClient';
import dto from 'erpcore/utils/dto';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { insertIf } from 'erpcore/utils/utils';

const CustomerCreateActionModal = ({ formName }) => {
    const dispatch = useDispatch();
    const [opened, setOpened] = useState(false);
    const [userTypes, setUserTypes] = useState(null);
    const formValues = useSelector(state => getFormValues(formName)(state));
    const { coordinators } = { ...formValues };

    const handleModal = () => {
        setOpened(!opened);
    };

    const onSubmit = ({ formData }) => {
        if ('userTypes' in formData) {
            formData.roles = [...insertIf(formData?.userTypes, [formData.userTypes])];
            delete formData.userTypes;
        }

        return new Promise((resolve, reject) => {
            dispatch({
                type: usersActions.START_CREATE_USER,
                promise: { resolve, reject },
                formData
            });
        })
            .then(response => {
                handleModal();
                return dispatch(
                    change(formName, 'coordinators', [...coordinators, response?.data?.id])
                );
            })
            .catch(error => {
                return error;
            });
    };

    const fetchUserTypes = useCallback(async () => {
        try {
            const userTypesAPI = await restClient.get('/api/user-types');
            const userTypeDTO = dto(userTypesAPI?.data?.data);
            setUserTypes(
                userTypeDTO?.reduce((acc, curr) => {
                    if (
                        curr?.attributes?.attribute === 'CAN_USER_TYPE_EVENT_COORDINATOR' ||
                        curr?.attributes?.attribute === 'CAN_USER_TYPE_CUSTOMER_COORDINATOR'
                    ) {
                        acc.push({
                            value: curr?.id,
                            label: curr?.attributes?.name
                        });
                    }
                    return acc;
                }, []) || []
            );
        } catch (error) {
            dispatch({
                type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                response: error?.response?.data
            });
        }
    }, []);

    useEffect(() => {
        fetchUserTypes();
    }, []);

    return (
        <>
            <Button
                onClick={handleModal}
                variation="action"
                iconName="plus"
                label="Create"
                labelOnlyAria
            />
            <Modal
                variation="medium"
                opened={opened}
                onClose={handleModal}
                title="Add new coordinator"
            >
                <UserCreateForm
                    form="CoordinatorCreateForm"
                    onSubmit={onSubmit}
                    coordinatorOnlyUserTypes={userTypes}
                />
            </Modal>
        </>
    );
};

CustomerCreateActionModal.defaultProps = {
    formName: null
};

CustomerCreateActionModal.propTypes = {
    formName: PropTypes.string
};

export default CustomerCreateActionModal;
