import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Menu from 'erpcore/components/Layout/MainNavigation/components/Menu';

import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { hasPermission, getPermissionsAccessForEntities } from 'erpcore/utils/RolesManager';

const MainNavigation = () => {
    const menuId = 'main-navigation';
    const meData = useSelector(getMeData) || {};
    const hasPermissionsAccessForEntities = getPermissionsAccessForEntities(meData);

    /**
     * Effect used only on component mount
     */
    useEffect(() => {
        // Expand Submenu on Page Load
        const menu = document.getElementById(menuId);
        const activeItems = menu.querySelectorAll('.main-navigation__submenu-link--active');

        if (activeItems.length) {
            activeItems.forEach(activeItem => {
                activeItem
                    .closest('.main-navigation__menu-item')
                    .classList.add('main-navigation__menu-item--open');
            });
        }
    }, []);

    return (
        <Menu id={menuId}>
            <Menu.Item to="/dashboard" label="Dashboard" />
            {hasPermissionsAccessForEntities.users && <Menu.Item to="/users" label="Users" />}
            {hasPermissionsAccessForEntities.companies && (
                <Menu.Item to="/companies" label="Customers" />
            )}
            {hasPermissionsAccessForEntities.contacts && (
                <Menu.Item to="/contacts" label="Contacts" />
            )}
            {hasPermissionsAccessForEntities.activities && (
                <Menu.Item to="/activities" label="Templates" />
            )}
            {hasPermissionsAccessForEntities.challenges && (
                <Menu.Item to="/challenges" label="Challenges" />
            )}
            {!!(
                hasPermissionsAccessForEntities.events ||
                hasPermissionsAccessForEntities.selfHostedEvents
            ) && (
                <Menu.Item
                    to="/events?events[filter]=c3RhdHVzJTVCMCU1RCU1QnZhbHVlJTVEPWFjdGl2ZSZzdGF0dXMlNUIwJTVEJTVCb3BlcmF0b3IlNUQ9ZXF1YWxzJnN0YXR1cyU1QjElNUQlNUJ2YWx1ZSU1RD1kcmFmdCZzdGF0dXMlNUIxJTVEJTVCb3BlcmF0b3IlNUQ9ZXF1YWxz&events[page]=1&events[order_by][id]=DESC"
                    label="Events"
                />
            )}

            {hasPermissionsAccessForEntities.media && (
                <Menu.Item to="/media-library" label="Media Library" />
            )}

            {hasPermissionsAccessForEntities.settings && (
                <Menu.Submenu label="Settings">
                    {hasPermission('CAN_MANAGE_COMMON_ACTIVITY_TYPE', meData) && (
                        <Menu.SubmenuItem to="/settings/activity-types" label="Template Types" />
                    )}
                    {hasPermission('CAN_MANAGE_COMMON_CHALLENGE_TYPE', meData) && (
                        <Menu.SubmenuItem to="/settings/challenge-types" label="Challenge Types" />
                    )}
                    {/* Need to add required permissions here */}
                    <Menu.SubmenuItem to="/settings/guidelines" label="Event Guidelines" />
                    {hasPermission('CAN_MANAGE_SECURITY_BUNDLE_PERMISSION', meData) && (
                        <Menu.SubmenuItem to="/settings/permissions" label="Permissions" />
                    )}
                    {hasPermission('CAN_MANAGE_SECURITY_BUNDLE_ROLE', meData) && (
                        <Menu.SubmenuItem to="/settings/roles" label="Roles" />
                    )}
                </Menu.Submenu>
            )}
        </Menu>
    );
};

export default withRouter(MainNavigation);
