import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Link } from 'react-router-dom';
import Svg from 'erpcore/components/Svg';
import Widget from 'erpcore/components/Widget';

import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';

import './UserWidget.scss';
import Image from 'erpcore/components/Image';

const UserWidget = ({ widgetData }) => {
    const { draggable } = {
        ...widgetData
    };
    const meData = useSelector(getMeData);

    const { first_name: firstName } = { ...meData };
    return (
        <Widget className="user-widget" draggable={draggable}>
            <Link to="/my-account" className="user-widget__photo">
                <Image
                    iri={meData?.image?.iri}
                    version="small"
                    width={100}
                    height={100}
                    alt="Profile"
                    fallback={<Svg icon="profile" />}
                />
            </Link>
            <div className="user-widget__content">
                <h3 className="user-widget__content-title">Welcome {firstName}!</h3>
                <ul className="user-widget__menu">
                    <li className="user-widget__menu-item">
                        <Link className="user-widget__menu-item-link" to="/my-account">
                            Edit your account
                        </Link>
                    </li>
                </ul>
            </div>
        </Widget>
    );
};

UserWidget.defaultProps = {
    widgetData: {}
};

UserWidget.propTypes = {
    widgetData: PropTypes.oneOfType([PropTypes.object])
};

export default UserWidget;
