import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import restClient from 'erpcore/api/restClient';
import { getFormValues } from 'redux-form';
import dto from 'erpcore/utils/dto';
import { resolveObjectPathByString } from 'erpcore/utils/utils';
import { SortableContainer } from 'react-sortable-hoc';
import MediaItem from 'erpcore/components/Form/components/Media/components/MediaItem';
import MediaAddItem from 'erpcore/components/Form/components/Media/components/MediaAddItem';
import Button from 'erpcore/components/Button';
import Modal from 'erpcore/components/Modal';
import isImage from 'is-image';
import ImageManager, { getFileExtension } from 'erpcore/components/ImageManager';
import nextId from 'react-id-generator';
import Lightbox from 'react-image-lightbox';
import {
    getImage,
    getImageCollection,
    getImageFetchingData
} from 'erpcore/components/ImageManager/ImageManager.selectors';
import './Media.scss';
import './ImageLightbox.scss';
import { actions as imageManagerActions } from 'erpcore/components/ImageManager/ImageManager.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';

const emptyMediaObjectData = {
    id: null,
    type: 'MediaObject',
    content_url: null,
    featured: false,
    iri: null,
    noData: true
};

const useForceUpdate = () => useState()[1];

const SortableGalleryContainer = SortableContainer(({ children }) => {
    return <div className="gallery__grid">{children}</div>;
});

const Media = ({
    meta,
    id,
    // isMulti,
    input,
    fields,
    allowedFileTypes,
    maxImageCount,
    featuredKey,
    versionName,
    canAddImage,
    canSort,
    canDelete,
    useImageManager,
    useLightbox,
    deleteModalButtonDelete,
    deleteModalButtonCancel,
    emptyGalleryMessage,
    useMediaLibrary,
    required
}) => {
    const dispatch = useDispatch();
    const [tempImages, setTempImages] = useState([]);
    const [uploadProgress, setUploadProgress] = useState({});
    const [sortDragging, setSortDragging] = useState(false);
    const [isReceiverOpen, setIsReceiverOpen] = useState(false);
    const [isInitialEditActive, setIsInitialEditActive] = useState(false);
    const [initialEditData, setInitialEditData] = useState(null);
    const [modalEditorOpened, setModalEditorOpened] = useState(false);
    const [modalDeleteIriOpened, setModalDeleteIriOpened] = useState(false);
    const [lightboxImageIri, setLightboxImageIri] = useState(null);

    const imageFetchingData = useSelector(state => getImageFetchingData(state));

    const isMulti = !!fields && !input;
    let fieldName = null;
    if (isMulti) {
        fieldName = fields.name;
    } else {
        fieldName = input.name;
    }

    const forceUpdate = useForceUpdate();

    const imageIri = input?.value;
    const image =
        useSelector(state => getImage(state, imageIri)) || (imageIri ? emptyMediaObjectData : null);

    const formValues = useSelector(state => getFormValues(meta?.form)(state));
    const imagesList = resolveObjectPathByString(formValues, fieldName);
    const imagesIris = Array.isArray(imagesList)
        ? imagesList.map(imagesListItem => imagesListItem.iri)
        : null;
    const allStateImages = useSelector(state => getImageCollection(state)) || {};
    const images = [];
    if (!!allStateImages && Array.isArray(imagesList) && imagesList?.length) {
        imagesList.forEach(item => {
            if (allStateImages?.[item.iri]) {
                images.push({
                    ...allStateImages?.[item.iri],
                    featured: featuredKey && imagesList[featuredKey]
                });
            } else {
                images.push(emptyMediaObjectData);
            }
        });
    }

    const getImageSrc = (iri = null, version = null) => {
        if (!iri) return null;

        let src =
            allStateImages[modalDeleteIriOpened].content_url_modified ||
            allStateImages[modalDeleteIriOpened].content_url;

        if (version) {
            const versionObject =
                allStateImages[modalDeleteIriOpened].versions &&
                allStateImages[modalDeleteIriOpened].versions.find(
                    item => item.name === version && item.content_url
                );

            if (versionObject) {
                src = versionObject.content_url;
            }
        }

        return src;
    };

    const startFetchImages = () => {
        return new Promise(resolve => {
            const filteredIris = [];

            if (isMulti) {
                if (imagesIris && Array.isArray(imagesIris)) {
                    imagesIris.forEach(iri => {
                        if (
                            !imageFetchingData?.[iri] &&
                            typeof iri === 'string' &&
                            !(images?.length ? images : []).map(item => item.iri).includes(iri)
                        ) {
                            filteredIris.push(iri);
                        }
                    });
                }
            } else if (imageIri && !imageFetchingData?.[imageIri]) {
                if (image) {
                    if (image?.iri !== imageIri) {
                        filteredIris.push(imageIri);
                    }
                } else {
                    filteredIris.push(imageIri);
                }
            }

            if (filteredIris?.length) {
                Promise.all(
                    filteredIris.map(iri => {
                        return new Promise((resolveInner, rejectInner) => {
                            return dispatch({
                                promise: { resolve: resolveInner, reject: rejectInner },
                                type: imageManagerActions.START_FETCHING_IMAGE,
                                iri
                            });
                        });
                    })
                )
                    .then(response => {
                        return resolve(response);
                    })
                    .catch(error => {
                        return resolve(error);
                    });
            }
        });
    };

    useEffect(() => {
        startFetchImages();
    }, [imageIri, imagesIris]);

    /**
     * Replaces redux-form fields.getAll() method, which doesn't always return latest true redux-form state.
     * @return {Array}
     */
    const getAllFieldsSafe = () => {
        const fieldsArray = [];
        if (fields) {
            fields.forEach((name, index, fieldsReference) => {
                fieldsArray.push(fieldsReference.get(index));
            });
        }
        return fieldsArray;
    };

    const getLightboxData = () => {
        const data = {
            mainId: null,
            mainSrc: null,
            nextId: null,
            nextSrc: null,
            prevId: null,
            prevSrc: null
        };

        if (isMulti && images?.length) {
            const onlyImages = [...images].reduce((accumulator, currentItem) => {
                if (
                    currentItem?.iri &&
                    // eslint-disable-next-line
                    currentItem?.content_url &&
                    // eslint-disable-next-line
                    isImage(currentItem?.content_url)
                ) {
                    accumulator.push({
                        iri: currentItem?.iri,
                        // eslint-disable-next-line
                        content_url: currentItem?.content_url_modified || currentItem?.content_url
                    });
                }
                return accumulator;
            }, []);

            // current lightbox image
            const currentIndex = onlyImages.findIndex(item => {
                if (item.iri === lightboxImageIri) {
                    data.mainId = lightboxImageIri;
                    data.mainSrc = item.content_url;
                    return true;
                }
                return false;
            });

            // prev and next lightbox image
            // if more than 1 image is available, there is always prev and next image
            if (currentIndex !== -1 && onlyImages.length > 1) {
                const nextIndex = (currentIndex + 1) % onlyImages.length;
                const prevIndex = (currentIndex + onlyImages.length - 1) % onlyImages.length;
                data.nextId = onlyImages[nextIndex].iri;
                data.nextSrc = onlyImages[nextIndex].content_url;
                data.prevId = onlyImages[prevIndex].iri;
                data.prevSrc = onlyImages[prevIndex].content_url;
            }
            // eslint-disable-next-line
        } else if (!isMulti && image?.content_url) {
            data.mainId = image?.iri;
            // eslint-disable-next-line
            data.mainSrc = image?.content_url_modified || image?.content_url;
            data.nextId = null;
            data.nextSrc = null;
            data.prevId = null;
            data.prevSrc = null;
        }

        return data;
    };

    const lightboxData = getLightboxData();

    const sortStart = () => {
        setSortDragging(true);
    };

    const sortEnd = ({ oldIndex, newIndex }) => {
        if (isMulti) {
            fields.move(oldIndex, newIndex);
        }

        setSortDragging(false);
    };

    const removeTempMedia = (tempId = null) => {
        if (tempId) {
            const newTempImages = [...tempImages].reduce((accumulator, current) => {
                if (current.tempId !== tempId) {
                    accumulator.push(current);
                }
                return accumulator;
            }, []);
            setTempImages(newTempImages);
        }
    };

    const dispatchNotification = notificationData => {
        dispatch({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: {
                code: 'media.customNotification',
                notificationData
            }
        });
    };

    const dispatchError = ({ identifier = null, type = null, data = null }) => {
        const notificationData = {
            identifier,
            type: 'warning',
            title: 'Error',
            expire: null
        };

        switch (type) {
            case 'fileTypeMismatch': {
                if (allowedFileTypes.length === 1) {
                    notificationData.text = `File type ${data?.fileExtension} is not allowed. Only file type ${data?.allowedFileTypes[0]} is allowed.`;
                } else {
                    notificationData.text = `File type ${
                        data?.fileExtension
                    } is not allowed. Allowed file types are ${data.allowedFileTypes.map(
                        (item, i) => `${i !== 0 ? ' ' : ''}${item}`
                    )}.`;
                }
                return dispatchNotification(notificationData);
            }
            default:
                return null;
        }
    };

    const addMediaObjectToGallery = (mediaObjectIri, position = 0, tempId = null) => {
        return new Promise(resolve => {
            removeTempMedia(tempId);
            if (isMulti) {
                fields.insert(position, { iri: mediaObjectIri });
            } else {
                input.onChange(mediaObjectIri);
            }

            resolve();
        });
    };

    const uploadImage = ({ tempId, formData }) => {
        return new Promise(async (resolve, reject) => {
            try {
                const request = await restClient.post(`api/media-objects`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                    onUploadProgress: progressEvent => {
                        const percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                        setUploadProgress({
                            ...uploadProgress,
                            [tempId]: {
                                progress: percentCompleted,
                                status: 'in-progress'
                            }
                        });
                    }
                });

                const { data: responseData } = request;

                setUploadProgress({
                    ...uploadProgress,
                    [tempId]: {
                        progress: 100,
                        status: 'success'
                    }
                });

                if (responseData) {
                    const mediaObject = { ...dto(responseData).data };

                    if (isMulti) {
                        const targetField = fields?.getAll()?.find(item => {
                            return item.tempId === tempId;
                        });

                        // manually update arrayItem data
                        if (targetField) {
                            forceUpdate();
                        }
                    }

                    resolve({ tempId, mediaObject });
                }
            } catch (error) {
                setUploadProgress({
                    ...uploadProgress,
                    [tempId]: {
                        progress: 100,
                        status: 'fail'
                    }
                });
                reject(error?.response?.data || error);
            }
        });
    };

    const initialImageEdit = ({ tempId, objectData, formData }) => {
        return new Promise(async resolve => {
            setInitialEditData({
                tempId,
                formData,
                imageData: {
                    meta: {
                        filename: objectData.filename,
                        type: objectData.type
                    },
                    content_url: URL.createObjectURL(objectData?.file)
                }
            });
            setIsInitialEditActive(true);
            resolve();
        });
    };

    // Array of concurrent "upload image" Promises
    const uploadImagesPromises = (data = []) => {
        return data.map(({ tempId, formData }) => uploadImage({ tempId, formData }));
    };

    // Array of concurrent "add MediaObject to Gallery" Promises
    const addMediaObjectsToGalleryPromises = data => {
        const collection = [];
        if (data?.length) {
            data.forEach((item, position) => {
                const { mediaObject, tempId } = item;
                if (mediaObject?.iri) {
                    collection.push(addMediaObjectToGallery(mediaObject.iri, position, tempId));
                }
            });
        }
        return collection;
    };

    const onImageDrop = async (event, files, inputField) => {
        setIsReceiverOpen(false);

        let queuedFiles = [];
        if (inputField) {
            queuedFiles = [...files].map(fileData => {
                return { data: fileData };
            });
        } else {
            queuedFiles = files;
        }

        function readFileAsync(file) {
            return new Promise(resolve => {
                const reader = new FileReader();

                reader.onloadend = () => {
                    resolve(reader.result);
                };

                reader.readAsDataURL(file);
            });
        }

        const processFile = file => {
            return new Promise(resolve => {
                if (file.data === undefined) return null;

                const tempId = nextId('temp-image-id-');

                const fileExtension = getFileExtension(file?.data?.name).toLowerCase();

                if (allowedFileTypes?.length && !allowedFileTypes.includes(fileExtension)) {
                    dispatchError({
                        identifier: tempId,
                        type: 'fileTypeMismatch',
                        data: {
                            fileExtension,
                            allowedFileTypes
                        }
                    });
                    return null;
                }

                return readFileAsync(file.data).then(rawData => {
                    const tempImage = {
                        isNewGalleryItem: true,
                        tempId,
                        meta: file.data,
                        raw: rawData
                    };

                    const objectData = {
                        raw: rawData,
                        file: file.data,
                        filename: file.data.name,
                        size: file.data.size,
                        type: file.data.type
                    };

                    const formData = new FormData();

                    formData.append('file', file.data);
                    formData.append(
                        'meta',
                        JSON.stringify({ filename: file.data.name, type: file.data.type })
                    );

                    resolve({ tempId, tempImage, objectData, formData });
                });
            });
        };

        // Array of concurrent "process file with FileReader" Promises
        const processFilesPromises = data => {
            return data.map(file => processFile(file));
        };

        Promise.all(processFilesPromises(queuedFiles)).then(dataForUpload => {
            if (isMulti) {
                setTempImages([
                    ...tempImages,
                    ...dataForUpload.map(item => {
                        return item.tempImage;
                    })
                ]);
            } else if (dataForUpload[0]?.tempImage) {
                setTempImages([dataForUpload[0].tempImage]);
            }
            if (dataForUpload.length === 1 && isImage(dataForUpload[0]?.objectData?.filename)) {
                initialImageEdit(dataForUpload[0]);
            } else {
                Promise.all(uploadImagesPromises(dataForUpload))
                    .then(mediaObjects => {
                        Promise.all(addMediaObjectsToGalleryPromises(mediaObjects)).then(() => {
                            startFetchImages();
                        });
                    })
                    .catch(error => {
                        return dispatch({
                            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                            response: error
                        });
                    });
            }
        });
    };

    const setFeatured = () => {
        // TODO: update redux-form array with new featured data
    };

    const getMediaItems = () => {
        const items = [];

        let combined = [];

        if (tempImages?.length) {
            combined = [...tempImages];
        }

        if (isMulti && images?.length) {
            combined = [...combined, ...images];
        } else if (!isMulti && image) {
            combined = [...combined, image];
        }

        if (combined.length) {
            [...combined].forEach((galleryItem, index) => {
                items.push(
                    <MediaItem
                        key={
                            galleryItem.id ||
                            galleryItem.iri ||
                            galleryItem.tempId ||
                            `mediaItem__${index}`
                        }
                        data={galleryItem}
                        index={index}
                        isFeatured={galleryItem.featured}
                        canFeature={featuredKey}
                        canSort={canSort}
                        canDelete={canDelete}
                        useImageManager={useImageManager}
                        useLightbox={useLightbox}
                        versionName={versionName}
                        setFeatured={setFeatured}
                        setLightboxImageIri={setLightboxImageIri}
                        setModalEditorOpened={setModalEditorOpened}
                        setModalDeleteIriOpened={setModalDeleteIriOpened}
                        uploadProgress={uploadProgress}
                        removeTempMedia={removeTempMedia}
                    />
                );
            });
        }

        if (items?.length) {
            return items;
        }

        return null;
    };

    const getSortableItems = () => {
        const items = [];

        let canAddNewImage = false;

        if (canAddImage) {
            if (!isMulti && !(imageIri || tempImages?.length)) {
                canAddNewImage = true;
            } else if (
                isMulti &&
                (maxImageCount === -1 ||
                    maxImageCount === false ||
                    maxImageCount > (fields?.length || 0) + (tempImages?.length || 0))
            ) {
                canAddNewImage = true;
            }
        }

        if (canAddNewImage) {
            items.push(
                <MediaAddItem
                    id={id}
                    key="add-new"
                    useMediaLibrary={useMediaLibrary}
                    isReceiverOpen={isReceiverOpen}
                    setIsReceiverOpen={setIsReceiverOpen}
                    onImageDrop={onImageDrop}
                    required={required}
                    input={{ id, form: meta?.form, name: input?.name }}
                    allowedFileTypes={allowedFileTypes}
                    maxImageCount={maxImageCount - Number(fields?.length || 0)}
                    isMulti={isMulti}
                />
            );
        }

        // render no images message if adding images is disabled and there are no images
        if (!canAddImage && fields && !fields.length) {
            items.push(<div className="gallery__info">{emptyGalleryMessage}</div>);
        }

        const galleryItems = getMediaItems();
        if (galleryItems?.length) {
            galleryItems.forEach(item => {
                items.push(item);
            });
        }

        return items;
    };

    const removeItem = (iri = false) => {
        return new Promise(resolve => {
            if (isMulti) {
                const index = getAllFieldsSafe().findIndex(item => item.iri === iri);

                if (index > -1) {
                    fields.remove(index);
                }

                resolve();
            } else {
                input.onChange(null);
                resolve();
            }
        });
    };

    // eslint-disable-next-line react/sort-comp
    const handleRemoveItemUserRequest = (removeItemIri = false) => {
        if (id === false) return null;

        removeItem(removeItemIri).finally(() => {
            forceUpdate();
            // startFetchImages();
        });

        return null;
    };

    const handleEditedImage = (/* { canvasData = null, positionData = null } */) => {
        // setBusy(true);
        // startFetchImages();
    };

    const setPrevLightboxImage = () => {
        const lightBoxData = getLightboxData();

        if (lightBoxData.prevId) {
            setLightboxImageIri(lightBoxData.prevId);
        }
    };

    const setNextLightboxImage = () => {
        const lightBoxData = getLightboxData();

        if (lightBoxData.nextId) {
            setLightboxImageIri(lightBoxData.nextId);
        }
    };

    return (
        <div
            className={`gallery${sortDragging ? ' gallery--dragging' : ''} ${
                required ? 'gallery--required' : ''
            }`}
        >
            <SortableGalleryContainer
                helperClass="gallery__item--dragged"
                axis="xy"
                lockToContainerEdges
                distance={3}
                onSortStart={sortStart}
                onSortEnd={sortEnd}
                lockOffset="5%"
                // disableAutoscroll
            >
                {getSortableItems()}
            </SortableGalleryContainer>
            <Modal
                root="body"
                variation="small"
                opened={!!modalDeleteIriOpened || modalDeleteIriOpened === 0}
                onClose={() => setModalDeleteIriOpened(false)}
                title={
                    // eslint-disable-next-line
                    allStateImages?.[modalDeleteIriOpened]?.content_url &&
                    // eslint-disable-next-line
                    isImage(allStateImages?.[modalDeleteIriOpened]?.content_url)
                        ? 'Remove image'
                        : 'Remove file'
                }
                subtitle={
                    <>
                        {allStateImages?.[modalDeleteIriOpened]?.content_url && // eslint-disable-line
                        isImage(allStateImages?.[modalDeleteIriOpened]?.content_url) // eslint-disable-line
                            ? 'Are you sure you want to remove this image?'
                            : 'Are you sure you want to remove this file?'}
                        {!!modalDeleteIriOpened &&
                            // eslint-disable-next-line
                            allStateImages?.[modalDeleteIriOpened]?.content_url &&
                            // eslint-disable-next-line
                            isImage(allStateImages?.[modalDeleteIriOpened]?.content_url) && (
                                <img
                                    style={{
                                        display: 'block',
                                        width: '64px',
                                        maxWidth: '100%',
                                        margin: '8px 0 0',
                                        border: '1px solid #f2f2f2'
                                    }}
                                    src={getImageSrc(modalDeleteIriOpened, 'small')}
                                    alt="alt"
                                />
                            )}
                    </>
                }
            >
                <React.Fragment>
                    <Button
                        label={deleteModalButtonDelete}
                        onClick={() => {
                            handleRemoveItemUserRequest(modalDeleteIriOpened);
                            setModalDeleteIriOpened(false);
                        }}
                    />
                    <Button
                        label={deleteModalButtonCancel}
                        onClick={() => setModalDeleteIriOpened(false)}
                        variation="secondary"
                    />
                </React.Fragment>
            </Modal>
            {!!useImageManager && (!!modalEditorOpened || modalEditorOpened === 0) && (
                <ImageManager
                    imageIri={modalEditorOpened}
                    opened={!!modalEditorOpened || modalEditorOpened === 0}
                    versionName={versionName}
                    onClose={() => setModalEditorOpened(false)}
                    onSaveImage={data => handleEditedImage(data)}
                />
            )}
            <ImageManager
                type="upload"
                localImageData={initialEditData?.imageData}
                opened={!!isInitialEditActive}
                versionName={versionName}
                onClose={() => {
                    setIsInitialEditActive(false);
                    if (initialEditData?.tempId) {
                        removeTempMedia(initialEditData?.tempId);
                    }
                    setInitialEditData(null);
                }}
                onSaveImage={async data => {
                    setIsInitialEditActive(false);

                    const { positionData, canvasData } = { ...data };
                    const { tempId, formData } = { ...initialEditData };

                    if (canvasData) {
                        const fetchBase64 = await fetch(canvasData);
                        const fileModified = await fetchBase64.blob();
                        formData.append('file_modified', fileModified);
                    }

                    if (positionData) {
                        formData.append(
                            'options',
                            JSON.stringify({ ...positionData, quality: 90 })
                        );
                    }

                    Promise.all(uploadImagesPromises([{ tempId, formData }]))
                        .then(mediaObjects => {
                            Promise.all(addMediaObjectsToGalleryPromises(mediaObjects)).then(() => {
                                // saveSort().finally(() => {
                                // TODO:
                                startFetchImages();
                                // });
                            });
                        })
                        .catch(error => {
                            return dispatch({
                                type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                                response: error
                            });
                        })
                        .finally(() => {
                            // no destructuring, access directly
                            // eslint-disable-next-line camelcase
                            if (initialEditData?.imageData?.content_url) {
                                URL.revokeObjectURL(initialEditData.imageData.content_url);
                            }
                            setInitialEditData(null);
                        });
                }}
            />
            {!!useLightbox && !!(lightboxImageIri || lightboxImageIri === 0) && (
                <Lightbox
                    mainSrc={lightboxData.mainSrc}
                    nextSrc={lightboxData.nextSrc}
                    prevSrc={lightboxData.prevSrc}
                    onCloseRequest={() => setLightboxImageIri(null)}
                    onMovePrevRequest={() => setPrevLightboxImage()}
                    onMoveNextRequest={() => setNextLightboxImage()}
                />
            )}
        </div>
    );
};

Media.defaultProps = {
    attachedToEntity: null,
    input: null,
    fields: null,
    meta: null,
    allowedFileTypes: null,
    maxImageCount: -1,
    featuredKey: null,
    versionName: 'small',
    canAddImage: true,
    canSort: true,
    canDelete: true,
    useImageManager: true,
    useLightbox: true,
    deleteModalButtonDelete: 'Remove',
    deleteModalButtonCancel: 'Cancel',
    emptyGalleryMessage: 'Gallery is empty!',
    useMediaLibrary: true,
    required: false
};

Media.propTypes = {
    attachedToEntity: PropTypes.shape({
        type: PropTypes.string,
        iri: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    }),
    id: PropTypes.string.isRequired,
    input: PropTypes.oneOfType([PropTypes.object]),
    fields: PropTypes.oneOfType([PropTypes.object]), // Passed by redux-form FieldArray. The fields object is a "pseudo-array".
    meta: PropTypes.oneOfType([PropTypes.object]), // provided by redux-form FieldArray
    allowedFileTypes: PropTypes.oneOfType([PropTypes.array]), // write it in lowercase
    maxImageCount: PropTypes.oneOfType([PropTypes.number]),
    featuredKey: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    versionName: PropTypes.string,
    canAddImage: PropTypes.bool, // Control if images can be added
    canSort: PropTypes.bool, // Control if images can be sorted
    canDelete: PropTypes.bool, // Control if items can be removed from the FieldArray
    useImageManager: PropTypes.bool, // Control if ImageManager is used to edit image
    useLightbox: PropTypes.bool, // Control if image can be displayed in lightbox
    deleteModalButtonDelete: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // Custom Delete Modal button label
    deleteModalButtonCancel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // Custom Delete Modal button label
    emptyGalleryMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]), // Custom "Empty Gallery with no add image button" label
    useMediaLibrary: PropTypes.bool,
    required: PropTypes.bool
};

export default Media;
