import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm } from 'redux-form';

import Button from 'erpcore/components/Button';
import Form from 'erpcore/components/Form';

import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { getBulkActionsModalIsOpened } from 'erpcore/components/Listing/Listing.selectors';

const MediaLibraryBulkDelete = ({
    handleSubmit,
    submitting,
    reducerName,
    onComplete,
    batchRequestEndpoint
}) => {
    const dispatch = useDispatch();
    const isOpen = useSelector(state => getBulkActionsModalIsOpened(state, reducerName));

    const handleModal = () => {
        return dispatch({
            type: listingActions.TOGGLE_BULK_ACTIONS_MODAL,
            name: reducerName,
            response: !isOpen
        });
    };

    const onSubmit = () => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: listingActions.START_BATCH_REQUESTS,
                reducerName,
                notificationCode: 'media.bulkActions.mediaSuccessfullyDelete',
                method: 'DELETE',
                apiEndpoint: batchRequestEndpoint
            })
        )
            .then(() => {
                if (onComplete && typeof onComplete === 'function') onComplete();
                handleModal();
            })
            .catch(error => {
                return error;
            });
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <p>Are you sure you want to delete selected media objects?</p>

            <Button submit loading={submitting} label="Delete" />
            <Button variation="secondary" label="Cancel" onClick={() => handleModal()} />
        </Form>
    );
};

MediaLibraryBulkDelete.defaultProps = {
    submitting: false,
    onComplete: () => {}
};

MediaLibraryBulkDelete.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    reducerName: PropTypes.string.isRequired,
    onComplete: PropTypes.func,
    batchRequestEndpoint: PropTypes.string.isRequired
};

export default reduxForm({
    form: 'MediaLibraryBulkDeleteForm',
    enableReinitialize: true
})(MediaLibraryBulkDelete);
