import React, { useState } from 'react';
import { change, Field, reduxForm } from 'redux-form';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Svg from 'erpcore/components/Svg';
import Tooltip from 'erpcore/components/Tooltip';
import Modal from 'erpcore/components/Modal';
import Form, { Media } from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';
import { actions as challengeTypesActions } from 'erpcore/screens/Settings/ChallengeTypes/ChallengeTypes.reducer';
import { valueValidation } from 'erpcore/components/Form/Form.utils';

const ChallengeTypeEditModal = ({
    iri,
    media,
    type,
    handleSubmit,
    submitting,
    pristine,
    invalid
}) => {
    const dispatch = useDispatch();
    const [opened, setOpened] = useState(false);

    const handleModal = () => {
        if (media) dispatch(change('ChangeTypeEditModal', 'icon', opened ? '' : media));
        setOpened(!opened);
    };

    const onSubmit = formData => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: challengeTypesActions.START_UPDATING_SETTINGS_CHALLENGE_TYPE_ICON,
                iri,
                formData,
                refreshListing: true
            })
        )
            .then(() => {
                handleModal();
            })
            .catch(error => {
                handleModal();
                return error;
            });
    };

    const modalTitle = type ? `Edit ${type} type` : 'Edit type';

    return iri ? (
        <>
            <Tooltip content="Edit icon">
                <button type="button" onClick={() => handleModal()}>
                    <Svg icon="edit" />
                </button>
            </Tooltip>
            <Modal
                variation="small"
                opened={opened}
                onClose={() => handleModal()}
                title={modalTitle}
            >
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.SectionTitleSmall>Type image</Form.SectionTitleSmall>
                    <Field
                        name="icon"
                        id="icon"
                        canDelete
                        component={Media}
                        useLightbox={false}
                        useImageManager
                        allowedFileTypes={['jpg', 'jpeg', 'png', 'gif']}
                        validate={valueValidation([{ validator: 'required' }])}
                        required
                    />
                    <Button
                        submit
                        disabled={pristine || invalid}
                        loading={submitting}
                        label={media ? 'Save' : 'Add'}
                    />
                    <Button variation="secondary" label="Cancel" onClick={() => handleModal()} />
                </Form>
            </Modal>
        </>
    ) : (
        ''
    );
};

ChallengeTypeEditModal.defaultProps = {
    submitting: false,
    pristine: false,
    invalid: false,
    media: '',
    type: ''
};

ChallengeTypeEditModal.propTypes = {
    iri: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    media: PropTypes.string,
    type: PropTypes.string
};

export default reduxForm({
    form: 'ChangeTypeEditModal',
    enableReinitialize: true
})(ChallengeTypeEditModal);
