import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import LayoutManager from 'erpcore/utils/LayoutManager';
import EventsEditPageHeader from 'erpcore/screens/Events/screens/EventsEdit/components/EventEditPageHeader';
import EntityNotificationsListing from 'erpcore/screens/EntityNotifications/screens/EntityNotificationsListing';
import PageContent from 'erpcore/components/Layout/PageContent';

const Notification = () => {
    const match = useRouteMatch();
    const id = match?.params?.id;
    const iri = `/api/events/${id}`;

    const params = {
        'filters[event][equals]': iri,
        'order_by[id]': 'DESC'
    };

    return (
        <LayoutManager slot="main" layoutType="merge" className="main--medium">
            <EventsEditPageHeader />
            <PageContent>
                <EntityNotificationsListing
                    entityIri={iri}
                    endpointParams={params}
                    endpoint="api/event-notifications"
                    entity="event"
                />
            </PageContent>
        </LayoutManager>
    );
};

export default Notification;
