import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import './EmploymentHistoryRecord.scss';

const EmploymentHistoryRecord = ({
    jobTitle,
    salary,
    salaryType,
    salaryPayoutCycle,
    employmentType,
    office,
    division,
    department,
    reportsTo,
    comment,
    contract
}) => {
    const { first_name: reportsToFirstName, last_name: reportsToLastName } = { ...reportsTo };
    const reportsToName =
        reportsToFirstName || reportsToLastName
            ? `${reportsToFirstName || null} ${reportsToLastName || null}`
            : null;

    const { content_url: contractContentUrl, meta: contractMeta } = { ...contract };
    const { filename: contractName } = { ...contractMeta };

    return (
        <div className="employment-history-record">
            <div className="employment-history-record__item">
                <h4 className="employment-history-record__title">Job title</h4>
                <div className="employment-history-record__value">{jobTitle?.name || null}</div>
            </div>

            <div className="employment-history-record__item">
                <h4 className="employment-history-record__title">Employment type</h4>
                <div className="employment-history-record__value">{employmentType || null}</div>
            </div>

            <div className="employment-history-record__item">
                <h4 className="employment-history-record__title">Salary</h4>
                <div className="employment-history-record__value">
                    {salary?.amount && (
                        <NumberFormat
                            value={salary.amount / 100}
                            suffix={` ${salary?.currency} / `}
                            displayType="text"
                            thousandSeparator
                            decimalSeparator="."
                            fixedDecimalScale
                            decimalScale={2}
                        />
                    )}
                    {salaryType || null} ({!!salaryPayoutCycle && `${salaryPayoutCycle} payout`})
                </div>
            </div>

            <div className="employment-history-record__item">
                <h4 className="employment-history-record__title">Office</h4>
                <div className="employment-history-record__value">{office?.name || null}</div>
            </div>

            <div className="employment-history-record__item">
                <h4 className="employment-history-record__title">Division</h4>
                <div className="employment-history-record__value">{division?.name || null}</div>
            </div>

            <div className="employment-history-record__item">
                <h4 className="employment-history-record__title">Department</h4>
                <div className="employment-history-record__value">{department?.name || null}</div>
            </div>

            <div className="employment-history-record__item">
                <h4 className="employment-history-record__title">Reports to</h4>
                <div className="employment-history-record__value">{reportsToName}</div>
            </div>

            <div className="employment-history-record__item">
                <h4 className="employment-history-record__title">Contract</h4>
                <div className="employment-history-record__value">
                    {!!contractContentUrl && (
                        <a
                            style={{
                                wordBreak: 'break-all'
                            }}
                            href={contractContentUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            download
                        >
                            {contractName || contractContentUrl}
                        </a>
                    )}
                </div>
            </div>

            <div className="employment-history-record__item">
                <h4 className="employment-history-record__title">Comment</h4>
                <div
                    className="employment-history-record__value"
                    dangerouslySetInnerHTML={{ __html: comment }}
                />
            </div>
        </div>
    );
};

EmploymentHistoryRecord.defaultProps = {
    jobTitle: null,
    salary: null,
    salaryType: null,
    salaryPayoutCycle: null,
    employmentType: null,
    office: null,
    division: null,
    department: null,
    reportsTo: null,
    comment: null,
    contract: null
};

EmploymentHistoryRecord.propTypes = {
    jobTitle: PropTypes.oneOfType([PropTypes.object]),
    salary: PropTypes.oneOfType([PropTypes.object]),
    salaryType: PropTypes.string,
    salaryPayoutCycle: PropTypes.string,
    employmentType: PropTypes.string,
    office: PropTypes.oneOfType([PropTypes.object]),
    division: PropTypes.oneOfType([PropTypes.object]),
    department: PropTypes.oneOfType([PropTypes.object]),
    reportsTo: PropTypes.oneOfType([PropTypes.object]),
    comment: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
    contract: PropTypes.oneOfType([PropTypes.object])
};

export default EmploymentHistoryRecord;
