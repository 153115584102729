import React from 'react';
import { useDispatch } from 'react-redux';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';
import { reset, SubmissionError } from 'redux-form';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import PageContent from 'erpcore/components/Layout/PageContent';
import ContactCreateEditForm from 'erpcore/screens/Contacts/components/ContactCreateEditForm';
import { actions as contactsActions } from 'erpcore/screens/Contacts/Contacts.reducer';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';

const ContactCreate = () => {
    const dispatch = useDispatch();
    const title = 'Add new contact';
    const initialValues = {
        phone: '+1'
    };

    const onSubmit = formData => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: contactsActions.START_CREATE_CONTACT,
                formData
            });
        })
            .then(() => {
                dispatch(reset('ContactCreateForm'));
            })
            .catch(error => {
                dispatch(reset('ContactCreateForm'));
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    };

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title} />
            <PageContent>
                <ContactCreateEditForm
                    onSubmit={onSubmit}
                    form="ContactCreateForm"
                    submitLabel="Create"
                    initialValues={initialValues}
                />
            </PageContent>
        </LayoutManager>
    );
};

export default ContactCreate;
