export const actions = {
    START_FETCHING_CUSTOMER_CONTACTS: 'START_FETCHING_CUSTOMER_CONTACTS',
    FETCHING_SUCCESSFUL_CUSTOMER_CONTACTS: 'FETCHING_SUCCESSFUL_CUSTOMER_CONTACTS',
    FETCHING_FAILED_CUSTOMER_CONTACTS: 'FETCHING_FAILED_CUSTOMER_CONTACTS',

    START_CREATE_CUSTOMER_CONTACT: 'START_CREATE_CUSTOMER_CONTACT',
    CREATE_CUSTOMER_CONTACT_SUCCESSFUL: 'CREATE_CUSTOMER_CONTACT_SUCCESSFUL',
    CREATE_CUSTOMER_CONTACT_FAILED: 'CREATE_CUSTOMER_CONTACT_FAILED',

    START_UPDATE_CUSTOMER_CONTACT: 'START_UPDATE_CUSTOMER_CONTACT',
    UPDATE_CUSTOMER_CONTACT_SUCCESSFUL: 'UPDATE_CUSTOMER_CONTACT_SUCCESSFUL',
    UPDATE_CUSTOMER_CONTACT_FAILED: 'UPDATE_SINGLE_CUSTOMER_FAILED',

    START_DELETE_CUSTOMER_CONTACT: 'START_DELETE_CUSTOMER_CONTACT',
    DELETE_CUSTOMER_CONTACT_SUCCESSFUL: 'DELETE_CUSTOMER_CONTACT_SUCCESSFUL',
    DELETE_CUSTOMER_CONTACT_FAILED: 'DELETE_CUSTOMER_CONTACT_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: [],
    singleFetching: false,
    singleError: null,
    deleteSingleError: null
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_FETCHING_CUSTOMER_CONTACTS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_CUSTOMER_CONTACTS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_CUSTOMER_CONTACTS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }

        default:
            return state;
    }
};
