import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { reduxForm } from 'redux-form';
import Form from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';
import Svg from 'erpcore/components/Svg';
import Tooltip from 'erpcore/components/Tooltip';
import Modal from 'erpcore/components/Modal';
import { actions as eventTeamActions } from 'erpcore/screens/Events/screens/EventsEdit/screens/Teams/Teams.reducer';

const DeleteTeam = ({ id, handleSubmit, submitting, name, onDelete }) => {
    const [opened, setOpened] = useState(false);

    const dispatch = useDispatch();

    const handleModal = () => {
        setOpened(!opened);
    };

    const onSubmit = () => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: eventTeamActions.START_DELETE_EVENT_TEAM,
                id
            })
        )
            .then(() => {
                handleModal();
                if (onDelete && typeof onDelete === 'function') onDelete();
            })
            .catch(error => {
                handleModal();
                return error;
            });
    };
    return (
        <Fragment>
            <Tooltip content="Delete team">
                <button type="button" onClick={() => handleModal()}>
                    <Svg icon="remove" />
                </button>
            </Tooltip>
            <Modal
                variation="small"
                opened={opened}
                onClose={() => handleModal()}
                title="Delete team"
                subtitle={
                    <>
                        Are you sure you want to delete team <strong>{name}</strong>?
                    </>
                }
            >
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Button submit loading={submitting} label="Delete" />
                    <Button variation="secondary" label="Cancel" onClick={() => handleModal()} />
                </Form>
            </Modal>
        </Fragment>
    );
};

DeleteTeam.defaultProps = {
    submitting: false,
    id: null,
    onDelete: () => {}
};

DeleteTeam.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string.isRequired,
    onDelete: PropTypes.func
};

export default reduxForm({
    enableReinitialize: true
})(DeleteTeam);
