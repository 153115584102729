import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Button from 'erpcore/components/Button';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import PageHeader from 'erpcore/components/Layout/PageHeader';

import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { hasPermission } from 'erpcore/utils/RolesManager';

const UserEditPageHeader = ({ pageTitle }) => {
    const meData = useSelector(getMeData) || {};

    return (
        <Fragment>
            <HeadMeta title={pageTitle} />
            <PageHeader title={pageTitle}>
                {hasPermission('CAN_MANAGE_CORE_BUNDLE_USER', meData) && (
                    <Button href="/users/create" label="Create user" />
                )}
            </PageHeader>
        </Fragment>
    );
};

UserEditPageHeader.defaultProps = {
    pageTitle: null
};

UserEditPageHeader.propTypes = {
    pageTitle: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
};

export default UserEditPageHeader;
