import { all } from 'redux-saga/effects';
import { authManagerSaga } from 'erpcore/utils/AuthManager/AuthManager.saga';
import { forgotPasswordSaga } from 'erpcore/screens/ForgotPassword/ForgotPassword.saga';
import imageManagerSaga from 'erpcore/components/ImageManager/ImageManager.saga';
import mediaLibraryGallerySaga from 'erpcore/components/Form/components/Media/components/MediaLibraryModal/components/MediaLibraryGallery/MediaLibraryGallery.saga';
import { myAccountSaga } from 'erpcore/screens/MyAccount/MyAccount.saga';
import activityLogSaga from 'erpcore/components/ActivityLog/ActivityLog.saga';
import { listingSaga } from 'erpcore/components/Listing/Listing.saga';
import { commentsSaga } from 'erpcore/components/Comments/Comments.saga';
import dashboardsSaga from 'erpcore/screens/Dashboard/Dashboard.saga';
import eventsWidgetSaga from 'erpcore/screens/Dashboard/DashboardWidgets.saga';
import { activitiesSaga } from 'erpcore/screens/Activities/Activities.saga';
import { challengesSaga } from 'erpcore/screens/Challenges/Challenges.saga';
import { challengeTypesSaga } from 'erpcore/screens/Settings/ChallengeTypes/ChallengeTypes.saga';
import { activityTypesSaga } from 'erpcore/screens/Settings/ActivityTypes/ActivityTypes.saga';
import { customersSaga } from 'erpcore/screens/Customers/Customers.saga';
import { customerContactsSaga } from 'erpcore/screens/Customers/screens/CustomerContacts/CustomerContacts.saga';
import { contactsSaga } from 'erpcore/screens/Contacts/Contacts.saga';
import { eventsSaga } from 'erpcore/screens/Events/Events.saga';
import permissionsSaga from 'erpcore/screens/Settings/Permissions/Permissions.saga';
import rolesSaga from 'erpcore/screens/Settings/Roles/Roles.saga';
import guidelinesSaga from 'erpcore/screens/Settings/EventGuidelines/EventGuidelines.saga';
import { employmentRecordsSaga } from 'erpcore/screens/Users/screens/UserEmploymentRecords/UserEmploymentRecords.saga';
import { entityChallengesSaga } from 'erpcore/screens/Challenges/screens/EntityChallenges/EntityChallenges.saga';
import { entityNotificationsSaga } from 'erpcore/screens/EntityNotifications/EntityNotifications.saga';
import { entityContentSaga } from 'erpcore/screens/EntityContent/EntityContent.saga';
import { usersSaga } from 'erpcore/screens/Users/Users.saga';
import { eventParticipantSaga } from 'erpcore/screens/Events/screens/EventsEdit/screens/Participants/Participants.saga';
import { mediaLibrarySaga } from 'erpcore/screens/MediaLibrary/MediaLibrary.saga';
import { eventTeamSaga } from 'erpcore/screens/Events/screens/EventsEdit/screens/Teams/Teams.saga';

export default function* rootSaga() {
    yield all([...activityTypesSaga]);
    yield all([...authManagerSaga]);
    yield all([...forgotPasswordSaga]);
    yield all([...imageManagerSaga]);
    yield all([...mediaLibraryGallerySaga]);
    yield all([...myAccountSaga]);
    yield all([...activityLogSaga]);
    yield all([...listingSaga]);
    yield all([...commentsSaga]);
    yield all([...dashboardsSaga]);
    yield all([...eventsWidgetSaga]);
    yield all([...activitiesSaga]);
    yield all([...challengesSaga]);
    yield all([...customersSaga]);
    yield all([...customerContactsSaga]);
    yield all([...contactsSaga]);
    yield all([...eventsSaga]);
    yield all([...employmentRecordsSaga]);
    yield all([...entityChallengesSaga]);
    yield all([...entityNotificationsSaga]);
    yield all([...entityContentSaga]);
    yield all([...permissionsSaga]);
    yield all([...rolesSaga]);
    yield all([...usersSaga]);
    yield all([...challengeTypesSaga]);
    yield all([...eventParticipantSaga]);
    yield all([...eventTeamSaga]);
    yield all([...guidelinesSaga]);
    yield all([...mediaLibrarySaga]);
    yield all([...guidelinesSaga]);
}
