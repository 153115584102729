import { takeLatest, put, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import dto from 'erpcore/utils/dto';
import { actions as entityNotificationsActions } from './EntityNotifications.reducer';

/**
 * Delete Single Entity Notification
 * @param  {Object} id ID of a Entity Notification
 * @return {Object} Response from API
 */
export function* deleteSingleEntityNotification({ promise, iri }) {
    try {
        const deleteSingleEntityNotificationAPI = yield restClient.delete(iri);
        yield put({
            type: entityNotificationsActions.DELETE_ENTITY_NOTIFICATION_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteSingleEntityNotificationAPI?.data
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: entityNotificationsActions.DELETE_ENTITY_NOTIFICATION_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Create Entity Notification
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createEntityNotification({ promise, formData, endpoint }) {
    try {
        const createEntityNotificationAPI = yield restClient.post(endpoint, formData);
        yield put({
            type: entityNotificationsActions.CREATE_ENTITY_NOTIFICATION_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createEntityNotificationAPI?.data
        });
        yield call(promise.resolve, createEntityNotificationAPI?.data);
    } catch (error) {
        yield put({
            type: entityNotificationsActions.CREATE_ENTITY_NOTIFICATION_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Entity Notification
 * @param  {Object} promise
 * @param {string} activity Iri
 * @return {Object} response from API
 */
export function* fetchSingleEntityNotification({ promise, iri, params }) {
    try {
        const fetchSingleEntityNotificationAPI = yield restClient.get(iri, { params });
        yield put({
            type: entityNotificationsActions.FETCH_ENTITY_NOTIFICATION_SUCCESSFUL
        });
        yield put({
            type: entityNotificationsActions.STORE_ENTITY_NOTIFICATION_DATA,
            iri,
            response: dto(fetchSingleEntityNotificationAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: entityNotificationsActions.FETCH_ENTITY_NOTIFICATION_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Single EntityNotification data
 * @param  {String} EntityNotification
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* updateSingleEntityNotification({ promise, formData, iri }) {
    try {
        const updateSingleEntityNotificationAPI = yield restClient.put(iri, formData);
        yield put({
            type: entityNotificationsActions.UPDATE_ENTITY_NOTIFICATION_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleEntityNotificationAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: entityNotificationsActions.UPDATE_ENTITY_NOTIFICATION_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export const entityNotificationsSaga = [
    takeLatest(
        entityNotificationsActions.START_DELETE_ENTITY_NOTIFICATION,
        deleteSingleEntityNotification
    ),
    takeLatest(
        entityNotificationsActions.START_CREATE_ENTITY_NOTIFICATION,
        createEntityNotification
    ),
    takeLatest(
        entityNotificationsActions.START_FETCHING_ENTITY_NOTIFICATION,
        fetchSingleEntityNotification
    ),
    takeLatest(
        entityNotificationsActions.START_UPDATE_ENTITY_NOTIFICATION,
        updateSingleEntityNotification
    )
];
