export const actions = {
    START_FETCHING_EVENT_PARTCIPANTS: 'START_FETCHING_EVENT_PARTCIPANTS',
    FETCHING_SUCCESSFUL_EVENT_PARTICIPANTS: 'FETCHING_SUCCESSFUL_EVENT_PARTICIPANTS',
    FETCHING_FAILED_EVENT_PARTICIPANTS: 'FETCHING_FAILED_EVENT_PARTICIPANTS',

    START_CREATING_EVENT_PARTICIPANT: 'START_CREATING_EVENT_PARTICIPANT',
    EVENT_PARTICIPANT_CREATING_SUCCESS: 'EVENT_PARTICIPANT_CREATING_SUCCESS',
    EVENT_PARTICIPANT_CREATING_FAILED: 'EVENT_PARTICIPANT_CREATING_FAILED',

    START_UPDATING_EVENT_PARTICIPANT: 'START_UPDATING_EVENT_PARTICIPANT',
    EVENT_PARTICIPANT_UPDATING_SUCCESS: 'EVENT_PARTICIPANT_UPDATING_SUCCESS',
    EVENT_PARTICIPANT_UPDATING_FAILED: 'EVENT_PARTICIPANT_UPDATING_FAILED',

    START_MAKING_PARTICIPANT_TEAM_LEADER: 'START_MAKING_PARTICIPANT_TEAM_LEADER',
    PARTICIPANT_TEAM_LEADER_SUCCESS: 'PARTICIPANT_TEAM_LEADER_SUCCESS',
    PARTICIPANT_TEAM_LEADER_FAILED: 'PARTICIPANT_TEAM_LEADER_FAILED',

    START_DELETING_EVENT_PARTICIPANT: 'START_DELETING_EVENT_PARTICIPANT',
    EVENT_PARTICIPANT_DELETE_SUCCESS: 'EVENT_PARTICIPANT_DELETE_SUCCESS',
    EVENT_PARTICIPANT_DELETE_FAILED: 'EVENT_PARTICIPANT_DELETE_FAILED',

    START_EVENT_IMPORTING_PARTICIPANTS: 'START_EVENT_IMPORTING_PARTICIPANTS',
    EVENT_IMPORTING_PARTICIPANTS_SUCCESS: 'EVENT_IMPORTING_PARTICIPANTS_SUCCESS',
    EVENT_IMPORTING_PARTICIPANTS_FAILED: 'EVENT_IMPORTING_PARTICIPANTS_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: []
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_FETCHING_EVENT_PARTCIPANTS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_EVENT_PARTICIPANTS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_EVENT_PARTICIPANTS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        default:
            return state;
    }
};
