import React from 'react';
import PropTypes from 'prop-types';
import {
    Field,
    getFormInitialValues,
    getFormValues,
    hasSubmitSucceeded,
    reduxForm
} from 'redux-form';
import Form, {
    Autocomplete,
    Email,
    GeneratePassword,
    Media,
    Select,
    Text
} from 'erpcore/components/Form';
import { valueValidation } from 'erpcore/components/Form/Form.utils';
import Button from 'erpcore/components/Button';
import UnsavedChanges from 'erpcore/components/UnsavedChanges';
import { useSelector } from 'react-redux';
import { diff } from 'deep-object-diff';

const UserCreateForm = ({
    handleSubmit,
    submitting,
    pristine,
    invalid,
    onSubmit,
    submitLabel,
    form,
    coordinatorOnlyUserTypes
}) => {
    const hasSubmitted = useSelector(state => hasSubmitSucceeded(form)(state));

    const formValues = useSelector(state => getFormValues(form)(state));
    const initialValues = useSelector(state => getFormInitialValues(form)(state));

    const checkIfFormIsDirty = () => {
        const difference = diff(initialValues, formValues);
        const keys = Object.keys(difference);

        keys.forEach(key => {
            if (difference[key] === null || difference[key] === '') {
                delete difference[key];
            }
        });

        delete difference?.included;
        delete difference?.location?.fullAddressSelected;

        if (difference?.location && !Object?.keys(difference?.location)?.length) {
            delete difference.location;
        }

        if (Object.keys(difference)?.length) {
            return true;
        }

        return false;
    };

    return (
        <Form
            onSubmit={handleSubmit(formData => onSubmit({ formData, preventEditRedirect: false }))}
        >
            <Form.SectionTitle>Personal details</Form.SectionTitle>

            <UnsavedChanges
                promptWhen={checkIfFormIsDirty() && !hasSubmitted && !submitting}
                onSubmit={handleSubmit(formData =>
                    onSubmit({ formData, preventEditRedirect: true })
                )}
            />

            <Form.Columns>
                <Form.Column>
                    <Form.Row>
                        <Field
                            name="first_name"
                            id="first_name"
                            fieldProps={{
                                label: 'First name',
                                clearable: true
                            }}
                            fieldAttr={{ required: true }}
                            component={Text}
                            validate={valueValidation([{ validator: 'required' }])}
                        />
                    </Form.Row>
                    <Form.Row>
                        <Field
                            name="last_name"
                            id="last_name"
                            fieldProps={{
                                label: 'Last name',
                                clearable: true
                            }}
                            fieldAttr={{ required: true }}
                            component={Text}
                            validate={valueValidation([{ validator: 'required' }])}
                        />
                    </Form.Row>
                    <Form.Row>
                        <Field
                            name="email"
                            id="email"
                            fieldProps={{
                                label: 'Email',
                                clearable: true
                            }}
                            fieldAttr={{ required: true }}
                            component={Email}
                            validate={valueValidation([
                                { validator: 'email' },
                                { validator: 'required' }
                            ])}
                        />
                    </Form.Row>
                </Form.Column>

                <Form.Column>
                    <Form.SectionTitleSmall>Profile photo</Form.SectionTitleSmall>
                    <Form.Row>
                        <Field
                            name="image"
                            id="image"
                            component={Media}
                            useLightbox
                            useImageManager
                            allowedFileTypes={['jpg', 'jpeg', 'png', 'gif']}
                        />
                    </Form.Row>
                </Form.Column>
            </Form.Columns>
            <Form.Row>
                <Field
                    name="timezone"
                    id="timezone"
                    fieldProps={{
                        label: 'Timezone',
                        options: {
                            endpoint: '/api/timezones?pagination=false&order_by[sort_order]=ASC',
                            mapData: {
                                value: 'iri',
                                label: 'title'
                            }
                        },
                        clearable: true,
                        helperText: 'User will receive emails with time specified in this timezone.'
                    }}
                    component={Autocomplete}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="userTypes"
                    id="userTypes"
                    fieldProps={{
                        label: 'User type',
                        options: coordinatorOnlyUserTypes || {
                            endpoint: '/api/user-types/',
                            mapData: {
                                value: 'iri',
                                label: 'name'
                            }
                        },
                        clearable: true
                    }}
                    component={coordinatorOnlyUserTypes ? Select : Autocomplete}
                />
            </Form.Row>
            <Form.SectionTitleSmall>Password</Form.SectionTitleSmall>
            <Form.Row>
                <GeneratePassword name="password" passwordLabel="Password" />
            </Form.Row>

            <Form.Row>
                <Button
                    onClick={handleSubmit(formData =>
                        onSubmit({ formData, preventEditRedirect: false })
                    )}
                    disabled={pristine || invalid}
                    loading={submitting}
                    label={submitLabel}
                />
            </Form.Row>
        </Form>
    );
};

UserCreateForm.defaultProps = {
    onSubmit: () => {},
    submitting: false,
    submitLabel: 'Create',
    pristine: false,
    invalid: false,
    form: 'UserForm',
    coordinatorOnlyUserTypes: null
};

UserCreateForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    submitLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    form: PropTypes.string,
    coordinatorOnlyUserTypes: PropTypes.oneOfType([PropTypes.array])
};

export default reduxForm({
    enableReinitialize: true,
    initialValues: {
        is_active: true
    }
})(UserCreateForm);
