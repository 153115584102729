import React from 'react';
import PropTypes from 'prop-types';

import { NavLink } from 'react-router-dom';
import Svg from 'erpcore/components/Svg';

import './Menu.scss';

/**
 * Main Navigation (Menu - for ease of use) Component
 */
function Menu({ id, children }) {
    return (
        <nav id={id} className="main-navigation">
            <ul className="main-navigation__menu" aria-labelledby="main-navigation">
                {children}
            </ul>
        </nav>
    );
}

Menu.defaultProps = {
    id: 'main-navigation',
    children: null
};

Menu.propTypes = {
    id: PropTypes.string,
    children: PropTypes.node
};

/**
 * Menu Item Component
 */
Menu.Item = function Item({ to, label }) {
    return (
        <li className="main-navigation__menu-item">
            <NavLink
                to={to}
                className="main-navigation__menu-link"
                activeClassName="main-navigation__menu-link--active"
            >
                {label}
            </NavLink>
        </li>
    );
};

Menu.Item.defaultProps = {
    to: null,
    label: ''
};

Menu.Item.propTypes = {
    to: PropTypes.string,
    label: PropTypes.string
};

/**
 * Submenu Component
 */
Menu.Submenu = function Submenu({ children, label }) {
    const toggleSubmenu = event => {
        event.preventDefault();
        event.target
            .closest('.main-navigation__menu-item')
            .classList.toggle('main-navigation__menu-item--open');
    };

    return (
        <li className="main-navigation__menu-item">
            <button
                type="button"
                className="main-navigation__menu-link"
                onClick={event => toggleSubmenu(event)}
            >
                {label}
                <mark className="main-navigation__menu-link-badge">
                    <Svg icon="arrowDown" />
                </mark>
            </button>
            <ul className="main-navigation__submenu">{children}</ul>
        </li>
    );
};

Menu.Submenu.defaultProps = {
    label: '',
    children: null
};

Menu.Submenu.propTypes = {
    label: PropTypes.string,
    children: PropTypes.node
};

/**
 * Submenu Item Component
 */
Menu.SubmenuItem = function SubmenuItem({ to, label }) {
    return (
        <li className="main-navigation__submenu-item">
            <NavLink
                to={to}
                className="main-navigation__submenu-link"
                activeClassName="main-navigation__submenu-link--active"
            >
                {label}
            </NavLink>
        </li>
    );
};

Menu.SubmenuItem.defaultProps = {
    to: null,
    label: ''
};

Menu.SubmenuItem.propTypes = {
    to: PropTypes.string,
    label: PropTypes.string
};

export default Menu;
