import React from 'react';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import EventViewPageHeader from 'erpcore/screens/Events/screens/EventsView/components/EventViewPageHeader';
import EventViewInfo from 'erpcore/screens/Events/screens/EventsView/components/EventViewInfo';
import EventViewDetails from 'erpcore/screens/Events/screens/EventsView/components/EventViewDetails';
import EventViewActions from 'erpcore/screens/Events/screens/EventsView/components/EventViewActions';
import EventViewLeaderboards from 'erpcore/screens/Events/screens/EventsView/components/EventViewLeaderboards';
import EventViewCoordinators from 'erpcore/screens/Events/screens/EventsView/components/EventViewCoordinators';
import EventViewTestimonials from 'erpcore/screens/Events/screens/EventsView/components/EventViewTestimonials';

const EventInfo = () => {
    return (
        <LayoutManager slot="main" layoutType="merge">
            <EventViewPageHeader />
            <PageContent>
                <PageContent.Section>
                    <EventViewInfo />
                </PageContent.Section>
                <PageContent.Section>
                    <PageContent.Columns>
                        <PageContent.GridColumn size={8}>
                            <PageContent.Title>Event details</PageContent.Title>
                            <EventViewDetails />
                        </PageContent.GridColumn>
                        <PageContent.GridColumn size={4}>
                            <PageContent.Title>Actions</PageContent.Title>
                            <EventViewActions />
                        </PageContent.GridColumn>
                    </PageContent.Columns>
                    <PageContent.Columns>
                        <PageContent.GridColumn size={8}>
                            <PageContent.Title>Leaderboard</PageContent.Title>
                            <EventViewLeaderboards />
                        </PageContent.GridColumn>
                        <PageContent.GridColumn size={4}>
                            <PageContent.Title>Event coordinators</PageContent.Title>
                            <EventViewCoordinators />
                        </PageContent.GridColumn>
                    </PageContent.Columns>
                    <PageContent.Row>
                        <PageContent.Title>Testimonials</PageContent.Title>
                        <EventViewTestimonials />
                    </PageContent.Row>
                </PageContent.Section>
            </PageContent>
        </LayoutManager>
    );
};

export default EventInfo;
