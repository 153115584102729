import { takeEvery, put, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import dto, { getIdFromIri } from 'erpcore/utils/dto';
import { actions as imageManagerActions } from './ImageManager.reducer';

/*
const fakeMediaObject = {
    id: 4,
    // content_url:
    // 'https://gloweas-api.myzone.com/media/upload/2019/10/04/5d96f7c2b2c3c569412192.jpg',
    // content_url: 'https://placekitten.com/2000/2000',
    content_url: '/email/test.jpg',
    versions: [
        {
            id: 0,
            content_url: 'https://placekitten.com/100/100',
            name: 'edited',
            meta: {
                name: 'my-logo.jpg',
                type: 'image/jpg',
                created_at: '2019-10-03T09:50:25.043Z',
                size: '457000',
                dimensions: '692 x 617'
            },
            options: {
                crop: {
                    height: 183,
                    width: 212,
                    x: 275,
                    y: 207
                },
                rotate: {
                    angle: -30
                },
                flip: {
                    h: false, // scaleX: -1
                    v: false // scaleY: -1
                }
            },
            // options: {
            //     height: 750,
            //     rotate: 30,
            //     scaleX: -1,
            //     scaleY: 1,
            //     width: 1125,
            //     x: 878,
            //     y: 776
            // },
            created_at: '2019-10-02T09:50:25.043Z'
        }
    ],
    meta: {
        // name: 'my-logo.jpg',
        // type: 'image/jpg',
        // created_at: '2019-10-02T09:50:25.043Z',
        // size: '3120000',
        // dimensions: '842 x 595'
    }
};
*/

/**
 * Create Image
 * @param  {Object} promise
 * @param  {string|integer} id
 * @return {void}
 */
export function* createImage({ promise, formData }) {
    try {
        const fetchImageAPI = yield restClient.post(`api/media-objects`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        });
        yield put({
            type: imageManagerActions.CREATE_IMAGE_SUCCESSFUL,
            response: dto(fetchImageAPI.data).data
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: dto(fetchImageAPI.data).data
        });
        yield call(promise.resolve, fetchImageAPI.data);
    } catch (error) {
        const errorData =
            error && error.response && error.response.data ? error.response.data : null;
        yield put({
            type: imageManagerActions.CREATE_IMAGE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: errorData
        });
        yield call(promise.reject, errorData);
    }
}

/**
 * Fetch Image
 * @param  {Object} promise
 * @param  {string} iri
 * @param  {Object} params
 * @return {void}
 */
export function* fetchImage({ promise, iri = null, params = {} }) {
    try {
        const defaultParams = {
            include: 'versions,tags'
        };
        const fetchImageAPI = yield restClient.get(`api/media-objects/${getIdFromIri(iri)}`, {
            params: { ...defaultParams, ...params }
        });
        yield put({
            type: imageManagerActions.FETCH_IMAGE_SUCCESSFUL,
            response: dto(fetchImageAPI.data).data,
            iri
            // response: fetchImageAPI.data
            // response: fakeMediaObject
        });
        yield call(promise.resolve);
    } catch (error) {
        const errorData =
            error && error.response && error.response.data ? error.response.data : null;
        yield put({
            type: imageManagerActions.FETCH_IMAGE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: errorData
        });
        yield call(promise.reject, errorData);
    }
}

/**
 * Update image data
 * @param  {Object} promise
 * @param  {string} iri
 * @param  {Object} formData
 * @return {void}
 */
export function* updateImage({ promise, formData, iri }) {
    try {
        const updateImageAPI = yield restClient.patch(
            `api/media-objects/${getIdFromIri(iri)}`,
            formData
        );
        yield put({
            type: imageManagerActions.UPDATE_IMAGE_SUCCESSFUL,
            // response: dto(fetchImageAPI.data)
            response: dto(updateImageAPI.data).data,
            iri
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateImageAPI.data
        });
        yield call(promise.resolve);
    } catch (error) {
        const errorData =
            error && error.response && error.response.data ? error.response.data : null;
        yield put({
            type: imageManagerActions.UPDATE_IMAGE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: errorData
        });
        yield call(promise.reject, errorData);
    }
}

/**
 * Delete Image
 * @param  {Object} promise
 * @param  {string|integer} id
 * @return {void}
 */
export function* deleteImage({ promise, id }) {
    try {
        const deleteImageAPI = yield restClient.delete(`api/media-objects/${id}`);
        yield put({
            type: imageManagerActions.DELETE_IMAGE_SUCCESSFUL
        });
        yield put({
            type: imageManagerActions.RESET_IMAGE_EDITOR_STATE
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteImageAPI.data
        });
        yield call(promise.resolve);
    } catch (error) {
        const errorData =
            error && error.response && error.response.data ? error.response.data : null;
        yield put({
            type: imageManagerActions.DELETE_IMAGE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: errorData
        });
        yield call(promise.reject, errorData);
    }
}

/**
 * Delete Image Version
 * @param  {Object} promise
 * @param  {string|integer} versionId
 * @param  {Object} params
 * @return {void}
 */
export function* deleteImageVersion({ promise, versionId }) {
    try {
        yield restClient.delete(`api/media-objects/${versionId}`);
        yield put({
            type: imageManagerActions.DELETE_IMAGE_VERSION_SUCCESSFUL
        });
        yield call(promise.resolve);
    } catch (error) {
        const errorData =
            error && error.response && error.response.data ? error.response.data : null;
        yield put({
            type: imageManagerActions.DELETE_IMAGE_VERSION_FAILED
        });
        yield call(promise.reject, errorData);
    }
}

/**
 * Create image version
 * @param  {Object} promise
 * @param  id {string|integer}
 * @param  versionData {Object}
 * @param  versionData.media_object {string} MediaObject IRI for which the version should be created ie. "/api/media-objects/50"
 * @param  versionData.name {string} MediaObjectVersion name ie. "edited"
 * @return {void}
 */
export function* createImageVersion({ promise, versionData }) {
    try {
        const updateImageAPI = yield restClient.post('api/media-objects', versionData);
        yield put({
            type: imageManagerActions.CREATE_IMAGE_VERSION_SUCCESSFUL
            // response: dto(updateImageAPI.data).data
            // response: updateImageAPI.data
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateImageAPI.data
        });
        yield call(promise.resolve);
    } catch (error) {
        const errorData =
            error && error.response && error.response.data ? error.response.data : null;
        yield put({
            type: imageManagerActions.CREATE_IMAGE_VERSION_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: errorData
        });
        yield call(promise.reject, errorData);
    }
}

/**
 * Register action to watcher
 */
export default [
    takeEvery(imageManagerActions.START_CREATE_IMAGE, createImage),
    takeEvery(imageManagerActions.START_FETCHING_IMAGE, fetchImage),
    takeEvery(imageManagerActions.START_UPDATE_IMAGE, updateImage),
    takeEvery(imageManagerActions.START_DELETE_IMAGE, deleteImage),
    takeEvery(imageManagerActions.START_DELETE_IMAGE_VERSION, deleteImageVersion),
    takeEvery(imageManagerActions.START_CREATE_IMAGE_VERSION, createImageVersion)
];
