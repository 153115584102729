import React, { useCallback } from 'react';
import { reduxForm, Field, getFormValues } from 'redux-form';
import Form, { Text, Select } from 'erpcore/components/Form';
import { valueValidation } from 'erpcore/components/Form/Form.utils';
import {
    entityNotificationsTriggerTypes,
    entityNotificationsUnits
} from 'erpcore/screens/EntityNotifications/EntityNotifications.data';
import Button from 'erpcore/components/Button';
import PropTypes from 'prop-types';
import { parseInteger } from 'erpcore/utils/utils';
import { useSelector } from 'react-redux';

const EntityNotificationsAddEditForm = ({
    invalid,
    pristine,
    submitting,
    submitLabel,
    handleSubmit,
    onSubmit,
    onCancel,
    form
}) => {
    const formValues = useSelector(state => getFormValues(form)(state));
    const { type } = { ...formValues };

    const isDisabled = useCallback(() => type === 'AT_THE_END', [type]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
                <Field
                    name="content"
                    fieldProps={{ label: 'Message' }}
                    fieldAttr={{ id: 'content', required: true }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.SectionTitleSmall>Display time settings</Form.SectionTitleSmall>
            <Form.Row>
                <Form.GridColumn size={6}>
                    <Field
                        name="time"
                        fieldProps={{ label: 'Value' }}
                        fieldAttr={{ id: 'time', required: !isDisabled(), disabled: isDisabled() }}
                        component={Text}
                        parse={parseInteger}
                        validate={valueValidation([isDisabled() ? {} : { validator: 'required' }])}
                    />
                </Form.GridColumn>
                <Form.GridColumn size={6}>
                    <Field
                        name="unit"
                        fieldProps={{ label: 'Unit', options: entityNotificationsUnits }}
                        fieldAttr={{ id: 'unit', required: !isDisabled(), disabled: isDisabled() }}
                        component={Select}
                        validate={valueValidation([isDisabled() ? {} : { validator: 'required' }])}
                    />
                </Form.GridColumn>
            </Form.Row>
            <Form.SectionTitleSmall>Trigger settings</Form.SectionTitleSmall>

            <Form.Row>
                <Field
                    name="type"
                    fieldProps={{
                        label: 'Choose trigger',
                        options: entityNotificationsTriggerTypes
                    }}
                    fieldAttr={{ id: 'type', required: true }}
                    component={Select}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>

            <Button
                label={submitLabel}
                submit
                disabled={invalid || pristine}
                loading={submitting}
            />

            {onCancel && <Button label="Cancel" variation="secondary" onClick={onCancel} />}
        </Form>
    );
};

EntityNotificationsAddEditForm.defaultProps = {
    invalid: false,
    pristine: false,
    handleSubmit: () => {},
    onSubmit: () => {},
    onCancel: null,
    submitting: false,
    submitLabel: 'Add'
};

EntityNotificationsAddEditForm.propTypes = {
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    handleSubmit: PropTypes.func,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    submitLabel: PropTypes.string,
    onCancel: PropTypes.func,
    form: PropTypes.string.isRequired
};

export default reduxForm({ form: 'EntityNotificationsAddEditForm', enableReinitialize: true })(
    EntityNotificationsAddEditForm
);
