import React from 'react';
import Form, { Switch } from 'erpcore/components/Form';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';

const EntityChallengeSwitch = ({ name, handleChange, iri, onChange }) => {
    return (
        <Form>
            <Field
                name={name}
                fieldAttr={{ id: `${name}_${iri}` }}
                onChange={value => {
                    onChange(value);
                    handleChange(value);
                }}
                component={Switch}
            />
        </Form>
    );
};

EntityChallengeSwitch.defaultProps = {
    onChange: () => {}
};

EntityChallengeSwitch.propTypes = {
    name: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    iri: PropTypes.string.isRequired,
    onChange: PropTypes.func
};

export default reduxForm({
    form: 'EntityChallengeSwitchForm',
    enableReinitialize: true,
    destroyOnUnmount: false
})(EntityChallengeSwitch);
