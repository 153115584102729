export const actions = {
    START_FETCHING_ACTIVITIES: 'START_FETCHING_ACTIVITIES',
    FETCHING_SUCCESSFUL_ACTIVITIES: 'FETCHING_SUCCESSFUL_ACTIVITIES',
    FETCHING_FAILED_ACTIVITIES: 'FETCHING_FAILED_ACTIVITIES',

    START_DELETE_ACTIVITY: 'START_DELETE_ACTIVITY',
    DELETE_ACTIVITY_SUCCESSFUL: 'DELETE_ACTIVITY_SUCCESSFUL',
    DELETE_ACTIVITY_FAILED: 'DELETE_ACTIVITY_FAILED',

    START_CREATE_ACTIVITY: 'START_CREATE_ACTIVITY',
    CREATE_ACTIVITY_SUCCESSFUL: 'CREATE_ACTIVITY_SUCCESSFUL',
    CREATE_ACTIVITY_FAILED: 'CREATE_ACTIVITY_FAILED',

    START_FETCHING_ACTIVITY: 'START_FETCHING_ACTIVITY',
    FETCH_ACTIVITY_SUCCESSFUL: 'FETCH_ACTIVITY_SUCCESSFUL',
    FETCH_ACTIVITY_FAILED: 'FETCH_ACTIVITY_FAILED',
    STORE_ACTIVITY_DATA: 'STORE_ACTIVITY_DATA',

    START_UPDATE_ACTIVITY: 'START_UPDATE_ACTIVITY',
    UPDATE_ACTIVITY_SUCCESSFUL: 'UPDATE_ACTIVITY_SUCCESSFUL',
    UPDATE_ACTIVITY_FAILED: 'UPDATE_ACTIVITY_FAILED',

    START_DUPLICATE_ACTIVITY: 'START_DUPLICATE_ACTIVITY',
    DUPLICATE_ACTIVITY_SUCCESS: 'DUPLICATE_ACTIVITY_SUCCESS',
    DUPLICATE_ACTIVITY_FAILED: 'DUPLICATE_ACTIVITY_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: [],
    singleFetching: false,
    singleError: null,
    deleteSingleError: null,
    isDuplicateActivityInProgress: false
};

export default (state = initialState, { type, response, iri }) => {
    switch (type) {
        case actions.START_DUPLICATE_ACTIVITY: {
            return {
                ...state,
                isDuplicateActivityInProgress: true
            };
        }
        case actions.DUPLICATE_ACTIVITY_SUCCESS: {
            return {
                ...state,
                isDuplicateActivityInProgress: false
            };
        }
        case actions.DUPLICATE_ACTIVITY_FAILED: {
            return {
                ...state,
                isDuplicateActivityInProgress: false
            };
        }
        case actions.START_FETCHING_ACTIVITIES: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.START_FETCHING_ACTIVITY: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCH_ACTIVITY_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.STORE_ACTIVITY_DATA: {
            // Updating the store
            return Object.assign({}, state, {
                [iri]: response?.data
            });
        }
        case actions.FETCH_ACTIVITY_FAILED: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCHING_SUCCESSFUL_ACTIVITIES: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_ACTIVITIES: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.DELETE_ACTIVITY_FAILED: {
            return {
                ...state,
                deleteSingleError: response
            };
        }

        case actions.UPDATE_ACTIVITY_FAILED: {
            return {
                ...state,
                singleError: response
            };
        }

        default:
            return state;
    }
};
