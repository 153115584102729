import React from 'react';
import PropTypes from 'prop-types';
import Svg from 'erpcore/components/Svg';
import './StarRating.scss';

const StarRating = ({ rating, starCount, ratingNumberPosition }) => {
    const thresholds = [35, 50, 66, 100];

    const calculateWidth = index => {
        if (!rating) {
            return '0%';
        }

        const remainings = parseFloat(rating).toFixed(1) - index;

        if (remainings > 1) {
            return '100%';
        }

        if (remainings <= 0) {
            return '0%';
        }

        const closestThreshold = thresholds.reduce((prev, curr) => {
            return Math.abs(curr - (parseFloat(rating).toFixed(1) - index) * 100) <
                Math.abs(prev - (parseFloat(rating).toFixed(1) - index) * 100)
                ? curr
                : prev;
        });

        return `${closestThreshold}%`;
    };

    const renderStars = () => {
        const stars = [];

        for (let i = 0; i < starCount; i += 1) {
            stars.push(
                <div key={`starRating__${i}`} className="star-rating__single">
                    <div className="star-rating__single-outer">
                        <Svg icon="starFull" />
                    </div>
                    <div
                        className="star-rating__single-inner"
                        style={{
                            width: calculateWidth(i)
                        }}
                    >
                        <Svg icon="starEmpty" />
                    </div>
                </div>
            );
        }

        return stars.map(star => star);
    };

    return (
        <div className="star-rating">
            {rating !== null && rating !== undefined && ratingNumberPosition === 'left' && (
                <span
                    className={`star-rating__rating star-rating__rating--${ratingNumberPosition}`}
                >
                    {rating}
                </span>
            )}
            {renderStars()}
            {rating !== null && rating !== undefined && ratingNumberPosition === 'right' && (
                <span
                    className={`star-rating__rating star-rating__rating--${ratingNumberPosition}`}
                >
                    {rating}
                </span>
            )}
        </div>
    );
};

StarRating.defaultProps = {
    starCount: 5,
    ratingNumberPosition: 'left'
};

StarRating.propTypes = {
    rating: PropTypes.string.isRequired,
    starCount: PropTypes.number,
    ratingNumberPosition: PropTypes.oneOf(['left', 'right'])
};

export default StarRating;
