import React from 'react';
import LayoutManager from 'erpcore/utils/LayoutManager';
import ActivityEditPageHeader from 'erpcore/screens/Activities/screens/ActivitiesEdit/components/ActivityEditPageHeader';
import PageContent from 'erpcore/components/Layout/PageContent';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleActivityData } from 'erpcore/screens/Activities/Activities.selectors';
import { useRouteMatch } from 'react-router-dom';
import { actions as activityActions } from 'erpcore/screens/Activities/Activities.reducer';
import EventGuidelineForm from 'erpcore/screens/Events/screens/EventsEdit/screens/EventGuidelines/components/EventGuidelinesForm';

const EventGuidelines = () => {
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const id = match?.params?.id;
    const iri = `/api/activities/${id}`;
    const activityData = useSelector(state => getSingleActivityData(state, iri));
    const { guideline_content: initialGuidelineContent, guideline: initialGuideline } = {
        ...activityData
    };

    const initialValues = {
        guideline: initialGuideline?.iri || '',
        guidelineContent: initialGuidelineContent || '',
        overrideGuideline: !!initialGuidelineContent
    };

    const onSubmit = formValues => {
        const { guideline, overrideGuideline, guidelineContent } = { ...formValues };
        const formData = {
            guideline,
            guideline_content: overrideGuideline ? guidelineContent : ''
        };
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: activityActions.START_UPDATE_ACTIVITY,
                formData,
                iri
            });
        });
    };

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <ActivityEditPageHeader />
            <PageContent>
                <EventGuidelineForm
                    onSubmit={onSubmit}
                    initialValues={initialValues}
                    form="ActivityEditEventGuidelineForm"
                />
            </PageContent>
        </LayoutManager>
    );
};

export default EventGuidelines;
