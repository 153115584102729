import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { reduxForm } from 'redux-form';
import Form from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';
import Svg from 'erpcore/components/Svg';
import Tooltip from 'erpcore/components/Tooltip';
import { actions as contactsActions } from 'erpcore/screens/Contacts/Contacts.reducer';
import Modal from 'erpcore/components/Modal';

const ContactsDelete = ({ id, handleSubmit, submitting, name }) => {
    const [opened, setOpened] = useState(false);
    const dispatch = useDispatch();
    const contactIri = `/api/contacts/${id}`;

    const handleModal = () => {
        setOpened(!opened);
    };

    const onSubmit = () => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: contactsActions.START_DELETE_CONTACT,
                iri: contactIri
            })
        )
            .then(() => {
                handleModal();
            })
            .catch(error => {
                handleModal();
                return error;
            });
    };
    return (
        <Fragment>
            <Tooltip content="Delete contact">
                <button type="button" onClick={() => handleModal()}>
                    <Svg icon="remove" />
                </button>
            </Tooltip>
            <Modal
                variation="small"
                opened={opened}
                onClose={() => handleModal()}
                title="Delete contact"
                subtitle={
                    <>
                        Are you sure you want to delete contact <strong>{name}</strong>?
                        <br />
                        This action is irreversible, record will be permanently deleted.
                    </>
                }
            >
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Button submit loading={submitting} label="Delete" />
                    <Button variation="secondary" label="Cancel" onClick={() => handleModal()} />
                </Form>
            </Modal>
        </Fragment>
    );
};

ContactsDelete.defaultProps = {
    submitting: false,
    id: null,
    name: null
};

ContactsDelete.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string
};

export default reduxForm({
    form: 'ContactDeleteForm',
    enableReinitialize: true
})(ContactsDelete);
