import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import Button from 'erpcore/components/Button';
import Form, { Media } from 'erpcore/components/Form';

import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { getBulkActionsModalIsOpened } from 'erpcore/components/Listing/Listing.selectors';
import { valueValidation } from 'erpcore/components/Form/Form.utils';

const EntityChallengeBulkMediaEdit = ({
    handleSubmit,
    submitting,
    reducerName,
    callback,
    invalid,
    pristine,
    batchRequestEndpoint
}) => {
    const dispatch = useDispatch();
    const isOpen = useSelector(state => getBulkActionsModalIsOpened(state, reducerName));

    const handleModal = () => {
        return dispatch({
            type: listingActions.TOGGLE_BULK_ACTIONS_MODAL,
            name: reducerName,
            response: !isOpen
        });
    };

    const onSubmit = data => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: listingActions.START_BATCH_REQUESTS,
                reducerName,
                notificationCode: 'challenge.bulkActions.challengesSuccessfullyUpdated',
                method: 'PUT',
                data: {
                    media: data?.media ? [{ media: data?.media, title: '', description: '' }] : []
                },
                apiEndpoint: batchRequestEndpoint
            })
        )
            .then(() => {
                if (callback) {
                    callback();
                }
                handleModal();
            })
            .catch(error => {
                return error;
            });
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
                <Field
                    name="media"
                    id="media"
                    component={Media}
                    useLightbox
                    useImageManager
                    allowedFileTypes={['jpg', 'jpeg', 'png', 'gif']}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>

            <Button disabled={invalid || pristine} submit loading={submitting} label="Save" />
            <Button variation="secondary" label="Cancel" onClick={() => handleModal()} />
        </Form>
    );
};

EntityChallengeBulkMediaEdit.defaultProps = {
    submitting: false,
    callback: null,
    invalid: false,
    pristine: false
};

EntityChallengeBulkMediaEdit.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    reducerName: PropTypes.string.isRequired,
    callback: PropTypes.func,
    batchRequestEndpoint: PropTypes.string.isRequired,
    invalid: PropTypes.bool,
    pristine: PropTypes.bool
};

export default reduxForm({
    form: 'EntityChallengeBulkEdit',
    enableReinitialize: true
})(EntityChallengeBulkMediaEdit);
