import React from 'react';
import Button from 'erpcore/components/Button';
import Form, { Text } from 'erpcore/components/Form';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { valueValidation } from 'erpcore/components/Form/Form.utils';
import style from './ActivityTypeCreateEditForm.module.scss';

const ActivityTypeCreateEditForm = ({
    handleSubmit,
    submitting,
    onCancel,
    inline,
    onSubmit,
    pristine,
    invalid
}) => {
    return (
        <Form
            onSubmit={handleSubmit(onSubmit)}
            className={inline ? style['form-wrapper__inline'] : ''}
        >
            <Form.Columns className={!inline ? style['form-wrapper'] : ''}>
                <Form.GridColumn size={!inline ? 12 : 10}>
                    <Field
                        name="name"
                        fieldProps={{ label: 'Title' }}
                        fieldAttr={{ id: 'name' }}
                        component={Text}
                        validate={valueValidation([{ validator: 'required' }])}
                    />
                </Form.GridColumn>
                <Form.GridColumn size={!inline ? 12 : 2}>
                    <Button
                        submit
                        loading={submitting}
                        disabled={pristine || invalid}
                        label={!inline ? 'Save' : 'Add new'}
                    />
                    {!inline && <Button variation="secondary" label="Cancel" onClick={onCancel} />}
                </Form.GridColumn>
            </Form.Columns>
        </Form>
    );
};

ActivityTypeCreateEditForm.defaultProps = {
    submitting: false,
    onCancel: () => {},
    inline: false,
    pristine: false,
    invalid: false
};

ActivityTypeCreateEditForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    onCancel: PropTypes.func,
    inline: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool
};

export default reduxForm({
    form: 'ActivityTypesCreateEditForm',
    enableReinitialize: true
})(ActivityTypeCreateEditForm);
