export const actions = {
    START_FETCHING_SETTINGS_ACTIVITY_TYPES: 'START_FETCHING_SETTINGS_ACTIVITY_TYPES',
    FETCHING_SUCCESSFUL_SETTINGS_ACTIVITY_TYPES: 'FETCHING_SUCCESSFUL_SETTINGS_ACTIVITY_TYPES',
    FETCHING_FAILED_SETTINGS_ACTIVITY_TYPES: 'FETCHING_FAILED_SETTINGS_ACTIVITY_TYPES',

    START_DELETE_ACTIVITY_TYPE: 'START_DELETE_ACTIVITY_TYPE',
    DELETE_ACTIVITY_TYPE_SUCCESSFUL: 'DELETE_ACTIVITY_TYPE_SUCCESSFUL',
    DELETE_ACTIVITY_TYPE_FAILED: 'DELETE_ACTIVITY_TYPE_FAILED',

    START_UPDATE_ACTIVITY_TYPE: 'START_UPDATE_ACTIVITY_TYPE',
    UPDATE_ACTIVITY_TYPE_SUCCESSFUL: 'UPDATE_ACTIVITY_TYPE_SUCCESSFUL',
    UPDATE_ACTIVITY_TYPE_FAILED: 'UPDATE_ACTIVITY_TYPE_FAILED',
    STORE_ACTIVITY_TYPE_DATA: 'STORE_ACTIVITY_TYPE_DATA',

    START_CREATE_ACTIVITY_TYPE: 'START_CREATE_ACTIVITY_TYPE',
    CREATE_ACTIVITY_TYPE_SUCCESSFUL: 'CREATE_ACTIVITY_TYPE_SUCCESSFUL',
    CREATE_ACTIVITY_TYPE_FAILED: 'CREATE_ACTIVITY_TYPE_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: [],
    deleteSingleError: null,
    singleError: null
};

export default (state = initialState, { type, response, iri }) => {
    switch (type) {
        case actions.START_FETCHING_SETTINGS_ACTIVITY_TYPES: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_SETTINGS_ACTIVITY_TYPES: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_SETTINGS_ACTIVITY_TYPES: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.DELETE_ACTIVITY_TYPE_FAILED: {
            return {
                ...state,
                deleteSingleError: response
            };
        }
        case actions.STORE_ACTIVITY_TYPE_DATA: {
            // Updating the store
            return Object.assign({}, state, {
                [iri]: response?.data
            });
        }
        case actions.UPDATE_ACTIVITY_TYPE_FAILED: {
            return {
                ...state,
                singleError: response
            };
        }
        default:
            return state;
    }
};
