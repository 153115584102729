import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, getFormValues } from 'redux-form';
import Form, { FileUploader } from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { actions as imageManagerActions } from 'erpcore/components/ImageManager/ImageManager.reducer';

const MediaLibraryUpload = ({
    form,
    allowedFileTypes,
    submitting,
    pristine,
    invalid,
    onSubmit,
    maxImageCount,
    isMulti
}) => {
    const dispatch = useDispatch();
    const formValues = useSelector(state => getFormValues(form)(state));

    return (
        <Form>
            <Form.Row>
                <Field
                    name="files"
                    id="files"
                    fieldProps={{
                        showUploaded: true,
                        allowedExtensions: allowedFileTypes || [],
                        maxImageCount,
                        isMulti,
                        gridView: true,
                        onComplete: data => {
                            dispatch({
                                type: imageManagerActions.UPDATE_IMAGE_SUCCESSFUL,
                                iri: data.data.id,
                                response: data.data
                            });
                        }
                    }}
                    hideDropZoneOnUpload
                    component={FileUploader}
                />
            </Form.Row>
            <Form.Row>
                <Button
                    disabled={pristine || invalid}
                    loading={submitting}
                    label="Insert media"
                    onClick={() => {
                        if (onSubmit && typeof onSubmit === 'function') onSubmit(formValues);
                    }}
                />
            </Form.Row>
        </Form>
    );
};

MediaLibraryUpload.defaultProps = {
    allowedFileTypes: null,
    submitting: false,
    pristine: false,
    invalid: false,
    onSubmit: () => {},
    maxImageCount: -1,
    isMulti: false
};

MediaLibraryUpload.propTypes = {
    form: PropTypes.string.isRequired,
    allowedFileTypes: PropTypes.oneOfType([PropTypes.array]),
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    onSubmit: PropTypes.func,
    maxImageCount: PropTypes.oneOfType([PropTypes.number]),
    isMulti: PropTypes.bool
};

export default reduxForm({
    enableReinitialize: true
})(MediaLibraryUpload);
