export const actions = {
    START_FETCHING_ENTITY_CONTENT: 'START_FETCHING_ENTITY_CONTENT',
    FETCHING_SUCCESSFUL_ENTITY_CONTENT: 'FETCHING_SUCCESSFUL_ENTITY_CONTENT',
    FETCHING_FAILED_ENTITY_CONTENT: 'FETCHING_FAILED_ENTITY_CONTENT',

    START_DELETE_ENTITY_CONTENT: 'START_DELETE_ENTITY_CONTENT',
    DELETE_ENTITY_CONTENT_SUCCESSFUL: 'DELETE_ENTITY_CONTENT_SUCCESSFUL',
    DELETE_ENTITY_CONTENT_FAILED: 'DELETE_ENTITY_CONTENT_FAILED',

    START_CREATE_ENTITY_CONTENT: 'START_CREATE_ENTITY_CONTENT',
    CREATE_ENTITY_CONTENT_SUCCESSFUL: 'CREATE_ENTITY_CONTENT_SUCCESSFUL',
    CREATE_ENTITY_CONTENT_FAILED: 'CREATE_ENTITY_CONTENT_FAILED',

    START_UPDATE_ENTITY_CONTENT: 'START_UPDATE_ENTITY_CONTENT',
    UPDATE_ENTITY_CONTENT_SUCCESSFUL: 'UPDATE_ENTITY_CONTENT_SUCCESSFUL',
    UPDATE_ENTITY_CONTENT_FAILED: 'UPDATE_ENTITY_CONTENT_FAILED',

    START_CREATE_ENTITY_CONTENT_ITEM: 'START_CREATE_ENTITY_CONTENT_ITEM',
    CREATE_ENTITY_CONTENT_ITEM_SUCCESSFUL: 'CREATE_ENTITY_CONTENT_ITEM_SUCCESSFUL',
    CREATE_ENTITY_CONTENT_ITEM_FAILED: 'CREATE_ENTITY_CONTENT_ITEM_FAILED',

    START_CREATE_ENTITY_CONTENT_ITEM_MEDIA: 'START_CREATE_ENTITY_CONTENT_ITEM_MEDIA',
    CREATE_ENTITY_CONTENT_ITEM_MEDIA_SUCCESSFUL: 'CREATE_ENTITY_CONTENT_ITEM_MEDIA_SUCCESSFUL',
    CREATE_ENTITY_CONTENT_ITEM_MEDIA_FAILED: 'CREATE_ENTITY_CONTENT_ITEM_MEDIA_FAILED'
};

export const initialState = {
    content: {},
    fetching: false
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_CREATE_ENTITY_CONTENT_ITEM: {
            return {
                ...state,
                fetching: true
            };
        }
        case actions.CREATE_ENTITY_CONTENT_ITEM_SUCCESSFUL: {
            return {
                ...state,
                fetching: false
            };
        }
        case actions.CREATE_ENTITY_CONTENT_ITEM_FAILED: {
            return {
                ...state,
                fetching: false
            };
        }
        case actions.START_CREATE_ENTITY_CONTENT_ITEM_MEDIA: {
            return {
                ...state,
                fetching: true
            };
        }
        case actions.CREATE_ENTITY_CONTENT_ITEM_MEDIA_SUCCESSFUL: {
            return {
                ...state,
                fetching: false
            };
        }
        case actions.CREATE_ENTITY_CONTENT_ITEM_MEDIA_FAILED: {
            return {
                ...state,
                fetching: false
            };
        }
        case actions.START_FETCHING_ENTITY_CONTENT: {
            return {
                ...state,
                fetching: true
            };
        }
        case actions.START_FETCHING_ACTIVITY: {
            return {
                ...state,
                fetching: true
            };
        }
        case actions.FETCH_ACTIVITY_SUCCESSFUL: {
            return {
                ...state,
                fetching: false
            };
        }
        case actions.FETCH_ACTIVITY_FAILED: {
            return {
                ...state,
                fetching: false
            };
        }
        case actions.FETCHING_SUCCESSFUL_ENTITY_CONTENT: {
            return {
                ...state,
                fetching: false,
                content: response
            };
        }
        case actions.FETCHING_FAILED_ENTITY_CONTENT: {
            return {
                ...state,
                fetching: false
            };
        }
        case actions.DELETE_ENTITY_CONTENT_FAILED: {
            return {
                ...state
            };
        }

        case actions.UPDATE_ENTITY_CONTENT_FAILED: {
            return {
                ...state
            };
        }

        default:
            return state;
    }
};
