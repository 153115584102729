import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'erpcore/components/Modal';
import EmploymentRecordForm from 'erpcore/screens/Users/components/EmploymentRecordForm';
import {
    getSingleEmploymentRecordFetching,
    getEmploymentRecordData
} from 'erpcore/screens/Users/screens/UserEmploymentRecords/UserEmploymentRecords.selectors';
import { actions as employmentRecordsActions } from 'erpcore/screens/Users/screens/UserEmploymentRecords/UserEmploymentRecords.reducer';
import { destroy, SubmissionError } from 'redux-form';
import PageLoader from 'erpcore/components/PageLoader';
import isFinite from 'lodash/isFinite';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';
import { dtoForm } from 'erpcore/utils/dto';
import { dtoIgnoreTimezone } from 'erpcore/utils/utils';
import moment from 'moment-timezone';

const ModalEmploymentRecordEdit = ({ activeModalIri, userIri, runAfterUpdate, closeModal }) => {
    const dispatch = useDispatch();
    const recordFetching = useSelector(state => getSingleEmploymentRecordFetching(state));
    const recordData =
        useSelector(state => dtoForm(getEmploymentRecordData(state, activeModalIri))) || {};

    const { start_date: startDateTemp, end_date: endDateTemp, job_title: jobTitle } = {
        ...recordData
    };

    const startDate = dtoIgnoreTimezone(startDateTemp);
    const endDate = dtoIgnoreTimezone(endDateTemp);

    const initialRecordValues = {
        user: userIri,
        start_date: startDate,
        end_date: endDate,
        job_title: jobTitle,
        salary: {
            amount: recordData?.salary?.amount ? recordData.salary.amount : null,
            currency: recordData?.salary?.currency
        },
        salary_type: recordData.salary_type,
        salary_payout_cycle: recordData.salary_payout_cycle,
        employment_type: recordData.employment_type,
        office: recordData.office,
        division: recordData.division,
        department: recordData.department,
        reports_to: recordData.reports_to,
        comment: recordData.comment,
        contract: recordData.contract
    };

    const onSubmitEditEmploymentRecord = formData => {
        // send ALL data (including initial data)

        if (formData.included) {
            delete formData.included;
        }

        if (formData.start_date === '') {
            formData.start_date = null;
        }

        if (formData.end_date === '') {
            formData.end_date = null;
        }

        if (formData?.salary?.amount) {
            if (!isFinite(formData.salary.amount)) {
                // if is not type Number
                formData.salary.amount = parseInt(formData.salary.amount.replace(/[^\d]/g, ''), 10);
            }
        }

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: employmentRecordsActions.START_UPDATE_SINGLE_EMPLOYMENT_RECORD,
                iri: activeModalIri,
                formData
            })
        )
            .then(() => {
                runAfterUpdate();
            })
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    };

    const fetchSingleUserEmploymentRecord = recordIri => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: employmentRecordsActions.START_FETCHING_SINGLE_EMPLOYMENT_RECORD,
                iri: recordIri
            });
        }).catch(error => ({ error }));
    };

    /*
     * Effects
     */
    useEffect(() => {
        if (activeModalIri) {
            fetchSingleUserEmploymentRecord(activeModalIri);
        } else {
            dispatch(destroy(['EmploymentRecordFormEdit']));
        }
    }, [activeModalIri]);

    const getTitle = () => {
        if (startDate) {
            return `${moment(startDate).format('Do MMM YYYY')}${
                endDate ? ` - ${moment(endDate).format('Do MMM YYYY')}` : ''
            }${jobTitle?.name ? `, ${jobTitle?.name}` : ''}`;
        }

        return null;
    };

    return (
        <Modal
            // variation="medium"
            opened={!!activeModalIri}
            onClose={() => closeModal()}
            title="Edit employment record"
        >
            {!!recordFetching && <PageLoader />}
            <EmploymentRecordForm
                onSubmit={onSubmitEditEmploymentRecord}
                onCancel={() => closeModal()}
                form="EmploymentRecordFormEdit"
                title={getTitle()}
                initialValues={initialRecordValues}
                submitLabel="Update"
            />
        </Modal>
    );
};

ModalEmploymentRecordEdit.defaultProps = {
    userIri: null,
    runAfterUpdate: () => {},
    closeModal: () => {},
    activeModalIri: false
};

ModalEmploymentRecordEdit.propTypes = {
    userIri: PropTypes.string,
    runAfterUpdate: PropTypes.func,
    closeModal: PropTypes.func,
    activeModalIri: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
};

export default ModalEmploymentRecordEdit;
