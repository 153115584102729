import React from 'react';
import Form, { Repeater } from 'erpcore/components/Form';
import { FieldArray, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import EntityContentRepeaterItem from 'erpcore/screens/EntityContent/components/EntityContentRepeaterItem';
import Button from 'erpcore/components/Button';
import UnsavedChanges from 'erpcore/components/UnsavedChanges';

const EntityContentForm = ({ onSubmit, handleSubmit, invalid, pristine, submitting, dirty }) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <UnsavedChanges promptWhen={dirty && !submitting} onSubmit={handleSubmit(onSubmit)} />

            <Form.Row>
                <FieldArray
                    name="contents"
                    component={Repeater}
                    RepeatableItem={EntityContentRepeaterItem}
                    canSort
                    canSortNewItems={false}
                    canAddMultiple
                    canSave={false}
                    uniqueIdentifier="iri"
                    addNewLabel="Add content group"
                />
            </Form.Row>

            <Button
                size="small"
                disabled={invalid || pristine}
                loading={submitting}
                submit
                label="Save"
            />
        </Form>
    );
};

EntityContentForm.defaultProps = {
    onSubmit: () => {},
    handleSubmit: () => {},
    invalid: false,
    pristine: false,
    submitting: false,
    dirty: false
};

EntityContentForm.propTypes = {
    onSubmit: PropTypes.func,
    handleSubmit: PropTypes.func,
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    dirty: PropTypes.bool
};

export default reduxForm({
    form: 'EntityContentForm',
    enableReinitialize: true
})(React.memo(EntityContentForm));
