import React, { useState } from 'react';
import Modal from 'erpcore/components/Modal';
import { actions as entityNotificationsActions } from 'erpcore/screens/EntityNotifications/EntityNotifications.reducer';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { diff } from 'deep-object-diff';
import Tooltip from 'erpcore/components/Tooltip';
import Svg from 'erpcore/components/Svg';
import EntityNotificationAddEditForm from '../EntityNotificationsAddEditForm';

const EntityNotificationsEdit = ({ callback, data }) => {
    const [opened, setOpened] = useState(false);
    const dispatch = useDispatch();
    const { time, content, _type: type } = { ...data };

    const formatTime = () => {
        switch (true) {
            case time % 3600 === 0:
                return time / 3600;
            case time % 60 === 0:
                return time / 60;
            default:
                return time;
        }
    };

    const formatUnit = () => {
        switch (true) {
            case time % 3600 === 0:
                return 'hours';
            case time % 60 === 0:
                return 'minutes';
            default:
                return 'seconds';
        }
    };

    const initialValues = {
        time: time ? formatTime() : undefined,
        unit: time ? formatUnit() : undefined,
        content,
        type
    };

    const handleModal = () => {
        setOpened(!opened);
    };

    const formatTimeValue = (timeInSeconds, unit) => {
        if (unit === 'hours') {
            return parseInt(timeInSeconds, 10) * 60 * 60;
        }

        if (unit === 'minutes') {
            return parseInt(timeInSeconds, 10) * 60;
        }

        if (unit === 'seconds') {
            return parseInt(timeInSeconds, 10);
        }

        return 0;
    };

    const onSubmit = formValues => {
        const difference = diff(initialValues, formValues);
        const formData = { ...difference };

        if (formData.time || formData?.unit) {
            formData.time = formatTimeValue(formValues.time, formValues?.unit);

            delete formData?.unit;
        }

        return new Promise((resolve, reject) => {
            dispatch({
                type: entityNotificationsActions.START_UPDATE_ENTITY_NOTIFICATION,
                promise: { resolve, reject },
                formData,
                iri: data?.iri
            });
        })
            .then(() => {
                handleModal();
                callback();
            })
            .catch(err => err);
    };

    return (
        <>
            <Tooltip content="Edit notification">
                <button type="button" onClick={() => handleModal()}>
                    <Svg icon="edit" />
                </button>
            </Tooltip>
            {opened && (
                <Modal
                    variation="medium"
                    opened={opened}
                    onClose={handleModal}
                    title="Edit notification"
                >
                    <EntityNotificationAddEditForm
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        onCancel={handleModal}
                        submitLabel="Update"
                    />
                </Modal>
            )}
        </>
    );
};

EntityNotificationsEdit.defaultProps = {
    callback: () => {},
    data: {}
};

EntityNotificationsEdit.propTypes = {
    callback: PropTypes.func,
    data: PropTypes.oneOfType([PropTypes.object])
};

export default EntityNotificationsEdit;
