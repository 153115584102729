import { put, call, takeLatest } from 'redux-saga/effects';
import dto from 'erpcore/utils/dto';
import restClient from 'erpcore/api/restClient';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as mediaLibraryActions } from './MediaLibraryGallery.reducer';

const requiredParams = {
    include: 'versions,createdBy,tags',
    'order_by[id]': 'DESC',
    'filters[visible][equals]': 'true',
    'filters[parent][not_exists]': 'true',
    limit: '30'
};

/**
 * Fetch images
 * @param promise
 * @param query {string}
 * @param allowedFileTypes {array}
 * @returns {Generator<SimpleEffect<"PUT", PutEffectDescriptor<{type: string}>>|SimpleEffect<"PUT", PutEffectDescriptor<{response: *, type: string}>>|AxiosPromise<any>|SimpleEffect<"CALL", CallEffectDescriptor<RT | RT | RT>>|SimpleEffect<"PUT", PutEffectDescriptor<{response, type: string}>>, void, *>}
 */
export function* fetchImages({ promise, query = '', allowedFileTypes }) {
    try {
        const imagesResponse = yield restClient.get(`api/media-objects`, {
            params: {
                ...requiredParams,
                'filters[extension][in]': [...(allowedFileTypes || '')],
                q: encodeURIComponent(query)
            }
        });
        yield put({
            type: mediaLibraryActions.FETCHING_MEDIA_LIBRARY_GALLERY_SUCCESS,
            response: dto(imagesResponse.data).data
        });
        yield put({
            type: mediaLibraryActions.SET_MEDIA_LIBRARY_GALLERY_META,
            response: imagesResponse.data.meta
        });
        yield call(promise.resolve);
    } catch (error) {
        const errorData =
            error && error.response && error.response.data ? error.response.data : null;
        yield put({
            type: mediaLibraryActions.FETCHING_MEDIA_LIBRARY_GALLERY_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: errorData
        });
        yield call(promise.reject, errorData);
    }
}

/**
 * Fetch more images (infinite scroll)
 * @param promise
 * @param page
 * @param query
 * @param allowedFileTypes
 * @returns {Generator<SimpleEffect<"PUT", PutEffectDescriptor<{type: string}>>|SimpleEffect<"PUT", PutEffectDescriptor<{response: *, type: string}>>|AxiosPromise<any>|SimpleEffect<"CALL", CallEffectDescriptor<RT | RT | RT>>|SimpleEffect<"PUT", PutEffectDescriptor<{response, type: string}>>, void, *>}
 */
export function* fetchMoreImages({ promise, page, query = '', allowedFileTypes }) {
    try {
        const imagesResponse = yield restClient.get(`api/media-objects`, {
            params: {
                ...requiredParams,
                'filters[extension][in]': [...(allowedFileTypes || '')],
                q: encodeURIComponent(query),
                page
            }
        });
        yield put({
            type: mediaLibraryActions.FETCHING_MORE_MEDIA_LIBRARY_GALLERY_SUCCESS,
            response: dto(imagesResponse.data).data
        });
        yield put({
            type: mediaLibraryActions.SET_MEDIA_LIBRARY_GALLERY_META,
            response: imagesResponse.data.meta
        });
        yield call(promise.resolve);
    } catch (error) {
        const errorData =
            error && error.response && error.response.data ? error.response.data : null;
        yield put({
            type: mediaLibraryActions.FETCHING_MORE_MEDIA_LIBRARY_GALLERY_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: errorData
        });
        yield call(promise.reject, errorData);
    }
}

/**
 * Delete Single Media Object
 * @param promise
 * @param iri
 * @returns {Generator<SimpleEffect<"CALL", CallEffectDescriptor<RT | RT | RT>>|SimpleEffect<"PUT", PutEffectDescriptor<{response: *, type: string}>>|AxiosPromise<any>|SimpleEffect<"PUT", PutEffectDescriptor<{type: string}>>, void, *>}
 */
export function* deleteSingleMediaObject({ promise, iri }) {
    try {
        const deleteSingleEventAPI = yield restClient.delete(iri);
        yield put({
            type: mediaLibraryActions.DELETE_MEDIA_LIBRARY_GALLERY_ITEM_SUCCESS
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteSingleEventAPI?.data
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: mediaLibraryActions.DELETE_MEDIA_LIBRARY_GALLERY_ITEM_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export default [
    takeLatest(mediaLibraryActions.FETCH_MEDIA_LIBRARY_GALLERY, fetchImages),
    takeLatest(mediaLibraryActions.FETCH_MORE_MEDIA_LIBRARY_GALLERY, fetchMoreImages),
    takeLatest(
        mediaLibraryActions.START_DELETING_MEDIA_LIBRARY_GALLERY_ITEM,
        deleteSingleMediaObject
    )
];
