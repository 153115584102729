import React from 'react';
import Form, { Repeater } from 'erpcore/components/Form';
import { FieldArray } from 'redux-form';
import PropTypes from 'prop-types';
import EntityContentItemRepeaterItem from 'erpcore/screens/EntityContent/components/EntityContentItemRepeaterItem';

const EntityContentItemForm = ({ member }) => {
    return (
        <Form.Row>
            <FieldArray
                name={`${member}.content_items`}
                component={Repeater}
                RepeatableItem={EntityContentItemRepeaterItem}
                canSort
                canSortNewItems={false}
                canAddMultiple
                canSave={false}
                uniqueIdentifier="iri"
                addNewLabel="Add content item"
            />
        </Form.Row>
    );
};

EntityContentItemForm.defaultProps = {
    member: null
};

EntityContentItemForm.propTypes = {
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default EntityContentItemForm;
