import React from 'react';
import PropTypes from 'prop-types';
import { Field, getFormValues, hasSubmitSucceeded, reduxForm } from 'redux-form';

import Button from 'erpcore/components/Button';
import CustomerCreateActionModal from 'erpcore/screens/Customers/components/CustomerCreateActionModal';
import CustomerEditActionModal from 'erpcore/screens/Customers/components/CustomerEditActionModal';
import Form, { Autocomplete, DateTime, SwitchCard, Text } from 'erpcore/components/Form';

import { valueValidation } from 'erpcore/components/Form/Form.utils';
import { useSelector } from 'react-redux';
import UnsavedChanges from 'erpcore/components/UnsavedChanges';
import { parseInteger } from 'erpcore/utils/utils';

const ProjectForm = ({
    handleSubmit,
    submitting,
    pristine,
    invalid,
    onSubmit,
    submitLabel,
    form,
    dirty
}) => {
    const formData = useSelector(state => getFormValues(form)(state));
    const hasSubmitted = useSelector(state => hasSubmitSucceeded(form)(state));

    return (
        <Form
            onSubmit={handleSubmit(formValues =>
                onSubmit({ formData: formValues, preventEditRedirect: false })
            )}
        >
            <UnsavedChanges
                promptWhen={dirty && !hasSubmitted && !submitting}
                onSubmit={handleSubmit(formValues =>
                    onSubmit({ formData: formValues, preventEditRedirect: true })
                )}
            />
            <Form.Row>
                <Field
                    name="activity"
                    id="activity"
                    fieldProps={{
                        label: 'Template',
                        options: {
                            endpoint: '/api/activities',
                            mapData: {
                                value: 'iri',
                                label: 'name'
                            }
                        },
                        clearable: true
                    }}
                    component={Autocomplete}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="company"
                    id="company"
                    fieldProps={{
                        label: 'Customer',
                        options: {
                            endpoint: '/api/companies',
                            mapData: {
                                value: 'iri',
                                label: 'name'
                            }
                        },
                        clearable: true,
                        actionButton: {
                            edit: {
                                component: <CustomerEditActionModal formName={form} />
                            },
                            create: {
                                component: <CustomerCreateActionModal formName={form} />
                            }
                        }
                    }}
                    fieldAttr={{ required: true }}
                    component={Autocomplete}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="is_self_hosted"
                    id="is_self_hosted"
                    fieldProps={{
                        label: 'Self-hosted',
                        description: ''
                    }}
                    component={SwitchCard}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="startsAt"
                    id="startsAt"
                    fieldProps={{
                        label: formData?.is_self_hosted ? 'Start date' : 'Start date and time',
                        helperText:
                            formData?.is_self_hosted && formData?.startsAt
                                ? 'automatically starts at 12:01 AM'
                                : null,
                        clearable: true,
                        showTimeSelect: !formData?.is_self_hosted
                    }}
                    outputNoTimeZone
                    component={DateTime}
                    fieldAttr={{
                        required:
                            !!formData?.is_self_hosted ||
                            (!!formData?.timezone && !formData?.startsAt)
                    }}
                    validate={valueValidation([
                        {
                            validator:
                                formData?.is_self_hosted || formData?.timezone ? 'required' : null
                        }
                    ])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="timezone"
                    id="timezone"
                    fieldProps={{
                        label: 'Timezone',
                        options: {
                            endpoint: '/api/timezones?order_by[sort_order]=ASC&pagination=false',
                            mapData: {
                                value: 'iri',
                                label: 'title'
                            }
                        },
                        clearable: true
                    }}
                    component={Autocomplete}
                    fieldAttr={{
                        required:
                            !!formData?.is_self_hosted ||
                            (!!formData?.startsAt && !formData?.timezone)
                    }}
                    validate={valueValidation([
                        {
                            validator:
                                formData?.is_self_hosted || formData?.startsAt ? 'required' : null
                        }
                    ])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="single_participant_team"
                    id="single_participant_team"
                    fieldProps={{
                        label: 'Only team leader will log in',
                        description: ''
                    }}
                    component={SwitchCard}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="number_of_participants"
                    id="number_of_participants"
                    fieldProps={{
                        label: 'Group Size',
                        clearable: false
                    }}
                    parse={parseInteger}
                    fieldAttr={{ required: true }}
                    component={Text}
                    validate={valueValidation([
                        { validator: 'required' },
                        { validator: 'numericality' }
                    ])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="number_of_teams"
                    id="number_of_teams"
                    fieldProps={{
                        label: 'Est. Teams',
                        clearable: false
                    }}
                    parse={parseInteger}
                    fieldAttr={{ required: true }}
                    component={Text}
                    validate={valueValidation([
                        { validator: 'required' },
                        { validator: 'numericality' }
                    ])}
                />
            </Form.Row>
            <Form.Row>
                <Button
                    submit
                    disabled={pristine || invalid}
                    loading={submitting}
                    label={submitLabel}
                />
            </Form.Row>
        </Form>
    );
};

ProjectForm.defaultProps = {
    onSubmit: () => {},
    submitting: false,
    pristine: false,
    invalid: false,
    submitLabel: 'Create',
    form: null,
    dirty: false
};

ProjectForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    submitLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    form: PropTypes.string,
    dirty: PropTypes.bool
};

export default reduxForm({
    form: 'ProjectForm',
    enableReinitialize: true
})(ProjectForm);
