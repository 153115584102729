import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Svg from 'erpcore/components/Svg';
import Tooltip from 'erpcore/components/Tooltip';
import Modal from 'erpcore/components/Modal';
import { actions as eventParticipantActions } from '../../Participants.reducer';
import ParticipantForm from '../ParticipantForm';

const EditParticipantModal = ({ id, eventIri, hasEmailAccess, initialValues, onUpdate }) => {
    const dispatch = useDispatch();
    const [opened, setOpened] = useState(false);

    const handleModal = () => {
        setOpened(!opened);
    };

    const onSubmit = formData => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: eventParticipantActions.START_UPDATING_EVENT_PARTICIPANT,
                id,
                formData
            })
        )
            .then(() => {
                handleModal();
                if (onUpdate && typeof onUpdate === 'function') onUpdate();
            })
            .catch(error => {
                handleModal();
                return error;
            });
    };

    return (
        <>
            <Tooltip content="Edit participant">
                <button type="button" onClick={() => handleModal()}>
                    <Svg icon="edit" />
                </button>
            </Tooltip>
            <Modal
                opened={opened}
                variation="medium"
                onClose={() => handleModal()}
                title="Edit participant"
            >
                <ParticipantForm
                    onSubmit={onSubmit}
                    form={`editParticipant-${id}`}
                    handleModal={handleModal}
                    eventIri={eventIri}
                    initialValues={initialValues}
                    submitLabel="Update"
                    hasEmailAccess={hasEmailAccess}
                />
            </Modal>
        </>
    );
};

EditParticipantModal.defaultProps = {
    initialValues: {},
    onUpdate: () => {},
    hasEmailAccess: false
};

EditParticipantModal.propTypes = {
    id: PropTypes.string.isRequired,
    eventIri: PropTypes.string.isRequired,
    hasEmailAccess: PropTypes.bool,
    initialValues: PropTypes.oneOfType([PropTypes.object]),
    onUpdate: PropTypes.func
};

export default EditParticipantModal;
