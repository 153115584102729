import React, { useEffect, useCallback, useRef, useMemo } from 'react';
import LayoutManager from 'erpcore/utils/LayoutManager';
import EventViewPageHeader from 'erpcore/screens/Events/screens/EventsView/components/EventViewPageHeader';
import PageContent from 'erpcore/components/Layout/PageContent';
import EventFeedFilters from 'erpcore/screens/Events/screens/EventsView/components/EventFeedFilters';
import { useDispatch, useSelector } from 'react-redux';
import { actions as eventActions } from 'erpcore/screens/Events/Events.reducer';
import { useRouteMatch } from 'react-router-dom';
import { getEventFeedsData, getEventFeedsFetching } from 'erpcore/screens/Events/Events.selectors';
import EventFeedCard from 'erpcore/screens/Events/screens/EventsView/components/EventFeedCard';
import ElementLoader from 'erpcore/components/ElementLoader';
import NotificationManager from 'erpcore/utils/NotificationManager';
import 'react-virtualized/styles.css';
import Button from 'erpcore/components/Button';
import { Pagination } from 'erpcore/components/Listing';

const Feed = () => {
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const id = match?.params?.id;
    const iri = `/api/events/${id}`;
    const feeds = useSelector(getEventFeedsData);
    const feedsFetching = useSelector(getEventFeedsFetching);

    const currentPageRef = useRef(1);
    const filteredTeamRef = useRef(null);
    const filteredTypeRef = useRef(null);
    const filteredStatusRef = useRef(null);

    const fetchEventFeeds = useCallback(() => {
        const params = {
            'filters[event_team][equals]': filteredTeamRef?.current,
            'filters[event_challenge.type][equals]': filteredTypeRef.current,
            'filters[status][equals]': filteredStatusRef.current,
            'filters[event][equals]': iri,
            include: 'event,eventChallenge,eventTeam,eventChallenge.type',
            'order_by[id]': 'ASC',
            pagination: true,
            page: currentPageRef?.current || 1
        };

        return new Promise((resolve, reject) => {
            dispatch({
                type: eventActions.START_FETCHING_EVENT_FEEDS,
                promise: { resolve, reject },
                params,
                append: params?.page && params?.page > 1
            });
        });
    }, [iri]);

    const onFilterChange = data => {
        currentPageRef.current = 1;
        filteredTeamRef.current = data?.teams;
        filteredTypeRef.current = data?.type;
        filteredStatusRef.current = data?.status;
        fetchEventFeeds(1);
    };

    useEffect(() => {
        fetchEventFeeds();
    }, []);

    const handleChangePagination = useCallback(data => {
        currentPageRef.current = data?.page || 1;
        fetchEventFeeds();
    }, []);

    const refreshButton = useMemo(() => {
        return <Button label="Refresh" onClick={fetchEventFeeds} variation="secondary" />;
    });

    return (
        <LayoutManager slot="main" className="main--medium main--virtualized" layoutType="merge">
            <EventViewPageHeader />
            <PageContent>
                {feedsFetching && <ElementLoader overlay />}
                <EventFeedFilters onChange={onFilterChange} />
                {!feeds?.data?.length && !feedsFetching && (
                    <NotificationManager code="listingNoData" />
                )}

                {refreshButton}

                <br />
                <br />

                {!!feeds?.data?.length && (
                    <>
                        <Pagination
                            meta={feeds?.meta}
                            onChangePagination={handleChangePagination}
                        />
                        <br />
                        {feeds.data.map(item => (
                            <EventFeedCard key={item?.id} data={item} callback={fetchEventFeeds} />
                        ))}
                        <br />
                        <Pagination
                            meta={feeds?.meta}
                            onChangePagination={handleChangePagination}
                        />
                        <br />
                        <br />
                        {refreshButton}
                    </>
                )}
            </PageContent>
        </LayoutManager>
    );
};

export default Feed;
