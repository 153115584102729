import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { generateKey, getResponsive } from 'erpcore/utils/utils';
import Svg from 'erpcore/components/Svg';
import NotificationManager from 'erpcore/utils/NotificationManager';
import {
    TableHeaderBulkAction,
    TableRowBulkAction
} from 'erpcore/components/Listing/components/BulkActions';
import TableDraggableList from 'erpcore/components/Listing/components/TableDraggableList';
import './Table.scss';
import GridItem from 'erpcore/components/GridItem';
import MediaUpload from '../MediaUpload';

const Table = ({
    data,
    loading,
    reducerName,
    queryParams,
    onSortTable,
    draggable,
    onDragFinished,
    onDragStart,
    showMediaUpload,
    onMediaUpload,
    layout
}) => {
    const { isMobile } = getResponsive();
    const [mobileOpened, setMobileOpened] = useState([]);
    const [mobileSortDropdown, setMobileSortDropdown] = useState(false);
    const { data: tableList, schema, bulkActions } = { ...data };

    const prepareSortData = item => {
        const { sortable } = item;
        const { defaultSort } = data;
        let activeSortClassName = '';
        if (queryParams.order_by) {
            const sortKey = Object.keys(queryParams.order_by)[0];
            if (sortKey === sortable) {
                activeSortClassName = ' table__sortable--asc';
                if (queryParams.order_by[sortable] === 'DESC') {
                    activeSortClassName = ' table__sortable--desc';
                }
            }
        }
        //  Action triggered when clicked on table heading sorter
        const sortFunction = () => {
            const orderByValue = 'ASC';
            let sortObject = {
                order_by: { [sortable]: orderByValue }
            };
            //  If is filtered by other field toggle it to ASC
            let isSortedByNewField = false;
            if (queryParams.order_by) {
                isSortedByNewField = Object.keys(queryParams.order_by)[0] !== sortable;
                if (
                    (!queryParams.order_by[sortable] || isSortedByNewField) &&
                    orderByValue !== 'ASC'
                ) {
                    sortObject = {
                        order_by: { [sortable]: 'ASC' }
                    };
                } else if (queryParams.order_by[sortable] === 'ASC' && orderByValue !== 'DESC') {
                    sortObject = {
                        order_by: { [sortable]: 'DESC' }
                    };
                } else if (queryParams.order_by[sortable] === 'DESC') {
                    if (defaultSort?.sortable === sortable) {
                        sortObject = {
                            order_by: {
                                [sortable]:
                                    queryParams.order_by[sortable] === 'DESC' ? 'ASC' : 'DESC'
                            }
                        };
                    } else {
                        sortObject = {
                            order_by: ''
                        };
                    }
                }
            }
            onSortTable(sortObject);
        };
        return {
            sortFunction,
            activeSortClassName
        };
    };

    const renderDesktopHeader = () => {
        if (!schema) return null;
        return (
            <thead className="table__header">
                <tr>
                    {bulkActions && (
                        <th key="bulkActions">
                            <TableHeaderBulkAction reducerName={reducerName} tableData={data} />
                        </th>
                    )}
                    {schema.length &&
                        schema.map(item => {
                            const { title, sortable, align } = item;
                            const sortData = prepareSortData(item);
                            return (
                                <th
                                    className={`table__header-cell${
                                        align ? ` table__header-cell--${align}` : ''
                                    }`}
                                    key={generateKey(title)}
                                >
                                    {sortable ? (
                                        <button
                                            type="button"
                                            className={`table__sortable${sortData.activeSortClassName}`}
                                            onClick={sortData.sortFunction}
                                        >
                                            <span className="table__sortable-label">{title}</span>
                                            <Svg
                                                icon="arrowDown"
                                                className="table__sortable-icon"
                                            />
                                        </button>
                                    ) : (
                                        title
                                    )}
                                </th>
                            );
                        })}
                </tr>
            </thead>
        );
    };

    const renderDesktopBody = () => {
        if (!tableList) return null;
        return (
            <tbody className="table__body">
                {tableList.map(item => {
                    return (
                        <tr className="table__body-row" key={item.iri}>
                            {bulkActions && (
                                <td
                                    className="table__body-data"
                                    key={generateKey(`${item.iri}bulkActions`)}
                                >
                                    <TableRowBulkAction
                                        reducerName={reducerName}
                                        key={item.iri}
                                        iri={item.iri}
                                        bulkActionsData={bulkActions}
                                    />
                                </td>
                            )}
                            {schema.length &&
                                schema.map(schemaItem => {
                                    return (
                                        <td
                                            className={
                                                schemaItem.align &&
                                                `table__body-data table__body-data--${schemaItem.align}`
                                            }
                                            key={generateKey(item.iri + schemaItem.field)}
                                        >
                                            {item[schemaItem.field]}
                                        </td>
                                    );
                                })}
                        </tr>
                    );
                })}
            </tbody>
        );
    };

    const renderDraggableTable = () => {
        if (!tableList) return null;

        return (
            <TableDraggableList
                schema={schema}
                tableList={tableList}
                onDragFinished={onDragFinished}
                onDragStart={onDragStart}
            />
        );
    };

    const filterSchemaMobile = tableSchema => {
        // init schema
        const initSchema = {
            title: [],
            status: [],
            subtitle: [],
            table: [],
            hidden: []
        };

        const pushToSchema = (item, type) => {
            switch (type) {
                // push to title
                case 'title':
                    initSchema.title.push(item);
                    break;
                // push to status
                case 'status':
                    initSchema.status.push(item);
                    break;
                // push to subtitle
                case 'subtitle':
                    initSchema.subtitle.push(item);
                    break;
                // push to hidden
                case 'hidden':
                    initSchema.hidden.push(item);
                    break;
                default:
                    if (item.field !== 'actions') initSchema.table.push(item);
            }
        };

        if (tableSchema) {
            // push data to schema object
            tableSchema.forEach(item => {
                const mobileType = item.mobile ? item.mobile.constructor : '';
                switch (mobileType) {
                    case Array:
                        item.mobile.forEach(subitem => {
                            pushToSchema(item, subitem);
                        });
                        break;
                    case String:
                        pushToSchema(item, item.mobile);
                        break;
                    default:
                        pushToSchema(item, item.mobile);
                }
            });

            // check if title exists
            // if not add first that doesn't have key: mobile
            if (initSchema.title.length === 0) {
                const firstTitle = tableSchema.find(item => {
                    return !Object.prototype.hasOwnProperty.call(item, 'mobile') ? item : false;
                });
                initSchema.title.push(firstTitle);
            }
        }
        return initSchema;
    };

    const handleMobileOpened = id => {
        setMobileOpened(existingActiveMobileItems => {
            if (existingActiveMobileItems?.includes(id)) {
                existingActiveMobileItems = existingActiveMobileItems.filter(item => item !== id);
            } else {
                existingActiveMobileItems.push(id);
            }
            return existingActiveMobileItems.slice();
        });
    };

    const renderMobileHeader = () => {
        let sortedBy = '';
        let sortedOrder = '';
        if (queryParams.order_by) {
            const sortKey = Object.keys(queryParams.order_by)[0];
            sortedBy = schema.filter(item => item.sortable === sortKey)[0]?.title || sortKey;
            sortedOrder = queryParams.order_by[sortKey];
        }

        let hasSortable = false;
        const sortableDropdown = schema.map(item => {
            const { title, sortable } = item;
            const sortData = prepareSortData(item);
            if (sortable) {
                hasSortable = true;
                return (
                    <button
                        type="button"
                        className={`table__sortable${sortData.activeSortClassName}`}
                        onClick={() => {
                            sortData.sortFunction();
                            setMobileSortDropdown(!mobileSortDropdown);
                        }}
                        key={generateKey(title)}
                    >
                        <span className="table__sortable-label">{title}</span>
                        <Svg icon="arrowDown" className="table__sortable-icon" />
                    </button>
                );
            }
            return null;
        });

        return (
            <>
                {bulkActions && (
                    <div className="table-mobile__bulk">
                        <TableHeaderBulkAction reducerName={reducerName} tableData={data} />
                    </div>
                )}
                {hasSortable ? (
                    <div
                        className={`table-mobile__sorting ${
                            mobileSortDropdown ? 'table-mobile__sorting--active' : ''
                        } table-mobile__sorting--${sortedOrder}`}
                    >
                        <button
                            type="button"
                            className="table-mobile__sorting-btn"
                            onClick={() => setMobileSortDropdown(!mobileSortDropdown)}
                        >
                            <Svg icon="sorting" className="table-mobile__sorting-icon" />
                            {sortedBy ? (
                                <span className="table-mobile__sorting-label">
                                    Sorted by : <strong>{sortedBy}</strong>
                                </span>
                            ) : (
                                <span className="table-mobile__sorting-label">Sort by</span>
                            )}
                        </button>
                        <div className="table-mobile__sorting-dropdown">{sortableDropdown}</div>
                    </div>
                ) : null}
            </>
        );
    };

    const renderMobileBody = () => {
        if (!tableList) return null;

        const mobileSchema = filterSchemaMobile(schema);

        return tableList.map(item => {
            return (
                <div
                    key={generateKey(item.iri)}
                    className={`table-mobile__list-item ${
                        mobileOpened.includes(item.iri) ? 'table-mobile__list-item--active' : ''
                    }`}
                >
                    <ul className="table-mobile__list-header">
                        {bulkActions && (
                            <li className="table-mobile__list-checkbox">
                                <TableRowBulkAction
                                    reducerName={reducerName}
                                    key={item.iri}
                                    iri={item.iri}
                                    bulkActionsData={bulkActions}
                                />
                            </li>
                        )}
                        <li className="table-mobile__list-title">
                            {item[mobileSchema.title[0].field]}
                        </li>
                        {mobileSchema.status.length > 0 && (
                            <li className="table-mobile__list-status">
                                {item[mobileSchema.status[0].field]}
                            </li>
                        )}
                        {(mobileSchema.table.length > 0 || item.actions) && (
                            <li className="table-mobile__list-arrow">
                                <Svg icon="arrowRight" />
                                <button
                                    type="button"
                                    className="table-mobile__btn"
                                    onClick={() => handleMobileOpened(item.iri)}
                                    onKeyPress={() => handleMobileOpened(item.iri)}
                                />
                            </li>
                        )}
                    </ul>
                    {mobileSchema.subtitle.length > 0 && (
                        <ul className="table-mobile__list-subtitle">
                            {mobileSchema.subtitle.map(subtitleItem => {
                                return (
                                    <li
                                        key={generateKey(subtitleItem.field)}
                                        className="table-mobile__list-subtitle-item"
                                    >
                                        {item[subtitleItem.field]}
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                    <div className="table-mobile__additional">
                        {mobileSchema.table.length > 0 && (
                            <table className="table-mobile__table">
                                <tbody>
                                    {mobileSchema.table.map(tableItem => {
                                        return (
                                            <tr
                                                key={generateKey(tableItem.field)}
                                                className="table-mobile__table-row"
                                            >
                                                <td className="table-mobile__table-col-left">
                                                    {tableItem.title}:
                                                </td>
                                                <td className="table-mobile__table-col-right">
                                                    {item[tableItem.field]}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        )}
                        {item.actions && (
                            <div className="table-mobile__actions">{item.actions}</div>
                        )}
                    </div>
                </div>
            );
        });
    };

    const renderMobileDraggableList = () => {
        if (!tableList) return null;

        const mobileSchema = filterSchemaMobile(schema);

        return (
            <TableDraggableList
                schema={mobileSchema}
                tableList={tableList}
                onDragFinished={onDragFinished}
                onDragStart={onDragStart}
                mobileOpened={mobileOpened}
                handleMobileOpened={handleMobileOpened}
            />
        );
    };

    const renderGridItems = useCallback(() => {
        if (!tableList) return null;
        return (
            <div className="table-grid">
                <div className="table-grid__row">
                    <div className="table-grid__row-flex">
                        {tableList?.map(item => {
                            const { title, subtitle, bottom, actions, background, extension } = {
                                ...item?.gridView
                            };
                            return (
                                <div className="table-grid__col" key={item.id}>
                                    <GridItem
                                        title={title}
                                        subtitle={subtitle}
                                        bottom={bottom}
                                        background={background}
                                        extension={extension}
                                    >
                                        {actions && item?.actions?.props?.children && (
                                            <ul>{item.actions.props.children}</ul>
                                        )}
                                    </GridItem>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }, [tableList]);

    if (draggable) {
        return isMobile ? (
            <div className="table-mobile">
                <div className="table-mobile__inner">
                    {renderMobileDraggableList()}
                    {(!tableList || tableList.length === 0) && !loading && (
                        <NotificationManager code="listingNoData" />
                    )}
                </div>
            </div>
        ) : (
            <div className="table">
                <div className="table__inner">
                    <div className="table__list table__list-draggable">
                        {renderDraggableTable()}
                    </div>
                    {(!tableList || tableList.length === 0) && !loading && (
                        <NotificationManager code="listingNoData" />
                    )}
                </div>
            </div>
        );
    }

    return isMobile ? (
        <div className="table-mobile">
            {showMediaUpload && <MediaUpload onComplete={onMediaUpload} />}
            <div className="table-mobile__inner">
                {renderMobileHeader()}
                <div className="table-mobile__list">{renderMobileBody()}</div>
                {(!tableList || tableList.length === 0) && !loading && (
                    <NotificationManager code="listingNoData" />
                )}
            </div>
        </div>
    ) : (
        <div className="table">
            {showMediaUpload && <MediaUpload onComplete={onMediaUpload} />}
            <div className="table__inner">
                {layout === 'list' && (
                    <table className="table__list">
                        {renderDesktopHeader()}
                        {renderDesktopBody()}
                    </table>
                )}
                {layout === 'grid' && renderGridItems()}
                {(!tableList || tableList.length === 0) && !loading && (
                    <NotificationManager code="listingNoData" />
                )}
            </div>
        </div>
    );
};

Table.defaultProps = {
    data: {},
    onSortTable: () => {},
    queryParams: {},
    loading: false,
    draggable: false,
    onDragFinished: () => {},
    onDragStart: () => {},
    showMediaUpload: false,
    onMediaUpload: () => {},
    layout: 'list'
};

Table.propTypes = {
    reducerName: PropTypes.string.isRequired,
    data: PropTypes.oneOfType([PropTypes.object]),
    onSortTable: PropTypes.func,
    queryParams: PropTypes.oneOfType([PropTypes.object]),
    loading: PropTypes.bool,
    draggable: PropTypes.bool,
    onDragFinished: PropTypes.func,
    onDragStart: PropTypes.func,
    showMediaUpload: PropTypes.bool,
    onMediaUpload: PropTypes.func,
    layout: PropTypes.string
};

Table.Truncate = function Actions({ children }) {
    return <div className="table__truncate">{children}</div>;
};
Table.Truncate.defaultProps = {
    children: null
};

Table.Truncate.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node])
};

export default Table;
