import React, { useEffect } from 'react';
import Form, { DateTime, Select, Text } from 'erpcore/components/Form';
import { change, Field, getFormValues, getFormInitialValues } from 'redux-form';
import { valueValidation } from 'erpcore/components/Form/Form.utils';
import moment from 'moment-timezone';
import {
    dateTypeConstraint,
    dateTypeDays,
    dateTypeMonths
} from 'erpcore/screens/Challenges/Challenges.data';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { parseInteger } from 'erpcore/utils/utils';
import styles from './ChallengeTypeDate.module.scss';

const ChallengeTypeDate = ({ form }) => {
    const dispatch = useDispatch();
    const formValues = useSelector(state => getFormValues(form)(state));
    const initialValues = useSelector(state => getFormInitialValues(form)(state));
    const { constraint, answersDate } = { ...formValues };

    useEffect(() => {
        if (typeof formValues?.answersDate === 'object') {
            dispatch(change(form, 'day', answersDate?.day));
            dispatch(change(form, 'month', answersDate?.month));
        }
    }, [answersDate]);

    useEffect(() => {
        if (initialValues?.constraint !== constraint) dispatch(change(form, 'answersDate', null));
    }, [constraint]);

    return (
        <>
            <Form.Row>
                <Field
                    name="constraint"
                    fieldAttr={{ id: 'constraint', required: true }}
                    fieldProps={{ label: 'Constraint', options: dateTypeConstraint }}
                    component={Select}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            {constraint && (
                <>
                    {constraint === 'DAY_AND_MONTH' && (
                        <Form.Columns>
                            <Form.GridColumn size={6}>
                                <Field
                                    name="day"
                                    fieldAttr={{
                                        required: true,
                                        id: 'day'
                                    }}
                                    fieldProps={{ label: 'Day', options: dateTypeDays }}
                                    component={Select}
                                    validate={valueValidation([{ validator: 'required' }])}
                                />
                            </Form.GridColumn>
                            <Form.GridColumn size={6}>
                                <Field
                                    name="month"
                                    fieldAttr={{
                                        required: true,
                                        id: 'month'
                                    }}
                                    fieldProps={{ label: 'Month', options: dateTypeMonths }}
                                    component={Select}
                                    validate={valueValidation([{ validator: 'required' }])}
                                />
                            </Form.GridColumn>
                        </Form.Columns>
                    )}

                    {constraint === 'FULL_DATE' && (
                        <Form.Row>
                            <Field
                                name="answersDate"
                                fieldAttr={{ id: 'answersDate', required: true }}
                                fieldProps={{
                                    label: 'Date',
                                    renderCustomHeader: props => {
                                        const {
                                            date,
                                            changeYear,
                                            changeMonth,
                                            decreaseMonth,
                                            increaseMonth,
                                            prevMonthButtonDisabled,
                                            nextMonthButtonDisabled
                                        } = { ...props };
                                        return (
                                            <div
                                                className={
                                                    styles['challenge-type-date__custom-header']
                                                }
                                            >
                                                <div
                                                    className={
                                                        styles[
                                                            'challenge-type-date__custom-header-current'
                                                        ]
                                                    }
                                                >
                                                    <button
                                                        type="button"
                                                        onClick={decreaseMonth}
                                                        disabled={prevMonthButtonDisabled}
                                                    >
                                                        {'<'}
                                                    </button>

                                                    <h4>{moment(date).format('LL')}</h4>

                                                    <button
                                                        type="button"
                                                        onClick={increaseMonth}
                                                        disabled={nextMonthButtonDisabled}
                                                    >
                                                        {'>'}
                                                    </button>
                                                </div>

                                                <div
                                                    className={
                                                        styles[
                                                            'challenge-type-date__custom-header-inputs'
                                                        ]
                                                    }
                                                >
                                                    <Field
                                                        name="selectedMonth"
                                                        fieldProps={{
                                                            label: 'Month',
                                                            options: dateTypeMonths.map(option => ({
                                                                label: option?.label,
                                                                value: option?.value
                                                            })),
                                                            clearable: false
                                                        }}
                                                        onChange={value => changeMonth(value - 1)}
                                                        component={Select}
                                                    />

                                                    <Field
                                                        name="selectedYear"
                                                        fieldProps={{ label: 'Enter year' }}
                                                        onChange={({ target: { value } }) => {
                                                            // eslint-disable-next-line no-restricted-globals
                                                            if (!isNaN(value)) {
                                                                changeYear(value);
                                                            }
                                                        }}
                                                        parse={parseInteger}
                                                        component={Text}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    }
                                }}
                                outputNoTimeZone
                                component={DateTime}
                                validate={valueValidation([{ validator: 'required' }])}
                            />
                        </Form.Row>
                    )}

                    {constraint === 'YEAR' && (
                        <Form.Row>
                            <Field
                                name="answersDate"
                                fieldAttr={{ id: 'answersDate', required: true }}
                                fieldProps={{
                                    label: 'Year'
                                }}
                                parse={parseInteger}
                                component={Text}
                                validate={valueValidation([{ validator: 'required' }])}
                            />
                        </Form.Row>
                    )}
                </>
            )}
        </>
    );
};

ChallengeTypeDate.defaultProps = {
    form: 'ChallengeCreateEditForm'
};

ChallengeTypeDate.propTypes = {
    form: PropTypes.oneOfType([PropTypes.object])
};

export default ChallengeTypeDate;
