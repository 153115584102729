import { takeLatest, put, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import dto from 'erpcore/utils/dto';
import { actions as challengesActions } from './Challenges.reducer';

/**
 * Delete Single Challenge
 * @param  {Object} id ID of a Challenge
 * @return {Object} Response from API
 */
export function* deleteSingleChallenge({ promise, iri }) {
    try {
        const deleteSingleChallengeAPI = yield restClient.delete(iri);
        yield put({
            type: challengesActions.DELETE_CHALLENGE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteSingleChallengeAPI?.data
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: challengesActions.DELETE_CHALLENGE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Create Challenge
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createChallenge({ promise, formData }) {
    try {
        const createChallengeAPI = yield restClient.post(`/api/challenges`, formData);
        yield put({
            type: challengesActions.CREATE_CHALLENGE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createChallengeAPI?.data
        });
        yield call(promise.resolve, createChallengeAPI?.data);
    } catch (error) {
        yield put({
            type: challengesActions.CREATE_CHALLENGE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Challenge
 * @param  {Object} promise
 * @param {string} challange Iri
 * @return {Object} response from API
 */
export function* fetchSingleChallenge({ promise, iri, params }) {
    try {
        const fetchSingleChallengeAPI = yield restClient.get(iri, { params });
        yield put({
            type: challengesActions.FETCH_CHALLENGE_SUCCESSFUL
        });
        yield put({
            type: challengesActions.STORE_CHALLENGE_DATA,
            iri,
            response: dto(fetchSingleChallengeAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: challengesActions.FETCH_CHALLENGE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Single Challenge data
 * @param  {Object} id ID of Challenge
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* updateSingleChallenge({ promise, formData, iri }) {
    try {
        const updateSingleChallengeAPI = yield restClient.put(iri, formData);
        yield put({
            type: challengesActions.UPDATE_CHALLENGE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleChallengeAPI?.data
        });
        yield put({
            type: challengesActions.STORE_CHALLENGE_DATA,
            iri,
            response: dto(updateSingleChallengeAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: challengesActions.UPDATE_CHALLENGE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Challenge Types
 * @param  {Object} promise
 * @return {Object} Response from API
 */
export function* fetchChallengeTypes({ promise }) {
    try {
        const fetchChallengeTypesAPI = yield restClient.get(
            '/api/challenge-types?pagination=false'
        );
        yield put({
            type: challengesActions.FETCHING_SUCCESSFUL_CHALLENGE_TYPES,
            response: dto(fetchChallengeTypesAPI?.data)
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: challengesActions.FETCHING_FAILED_CHALLENGE_TYPES
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export const challengesSaga = [
    takeLatest(challengesActions.START_DELETE_CHALLENGE, deleteSingleChallenge),
    takeLatest(challengesActions.START_CREATE_CHALLENGE, createChallenge),
    takeLatest(challengesActions.START_FETCHING_CHALLENGE, fetchSingleChallenge),
    takeLatest(challengesActions.START_UPDATE_CHALLENGE, updateSingleChallenge),
    takeLatest(challengesActions.START_FETCHING_CHALLENGE_TYPES, fetchChallengeTypes)
];
