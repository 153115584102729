import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import Button from 'erpcore/components/Button';
import Form, { Text, Email, Autocomplete } from 'erpcore/components/Form';

import { valueValidation } from 'erpcore/components/Form/Form.utils';

const ParticipantForm = ({
    handleSubmit,
    submitting,
    pristine,
    invalid,
    onSubmit,
    submitLabel,
    handleModal,
    eventIri,
    hasEmailAccess
}) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
                <Field
                    name="first_name"
                    id="first_name"
                    fieldProps={{
                        label: 'First name',
                        clearable: true
                    }}
                    component={Text}
                    fieldAttr={{ required: true }}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="last_name"
                    id="last_name"
                    fieldProps={{
                        label: 'Last name',
                        clearable: true
                    }}
                    component={Text}
                    fieldAttr={{ required: true }}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="nickname"
                    id="nickname"
                    fieldProps={{
                        label: 'Nickname',
                        clearable: true
                    }}
                    component={Text}
                    fieldAttr={{ disabled: true }}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="email"
                    id="email"
                    fieldProps={{
                        label: 'Email',
                        clearable: true
                    }}
                    component={Email}
                    fieldAttr={{ required: hasEmailAccess, disabled: !hasEmailAccess }}
                    validate={valueValidation(
                        hasEmailAccess ? [{ validator: 'email' }, { validator: 'required' }] : []
                    )}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="event_team"
                    id="event_team"
                    fieldProps={{
                        label: 'Team',
                        options: {
                            endpoint: `/api/event-teams?filters[event][equals]=${eventIri}`,
                            mapData: {
                                value: 'iri',
                                label: 'name'
                            }
                        }
                    }}
                    component={Autocomplete}
                />
            </Form.Row>
            <Button
                submit
                disabled={pristine || invalid}
                loading={submitting}
                label={submitLabel}
            />
            {handleModal && (
                <Button variation="secondary" label="Cancel" onClick={() => handleModal()} />
            )}
        </Form>
    );
};

ParticipantForm.defaultProps = {
    onSubmit: () => {},
    submitting: false,
    pristine: false,
    invalid: false,
    submitLabel: 'Create',
    handleModal: () => {},
    hasEmailAccess: false
};

ParticipantForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    invalid: PropTypes.bool,
    submitLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    handleModal: PropTypes.func,
    eventIri: PropTypes.string.isRequired,
    hasEmailAccess: PropTypes.bool
};

export default reduxForm({
    form: 'ParticipantForm',
    enableReinitialize: true
})(ParticipantForm);
