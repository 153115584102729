import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Form, { SwitchCard, Autocomplete, TextEditor } from 'erpcore/components/Form';
import { projectDefaultToolbar } from 'erpcore/components/Form/components/TextEditor';
import { change, Field, getFormValues, reduxForm } from 'redux-form';
import Button from 'erpcore/components/Button';
import { dtoForm } from 'erpcore/utils/dto';
import {
    getGuidelineData,
    getEventGuidelineFetching
} from 'erpcore/screens/Settings/EventGuidelines/EventGuidelines.selectors';
import { actions as guidelinesActions } from 'erpcore/screens/Settings/EventGuidelines/EventGuidelines.reducer';
import { valueValidation } from 'erpcore/components/Form/Form.utils';
import './EventGuidelinesForm.scss';
import ElementLoader from 'erpcore/components/ElementLoader';

const SettingsForm = ({ invalid, pristine, handleSubmit, onSubmit, form, submitting }) => {
    const dispatch = useDispatch();
    const formValues = useSelector(state => getFormValues(form)(state));
    const { guideline: guidelineIri, overrideGuideline, guidelineContent } = { ...formValues };
    const guidelineContentRef = useRef(guidelineContent);
    const guidelineData = dtoForm(useSelector(state => getGuidelineData(state, guidelineIri)));
    const guidelineDataFetching = useSelector(state => getEventGuidelineFetching(state));
    const guidelineDataRef = useRef(guidelineData);
    const overrideGuidelineRef = useRef(overrideGuideline);
    const prePopulatedGuidelineIri = useRef(null);

    const fetchGuidelineData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: guidelinesActions.START_FETCHING_GUIDELINE,
                iri: guidelineIri
            });
        });
    };

    const updateContent = (content = '') => {
        dispatch(change(form, 'guidelineContent', content));
    };

    const isSwitchDisabled = useMemo(() => {
        return !guidelineIri || !!guidelineDataFetching;
    }, [guidelineIri, guidelineDataFetching]);

    useEffect(() => {
        if (guidelineIri) {
            fetchGuidelineData();
        }
    }, [guidelineIri]);

    useEffect(() => {
        if (!overrideGuideline && overrideGuidelineRef.current) {
            // override is just now switched off -> clear content
            prePopulatedGuidelineIri.current = null;
            updateContent(''); // this IS done as soon as the switch is toggled
        } else if (
            overrideGuideline && // switch is ON
            !guidelineDataFetching && // data is not being fetched currently
            guidelineIri && // guideline is selected
            guidelineIri === guidelineData?.iri && // selected guideline data is available
            guidelineIri !== prePopulatedGuidelineIri?.current && // selected guideline data has NOT been populated (this check is implemented so that the data would not be populated when user deletes all content)
            !guidelineContent // form content is empty
        ) {
            prePopulatedGuidelineIri.current = guidelineIri;
            updateContent(guidelineData?.content || ''); // this is NOT done as soon as the switch is toggled
        }
    }, [guidelineIri, guidelineDataFetching, guidelineData, overrideGuideline, guidelineContent]);

    useEffect(() => {
        if (guidelineContent !== guidelineContentRef?.current) {
            guidelineContentRef.current = guidelineContent;
        }
    }, [guidelineContent]);

    useEffect(() => {
        guidelineDataRef.current = guidelineData;
    }, [guidelineData]);

    useEffect(() => {
        overrideGuidelineRef.current = overrideGuideline;
    }, [overrideGuideline]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
                <Field
                    name="guideline"
                    id="guideline"
                    fieldProps={{
                        label: 'Choose Guideline',
                        options: {
                            endpoint: '/api/guidelines?order_by[id]=DESC',
                            mapData: {
                                value: 'iri',
                                label: 'title'
                            }
                        },
                        clearable: true
                    }}
                    fieldAttr={{
                        disabled: !!overrideGuideline
                    }}
                    component={Autocomplete}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="overrideGuideline"
                    fieldAttr={{
                        id: 'overrideGuideline',
                        disabled: !!isSwitchDisabled
                    }}
                    fieldProps={{
                        label: (
                            <>
                                Override Guidelines
                                {!!guidelineDataFetching && (
                                    <span style={{ marginLeft: '16px' }}>
                                        <ElementLoader overlay />
                                    </span>
                                )}
                            </>
                        )
                    }}
                    component={SwitchCard}
                >
                    {!!overrideGuideline && !isSwitchDisabled && (
                        <Form.Row className="event-guideline">
                            <Field
                                name="guidelineContent"
                                id="guidelineContent"
                                fieldProps={{
                                    label: 'Description',
                                    clearable: true,
                                    toolbar: {
                                        ...projectDefaultToolbar,
                                        options: [
                                            'blockType',
                                            ...(projectDefaultToolbar?.options || [])
                                        ],
                                        blockType: {
                                            inDropdown: true,
                                            options: ['Normal', 'H2']
                                        }
                                    }
                                }}
                                useProjectDefaultToolbar={false}
                                fieldAttr={{
                                    required: true
                                }}
                                component={TextEditor}
                                validate={valueValidation([{ validator: 'required' }])}
                            />
                        </Form.Row>
                    )}
                </Field>
            </Form.Row>

            <Button disabled={invalid || pristine} loading={submitting} submit label="Save" />
        </Form>
    );
};

SettingsForm.defaultProps = {
    invalid: false,
    pristine: false,
    submitting: false,
    handleSubmit: () => {},
    onSubmit: () => {}
};

SettingsForm.propTypes = {
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    handleSubmit: PropTypes.func,
    onSubmit: PropTypes.func,
    form: PropTypes.string.isRequired
};

export default reduxForm({
    enableReinitialize: true
})(SettingsForm);
