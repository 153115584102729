import React from 'react';
import LayoutManager from 'erpcore/utils/LayoutManager';
import EventsEditPageHeader from 'erpcore/screens/Events/screens/EventsEdit/components/EventEditPageHeader';
import PageContent from 'erpcore/components/Layout/PageContent';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { actions as eventActions } from 'erpcore/screens/Events/Events.reducer';
import EventGuidelineForm from 'erpcore/screens/Events/screens/EventsEdit/screens/EventGuidelines/components/EventGuidelinesForm';
import { getSingleEventData } from 'erpcore/screens/Events/Events.selectors';

const EventGuidelines = () => {
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const eventId = match?.params?.id;
    const iri = `/api/events/${eventId}`;

    const eventData = useSelector(state => getSingleEventData(state, iri));
    const { guideline_content: initialGuidelineContent, guideline: initialGuideline } = {
        ...eventData
    };

    const initialValues = {
        guideline: initialGuideline?.iri || '',
        guidelineContent: initialGuidelineContent || '',
        overrideGuideline: !!initialGuidelineContent
    };

    const onSubmit = formValues => {
        const { guideline, overrideGuideline, guidelineContent } = { ...formValues };
        const formData = {
            guideline,
            guideline_content: overrideGuideline ? guidelineContent : ''
        };
        return new Promise((resolve, reject) => {
            dispatch({
                type: eventActions.START_UPDATE_EVENT,
                iri,
                promise: { resolve, reject },
                formData
            });
        });
    };

    return (
        <LayoutManager slot="main" className="main--medium" layoutType="merge">
            <EventsEditPageHeader />
            <PageContent>
                <EventGuidelineForm
                    onSubmit={onSubmit}
                    initialValues={initialValues}
                    form="EventEditGuidelineForm"
                />
            </PageContent>
        </LayoutManager>
    );
};

export default EventGuidelines;
