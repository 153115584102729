import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { useDispatch } from 'react-redux';
import Form from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';
import Svg from 'erpcore/components/Svg';
import Tooltip from 'erpcore/components/Tooltip';
import Modal from 'erpcore/components/Modal';
import { actions as customerActions } from 'erpcore/screens/Customers/Customers.reducer';

const CustomerDelete = ({ id, handleSubmit, submitting, customerName }) => {
    const dispatch = useDispatch();
    const [opened, setOpened] = useState(false);
    const customerIri = `api/companies/${id}`;

    const handleModal = () => {
        setOpened(!opened);
    };

    const onSubmit = () => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: customerActions.START_DELETE_CUSTOMER,
                iri: customerIri
            })
        )
            .then(() => {
                handleModal();
            })
            .catch(error => {
                handleModal();
                return error;
            });
    };

    return (
        <Fragment>
            <Tooltip content="Delete customer">
                <button type="button" onClick={() => handleModal()}>
                    <Svg icon="remove" />
                </button>
            </Tooltip>
            <Modal
                variation="small"
                opened={opened}
                onClose={() => handleModal()}
                title="Delete customer"
                subtitle={
                    <>
                        Are you sure you want to delete customer <strong>{customerName}</strong>?
                        <br />
                        Customer will be archived, you will be able to access it in the future along
                        with all related records.
                    </>
                }
            >
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Button submit loading={submitting} label="Delete" />
                    <Button variation="secondary" label="Cancel" onClick={() => handleModal()} />
                </Form>
            </Modal>
        </Fragment>
    );
};

CustomerDelete.defaultProps = {
    submitting: false,
    id: null,
    customerName: null
};

CustomerDelete.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    customerName: PropTypes.string
};

export default reduxForm({
    form: 'CustomerDeleteForm',
    enableReinitialize: true
})(CustomerDelete);
