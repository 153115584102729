import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import Button from 'erpcore/components/Button';
import Form, { Text, TextEditor } from 'erpcore/components/Form';

import { valueValidation } from 'erpcore/components/Form/Form.utils';
import { projectDefaultToolbar } from 'erpcore/components/Form/components/TextEditor';

const EventGuidelineForm = ({
    // form,
    handleSubmit,
    submitting,
    pristine,
    invalid,
    onSubmit,
    submitLabel
}) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
                <Field
                    name="title"
                    id="title"
                    fieldProps={{
                        label: 'Title',
                        clearable: true
                    }}
                    fieldAttr={{ required: true }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="content"
                    id="content"
                    fieldProps={{
                        label: 'Description',
                        clearable: true,
                        toolbar: {
                            ...projectDefaultToolbar,
                            options: ['blockType', ...(projectDefaultToolbar?.options || [])],
                            blockType: {
                                inDropdown: true,
                                options: ['Normal', 'H2']
                            }
                        }
                    }}
                    useProjectDefaultToolbar={false}
                    fieldAttr={{ required: true }}
                    component={TextEditor}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Button
                    submit
                    disabled={pristine || invalid}
                    loading={submitting}
                    label={submitLabel}
                />
            </Form.Row>
        </Form>
    );
};

EventGuidelineForm.defaultProps = {
    // form: 'EventGuidelineForm',
    onSubmit: () => {},
    submitting: false,
    pristine: false,
    invalid: false,
    submitLabel: 'Create'
};

EventGuidelineForm.propTypes = {
    // form: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    submitLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default reduxForm({
    form: 'EventGuidelineForm',
    enableReinitialize: true
})(EventGuidelineForm);
