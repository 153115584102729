const hasPermission = (permission, meData) => {
    const { userPermissions } = { ...meData };

    const permissionElement =
        userPermissions &&
        userPermissions.length > 0 &&
        userPermissions.find(element => element === permission);

    if (permissionElement) {
        return true;
    }

    return false;
};

const getPermissionsAccessForEntities = meData => {
    const entitiesPermissions = {
        activities: false,
        challenges: false,
        companies: false,
        contacts: false,
        dashboard: false,
        events: false,
        selfHostedEvents: false,
        settings: false,
        users: false,
        media: false
    };

    // Activities
    if (hasPermission('CAN_MANAGE_COMMON_ACTIVITY', meData)) {
        entitiesPermissions.activities = true;
    }

    // Challenges
    if (hasPermission('CAN_MANAGE_COMMON_CHALLENGE', meData)) {
        entitiesPermissions.challenges = true;
    }

    // Customers
    if (hasPermission('CAN_MANAGE_COMPANY_BUNDLE_COMPANY', meData)) {
        entitiesPermissions.companies = true;
    }

    // Contacts
    if (hasPermission('CAN_MANAGE_CONTACT_BUNDLE_CONTACT', meData)) {
        entitiesPermissions.contacts = true;
    }

    // Events
    if (
        hasPermission('CAN_MANAGE_COMMON_EVENT', meData) ||
        hasPermission('CAN_MANAGE_OWN_EVENT', meData)
    ) {
        entitiesPermissions.events = true;
    }

    // Self-hosted Events
    if (hasPermission('CAN_SELF_MANAGE_OWN_EVENT', meData)) {
        entitiesPermissions.selfHostedEvents = true;
    }

    // Self-hosted Events
    if (hasPermission('CAN_SELF_MANAGE_OWN_EVENT', meData)) {
        entitiesPermissions.selfHostedEvents = true;
    }

    // Dashboard
    entitiesPermissions.dashboard = true;

    // Settings
    if (
        hasPermission('CAN_MANAGE_SECURITY_BUNDLE_ROLE', meData) ||
        hasPermission('CAN_MANAGE_SECURITY_BUNDLE_PERMISSION', meData) ||
        hasPermission('CAN_MANAGE_COMMON_CHALLENGE_TYPE', meData) ||
        hasPermission('CAN_MANAGE_COMMON_GUIDELINE', meData)
    ) {
        entitiesPermissions.settings = true;
    }

    // Users
    if (
        hasPermission('CAN_MANAGE_CORE_BUNDLE_USER', meData) ||
        hasPermission('CAN_MANAGE_COMMON_EMPLOYMENTRECORD', meData)
    ) {
        entitiesPermissions.users = true;
    }

    // Media library
    if (hasPermission('CAN_MANAGE_MEDIA_BUNDLE_MEDIAOBJECT', meData)) {
        entitiesPermissions.media = true;
    }

    return entitiesPermissions;
};

export { hasPermission, getPermissionsAccessForEntities };
