import React, { useCallback } from 'react';
import Modal from 'erpcore/components/Modal';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import Form, { FileUploader } from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { useDispatch } from 'react-redux';
import exampleEventChallengeFile from 'erpcore/assets/exampleFiles/export-event-challenges.xlsx';

const EntityChallengeImportModal = ({
    modalOpened,
    handleModal,
    handleSubmit,
    onSubmit,
    entity,
    entityIri,
    callback
}) => {
    const dispatch = useDispatch();
    const downloadSample = useCallback(() => {
        const link = document.createElement('a');

        link.href = exampleEventChallengeFile;

        link.setAttribute('download', `sample.xlsx`);
        link.click();
    }, []);

    return (
        <Modal opened={modalOpened} onClose={handleModal} title="Import challenges">
            <Form onSubmit={handleSubmit(onSubmit)}>
                <p>1. Download sample import file</p>
                <p>
                    <Button
                        label="Download .XLSX"
                        iconName="download"
                        variation="secondary"
                        size="small"
                        onClick={downloadSample}
                    />
                </p>
                <p>2. Fill the data</p>
                <p>3. Upload import file by dragging & dropping or by browsing file</p>
                <Form.Row>
                    <Field
                        name="files"
                        id="files"
                        fieldProps={{
                            showUploaded: false,
                            allowedExtensions: ['xlsx'],
                            endpoint:
                                entity === 'challenges'
                                    ? '/api/challenges/import'
                                    : `${entityIri}/import-challenges`,
                            headers: { Accept: 'text/xlsx' },
                            inputName: 'qqfile',
                            onComplete: response => {
                                if (response?.code === 'import.completed') {
                                    dispatch({
                                        type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                                        response
                                    });

                                    if (callback) {
                                        callback();
                                        handleModal();
                                    }
                                }

                                if (response?.code === 'import.partiallyCompleted') {
                                    dispatch({
                                        type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                                        response: {
                                            code: 'import.partiallyCompleted',
                                            detail: `Challenges are partially imported (${
                                                response?.data?.successfulImports
                                            }/${response?.data?.successfulImports +
                                                response?.error
                                                    ?.length}), they are set to queue and might take some time to complete. ${
                                                response?.error?.length
                                            } challenges failed to import, important data is missing.`
                                        }
                                    });

                                    if (callback) {
                                        callback();
                                    }
                                }
                            }
                        }}
                        component={FileUploader}
                    />
                </Form.Row>
            </Form>
        </Modal>
    );
};

EntityChallengeImportModal.defaultProps = {
    modalOpened: false,
    handleModal: () => {},
    handleSubmit: () => {},
    onSubmit: () => {},
    callback: null
};

EntityChallengeImportModal.propTypes = {
    modalOpened: PropTypes.bool,
    handleModal: PropTypes.func,
    entity: PropTypes.string.isRequired,
    entityIri: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func,
    onSubmit: PropTypes.func,
    callback: PropTypes.func
};

export default reduxForm({ form: 'EntityChallengeImportModal' })(EntityChallengeImportModal);
