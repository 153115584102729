import React, { useState, useEffect } from 'react';
import Form, { Text } from 'erpcore/components/Form';
import { Field, getFormInitialValues, getFormValues } from 'redux-form';
import { valueValidation } from 'erpcore/components/Form/Form.utils';
import Button from 'erpcore/components/Button';
import QRCode from 'react-qr-code';
import { useDispatch, useSelector } from 'react-redux';
import restClient from 'erpcore/api/restClient';
import { actions as notificationActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';

const ChallengeTypeQRCode = ({ form, entity, data }) => {
    const match = useRouteMatch();
    const id = match?.params?.id;
    const dispatch = useDispatch();
    const [isGenerateQRCode, setIsGenerateQRCode] = useState(false);
    const [generatingQRPdf, setGeneratingQRPdf] = useState(false);
    const formValues = useSelector(state => getFormValues(form)(state));
    const formInitialValues = useSelector(state => getFormInitialValues(form)(state));

    const generateQRCode = () => {
        setIsGenerateQRCode(true);
    };

    const generatePDFDownloadLink = () => {
        switch (entity) {
            case 'event':
                return `${data?.iri}/pdf`;
            case 'activity':
                return `${data?.iri}/pdf`;
            default:
                return `/api/challenges/${id}/pdf`;
        }
    };

    const generateQRPdf = () => {
        setGeneratingQRPdf(true);

        restClient
            .get(generatePDFDownloadLink(), {
                responseType: 'arraybuffer'
            })
            .then(response => {
                setGeneratingQRPdf(false);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `QRcode.pdf`);
                link.click();
            })
            .catch(() => {
                setGeneratingQRPdf(false);
                dispatch({
                    type: notificationActions.ADD_FLOATING_NOTIFICATION,
                    response: { code: 'error500' }
                });
            });
    };

    useEffect(() => {
        if (formInitialValues?.answersQr && typeof formInitialValues?.answersQr === 'string') {
            setIsGenerateQRCode(true);
        }
    }, [formInitialValues]);

    return (
        <>
            <Form.Row>
                <Field
                    name="answersQr"
                    fieldAttr={{ id: 'answersQr', required: true }}
                    fieldProps={{ label: 'Text' }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Button
                    disabled={!formValues?.answersQr || !formValues?.answersQr?.length}
                    variation="secondary"
                    label="Generate code"
                    onClick={generateQRCode}
                />
            </Form.Row>

            {isGenerateQRCode && formValues?.answersQr && (
                <>
                    <Form.Row>
                        <QRCode size={200} value={formValues.answersQr} />
                    </Form.Row>
                    {formInitialValues?.answersQr && (
                        <Form.Row>
                            <Button
                                onClick={generateQRPdf}
                                loading={generatingQRPdf}
                                label="Download"
                                small
                                variation="tertiary"
                            />
                        </Form.Row>
                    )}
                </>
            )}
        </>
    );
};

ChallengeTypeQRCode.defaultProps = {
    form: 'ChallengeCreateEditForm'
};

ChallengeTypeQRCode.propTypes = {
    form: PropTypes.string,
    entity: PropTypes.string.isRequired,
    data: PropTypes.oneOfType([PropTypes.object]).isRequired
};

export default ChallengeTypeQRCode;
