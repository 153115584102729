import React from 'react';
import './EventViewCoordinators.scss';
import { useSelector } from 'react-redux';
import { getSingleEventData } from 'erpcore/screens/Events/Events.selectors';
import { useRouteMatch } from 'react-router-dom';
import Image from 'erpcore/components/Image';
import Svg from 'erpcore/components/Svg';

const EventViewCoordinators = () => {
    const match = useRouteMatch();
    const id = match?.params?.id;
    const iri = `/api/events/${id}`;
    const eventData = useSelector(state => getSingleEventData(state, iri));
    const { coordinators } = { ...eventData };

    return (
        <div className="event-view-coordinators">
            {coordinators?.map((coordinator, index) => {
                const {
                    first_name: firstName,
                    last_name: lastName,
                    email,
                    image_versions: versions
                } = { ...coordinator };

                const smallImg = versions?.find(version => version?.name === 'small');

                return (
                    <div
                        key={`coordinator__${index}__${iri}`}
                        className="event-view-coordinators__coordinator"
                    >
                        <div className="event-view-coordinators__coordinator-image">
                            <Image
                                iri={smallImg?.id}
                                version="small"
                                width={40}
                                height={40}
                                alt="Profile"
                                fallback={<Svg icon="profile" />}
                            />
                        </div>
                        <div className="event-view-coordinators__coordinator-content">
                            <h4>
                                {firstName} {lastName}
                            </h4>
                            <p>{email}</p>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default EventViewCoordinators;
