import React from 'react';
import PropTypes from 'prop-types';
import Notification from 'erpcore/components/Notification';

/**
 * Notification Manager - Activity Content Notifications
 * @return {Node} Returns Notification component
 */
// eslint-disable-next-line no-unused-vars
const ActivityContentNotifications = ({ identifier, code, detail, notificationData }) => {
    switch (code) {
        case 'activitycontent.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Template content successfully created!"
                    type="success"
                    expire="3500"
                />
            );
        case 'activitycontent.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Template content successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'activitycontent.itemSuccessfulyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Template content successfully deleted!"
                    type="success"
                    expire="3500"
                />
            );
        case 'activitycontentitem.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Template content item successfully created!"
                    type="success"
                    expire="3500"
                />
            );
        case 'activitycontentitem.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Template content item successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'activitycontentitem.itemSuccessfulyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Template content item successfully removed!"
                    type="success"
                    expire="3500"
                />
            );
        case 'activitycontentitemmedia.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Template content item media successfully created!"
                    type="success"
                    expire="3500"
                />
            );
        case 'activitycontentitemmedia.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Template content item media successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'activitycontentitemmedia.itemSuccessfulyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Template content item media successfully removed!"
                    type="success"
                    expire="3500"
                />
            );
        default:
            return null;
    }
};

ActivityContentNotifications.defaultProps = {
    identifier: null,
    code: null,
    detail: null,
    notificationData: null
};

ActivityContentNotifications.propTypes = {
    identifier: PropTypes.string,
    code: PropTypes.string,
    detail: PropTypes.string,
    notificationData: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])
};

export default ActivityContentNotifications;
