import React from 'react';
import LayoutManager from 'erpcore/utils/LayoutManager';
import ActivityEditPageHeader from 'erpcore/screens/Activities/screens/ActivitiesEdit/components/ActivityEditPageHeader';
import PageContent from 'erpcore/components/Layout/PageContent';
import { useRouteMatch } from 'react-router-dom';
import EntityChallengesListing from 'erpcore/screens/Challenges/screens/EntityChallenges/screens/EntityChallenges';

const ActivityChallengesListing = () => {
    const match = useRouteMatch();
    const id = match?.params?.id;
    const iri = `/api/activities/${id}`;

    const params = {
        'filters[activity][equals]': iri,
        include: 'type,type.icon,type.icon.versions,media,media.media,challenge'
    };

    return (
        <LayoutManager slot="main" className="main--medium" layoutType="merge">
            <ActivityEditPageHeader />
            <PageContent>
                <EntityChallengesListing
                    entityIri={iri}
                    endpoint="/api/activity-challenges"
                    endpointParams={params}
                    entity="activity"
                    batchRequestEndpoint="/api/batch-requests/activity-challenges"
                    batchExportRequestEndpoint={`${iri}/export-challenges`}
                    defaultOrder={{ order: 'ASC', sortable: 'position' }}
                />
            </PageContent>
        </LayoutManager>
    );
};

export default ActivityChallengesListing;
