import { takeEvery, put, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import dto from 'erpcore/utils/dto';

import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as dashboardWidgetsActions } from 'erpcore/screens/Dashboard/DashboardWidgets.reducer';
import { actions as eventWidgetActions } from './EventsWidget.reducer';

/**
 *  Fetch events
 * @param promise
 * @param widgetId {string}
 * @param dateStart {M/D/YYYY}
 * @param dateEnd {M/D/YYYY}
 * @param dateNow {YYYY-MM-DD}
 * @returns {Generator<SimpleEffect<"PUT", PutEffectDescriptor<{widgetId, type: string}>>|SimpleEffect<"PUT", PutEffectDescriptor<{type: string}>>|AxiosPromise<any>|SimpleEffect<"PUT", PutEffectDescriptor<{response: *, type: string}>>|SimpleEffect<"PUT", PutEffectDescriptor<{widgetId, response: Object, type: string}>>|SimpleEffect<"CALL", CallEffectDescriptor<RT | RT | RT>>, void, *>}
 */
export function* fetchEvents({ promise, widgetId, dateStart, dateEnd, dateNow }) {
    try {
        yield put({
            type: dashboardWidgetsActions.START_FETCHING_WIDGET_DATA,
            widgetId
        });
        const dates =
            dateStart && dateEnd
                ? { 'filters[starts_at][between]': [encodeURI(dateStart), encodeURI(dateEnd)] }
                : { 'filters[starts_at][larger_than_or_equal]': encodeURI(dateStart || dateNow) };

        const [apiListing, apiStats] = yield Promise.all([
            restClient.get('/api/events', {
                params: {
                    pagination: false,
                    'order_by[starts_at]': 'ASC',
                    include: 'image,image.versions,activity',
                    relations: 'image,image.versions,activity',
                    ...dates
                }
            }),
            restClient.get('/api/event-stats')
        ]);

        yield put({
            type: eventWidgetActions.FETCHING_SUCCESSFUL_WIDGET_EVENTS
        });
        yield put({
            type: dashboardWidgetsActions.FETCHING_WIDGET_DATA_SUCCESSFUL,
            widgetId
        });
        yield put({
            type: dashboardWidgetsActions.STORE_WIDGET_DATA,
            widgetId,
            response: {
                listing: dto(apiListing?.data),
                stats: apiStats?.data
            }
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: eventWidgetActions.FETCHING_FAILED_WIDGET_EVENTS
        });
        yield put({
            type: dashboardWidgetsActions.FETCHING_WIDGET_DATA_FAILED,
            widgetId
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export default [takeEvery(eventWidgetActions.START_FETCHING_WIDGET_EVENTS, fetchEvents)];
