import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm } from 'redux-form';
import Button from 'erpcore/components/Button';
import Form from 'erpcore/components/Form';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { actions as entityChallengesActions } from 'erpcore/screens/Challenges/screens/EntityChallenges/EntityChallenges.reducer';
import {
    getBulkActionsIris,
    getBulkActionsModalIsOpened
} from 'erpcore/components/Listing/Listing.selectors';
import { getIdFromIri } from 'erpcore/utils/dto';
import { parseParamsForApi } from 'erpcore/components/Listing/Listing.utils';

const EntityChallengeBulkExport = ({
    handleSubmit,
    submitting,
    reducerName,
    entity,
    batchRequestEndpoint
}) => {
    const dispatch = useDispatch();
    const isOpen = useSelector(state => getBulkActionsModalIsOpened(state, reducerName));
    const iries = useSelector(state => getBulkActionsIris(state, reducerName));

    const handleModal = () => {
        return dispatch({
            type: listingActions.TOGGLE_BULK_ACTIONS_MODAL,
            name: reducerName,
            response: !isOpen
        });
    };

    const onSubmit = () => {
        const params = {
            'ids[in]': iries.map(iri => getIdFromIri(iri))
        };

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: entityChallengesActions.START_BATCH_EXPORT,
                params: parseParamsForApi(params),
                config: { responseType: 'arraybuffer' },
                endpoint: batchRequestEndpoint
            })
        )
            .then(CSVData => {
                if (CSVData) {
                    const blob = window.URL.createObjectURL(
                        new Blob([CSVData], {
                            type:
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                        })
                    );

                    const link = document.createElement('a');
                    link.href = blob;
                    link.setAttribute('download', 'challenges-export.xlsx');
                    link.click();

                    document.removeChild(link);
                }
            })
            .catch(error => {
                return error;
            })
            .finally(handleModal);
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <p>{entity ? `Export ${entity} challenges` : 'Export selected challenges'}</p>

            <Button submit loading={submitting} label="Yes" />
            <Button variation="secondary" label="Cancel" onClick={() => handleModal()} />
        </Form>
    );
};

EntityChallengeBulkExport.defaultProps = {
    submitting: false,
    entity: null
};

EntityChallengeBulkExport.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    reducerName: PropTypes.string.isRequired,
    batchRequestEndpoint: PropTypes.string.isRequired,
    entity: PropTypes.string
};

export default reduxForm({
    form: 'EntityChallengeBulkExportForm',
    enableReinitialize: true
})(EntityChallengeBulkExport);
