import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import Listing from 'erpcore/components/Listing';
import ElementLoader from 'erpcore/components/ElementLoader';
import { getImageSrcFromMediaObject } from 'erpcore/components/ImageManager';
import imagePlaceholder from 'erpcore/assets/images/image-placeholder.png';
import StatusBadge from 'erpcore/components/StatusBadge';
import { stateBadges, statusBadges } from 'erpcore/screens/Events/Events.data';
import styles from './EventsWidgetListing.module.scss';

const EventsWidgetListing = ({ fetching, events, dates, dateKey }) => {
    const [tabPosition, setTabPosition] = useState('upcoming');

    const momentToday = dates?.now ? moment(dates.now) : moment();
    const momentTomorrow = dates?.now ? moment(dates.now).add(1, 'day') : moment().add(1, 'day');
    const momentYesterday = dates?.now
        ? moment(dates.now).subtract(1, 'day')
        : moment().subtract(1, 'day');

    const generateItemData = useCallback((item, dateFormat = 'MMM Do, h:mm a') => {
        const {
            id,
            iri,
            name: eventName,
            activity,
            starts_at: startsAt,
            is_self_hosted: isSelfHosted,
            status,
            state,
            number_of_participants,
            number_of_teams,
            image
        } = item;
        const smallImageUrl = getImageSrcFromMediaObject(image, 'small');
        return {
            id,
            iri,
            name: (
                <div className="events-listing__name">
                    <div
                        className={`activities-listing__name-image ${
                            !smallImageUrl ? 'activities-listing__name-image--placeholder' : ''
                        }`}
                    >
                        <img src={smallImageUrl || imagePlaceholder} alt="Events" />
                    </div>
                    <div className="events-listing__name-content">
                        <h4>{eventName}</h4>
                        <p className="events-listing__activity-mobile">{activity?.name}</p>
                    </div>
                </div>
            ),
            activity: activity?.name,
            startsAt,
            start_date: (
                <>
                    {startsAt ? moment(startsAt).format(dateFormat) : '-'}
                    {!!isSelfHosted && (
                        <>
                            <br />
                            <strong>
                                <small>(Self-hosted)</small>
                            </strong>
                        </>
                    )}
                </>
            ),
            number_of_participants: (
                <span
                    style={{
                        whiteSpace: 'nowrap'
                    }}
                >
                    Group Size <b>{number_of_participants || '-'}</b>
                </span>
            ),
            number_of_teams: (
                <span
                    style={{
                        whiteSpace: 'nowrap'
                    }}
                >
                    Est. Teams <b>{number_of_teams || '-'}</b>
                </span>
            ),
            status: (
                <StatusBadge
                    type={statusBadges[status]?.type || status}
                    text={statusBadges[status]?.label || status}
                />
            ),
            state: !!state && (
                <StatusBadge
                    type={stateBadges[state]?.type || state}
                    text={stateBadges[state]?.label || state}
                />
            ),
            actions: <Link to={`/events/${id}/view/event-info`}>View event</Link>
        };
    }, []);

    const generateEventDateTitle = useCallback(
        currentDate => {
            let title = `${moment(currentDate).format('dddd')}, ${moment(currentDate).format(
                'MMM'
            )} ${moment(currentDate).format('Do')}`;

            // today
            if (moment(currentDate).isSame(momentToday, 'day')) {
                title = 'Today';
            }

            // tomorrow
            else if (moment(currentDate).isSame(momentTomorrow, 'day')) {
                title = 'Tomorrow';
            }

            // yesterday
            else if (moment(currentDate).isSame(momentYesterday, 'day')) {
                title = 'Yesterday';
            }

            return title;
        },
        [momentToday, momentTomorrow, momentYesterday]
    );

    const generateEventByDates = useCallback((data, reverse = false) => {
        const newData = data.reduce((acc, item) => {
            const key = moment(item?.startsAt).format('MM-DD');
            const hasKey = !!acc.find(current => current.key === key);
            let newAcc = [
                ...acc,
                { key, title: generateEventDateTitle(item?.startsAt), items: [item] }
            ];
            if (hasKey) {
                newAcc = [
                    ...acc.map(current => {
                        return {
                            key: current.key,
                            title: current.title,
                            items:
                                current.key === key
                                    ? [...(current?.items || []), item]
                                    : [...(current?.items || [])]
                        };
                    })
                ];
            }
            return newAcc;
        }, []);
        return reverse ? newData.reverse() : newData;
    }, []);

    const data = useMemo(() => {
        const upcoming = [];
        let upcomingByDates = [];

        const previous = [];
        let previousByDates = [];

        const tableSchema = [
            {
                title: 'Name',
                field: 'name',
                mobile: 'title'
            },
            {
                title: 'Activity',
                field: 'activity',
                mobile: 'hidden'
            },
            {
                title: 'Start',
                field: 'start_date'
            },
            {
                title: 'Group Size',
                field: 'number_of_participants',
                mobile: 'hidden'
            },
            {
                title: 'Est. Teams',
                field: 'number_of_teams',
                mobile: 'hidden'
            },
            {
                title: 'Status',
                field: 'status'
            },
            {
                title: 'State',
                field: 'state'
            },
            {
                title: 'Actions',
                field: 'actions',
                align: 'right'
            }
        ];

        events.forEach(event => {
            // upcoming
            if (
                moment(event[dateKey]).isSame(momentToday, 'day') ||
                moment(event[dateKey]).isAfter(momentToday, 'day')
            ) {
                upcoming.push(generateItemData(event, 'h:mm a'));
            }
            // past/previous
            // you can check with: moment(event[dateKey]).isBefore()
            else {
                previous.push(generateItemData(event, 'h:mm a'));
            }
        });

        if (previous?.length > 0) previousByDates = generateEventByDates(previous, true);

        if (upcoming?.length > 0) upcomingByDates = generateEventByDates(upcoming);

        return {
            upcoming: {
                data: upcoming,
                schema: tableSchema
            },
            previous: {
                data: previous,
                schema: tableSchema
            },
            previousByDates,
            upcomingByDates,
            tableSchema
        };
    }, [events]);

    return fetching ? (
        <ElementLoader overlay />
    ) : (
        <div className={styles.main}>
            <nav className="tabs tabs--with-border-bottom">
                <ul className="tabs__list">
                    <li className="tabs__list-item">
                        <button
                            type="button"
                            onClick={() => setTabPosition('upcoming')}
                            className={`tabs__link ${
                                tabPosition === 'upcoming' ? 'tabs__link--active' : ''
                            }`}
                        >
                            Upcoming
                        </button>
                    </li>
                    <li className="tabs__list-item">
                        <button
                            type="button"
                            onClick={() => setTabPosition('previous')}
                            className={`tabs__link ${
                                tabPosition === 'previous' ? 'tabs__link--active' : ''
                            }`}
                        >
                            Previous
                        </button>
                    </li>
                </ul>
            </nav>

            <div className={styles.listing__inner}>
                {tabPosition === 'upcoming' && (
                    <>
                        {data?.upcomingByDates?.length > 0 &&
                            data.upcomingByDates.map(item => {
                                const name = `EventWidgetListingUpcoming__${item.key}`;
                                return (
                                    <div key={name} className={styles.listing}>
                                        <span className={styles.listing__title}>{item.title}</span>
                                        <Listing
                                            name={name}
                                            reducerName={name}
                                            table={{
                                                data: item.items,
                                                schema: data?.tableSchema
                                            }}
                                            hideFooter
                                            hideHeader
                                        />
                                    </div>
                                );
                            })}
                        {data?.upcomingByDates?.length === 0 && <p>No upcoming events.</p>}
                    </>
                )}

                {tabPosition === 'previous' && (
                    <>
                        {data?.previousByDates?.length > 0 &&
                            data.previousByDates.map(item => {
                                const name = `EventWidgetListingPrevious__${item.key}`;
                                return (
                                    <div key={name} className={styles.listing}>
                                        <span className={styles.listing__title}>{item.title}</span>
                                        <Listing
                                            name={name}
                                            reducerName={name}
                                            table={{
                                                data: item.items,
                                                schema: data?.tableSchema
                                            }}
                                            hideFooter
                                            hideHeader
                                        />
                                    </div>
                                );
                            })}
                        {data?.previousByDates?.length === 0 && (
                            <p>No events in the last two weeks.</p>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

EventsWidgetListing.defaultProps = {
    fetching: true,
    events: [],
    dates: {
        now: null,
        start: null,
        end: null
    },
    dateKey: 'starts_at'
};

EventsWidgetListing.propTypes = {
    fetching: PropTypes.bool,
    events: PropTypes.oneOfType([PropTypes.array]),
    dates: PropTypes.oneOfType([PropTypes.object]),
    dateKey: PropTypes.string
};

export default React.memo(EventsWidgetListing);
