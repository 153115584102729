const salaryType = [
    {
        label: 'Hourly',
        value: 'Hourly'
    },
    {
        label: 'Daily',
        value: 'Daily'
    },
    {
        label: 'Weekly',
        value: 'Weekly'
    },
    {
        label: 'Bi-Weekly',
        value: 'Bi-Weekly'
    },
    {
        label: 'Monthly',
        value: 'Monthly'
    },
    {
        label: 'Yearly',
        value: 'Yearly'
    }
];

const salaryPayoutCycle = [
    {
        label: 'Weekly',
        value: 'Weekly'
    },
    {
        label: 'Bi-Weekly',
        value: 'Bi-Weekly'
    },
    {
        label: 'Monthly',
        value: 'Monthly'
    },
    {
        label: 'Yearly',
        value: 'Yearly'
    }
];

const currency = [
    {
        label: 'CAD',
        value: 'CAD'
    },
    {
        label: 'USD',
        value: 'USD'
    },
    {
        label: 'EUR',
        value: 'EUR'
    },
    {
        label: 'HRK',
        value: 'HRK'
    }
];

export { salaryType, salaryPayoutCycle, currency };
