import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { useDispatch } from 'react-redux';
import Form from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';
import Svg from 'erpcore/components/Svg';
import Tooltip from 'erpcore/components/Tooltip';
import Modal from 'erpcore/components/Modal';
import { actions as entityChallengesActions } from 'erpcore/screens/Challenges/screens/EntityChallenges/EntityChallenges.reducer';

const EntityChallengeDelete = ({
    entityChallengeIri,
    handleSubmit,
    submitting,
    callback,
    entity
}) => {
    const dispatch = useDispatch();
    const [opened, setOpened] = useState(false);
    const entityChallenge = entityChallengeIri;

    const handleModal = () => {
        setOpened(!opened);
    };

    const onSubmit = () => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: entityChallengesActions.START_DELETE_ENTITY_CHALLENGE,
                iri: entityChallenge
            })
        )
            .then(() => {
                handleModal();
                callback();
            })
            .catch(error => {
                handleModal();
                return error;
            });
    };

    return (
        <Fragment>
            <Tooltip content={`Delete ${entity} challenge`}>
                <button type="button" onClick={() => handleModal()}>
                    <Svg icon="remove" />
                </button>
            </Tooltip>
            {opened && (
                <Modal
                    variation="small"
                    opened={opened}
                    onClose={() => handleModal()}
                    title={`Delete ${entity} challenge`}
                    subtitle={`Are you sure you want to delete this ${entity} challenge?`}
                >
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Button submit loading={submitting} label="Delete" />
                        <Button
                            variation="secondary"
                            label="Cancel"
                            onClick={() => handleModal()}
                        />
                    </Form>
                </Modal>
            )}
        </Fragment>
    );
};

EntityChallengeDelete.defaultProps = {
    submitting: false,
    callback: () => {}
};

EntityChallengeDelete.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    entityChallengeIri: PropTypes.string.isRequired,
    callback: PropTypes.func,
    entity: PropTypes.string.isRequired
};

export default reduxForm({
    form: 'ActivityChallengeDeleteForm',
    enableReinitialize: true
})(EntityChallengeDelete);
