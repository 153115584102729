export const dateTypeConstraint = [
    {
        label: 'Full date',
        value: 'FULL_DATE'
    },
    {
        label: 'Day + Month',
        value: 'DAY_AND_MONTH'
    },
    {
        label: 'Year',
        value: 'YEAR'
    }
];

export const dateTypeMonths = [
    {
        label: 'January',
        value: 1
    },
    {
        label: 'February',
        value: 2
    },
    {
        label: 'March',
        value: 3
    },
    {
        label: 'April',
        value: 4
    },
    {
        label: 'May',
        value: 5
    },
    {
        label: 'June',
        value: 6
    },
    {
        label: 'July',
        value: 7
    },
    {
        label: 'August',
        value: 8
    },
    {
        label: 'September',
        value: 9
    },
    {
        label: 'October',
        value: 10
    },
    {
        label: 'November',
        value: 11
    },
    {
        label: 'December',
        value: 12
    }
];

export const dateTypeDays = (() => {
    const days = [];

    for (let i = 1; i < 32; i += 1) {
        days.push({ label: i, value: i });
    }

    return days;
})();

export const cantBeTimed = ['TYPE_VIDEO', 'TYPE_QR', 'TYPE_PHOTO', 'TYPE_STICKER_PHOTO'];
