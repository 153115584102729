export const actions = {
    START_FETCHING_ENTITY_CHALLENGES: 'START_FETCHING_ENTITY_CHALLENGES',
    FETCHING_SUCCESSFUL_ENTITY_CHALLENGES: 'FETCHING_SUCCESSFUL_ENTITY_CHALLENGES',
    FETCHING_FAILED_ENTITY_CHALLENGES: 'FETCHING_FAILED_ENTITY_CHALLENGES',

    START_FETCHING_ENTITY_DATA: 'START_FETCHING_ENTITY_DATA',
    FETCHING_ENTITY_DATA_SUCCESSFUL: 'FETCHING_ENTITY_DATA_SUCCESSFUL',
    FETCHING_ENTITY_DATA_FAILED: 'FETCHING_ENTITY_DATA_FAILED',

    START_DELETE_ENTITY_CHALLENGE: 'START_DELETE_ENTITY_CHALLENGE',
    DELETE_ENTITY_CHALLENGE_SUCCESSFUL: 'DELETE_ENTITY_CHALLENGE_SUCCESSFUL',
    DELETE_ENTITY_CHALLENGE_FAILED: 'DELETE_ENTITY_CHALLENGE_FAILED',

    START_CREATE_ENTITY_CHALLENGE: 'START_CREATE_ENTITY_CHALLENGE',
    CREATE_ENTITY_CHALLENGE_SUCCESSFUL: 'CREATE_ENTITY_CHALLENGE_SUCCESSFUL',
    CREATE_ENTITY_CHALLENGE_FAILED: 'CREATE_ENTITY_CHALLENGE_FAILED',

    START_FETCHING_ENTITY_CHALLENGE: 'START_FETCHING_ENTITY_CHALLENGE',
    FETCH_ENTITY_CHALLENGE_SUCCESSFUL: 'FETCH_ENTITY_CHALLENGE_SUCCESSFUL',
    FETCH_ENTITY_CHALLENGE_FAILED: 'FETCH_ENTITY_CHALLENGE_FAILED',
    STORE_ENTITY_CHALLENGE_DATA: 'STORE_ENTITY_CHALLENGE_DATA',

    START_UPDATE_ENTITY_CHALLENGE: 'START_UPDATE_ENTITY_CHALLENGE',
    UPDATE_ENTITY_CHALLENGE_SUCCESSFUL: 'UPDATE_ENTITY_CHALLENGE_SUCCESSFUL',
    UPDATE_ENTITY_CHALLENGE_FAILED: 'UPDATE_ENTITY_CHALLENGE_FAILED',

    START_UPDATE_ENTITY_CHALLENGE_POSITIONS: 'START_UPDATE_ENTITY_CHALLENGE_POSITIONS',
    UPDATE_ENTITY_CHALLENGE_POSITIONS_SUCCESSFUL: 'UPDATE_ENTITY_CHALLENGE_POSITIONS_SUCCESSFUL',
    UPDATE_ENTITY_CHALLENGE_POSITIONS_FAILED: 'UPDATE_ENTITY_CHALLENGE_POSITIONS_FAILED',

    START_BATCH_EXPORT: 'START_BATCH_EXPORT',

    CLEAR_STATE: 'CLEAR_STATE'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: [],
    singleFetching: false,
    singleError: null,
    deleteSingleError: null,
    isPositionUpdating: false
};

export default (state = initialState, { type, response, iri }) => {
    switch (type) {
        case actions.START_FETCHING_ENTITY_CHALLENGES: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.START_FETCHING_ENTITY_CHALLENGE: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCH_ENTITY_CHALLENGE_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.START_FETCHING_ENTITY_DATA: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCHING_ENTITY_DATA_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false,
                [iri]: response
            };
        }
        case actions.STORE_ENTITY_CHALLENGE_DATA: {
            return {
                ...state,
                [iri]: response?.data
            };
        }
        case actions.START_UPDATE_ENTITY_CHALLENGE_POSITIONS: {
            return {
                ...state,
                isPositionUpdating: true
            };
        }
        case actions.START_UPDATE_ENTITY_CHALLENGE: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.UPDATE_ENTITY_CHALLENGE_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.UPDATE_ENTITY_CHALLENGE_POSITIONS_SUCCESSFUL: {
            return {
                ...state,
                isPositionUpdating: false
            };
        }
        case actions.UPDATE_ENTITY_CHALLENGE_POSITIONS_FAILED: {
            return {
                ...state,
                isPositionUpdating: false
            };
        }
        case actions.FETCH_ENTITY_CHALLENGE_FAILED: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCHING_SUCCESSFUL_ENTITY_CHALLENGES: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_ENTITY_CHALLENGES: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.DELETE_ENTITY_CHALLENGE_FAILED: {
            return {
                ...state,
                deleteSingleError: response
            };
        }

        case actions.UPDATE_ENTITY_CHALLENGE_FAILED: {
            return {
                ...state,
                singleError: response,
                singleFetching: false
            };
        }

        case actions.CLEAR_STATE: {
            return {
                ...initialState
            };
        }

        default:
            return state;
    }
};
