import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import restClient from 'erpcore/api/restClient';
import PropTypes from 'prop-types';
import Modal from 'erpcore/components/Modal';
import Button from 'erpcore/components/Button';
import Form, { FileUploader } from 'erpcore/components/Form';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import styles from './ImportParticipantsModal.module.scss';

const ImportParticipantsModal = ({ isOpened, eventIri, onClose, onSuccess }) => {
    const dispatch = useDispatch();

    const [opened, setOpened] = useState(false);
    const [sampleFetching, setSampleFetching] = useState(false);

    const handleModal = () => {
        setOpened(!opened);
        if (opened && onClose && typeof onClose === 'function') onClose();
    };

    useEffect(() => {
        setOpened(isOpened);
    }, [isOpened, setOpened]);

    const downloadSample = () => {
        setSampleFetching(true);
        restClient
            .get('/api/events/import-participants-example')
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'sample.xlsx');
                link.click();
            })
            .catch(() => {
                dispatch({
                    type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                    response: { code: 'error500' }
                });
            })
            .finally(() => setSampleFetching(false));
    };

    return (
        <Modal
            opened={opened}
            variation="medium"
            onClose={() => handleModal()}
            title="Import participants"
        >
            <Form>
                <div className={styles.block}>
                    <p>1. Download sample import file</p>
                    <div className={styles.block__inner}>
                        <Button
                            label="Download .XLSX"
                            iconName="download"
                            variation="secondary"
                            size="small"
                            onClick={downloadSample}
                            loading={sampleFetching}
                        />
                    </div>
                </div>
                <div className={styles.block}>
                    <p>2. Fill the data</p>
                </div>
                <div className={styles.block}>
                    <p>3. Upload import file by dragging &amp; dropping or by browsing file</p>
                </div>
                <Form.Row>
                    <Field
                        name="files"
                        id="files"
                        fieldProps={{
                            allowedExtensions: ['xlsx'],
                            endpoint: `${eventIri}/import-participants`,
                            inputName: 'qqfile',
                            headers: { Accept: 'text/xlsx' },
                            onComplete: response => {
                                if (response?.success === false) {
                                    const notificationData = {
                                        code: 'generalError'
                                    };

                                    if (response?.message) {
                                        notificationData.detail = response.message;
                                    } else if (response?.errors) {
                                        notificationData.detail = response.errors;
                                    } else if (response?.title) {
                                        notificationData.detail = response.title;
                                    }

                                    dispatch({
                                        type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                                        response: notificationData
                                    });
                                }

                                if (response?.success === true) {
                                    dispatch({
                                        type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                                        response: { code: 'eventparticipant.importSuccess' }
                                    });
                                    if (onSuccess && typeof onSuccess === 'function') onSuccess();
                                }

                                // close modal
                                handleModal();
                            }
                        }}
                        component={FileUploader}
                    />
                </Form.Row>
            </Form>
        </Modal>
    );
};

ImportParticipantsModal.defaultProps = {
    isOpened: false,
    onSuccess: () => {}
};

ImportParticipantsModal.propTypes = {
    isOpened: PropTypes.bool,
    eventIri: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func
};

export default reduxForm({
    form: 'ImportParticipantsForm',
    enableReinitialize: true
})(ImportParticipantsModal);
