import React, { useCallback, useState } from 'react';
import moment from 'moment-timezone';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import PageContent from 'erpcore/components/Layout/PageContent';
import Listing from 'erpcore/components/Listing';
import isImage from 'is-image';
import { useDispatch, useSelector } from 'react-redux';
import {
    getListingFetching,
    getListingResponse,
    getQueryParams
} from 'erpcore/components/Listing/Listing.selectors';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import TableActions from 'erpcore/components/Listing/components/TableActions';
import {
    formatBytes,
    getImageSrcFromMediaObject,
    getFileExtension,
    isVideo
} from 'erpcore/components/ImageManager';
import Modal from 'erpcore/components/Modal';
import imagePlaceholder from 'erpcore/assets/images/image-placeholder.png';
import Lightbox from 'react-image-lightbox';
import Svg from 'erpcore/components/Svg';
import Tooltip from 'erpcore/components/Tooltip';
import Tag from 'erpcore/components/Tag';
import MediaItemTags from 'erpcore/components/Form/components/Media/components/MediaItemTags';
import MediaLibraryFileEdit from 'erpcore/screens/MediaLibrary/components/MediaLibraryFileEdit';
import MediaLibraryEdit from '../../components/MediaLibraryEdit';
import MediaLibraryDelete from '../../components/MediaLibraryDelete';
import MediaLibraryBulkDelete from '../../components/MediaLibraryBulkDelete';
import styles from './MediaLibraryListing.module.scss';

const perPageOptions = [18, 30, 60, 90, 'All results'];

const MediaLibraryListing = () => {
    const dispatch = useDispatch();

    const [lightBoxMediaData, setLightBoxMediaData] = useState(null);

    const [videoUrl, setVideoUrl] = useState(null);

    const title = 'Media Library';
    const listingReducerName = 'mediaLibrary';
    const listingActionName = 'MEDIA_LIBRARY';

    const listing = useSelector(state => getListingResponse(state, listingReducerName));
    const listingFetching = useSelector(state => getListingFetching(state, listingReducerName));
    const listingParams = useSelector(state => getQueryParams(state, { name: listingReducerName }));

    const endpoint =
        'api/media-objects?include=versions,createdBy,tags&filters[parent][not_exists]=true&filters[visible][equals]=true';

    const fetchMedia = params => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                entity: listingActionName,
                type: listingActions.START_FETCHING_LISTING,
                name: listingReducerName,
                params: {
                    limit: perPageOptions[0],
                    ...params
                },
                endpoint
            });
        }).catch(error => {
            return error;
        });
    };

    const generateLightBoxImages = useCallback(
        id => {
            const lightBoxData = {
                currentUrl: '',
                nextUrl: '',
                previousUrl: '',
                previousID: null,
                nextID: null
            };

            const imagesOnly = listing?.data?.reduce((images, currentImage) => {
                if (isImage(currentImage.content_url)) {
                    return [...images, currentImage];
                }
                return [...images];
            }, []);

            // set current
            lightBoxData.currentUrl = imagesOnly.find(image => image.id === id).content_url;
            const currentIndex = imagesOnly.findIndex(image => image.id === id);

            // set previous and next
            if (imagesOnly.length > 1) {
                // set previous
                if (imagesOnly[currentIndex - 1]) {
                    lightBoxData.previousUrl = imagesOnly[currentIndex - 1].content_url;
                    lightBoxData.previousID = imagesOnly[currentIndex - 1].id;
                } else {
                    lightBoxData.previousUrl = imagesOnly[imagesOnly.length - 1].content_url;
                    lightBoxData.previousID = imagesOnly[imagesOnly.length - 1].id;
                }
                // set next
                if (imagesOnly[currentIndex + 1]) {
                    lightBoxData.nextUrl = imagesOnly[currentIndex + 1].content_url;
                    lightBoxData.nextID = imagesOnly[currentIndex + 1].id;
                } else {
                    lightBoxData.nextUrl = imagesOnly[0].content_url;
                    lightBoxData.nextID = imagesOnly[0].id;
                }
            }

            setLightBoxMediaData(lightBoxData);
        },
        [listing]
    );

    const tableData = () => {
        const table = {};
        table.data = [];

        table.bulkActions = {
            name: listingReducerName,
            apiRoute: endpoint,
            actions: [
                {
                    label: 'Delete media objects',
                    renderComponent: (
                        <MediaLibraryBulkDelete
                            reducerName={listingReducerName}
                            onComplete={() => fetchMedia(listingParams)}
                            batchRequestEndpoint="/api/batch-requests/media-objects"
                        />
                    )
                }
            ]
        };

        table.schema = [
            {
                title: 'File',
                field: 'filename',
                mobile: 'title'
            },
            {
                title: 'File type',
                field: 'type'
            },
            {
                title: 'Size',
                field: 'size'
            },
            {
                title: 'Tags',
                field: 'tags'
            },
            {
                title: 'Author',
                field: 'author'
            },
            {
                title: 'Date added',
                field: 'created_at',
                sortable: 'created_at'
            },
            {
                title: 'Actions',
                field: 'actions'
            }
        ];

        table.defaultSort = {
            order: 'DESC',
            sortable: 'id'
        };

        if (listing.data) {
            listing.data.map(row => {
                const {
                    id,
                    iri,
                    meta,
                    created_at: createdAt,
                    created_by: createdBy,
                    content_url: contentUrl,
                    tags
                } = {
                    ...row
                };
                const { first_name: authorFirstName = '', last_name: authorLastName = '' } = {
                    ...createdBy
                };
                const { filename, size, title: fileTitle } = { ...meta };

                const smallImageUrl = getImageSrcFromMediaObject(row, 'small');
                const fileExtension = getFileExtension(contentUrl).toUpperCase();

                let actionType = 'download';
                if (isVideo(fileExtension.toLowerCase())) {
                    actionType = 'video';
                } else if (fileExtension === 'PDF' || fileExtension === 'TXT') {
                    actionType = 'view';
                }

                return table.data.push({
                    id,
                    iri,
                    filename: (
                        <div className={styles['listing-picture']}>
                            {isImage(contentUrl) ? (
                                <div
                                    className={`${styles['listing-picture__image']} ${
                                        !smallImageUrl
                                            ? styles['listing-picture__image--placeholder']
                                            : ''
                                    }`}
                                >
                                    <img src={smallImageUrl || imagePlaceholder} alt={title} />
                                </div>
                            ) : (
                                <div
                                    data-file-type={fileExtension.toLowerCase()}
                                    className={styles['listing-picture__image']}
                                >
                                    <span>{fileExtension}</span>
                                </div>
                            )}
                            <div className={styles['listing-picture__content']}>
                                {fileTitle && <h4>{fileTitle}</h4>}
                                <p>{filename}</p>
                            </div>
                        </div>
                    ),
                    type: fileExtension,
                    size: formatBytes(size),
                    tags: !tags?.length ? null : (
                        <Tag.Wrapper>
                            {tags.map(item => (
                                <Tag>{item?.name}</Tag>
                            ))}
                        </Tag.Wrapper>
                    ),
                    author: `${authorFirstName} ${authorLastName}`,
                    created_at: moment(createdAt).format('MMM Do, h:mm a'),
                    gridView: {
                        title: filename,
                        subtitle: formatBytes(size),
                        bottom: (
                            <>
                                {fileExtension.toLowerCase()}
                                {tags?.length && (
                                    <MediaItemTags
                                        style={{ float: 'right', marginTop: '-4px' }}
                                        tags={tags}
                                    />
                                )}
                            </>
                        ),
                        extension: fileExtension.toLowerCase(),
                        background: isImage(contentUrl) ? smallImageUrl : null,
                        actions: true
                    },
                    actions: (
                        <TableActions>
                            {isImage(contentUrl) ? (
                                <TableActions.Action>
                                    <Tooltip content="View media">
                                        <button
                                            type="button"
                                            onClick={() => generateLightBoxImages(id)}
                                        >
                                            <Svg icon="search" />
                                        </button>
                                    </Tooltip>
                                </TableActions.Action>
                            ) : (
                                <TableActions.Action>
                                    {actionType === 'video' && (
                                        <Tooltip content="Play video">
                                            <button
                                                type="button"
                                                onClick={() => setVideoUrl(contentUrl)}
                                            >
                                                <Svg icon="search" />
                                            </button>
                                        </Tooltip>
                                    )}
                                    {actionType === 'view' && (
                                        <Tooltip content="View media">
                                            <a
                                                target="_blank"
                                                href={contentUrl}
                                                rel="noopener noreferrer"
                                            >
                                                <Svg icon="search" />
                                            </a>
                                        </Tooltip>
                                    )}
                                    {actionType === 'download' && (
                                        <Tooltip content="Download media">
                                            <a
                                                download={filename}
                                                target="_blank"
                                                href={contentUrl}
                                                rel="noopener noreferrer"
                                            >
                                                <Svg icon="download" />
                                            </a>
                                        </Tooltip>
                                    )}
                                </TableActions.Action>
                            )}
                            {isImage(contentUrl) ? (
                                <TableActions.Action>
                                    <MediaLibraryEdit
                                        imageIri={iri}
                                        onSaveImage={() => fetchMedia(listingParams)}
                                    />
                                </TableActions.Action>
                            ) : (
                                <TableActions.Action>
                                    <MediaLibraryFileEdit
                                        iri={iri}
                                        onSave={() => fetchMedia(listingParams)}
                                    />
                                </TableActions.Action>
                            )}
                            <TableActions.Action>
                                <MediaLibraryDelete
                                    iri={iri}
                                    form={`mediaLibraryItemDelete__${id}`}
                                    onComplete={() => fetchMedia(listingParams)}
                                />
                            </TableActions.Action>
                        </TableActions>
                    )
                });
            });
        }

        return table;
    };

    return (
        <LayoutManager slot="main" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title} />
            <PageContent>
                <Listing
                    hideFilters
                    showMediaUpload
                    showLayoutOptions
                    name={listingReducerName}
                    reducerName={listingReducerName}
                    loading={listingFetching}
                    meta={listing?.meta}
                    table={tableData()}
                    onListingConfigUpdate={params => fetchMedia(params)}
                    onMediaUpload={() => fetchMedia(listingParams)}
                    layout="list"
                    perPageOptions={perPageOptions}
                />
                {lightBoxMediaData && listing?.data?.length > 0 && (
                    <Lightbox
                        prevSrc={lightBoxMediaData?.previousUrl || ''}
                        mainSrc={lightBoxMediaData?.currentUrl || ''}
                        nextSrc={lightBoxMediaData?.nextUrl || ''}
                        onCloseRequest={() => setLightBoxMediaData(null)}
                        onMovePrevRequest={() => {
                            if (lightBoxMediaData.previousID !== null)
                                generateLightBoxImages(lightBoxMediaData.previousID);
                        }}
                        onMoveNextRequest={() => {
                            if (lightBoxMediaData.nextID !== null)
                                generateLightBoxImages(lightBoxMediaData.nextID);
                        }}
                    />
                )}
                {videoUrl && (
                    <Modal
                        opened
                        className="video-modal"
                        variation="transparent"
                        onClose={() => setVideoUrl(null)}
                    >
                        <div className="video-modal__container">
                            <video controls autoPlay>
                                <source src={videoUrl} />
                            </video>
                        </div>
                    </Modal>
                )}
            </PageContent>
        </LayoutManager>
    );
};

export default MediaLibraryListing;
