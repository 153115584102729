import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { DateRangePicker } from 'react-dates';
import { getResponsive } from 'erpcore/utils/utils';
import Input from '../Input';
import 'react-dates/lib/css/_datepicker.css';
import './DateRange.scss';

const DateRange = ({ input, meta, fieldProps, fieldAttr, field }) => {
    const defaultDateFormat = 'M/D/YYYY';

    const { name, value } = input;
    const { dateFormat, keyStart, keyEnd, minimumNights, arraySupport } = fieldProps;
    const { required, disabled, startId, endId } = fieldAttr;

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [focusedInput, setFocusedInput] = useState(null);
    const { isMobile } = getResponsive();
    const [preset, setPreset] = useState(false);

    function onDateChange(dates) {
        if (dates.startDate && dates.endDate && !arraySupport) {
            // create empty object as fallback
            const reduxKeys = {};
            // add first key to the object
            reduxKeys[keyStart || 'start'] = moment(dates.startDate).format(
                dateFormat || defaultDateFormat
            );
            // add second key to the object
            reduxKeys[keyEnd || 'end'] = moment(dates.endDate).format(
                dateFormat || defaultDateFormat
            );
            // dispatch to redux form
            input.onChange(reduxKeys);
        } else if (dates.startDate && dates.endDate && arraySupport) {
            // create empty array as fallback
            const reduxArray = [];
            // push start date
            reduxArray.push(moment(dates.startDate).format(dateFormat || defaultDateFormat));
            // push end date
            reduxArray.push(moment(dates.endDate).format(dateFormat || defaultDateFormat));
            // dispatch to redux form
            input.onChange(reduxArray);
            //  reseting preset
        } else {
            // set empty/error value
            input.onChange(null);
        }
        setStartDate(dates.startDate || null);
        setEndDate(dates.endDate || null);
        setPreset('');
    }

    function onFocusChange(focusValue) {
        setFocusedInput(focusValue);
    }

    useEffect(() => {
        if (focusedInput) {
            input.onFocus();
        } else if (!focusedInput && meta.visited) {
            // clear field if both values are not present
            if (!startDate || !endDate) {
                setStartDate(null);
                setEndDate(null);
            }
            input.onBlur();
        }
    }, [focusedInput]);

    useEffect(() => {
        if (!value) {
            setStartDate(null);
            setEndDate(null);
        }
        if (value) {
            if (Object.prototype.toString.call(value) === '[object Array]') {
                if (value[0]) setStartDate(moment(value[0]));
                if (value[1]) setEndDate(moment(value[1]));
            }
            if (Object.prototype.toString.call(value) === '[object Object]') {
                if (value[keyStart || 'start']) setStartDate(moment(value[keyStart || 'start']));
                if (value[keyEnd || 'end']) setEndDate(moment(value[keyEnd || 'end']));
            }
        }
    }, [value]);

    useEffect(() => {
        if (value) {
            if (Object.prototype.toString.call(value) === '[object Array]') {
                if (value[0]) setStartDate(moment(value[0]));
                if (value[1]) setEndDate(moment(value[1]));
            }
            if (Object.prototype.toString.call(value) === '[object Object]') {
                if (value[keyStart || 'start']) setStartDate(moment(value[keyStart || 'start']));
                if (value[keyEnd || 'end']) setEndDate(moment(value[keyEnd || 'end']));
            }
        }
    }, []);

    const renderDateRangePresets = () => {
        const handlePresetChange = event => {
            const { value: presetValue } = event.target;
            if (presetValue === 'last7Days') {
                onDateChange({ startDate: moment().subtract(7, 'days'), endDate: moment() });
            } else if (presetValue === 'last30Days') {
                onDateChange({ startDate: moment().subtract(30, 'days'), endDate: moment() });
            } else if (presetValue === 'last365Days') {
                onDateChange({ startDate: moment().subtract(365, 'days'), endDate: moment() });
            } else if (presetValue === 'currentWeek') {
                onDateChange({
                    startDate: moment().startOf('week'),
                    endDate: moment().endOf('week')
                });
            } else if (presetValue === 'currentMonth') {
                onDateChange({
                    startDate: moment().startOf('month'),
                    endDate: moment().endOf('month')
                });
            } else if (presetValue === 'currentYear') {
                onDateChange({
                    startDate: moment().startOf('year'),
                    endDate: moment().endOf('year')
                });
            } else if (presetValue === 'startOfWeek') {
                onDateChange({
                    startDate: moment().startOf('week'),
                    endDate: moment()
                });
            } else if (presetValue === 'startOfMonth') {
                onDateChange({
                    startDate: moment().startOf('month'),
                    endDate: moment()
                });
            } else if (presetValue === 'startOfYear') {
                onDateChange({
                    startDate: moment().startOf('year'),
                    endDate: moment()
                });
            } else if (presetValue === 'endOfWeek') {
                onDateChange({
                    startDate: moment(),
                    endDate: moment().endOf('week')
                });
            } else if (presetValue === 'endOfMonth') {
                onDateChange({
                    startDate: moment(),
                    endDate: moment().endOf('month')
                });
            } else if (presetValue === 'endOfYear') {
                onDateChange({
                    startDate: moment(),
                    endDate: moment().endOf('year')
                });
            } else if (presetValue === 'previousWeek') {
                onDateChange({
                    startDate: moment()
                        .subtract(1, 'weeks')
                        .startOf('week'),
                    endDate: moment()
                        .subtract(1, 'weeks')
                        .endOf('week')
                });
            } else if (presetValue === 'previousMonth') {
                onDateChange({
                    startDate: moment()
                        .subtract(1, 'months')
                        .startOf('month'),
                    endDate: moment()
                        .subtract(1, 'months')
                        .endOf('month')
                });
            } else if (presetValue === 'previousYear') {
                onDateChange({
                    startDate: moment()
                        .subtract(1, 'years')
                        .startOf('year'),
                    endDate: moment()
                        .subtract(1, 'years')
                        .endOf('year')
                });
            } else if (presetValue === 'allTime') {
                onDateChange({
                    startDate: moment()
                        .subtract(100, 'years')
                        .startOf('year'),
                    endDate: moment()
                });
            }
            setPreset(presetValue);
        };

        return (
            <div className="daterange-presets">
                <select
                    onChange={handlePresetChange}
                    className="daterange-presets__select"
                    value={preset}
                >
                    <option value="">Date range preset</option>
                    {/* <option value="last365Days">Last 365 Days</option> */}
                    <option value="currentWeek">This week</option>
                    <option value="currentMonth">This month</option>
                    {/* <option value="currentYear">Current Year</option> */}
                    {/* <option value="startOfWeek">From Start of the Week</option> */}
                    {/* <option value="startOfMonth">From Start of the Month</option> */}
                    {/* <option value="startOfYear">From Start of the Year</option> */}
                    {/* <option value="endOfWeek">Till End of the Week</option> */}
                    {/* <option value="endOfMonth">Till End of the Month</option> */}
                    {/* <option value="endOfYear">Till End of the Year</option> */}
                    <option value="previousWeek">Last week</option>
                    <option value="previousMonth">Last month</option>
                    {/* <option value="previousYear">Previous Year</option> */}
                    <option value="last7Days">Last 7 days</option>
                    <option value="last30Days">Last 30 days</option>
                    <option value="allTime">All time</option>
                </select>
            </div>
        );
    };

    return (
        <Input
            fieldProps={fieldProps}
            fieldAttr={fieldAttr}
            field={field}
            input={input}
            meta={meta}
            className="input--daterange"
        >
            <DateRangePicker
                appendToBody={false}
                block
                disabled={disabled}
                disableScroll={false}
                endDate={endDate}
                endDateAriaLabel="Select end date"
                endDateId={endId || `${name}EndDate`}
                endDatePlaceholderText=""
                focusedInput={focusedInput}
                hideKeyboardShortcutsPanel
                isOutsideRange={() => false}
                minimumNights={minimumNights || 0}
                noBorder
                numberOfMonths={isMobile ? 1 : 2}
                onDatesChange={onDateChange}
                onFocusChange={onFocusChange}
                required={required}
                startDate={startDate}
                startDateAriaLabel="Select start date"
                startDateId={startId || `${name}StartDate`}
                startDatePlaceholderText=""
                renderCalendarInfo={renderDateRangePresets}
            />
        </Input>
    );
};

DateRange.defaultProps = {
    fieldProps: {},
    fieldAttr: {},
    field: {},
    input: {},
    meta: {}
};

DateRange.propTypes = {
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    field: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object])
};

export default DateRange;
