export const entityNotificationsUnits = [
    { value: 'seconds', label: 'Seconds' },
    { value: 'minutes', label: 'Minutes' },
    { value: 'hours', label: 'Hours' }
];

export const entityNotificationsTriggerTypes = [
    { value: 'AFTER_START', label: 'After the start' },
    { value: 'BEFORE_END', label: 'Before the end' },
    { value: 'AT_THE_END', label: 'When event ends' }
];
