import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
    getImage,
    getImageFetchingData,
    getImageUrl
} from 'erpcore/components/ImageManager/ImageManager.selectors';
import { actions as imageManagerActions } from 'erpcore/components/ImageManager/ImageManager.reducer';

const Video = ({ iri, version, width, height, className, controls, onLoad }) => {
    const dispatch = useDispatch();
    const videoRef = useRef();
    const video = useSelector(state => getImage(state, iri));

    const videoUrl = useSelector(state => getImageUrl(state, iri, version, 'video'));

    const videoFetchingData = useSelector(state => getImageFetchingData(state));

    const fetchImage = () => {
        return new Promise((resolve, reject) => {
            let fetchNeeded = false;

            if (iri && !videoFetchingData?.[iri]) {
                if (video) {
                    if (video?.iri !== iri) {
                        fetchNeeded = true;
                    }
                } else {
                    fetchNeeded = true;
                }
            }

            if (fetchNeeded) {
                dispatch({
                    promise: { resolve, reject },
                    type: imageManagerActions.START_FETCHING_IMAGE,
                    iri
                });
            }
        });
    };

    useEffect(() => {
        if (iri) {
            fetchImage(iri);
        }
    }, [iri]);

    useEffect(() => {
        if (onLoad && videoRef.current) {
            videoRef.current.addEventListener('loadeddata', onLoad);

            return () => {
                videoRef.current.removeEventListener('loadeddata', onLoad);
            };
        }

        return () => {};
    }, []);

    return (
        <video
            ref={videoRef}
            controls={controls}
            className={className}
            src={videoUrl}
            width={width}
            height={height}
        />
    );
};

Video.defaultProps = {
    iri: null,
    version: null,
    width: 100,
    height: 100,
    className: null,
    controls: true,
    onLoad: null
};

Video.propTypes = {
    controls: false,
    iri: PropTypes.string,
    version: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
    onLoad: PropTypes.func
};

export default Video;
