import React from 'react';
import { reduxForm, Field } from 'redux-form';
import Form, { Autocomplete, Select } from 'erpcore/components/Form';
import { useRouteMatch } from 'react-router-dom';
import { eventFeedStatus } from 'erpcore/screens/Events/Events.data';

const EventFeedFilters = () => {
    const match = useRouteMatch();
    const id = match?.params?.id;
    const iri = `/api/events/${id}`;

    return (
        <Form>
            <Form.Columns>
                <Form.Column>
                    <Field
                        name="teams"
                        fieldAttr={{ id: 'teams' }}
                        fieldProps={{
                            label: 'Team',
                            options: {
                                endpoint: `/api/event-teams?filters[event][equals]=${iri}`,
                                mapData: {
                                    label: 'name',
                                    value: 'iri'
                                }
                            }
                        }}
                        component={Autocomplete}
                    />
                </Form.Column>

                <Form.Column>
                    <Field
                        name="type"
                        fieldAttr={{ id: 'type' }}
                        fieldProps={{
                            label: 'Challenge type',
                            options: {
                                endpoint: `/api/challenge-types`,
                                mapData: {
                                    label: 'name',
                                    value: 'iri'
                                }
                            }
                        }}
                        component={Autocomplete}
                    />
                </Form.Column>

                <Form.Column>
                    <Field
                        name="status"
                        fieldAttr={{ id: 'status' }}
                        fieldProps={{
                            label: 'Status',
                            options: eventFeedStatus
                        }}
                        component={Select}
                    />
                </Form.Column>
            </Form.Columns>
        </Form>
    );
};

export default reduxForm({ form: 'EventFeedFiltersForm', enableReinitialize: true })(
    EventFeedFilters
);
