import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ElementLoader from 'erpcore/components/ElementLoader';
import Svg from 'erpcore/components/Svg';
import SortableItem from 'erpcore/components/Form/components/Media/components/SortableItem';
import isImage from 'is-image';
import {
    getImageVersion,
    getFileExtension,
    formatBytes,
    isVideo
} from 'erpcore/components/ImageManager';
import Modal from 'erpcore/components/Modal';

const MediaItem = ({
    data,
    index,
    isFeatured,
    canFeature,
    canSort,
    canDelete,
    useImageManager,
    useLightbox,
    versionName,
    setFeatured,
    setLightboxImageIri,
    setModalEditorOpened,
    setModalDeleteIriOpened,
    uploadProgress,
    removeTempMedia
}) => {
    const [videoUrl, setVideoUrl] = useState(null);

    const key = data.iri || data.tempId;
    const { isNewGalleryItem = false, tempId } = data;

    const mediaIsImage = !!(data.raw || isImage(data.content_url));

    const style = {};

    if (mediaIsImage) {
        const editedImageUrl = getImageVersion({
            imageData: data,
            versionName,
            getProperty: 'content_url'
        });

        let imageUrl = '';
        if (editedImageUrl) {
            imageUrl = editedImageUrl;
        } else if (data.content_url_modified || data.content_url) {
            imageUrl = data.content_url_modified || data.content_url;
        } else {
            imageUrl = data.raw;
        }

        style.backgroundImage = `url(${imageUrl})`;
    }

    const fileExtension = getFileExtension(data.content_url).toLowerCase();

    const getProgress = () => {
        if (
            tempId &&
            uploadProgress &&
            uploadProgress[tempId] !== undefined &&
            uploadProgress[tempId].progress !== undefined &&
            (uploadProgress[tempId].progress || uploadProgress[tempId].progress === 0)
        ) {
            const { status = 'in-progress' } = { ...uploadProgress?.[tempId] };

            const messagesMap = {
                'in-progress': null,
                success: 'Image successfully uploaded!',
                fail: 'Image upload failed!'
            };

            return (
                <div className={`gallery__item-progress gallery__item-progress--${status}`}>
                    <div
                        className="gallery__item-progress-bar"
                        style={{ height: `${100 - uploadProgress?.[tempId]?.progress}%` }}
                    />
                    <div className="gallery__item-progress-loader">
                        <ElementLoader />
                    </div>
                    <span
                        className="gallery__item-progress-value"
                        title={messagesMap[status] !== undefined ? messagesMap[status] : null}
                    >
                        {status === 'in-progress' && `${uploadProgress?.[tempId]?.progress}%`}
                        {status === 'success' && (
                            <Svg className="gallery__item-progress-value-icon" icon="checkmark" />
                        )}
                        {status === 'fail' && (
                            <Svg className="gallery__item-progress-value-icon" icon="close" />
                        )}
                    </span>
                </div>
            );
        }

        return null;
    };

    return (
        <SortableItem
            key={key}
            collection={isNewGalleryItem ? 'new' : 'api'}
            disabled={!canSort || isNewGalleryItem}
            index={index}
            isFeatured={isFeatured}
            isImage={mediaIsImage}
        >
            <div className="gallery__item-inner">
                <div className="gallery__item-content" style={style}>
                    {!mediaIsImage && (
                        <span
                            className="gallery__item-file-type"
                            data-file-type={fileExtension}
                            aria-label={fileExtension}
                        />
                    )}
                    {!mediaIsImage && (data?.meta?.filename || data?.meta?.size) && (
                        <div className="gallery__item-information">
                            {data?.meta?.filename && (
                                <div className="gallery__item-information-item">
                                    {data.meta.filename}
                                </div>
                            )}
                            {(!!data?.meta?.size || data?.meta?.size === 0) && (
                                <div className="gallery__item-information-item gallery__item-information-item--small">
                                    {formatBytes(data.meta.size)}
                                </div>
                            )}
                        </div>
                    )}
                    <div className="gallery__item-actions">
                        {!isNewGalleryItem && (
                            <React.Fragment>
                                {!!canFeature && (
                                    <button
                                        type="button"
                                        className={`gallery__item-action${
                                            data[isFeatured] ? ' gallery__item-action--active' : ''
                                        }`}
                                        onClick={() => setFeatured(data.iri, !data[isFeatured])}
                                    >
                                        <Svg
                                            className="gallery__item-action-icon"
                                            icon="checkmark"
                                        />
                                    </button>
                                )}
                                {!mediaIsImage &&
                                    data.content_url &&
                                    (isVideo(fileExtension) ? (
                                        <button
                                            type="button"
                                            className="gallery__item-action"
                                            onClick={() => setVideoUrl(data.content_url)}
                                        >
                                            <Svg
                                                className="gallery__item-action-icon"
                                                icon="search"
                                            />
                                        </button>
                                    ) : (
                                        <a
                                            href={data.content_url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="gallery__item-action"
                                        >
                                            <Svg
                                                className="gallery__item-action-icon"
                                                icon={
                                                    fileExtension === 'pdf' ? 'search' : 'download'
                                                }
                                            />
                                        </a>
                                    ))}
                                {!!mediaIsImage && !!useLightbox && (
                                    <button
                                        type="button"
                                        className="gallery__item-action"
                                        onClick={() => setLightboxImageIri(data.iri)}
                                    >
                                        <Svg className="gallery__item-action-icon" icon="search" />
                                    </button>
                                )}
                                {!!mediaIsImage && !!useImageManager && (
                                    <button
                                        type="button"
                                        className="gallery__item-action"
                                        onClick={() => setModalEditorOpened(data.iri)}
                                    >
                                        <Svg className="gallery__item-action-icon" icon="edit" />
                                    </button>
                                )}
                                {!!canDelete && (
                                    <button
                                        type="button"
                                        className="gallery__item-action"
                                        onClick={() => setModalDeleteIriOpened(data.iri)}
                                    >
                                        <Svg className="gallery__item-action-icon" icon="remove" />
                                    </button>
                                )}
                            </React.Fragment>
                        )}
                        {!!isNewGalleryItem && (
                            <React.Fragment>
                                {!!tempId && uploadProgress?.[tempId]?.status === 'fail' && (
                                    <button
                                        type="button"
                                        className="gallery__item-action"
                                        onClick={() => removeTempMedia(tempId)}
                                    >
                                        <Svg className="gallery__item-action-icon" icon="remove" />
                                    </button>
                                )}
                            </React.Fragment>
                        )}
                    </div>
                    {isNewGalleryItem && getProgress()}
                </div>
            </div>
            {videoUrl && (
                <Modal
                    opened
                    className="video-modal"
                    variation="transparent"
                    onClose={() => setVideoUrl(null)}
                >
                    <div className="video-modal__container">
                        <video controls autoPlay>
                            <source src={videoUrl} />
                        </video>
                    </div>
                </Modal>
            )}
        </SortableItem>
    );
};

MediaItem.defaultProps = {
    data: {},
    index: null,
    isFeatured: false,
    canFeature: false,
    canSort: true,
    canDelete: true,
    useImageManager: true,
    useLightbox: false,
    versionName: 'small',
    setFeatured: () => {},
    setLightboxImageIri: () => {},
    setModalEditorOpened: () => {},
    setModalDeleteIriOpened: () => {},
    removeTempMedia: () => {},
    uploadProgress: null
};
MediaItem.propTypes = {
    data: PropTypes.oneOfType([PropTypes.object]),
    index: PropTypes.number,
    isFeatured: PropTypes.bool,
    canFeature: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    canSort: PropTypes.bool,
    canDelete: PropTypes.bool,
    useImageManager: PropTypes.bool,
    useLightbox: PropTypes.bool,
    versionName: PropTypes.string,
    setFeatured: PropTypes.func,
    setLightboxImageIri: PropTypes.func,
    setModalEditorOpened: PropTypes.func,
    setModalDeleteIriOpened: PropTypes.func,
    removeTempMedia: PropTypes.func,
    uploadProgress: PropTypes.oneOfType([PropTypes.object])
};

export default MediaItem;
